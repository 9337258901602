/**
 * CLFB IO view module.
 * @module parameter
 */

"use strict";

import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Help from '../core/help';
import * as Helpers from '../core/helpers';
import * as Permissions from '../core/permissions';
import * as State from '../core/state';
import {View} from '../core/view';

export class ClfbIoView extends View {
    constructor() {
        super();
        this.viewName = 'ClfbIoView';
        this.clfb = null;
        this.recording = false;
        this.recordedData = [];
        this.loadingTimeout = -1;
    }

    destructor() {
        super.destructor();
    }

    record(actionId, args, elem) {
        if(this.recording) {
            Debug.log('Finish recording, uid: ' + actionId);
            this.recording = false;
            Debug.log(this.recordedData);

            const timeZoneOffset = State.getTimeZoneOffset();
            const use24Hours = State.get24HoursIsPreferred();

            // TODO record both input and output
            this.recordedData.map(x => {
                x.unshift(
                    Helpers.formatDateTime(
                        x[0], timeZoneOffset, use24Hours, false
                    )
                );
            });

            let csv = Helpers.generateCsv(
                ['Date/time', 'Timestamp', 'Input Value', 'Output Value'],
                this.recordedData
            );

            this.recordedData = [];
            $(elem).html('Record changes');
            $(elem).removeClass('btn-recording').addClass('btn-record');

            return saveAs(
                new File([csv],
                    'changes-' + Helpers.makeFileName(this.clfb.name) + '.csv',
                    {type: 'text/csv;charset=utf-8'}
                )
            );
        } else {
            this.recording = true;
            Debug.log('Record parameter changes, uid-qid: ' + actionId);

            $(elem).html('Stop recording and download data');
            $(elem).removeClass('btn-record').addClass('btn-recording');
        }
    }

    registerActionHandlers(){
        Debug.log('Register action handlers', 0, false);

        const id = this.clfb.uid;

        this.registerActionHandler(
            id, 'record', this.record.bind(this)
        );

    }

    /**
     * Handler for when this CLFB is removed
     */
    objectRemoved(msg) {
        if(
            !msg.hasOwnProperty('data') ||
            !msg.data.hasOwnProperty('uid') ||
            msg.data.uid !== this.clfb.uid
        ) {
            return false;
        }
        window.visit('/#/');
    }

    onStatusChange(msg) {
        if(msg.data.uid !== this.clfb.uid){
            return;
        }
        this.clfb.status = msg.data.status;
        Gui.disableLoadingIcons();
        window.clearTimeout(this.loadingTimeout);

        Gui.switchClfbValue(
            this.clfb.uid,
            'input',
            this.clfb.status.inputValue
        );

        Gui.switchClfbValue(
            this.clfb.uid,
            'output',
            this.clfb.status.outputValue
        );

        if(this.recording) {
            this.recordedData.push([
                Date.now(),
                this.clfb.status.inputValue,
                this.clfb.status.outputValue,
            ]);
        }
    }

    /**
     * Render this parameter view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args) {
        if(!args.hasOwnProperty('uid')){
            return Gui.show404();
        }

        Debug.log(
            'Render I/O view for CLFB ' +
            args.uid
        );

        this.Api.getClfb(args.uid).then((clfb) => {
            this.clfb = clfb;
            Debug.log('CLFB info:');
            Debug.log(this.clfb);

            this.addListener(
                'clfb/status',
                this.onStatusChange.bind(this)
            );

            this.addListener(
                'object/removed',
                this.objectRemoved.bind(this)
            );

            this.registerActionHandlers();

            let context = {
                'clfb': this.clfb,
            };

            Gui.render('clfb-io.html', context);
        }).catch((e) => {
            Debug.log('Error rendering CLFB I/O screen', 3);
            Debug.log(e, 3);
            return Gui.soft404(_('Could not find CLFB'));
        });
    }
}
