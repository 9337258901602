/**
 * Debug Wait module
 * @module DebugWait
 */

"use strict";

export class Wait {
    constructor(amount) {
        this.amount = amount;
    }

    wait(callback) {
        window.setTimeout(callback, this.amount);
    }
}

export class WaitUnless {
    constructor(amount, check) {
        this.amount = amount;
        this.check = check;
    }

    waitUnless(callback) {
        let intervalId = -1;
        let timeoutId = -1;

        timeoutId = window.setTimeout(() => {
            if(intervalId !== -1) {
                clearInterval(intervalId);
            }
            return callback();
        }, this.amount);

        intervalId = window.setInterval(() => {
            if(this.check()) {
                if(intervalId !== -1) {
                    clearInterval(intervalId);
                }
                if(timeoutId !== -1) {
                    clearTimeout(timeoutId);
                }
                return callback();
            }
        }, 5000);
    }
}
