/**
 * Fieldbus view module.
 * @module fieldbus
 */

"use strict";

import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as Gui from '../core/gui';
import * as Debug from '../core/debug';
import * as Permissions from '../core/permissions';
import * as Signals from '../core/signals';
import * as State from '../core/state';
import * as Helpers from '../core/helpers';
import * as Help from '../core/help';
import {RelatedView} from './related';

export class FieldbusView {
    constructor() {
        this.viewName = 'FieldbusView';
        this.fieldbus = null;
        this.stateListenerId = -1;
        this.settingsListenerId = -1;
        this.actionHandlerIds = [];
        this.loadingTimeout = -1;
        this.relatedView = null;
    }

    destructor() {
        if(this.stateListenerId !== -1){
            Signals.removeListener(this.stateListenerId);
        }
        if(this.settingsListenerId !== -1) {
            Signals.removeListener(this.settingsListenerId);
        }
        if(this.relatedView !== null) {
            this.relatedView.destructor();
        }
        Actions.removeActionHandlers(this.actionHandlerIds);
        window.clearTimeout(this.loadingTimeout);
    }

    onStatusChange(msg) {
        if(msg.data.uid !== this.fieldbus.uid){
            return;
        }
        this.fieldbus.status = msg.data.status;
        Gui.disableLoadingIcons();
        window.clearTimeout(this.loadingTimeout);

        // Special case where we disable the export action when the fieldbus
        // !== 'running'
        if(this.fieldbus.status.mode === 'running') {
            $('a[data-id=' + this.fieldbus.uid + '-' + 'export]').removeClass('disabled');
        } else {
            $('a[data-id=' + this.fieldbus.uid + '-' + 'export]').addClass('disabled');
        }
    }

    onSettingsChange(msg) {
        if(msg.data.fieldbus !== this.fieldbus.uid) {
            return;
        }
        this.fieldbus.settings = msg.data.settings;

        for(let setting in this.fieldbus.settings) {
            if(!this.fieldbus.settings.hasOwnProperty(setting)) {
                continue;
            }

            Gui.switchSetting(
                this.fieldbus.uid,
                setting,
                this.fieldbus.settings[setting]
            );
        }
    }

    fieldbusEnableDisable(actionId, args, elem) {
        /** @type {(uid: string) => Promise<any>} */
        let apiCall;

        if(Helpers.arrayContains(
            this.fieldbus.status.mode,
            ['running', 'waiting', 'starting']
        )) {
            if(!Permissions.canPerformAction(this.fieldbus, 'disable')){
                return;
            }
            apiCall = Api.fieldbusDisable;
        } else {
            if(!Permissions.canPerformAction(this.fieldbus, 'enable')){
                return;
            }
            apiCall = Api.fieldbusEnable;
        }

        Gui.disableLoadingIcons();
        window.clearTimeout(this.loadingTimeout);
        Gui.enableLoadingIcon(elem);

        apiCall(actionId).then(() => {
            this.loadingTimeout = window.setTimeout(
                Gui.disableLoadingIcons,
                10000
            );
        }).catch((err) => {
            Gui.disableLoadingIcon(elem);
        });
    }

    toggleHelp() {
        Gui.toggleHelpSection();
    }

    closeHelp() {
        Gui.closeHelpSection();
    }

    registerActionHandlers() {
        Debug.log('Register action handlers', 0, false);

        const id = this.fieldbus.uid;

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
               id, 'fieldbus-enable-disable', this.fieldbusEnableDisable.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'toggle-help', this.toggleHelp.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'close-help', this.closeHelp.bind(this)
            )
        );
    }

    render(event, args) {
        Debug.log('Render view for fieldbus: ' + args.uid, 0);

        if(!args.hasOwnProperty('uid')){
            return Gui.show404();
        }

        Api.getFieldbus(args.uid).then((fieldbus) => {
            Debug.log('Retrieved fieldbus');
            Debug.log(fieldbus);
            this.fieldbus = fieldbus;
            this.relatedView = new RelatedView(this.fieldbus);
            this.registerActionHandlers();

            this.stateListenerId = Signals.addListener(
                'fieldbus/status',
                this.onStatusChange.bind(this)
            );

            this.settingsListenerId = Signals.addListener(
                'fieldbus/settings',
                this.onSettingsChange.bind(this)
            );

            let context = {
                'fieldbus': this.fieldbus,
                'helpText': '',
            };

            Help.fieldbus().then((helpText) => {context.helpText = helpText;})
            .catch(() => {}).finally(() => {
                Gui.render('fieldbus.html', context);
                this.relatedView.render();
            });
        }).catch((e) => {
            Debug.log('Error rendering fieldbus screen', 3);
            Debug.log(e, 3);
            return Gui.soft404(_('Could not find fieldbus'));
        });
    }
}
