"use strict";

export const VERSION = '0.0.1';

export const USER_LEVELS = [
    'basic',
    'advanced',
    'expert',
    'admin',
    'service',
    'factory',
    'engineering',
];

// Maximum amount of related objects
export const MAX_RELATED_OBJECTS = 84;

// Log flush timeout in seconds. This controls how often the Debugger sends
// system logs to the API logs/system endpoint
export const LOG_FLUSH_TIMEOUT = 60;

// After this many milliseconds the client tries to reconnect to the websocket
// after losing the connection (or not being able to connect at all)
export const WEBSOCKET_TIMEOUT = 10000;

// Interval in milliseconds for sending ping messages to the websocket in
export const PING_INTERVAL = 5000;

export const TIMEZONE_OFFSET = new Date().getTimezoneOffset();

export const REPORT_URL = window.CONFIG.reportUrl ? window.CONFIG.reportUrl : 'https://config-db.simco-cloud.nl/report';
// Help path used in case the current screen does not have a linked help
export const DEFAULT_HELP_PATH = '/#/help/Operation/Getting_to_know_the_system';

// PIDs that represent sensors
export const SENSORS = ['117', '125'];

// SEGMENT PARAMETER PREFIXES
export const SEGMENT_PREFIXES = {
    '117': 'SENSOR_HIRES_WEB_VOLTAGE_SEG',
    '125': 'SENSOR_EX_HIRES_WEB_VOLTAGE_SEG',
};

// Whether specific devices, or fieldbuses/clfbs have dashboards
export const HAS_DASHBOARD_DEFAULTS = {
    devices: Object.keys(window.CONSTANTS.PARAMETERS).filter(pid => {
        const hasDashboardParams =
            Object.keys(window.CONSTANTS.PARAMETERS[pid]).filter(qid => {
                const param = window.CONSTANTS.PARAMETERS[pid][qid];
                return param.dashboard.showOnDashboard;
            }).length > 0;

        const hasDashboardActions =
            Object.keys(window.CONSTANTS.ACTIONS.device[pid]).filter(actionName => {
                const action = window.CONSTANTS.ACTIONS.device[pid][actionName];
                return action.dashboard.showOnDashboard;
            }).length > 0;

        return hasDashboardParams || hasDashboardActions;
    }),

    fieldbus: (
        window.CONSTANTS.SETTINGS.fieldbus.filter(setting => {
            return setting.dashboard.showOnDashboard;
        }).length > 0 ||
        Object.keys(window.CONSTANTS.ACTIONS.fieldbus).filter(actionName => {
            const action = window.CONSTANTS.ACTIONS.fieldbus[actionName];
            return action.dashboard.showOnDashboard;
        }).length > 0
    ),

    clfb: (
        window.CONSTANTS.SETTINGS.clfb.filter(setting => {
            return setting.dashboard.showOnDashboard;
        }).length > 0 ||
        Object.keys(window.CONSTANTS.ACTIONS.clfb).filter(actionName => {
            const action = window.CONSTANTS.ACTIONS.clfb[actionName];
            return action.dashboard.showOnDashboard;
        }).length > 0
    ),
};

export const TIME_ZONES = {
    '-720': "UTC−12:00",
    '-660': "UTC−11:00",
    '-600': "UTC−10:00",
    '-570': "UTC−09:30",
    '-540': "UTC−09:00",
    '-480': "UTC−08:00",
    '-420': "UTC−07:00",
    '-360': "UTC−06:00",
    '-300': "UTC−05:00",
    '-240': "UTC−04:00",
    '-210': "UTC−03:30",
    '-180': "UTC−03:00",
    '-120': "UTC−02:00",
    '-60':  "UTC−01:00",
    '0':    "UTC±00:00",
    '60':   "UTC+01:00",
    '120':  "UTC+02:00",
    '180':  "UTC+03:00",
    '210':  "UTC+03:30",
    '240':  "UTC+04:00",
    '270':  "UTC+04:30",
    '300':  "UTC+05:00",
    '330':  "UTC+05:30",
    '345':  "UTC+05:45",
    '360':  "UTC+06:00",
    '390':  "UTC+06:30",
    '420':  "UTC+07:00",
    '480':  "UTC+08:00",
    '525':  "UTC+08:45",
    '540':  "UTC+09:00",
    '570':  "UTC+09:30",
    '600':  "UTC+10:00",
    '630':  "UTC+10:30",
    '660':  "UTC+11:00",
    '720':  "UTC+12:00",
    '765':  "UTC+12:45",
    '780':  "UTC+13:00",
    '840':  "UTC+14:00",
};
