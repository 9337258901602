/**
 * Login view module.
 * @module login
 */

"use strict";

import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Messages from '../core/messages';
import * as Session from '../core/session';
import * as State from '../core/state';
import {View} from '../core/view';

export class LoginView extends View {
    constructor() {
        super();
        this.viewName = 'LoginView';
    }

    destructor() {
        super.destructor();
    }

    /**
     * Render this login view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args) {
        if(Session.loggedin) {
            return window.visit('/#/');
        }

        if(Session.getAccessToken() === false) {
            Gui.hideIcons();
        }

        if(event.type === 'submit') {
            const username = Helpers.inputVal('username');
            const password = Helpers.inputVal('password');
            const language = Helpers.inputVal('language');
            if(typeof language === 'string') {
                Session.setLanguage(language);
            }

            if(typeof username !== 'string' || typeof password !== 'string') {
                Debug.log('Unexpected login error, wrong username or password type', 3);
                return $('section.login p.error').html(_('Invalid login, please try again')).fadeIn();
            }

            this.Api.login(username, password).then((data) => {
                Session.processLogin(data.accessToken);
                Debug.log('User logged in successfully', 1);
                Debug.log(Session.getLoginData());

                if(password === '') {
                    const loginData = Session.getLoginData();
                    let messageUrl = '';

                    if(loginData && loginData.hasOwnProperty('userId')){
                        messageUrl = '/#/user/' + loginData.userId;
                    }

                    Messages.addMessage(
                        _('You have logged in with an empty password. Click here to change it.'),
                        'warning',
                        0,
                        messageUrl
                    );
                }

                return State.initState().then(() => {
                    Gui.showIcons();
                    Gui.updateUserName(Session.getLoginData());
                    Gui.showStatusList();

                    if(data.hasOwnProperty('activeUsers') && data.activeUsers.length > 0) {
                        return Gui.render('active-users.html', {activeUsers: data.activeUsers});
                    } else {
                        return window.visit('/#/');
                    }
                }).catch((err) => {
                    Debug.log('Error initializing state after login', 3);
                    Debug.log(err, 3);
                    Gui.render('error.html', {
                        'message': 'Failed to initialize the state'
                    });
                });
            }).catch((err) => {
                Debug.log('Error logging in user', 3);
                Debug.log(err, 3);
                $('section.login p.error').html(_('Invalid login, please try again')).fadeIn();
            });

        } else {
            let context = {};
            Gui.render('login.html', context);
        }
    }

    /**
     * Render password reset screen
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    renderPasswordReset(event, args) {
        if(event.type === 'submit') {
            const username = Helpers.inputVal('username');
            const key = Helpers.inputVal('key');
            if(typeof username !== 'string' || typeof key !== 'string') {
                Debug.log('Failed to reset password, wrong key or username', 3);
                Gui.render('password-reset.html', {
                    errorMessage: _('Failed to reset the password. Please check the username and recovery key.')
                });
                return;
            }

            this.Api.passwordReset(key, username).then((result) => {
                Gui.render('password-reset.html', {
                    'id': result.id,
                    'password': result.password,
                    'username': username,
                });
            }).catch((err) => {
                Debug.log('Failed to reset password', 3);
                Gui.render('password-reset.html', {
                    errorMessage: _('Failed to reset the password. Please check the username and recovery key.')
                });
            });
        } else {
            Gui.render('password-reset.html');
        }
    }
}
