/**
 * Debug View Module
 * @module DebugView
 */

"use strict";

import * as Gui from '../core/gui';
import * as State from '../core/state';
import * as Record from './record';

let debugShown = false;

export function init() {
    $('body').append(Gui.renderToString('debug.html'));
}

export function toggleDebugOverlay() {
    debugShown = !debugShown;
    if(debugShown) {
        $('section.debug').show();
    } else {
        $('section.debug').hide();
    }
}

function render(html) {
    $('section.debug-content').html(html).show();
}

export function showLog() {
    render(Gui.renderToString('debug-log.html'));
}

export function showState() {
    render(Gui.renderToString('debug-state.html'));
}

export function showReport() {
    render(Gui.renderToString('debug-report.html'));
}

export function showTest() {
    render(Gui.renderToString('debug-test.html', {
        recordedTests: State.getRecordedTests(),
        testName: Record.getTestName(),
        isRecording: Record.isRecording(),
    }));
}

export function closeCurrentScreen() {
    render('');
    $('section.debug-content').hide();
}
