"use strict";

let translations = {
    // IQ4 Text for event WARNING_MASK_24V_OUTPUT_OVERLOAD,
    '24v output overload': '24V 出力　過負荷',

    // ../src/templates/chart.html,
    '48h chart': '48時間チャート',

    // ../src/templates/time.html,
    'AM/PM': '午前/午後',

    // ../src/templates/log.html,
    'Actions': 'アクション',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,src/templates/setting.html,
    'Actual value': '実際の値',

    // ../src/templates/user.html,
    'Add new user': 'ユーザー追加',

    // ../src/templates/related-add.html,
    'Add related objects': 'オブジェクト追加',

    //     // IQ4 Text for parameter CLEANFLEX_AIR_PRESSURE,IQ4 Text for parameter BLOWFLEX_AIR_PRESSURE,
    'Air pressure': 'エア圧',

    // IQ4 Text for parameter CLEANFLEX_TRIGGER_LEVEL,IQ4 Text for parameter BLOWFLEX_TRIGGER_LEVEL,
    'Air pressure trigger level high voltage': '高電圧出力発生エア圧',

    // IQ4 Text for parameter GATEWAY_ALARM_SETPOINT,IQ4 Text for parameter GATEWAY_EX_ALARM_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_ALARM_SETPOINT,
    'Alarm level, measured value over maximum': '最大値超過アラームレベル',

    // IQ4 Text for parameter GATEWAY_ALARM_MINIMUM,IQ4 Text for parameter GATEWAY_EX_ALARM_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_ALARM_MINIMUM,
    'Alarm level, measured value under minimum': '最小限度値アラームレベル',

    // ../src/templates/log.html,
    'Alarms': 'アラーム',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_VALUE,
    'Ambient temperature': '環境温度',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_AVAILABLE,
    'Ambient temperature sensor available': '環境温度センサー利用可能',

    // ../src/../src/views/related.js,
    'An error occured while creating a new CLFB': 'CLFB構築中にエラー発生',

    // IQ4 Text for parameter PERFORMAX_ANALOG_CLEANBAR_ENABLED,IQ4 Text for parameter EASION_ANALOG_CLEANBAR_ENABLED,IQ4 Text for parameter EASION_ANALOG_CLEANBAR_MODE,IQ4 Text for parameter PERFORMAX_ANALOG_CLEANBAR_MODE,
    'Analog cleanbar enabled': 'クリーンバーアナログ信号有効',

    // IQ4 Text for parameter CM_TINY_ARC_DETECT_COUNTER,IQ4 Text for parameter IQ_COM_ARC_DETECT_COUNTER,IQ4 Text for parameter MPM_ARC_DETECT_COUNTER,
    'Arcs per minute counter': 'アーク/分　カウンター',

    // ../src/templates/user-delete.html,
    'Are you sure you want to delete the user': 'ユーザー消去を実行しますか',

    // IQ4 Text for parameter GATEWAY_AVAILABLE_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_AVAILABLE_SEGMENTS,
    'Available Sensor segments': '利用可能なセンサーセグメント',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Available updates': '利用可能なアップデート',

    // ../src/templates/settings-updates.html,
    'Available version': '利用可能なバージョン',

    // ../src/templates/device.html,
    'Back to top': 'トップに戻る',

    // IQ4 Text for setting CLFB_NAME,
    'CLFB name': 'CLFB名称',

    // Action title for calibrate,
    'Calibrate bar': 'バーのキャリブレーション実行',

    // ../src/../src/views/related.js,
    'Can not delete CLFB output': 'CLFB出力消去不可',

    // ../src/templates/parameter.html,src/templates/setting.html,
    'Cancel': 'キャンセル',

    // IQ4 Text for parameter CMME_CHARGE_SINGLE_SHOT_TIME,IQ4 Text for parameter IQ_COM_CHARGE_SINGLE_SHOT_TIME,
    'Charge pulse single shot': 'チャージングパルス　シングルショット',

    // ../src/templates/settings-install-updates-error.html,src/templates/settings.html,
    'Check available updates': '利用可能なアップデートの確認',

    // IQ4 Text for parameter PERFORMAX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter EASION_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter THUNDERION2_CLEAN_BAR_WARNING_LEVEL,
    'Clean bar warning level': 'クリーンバーワーニングレベル',

    // Action title for clear,
    'Clear action log': 'アクションログのクリア',

    // ../src/../src/views/time.js,src/templates/clfb.html,src/templates/parameter.html,src/templates/device-dashboard.html,src/templates/dashboard.html,src/templates/device.html,src/templates/fieldbus.html,src/templates/setting.html,src/templates/action.html,src/templates/log-table.html,
    'Close': 'クローズ',

    // ../src/templates/log.html,
    'Code': 'コード',

    // ../src/templates/connecting.html,
    'Connecting to websocket, please wait.': 'WebSocket接続中。お待ち下さい。',

    // ../src/templates/log-table.html,
    'Could not retrieve log': 'ログ収集不可',

    // ../src/../src/views/setting.js,
    'Could not save the new settings': '新設定の保存できませんでした',

    // ../src/../src/views/parameter.js,
    'Could not save the new value': '新値の保存できませんでした',

    // Action title for create-related,
    'Create related object': '関連するオブジェクトを作成',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_SETPOINT,
    'Current Control current setpoint': '現在の制御電流セットポイント',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_LIMIT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_LIMIT_SETPOINT,
    'Current Control output voltage limit': '現在の制御電圧出力リミット',

    // ../src/templates/time.html,
    'Current system time in UTC': '現在のUTC時間',

    // ../src/templates/time.html,
    'Current time zone': '現在のタイムゾーン',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Current version': '現在のバージョン',

    // IQ4 Text for parameter CMME_CYCLE_OK_HIGH_DELAY_THRESHOLD,
    'Cycle OK high delay': 'サイクルOK Highディレイ',

    // IQ4 Text for parameter CMME_CYCLE_OK_LOW_LEVEL_THRESHOLD_MNGR4,
    'Cycle OK low level': 'サイクルOK　Lowレベル',

    // ../src/templates/time.html,src/templates/log.html,
    'Date': '日付',

    // ../src/templates/debug.html,
    'Debug': 'デバッグ',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,
    'Default value': 'デフォルト値',

    // ../src/templates/debug-test.html,src/templates/user.html,
    'Delete': '消去',

    // Action title for delete-related,
    'Delete related object': '関連するオブジェクトを消去',

    // ../src/templates/user-delete.html,
    'Delete user': 'ユーザーの消去',

    // IQ4 Text for setting CLFB_DERIVATIVEGAIN,
    'Derivative Gain': '微分ゲイン',

    // ../src/templates/debug-report.html,
    'Description': '説明',

    // IQ4 Text for parameter CM_TINY_DEVICE_NAME,IQ4 Text for parameter CMME_DEVICE_NAME,IQ4 Text for parameter IQ_COM_DEVICE_NAME,IQ4 Text for parameter GATEWAY_DEVICE_NAME,IQ4 Text for parameter GATEWAY_EX_DEVICE_NAME,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_NAME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_DEVICE_NAME,IQ4 Text for parameter EASION_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_EX_DEVICE_NAME,IQ4 Text for parameter THUNDERION2_DEVICE_NAME,IQ4 Text for parameter VICINION_DEVICE_NAME,IQ4 Text for parameter CLEANFLEX_DEVICE_NAME,IQ4 Text for parameter BLOWFLEX_DEVICE_NAME,IQ4 Text for parameter MPM_DEVICE_NAME,IQ4 Text for parameter PERFOMASTER_DEVICE_NAME,
    'Device name': 'デバイス名',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE,IQ4 Text for parameter CMME_DEVICE_TYPE,IQ4 Text for parameter IQ_COM_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_EX_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE,IQ4 Text for parameter EASION_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE,IQ4 Text for parameter VICINION_DEVICE_TYPE,IQ4 Text for parameter CLEANFLEX_DEVICE_TYPE,IQ4 Text for parameter BLOWFLEX_DEVICE_TYPE,IQ4 Text for parameter MPM_DEVICE_TYPE,
    'Device type': 'デバイスタイプ',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE_VERSION,IQ4 Text for parameter CMME_DEVICE_TYPE_VERSION,IQ4 Text for parameter IQ_COM_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE_VERSION,IQ4 Text for parameter EASION_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE_VERSION,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE_VERSION,
    'Device type version': 'デバイスタイプバージョン',

    // Action title for disable,
    'Disable': '無効',

    // Action title for remove,
    'Disconnect': '接続解除',

    // ../src/templates/log.html,
    'Dismiss log': 'ログ消去',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_AVAILABLE,
    'Distance sensor available': '距離センサー利用可能',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_MALFUNCTION,
    'Distance sensor malfunction': '距離センサー異常',

    // IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE_OVERRIDE,
    'Distance sensor override': '',

    //     // ../src/templates/user.html,
    'Edit': '編集',

    // ../src/templates/macros.html,
    'Edit parameter': 'パラメータを編集',

    // Action title for edit-related,
    'Edit related objects': '関連するオブジェクトを編集',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Edit setting': '設定の編集',

    // ../src/templates/user-edit.html,
    'Edit user': 'ユーザーの編集',

    // Action title for enable,
    'Enable': '有効化',

    // IQ4 Text for parameter GATEWAY_ENABLED_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_ENABLED_SEGMENTS,
    'Enabled Sensor segments': '有効なセンサーセグメント',

    // ../src/../src/views/time.js,../src/../src/views/time.js,
    'Error changing the system time': 'システム時間の変更エラー',

    // ../src/../src/views/user.js,
    'Error deleting user': 'ユーザー消去エラー',

    // ../src/templates/settings-install-updates-error.html,
    'Error installing updates': 'アップデートインストールエラー',

    // ../src/../src/views/chart.js,../src/../src/views/table.js,
    'Error retrieving history': 'ヒストリー収集エラー',

    // ../src/../src/views/chart.js,../src/../src/views/table.js,
    'Error retrieving recent history': '直近のヒストリー収集エラー',

    // ../src/../src/views/user.js,../src/../src/views/user.js,
    'Error: failed to create user': 'ユーザ作成エラー',

    // ../src/../src/views/user.js,
    'Error: failed to delete user': 'ユーザー消去エラー',

    // ../src/../src/views/user.js,
    'Error: failed to edit user': 'ユーザー編集エラー',

    // ../src/../src/views/user.js,
    'Error: failed to update user': 'ユーザーのアップデートエラー',

    // ../src/templates/log.html,
    'Events': 'イベント',

    // Action title for export,
    'Export configuration': '構成のエキスポート',

    // ../src/templates/settings.html,
    'Factory name': 'ファクトリー名',

    // ../src/../src/views/log.js,
    'Failed to clear the log': 'ログクリアエラー',

    // ../src/templates/failed-to-connect.html,
    'Failed to connect to the websocket, please check your settings.': 'WebSocket接続エラー。設定確認下さい。',

    // ../src/../src/views/action.js,
    'Failed to perform the action ': 'アクション実行エラー',

    // ../src/../src/views/login.js,../src/../src/views/login.js,
    'Failed to reset the password. Please check the username and recovery key.': 'パスワードリセットエラー。ユーザー名とリカバリーキーを確認下さい。',

    // ../src/../src/views/related.js,
    'Failed to retrieve CLFB': 'CLFBの確立失敗',

    // ../src/../src/views/related.js,
    'Failed to retrieve device': 'デバイス接続失敗',

    // ../src/../src/views/related.js,
    'Failed to retrieve fieldbus': 'フィールドバス確立失敗',

    // ../src/../src/views/related.js,../src/../src/views/related.js,../src/../src/views/related.js,
    'Failed to retrieve the CLFB': 'CLFB確立失敗',

    // ../src/../src/views/related.js,
    'Failed to retrieve the fieldbus': 'フィールドバス確立失敗',

    // ../src/../src/views/related.js,../src/../src/views/related.js,../src/../src/views/related.js,
    'Failed to update the CLFB settings': 'CLFB設定のアップデートエラー',

    // ../src/../src/views/related.js,
    'Failed to update the Fieldbus Map': 'Fieldbusマップのアップデートエラー',

    // IQ4 Text for setting FIELDBUS_NAME,
    'Fieldbus name': 'Fieldbus名',

    // ../src/templates/log.html,
    'Filter': 'フィルター',

    // IQ4 Text for parameter CM_TINY_FIRMWARE_VERSION,IQ4 Text for parameter CMME_FIRMWARE_VERSION,IQ4 Text for parameter IQ_COM_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter SEGMENT_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_FIRMWARE_VERSION,IQ4 Text for parameter EASION_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_EX_FIRMWARE_VERSION,IQ4 Text for parameter THUNDERION2_FIRMWARE_VERSION,IQ4 Text for parameter VICINION_FIRMWARE_VERSION,IQ4 Text for parameter CLEANFLEX_FIRMWARE_VERSION,IQ4 Text for parameter BLOWFLEX_FIRMWARE_VERSION,IQ4 Text for parameter MPM_FIRMWARE_VERSION,IQ4 Text for parameter PERFOMASTER_FIRMWARE_VERSION,
    'Firmware version': 'ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG1,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG1,
    'Firmware version seg1': 'seg1 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG10,
    'Firmware version seg10': 'seg10 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG11,
    'Firmware version seg11': 'seg11 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG12,
    'Firmware version seg12': 'seg12 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG13,
    'Firmware version seg13': 'seg13 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG14,
    'Firmware version seg14': 'seg14 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG15,
    'Firmware version seg15': 'seg15 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG16,
    'Firmware version seg16': 'seg16 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG2,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG2,
    'Firmware version seg2': 'seg2 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG3,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG3,
    'Firmware version seg3': 'seg3 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG4,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG4,
    'Firmware version seg4': 'seg4 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG5,
    'Firmware version seg5': 'seg5 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG6,
    'Firmware version seg6': 'seg6 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG7,
    'Firmware version seg7': 'seg7 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG8,
    'Firmware version seg8': 'seg8 ファームウエアバージョン',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG9,
    'Firmware version seg9': 'seg9 ファームウエアバージョン',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Hide release notes': 'リリースノート非表示',

    // ../src/../src/views/chart.js,../src/../src/views/clfb-io-chart.js,
    'Hide table': 'テーブル非表示',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter CMME_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter MPM_OUTPUT_CURRENT_FEEDBACK,
    'High voltage output current': '高電圧出力電流',

    // IQ4 Text for parameter CMME_OUTPUT_CURRENT_LIMIT_MNGR4,
    'High voltage output current limit': '高電圧出力電流リミット',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_CURRENT_PEAK_MNGR4,
    'High voltage output current peak per cycle': '高電圧出力電流ピーク/サイクル',

    // IQ4 Text for parameter THUNDERION2_OUTPUT_FREQUENCY_SETPOINT,IQ4 Text for parameter VICINION_OUTPUT_FREQUENCY_SETPOINT,
    'High voltage output frequency': '高電圧出力周波数',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter VICINION_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_FEEDBACK,
    'High voltage output voltage': '高電圧出力電圧',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter MPM_HV_OVERLOAD_LEVEL_ALARM,
    'High voltage overload alarm level': '高電圧出力過負荷アラームレベル',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter MPM_HV_OVERLOAD_LEVEL_WARNING,
    'High voltage overload warning level': '高電圧出力過負荷ワーニングレベル',

    // Action title for identify,
    'Identify': '識別する',

    // IQ4 Text for setting CLFB_INPUTSEGMENTS,
    'Input Segments Selection': 'セグメント選択の入力',

    // ../src/templates/clfb.html,src/templates/clfb-io.html,src/templates/clfb-chart.html,
    'Input value': '値の入力',

    // ../src/templates/settings-updates.html,
    'Install all updates': '全アップデートのインストール',

    // IQ4 Text for setting CLFB_INTEGRALDEADBAND,
    'Integral Deadband': '積分デッドバンド',

    // IQ4 Text for setting CLFB_INTEGRALGAIN,
    'Integral Gain': '積分ゲイン',

    // IQ4 Text for setting CLFB_INTEGRALMAX,
    'Integral Maximum': '積分最大値',

    // IQ4 Text for setting CLFB_INTEGRALMIN,
    'Integral Minimum': '積分最小値',

    // IQ4 Text for parameter CM_TINY_INTERNAL_TEMP,IQ4 Text for parameter CMME_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter QID_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE,IQ4 Text for parameter THUNDERION2_INTERNAL_TEMPERATURE,IQ4 Text for parameter VICINION_INTERNAL_TEMPERATURE,IQ4 Text for parameter EASION_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter BLOWFLEX_INTERNAL_TEMPERATURE,IQ4 Text for parameter MPM_INTERNAL_TEMP,IQ4 Text for parameter INTERNAL_TEMPERATURE,
    'Internal temperature': '内部温度',

    // ../src/templates/macros.html,
    'Internal use': '内部利用',

    // ../src/../src/views/login.js,../src/../src/views/login.js,
    'Invalid login, please try again': 'ログイン不可 リトライください',

    // ../src/../src/views/parameter.js,
    'Invalid parameter': '無効なパラメータ',

    // ../src/../src/views/related.js,
    'Invalid related object target': '無効な関連するオブジェクトターゲット',

    // ../src/../src/views/setting.js,
    'Invalid setting': '無効な設定',

    // IQ4 Text for parameter PERFORMAX_BALANCE_OFFSET,IQ4 Text for parameter EASION_BALANCE_OFFSET,IQ4 Text for parameter PERFORMAX_EX_BALANCE_OFFSET,IQ4 Text for parameter THUNDERION2_BALANCE_OFFSET,
    'Ionisation balance offset': 'イオンバランスオフセット',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter EASION_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter THUNDERION2_IONISATION_CURRENT_NEGATIVE,IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEG,
    'Ionisation current negative emitters': '負極イオン化電流',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter EASION_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter THUNDERION2_IONISATION_CURRENT_POSITIVE,IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POS,
    'Ionisation current positive emitters': '正極イオン化電流',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_EFFICIENCY,IQ4 Text for parameter EASION_IONIZATION_EFFICIENCY,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_EFFICIENCY,IQ4 Text for parameter THUNDERION2_IONISATION_EFFICIENCY,
    'Ionisation efficiency': 'イオン発生効率',

    // ../src/templates/login.html,
    'Language': '言語',

    // IQ4 Text for parameter CM_TINY_LAST_ALARM,IQ4 Text for parameter CMME_LAST_ALARM,IQ4 Text for parameter IQ_COM_LAST_ALARM,IQ4 Text for parameter GATEWAY_LAST_ALARM,IQ4 Text for parameter GATEWAY_EX_LAST_ALARM,IQ4 Text for parameter PERFORMAX_LAST_ALARM,IQ4 Text for parameter EASION_LAST_ALARM,IQ4 Text for parameter PERFORMAX_EX_LAST_ALARM,IQ4 Text for parameter THUNDERION2_LAST_ALARM,IQ4 Text for parameter VICINION_LAST_ALARM,IQ4 Text for parameter CLEANFLEX_LAST_ALARM,IQ4 Text for parameter BLOWFLEX_LAST_ALARM,IQ4 Text for parameter MPM_LAST_ALARM,
    'Last alarm date-time': '前回のアラーム発生時刻',

    // IQ4 Text for parameter SEGMENT_4_QID_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_LAST_CALIBRATION_DATE,IQ4 Text for parameter EASION_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_EX_LAST_CALIBRATION_DATE,
    'Last calibration date': '前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG1,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG1,
    'Last calibration date seg1': 'seg1 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG10,
    'Last calibration date seg10': 'seg10 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG11,
    'Last calibration date seg11': 'seg11 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG12,
    'Last calibration date seg12': 'seg12 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG13,
    'Last calibration date seg13': 'seg13 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG14,
    'Last calibration date seg14': 'seg14 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG15,
    'Last calibration date seg15': 'seg15 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG16,
    'Last calibration date seg16': 'seg16 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG2,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG2,
    'Last calibration date seg2': 'seg2 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG3,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG3,
    'Last calibration date seg3': 'seg3 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG4,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG4,
    'Last calibration date seg4': 'seg4 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG5,
    'Last calibration date seg5': 'seg5 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG6,
    'Last calibration date seg6': 'seg6 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG7,
    'Last calibration date seg7': 'seg7 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG8,
    'Last calibration date seg8': 'seg8 前回のキャリブレーション日',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG9,
    'Last calibration date seg9': 'seg9 前回のキャリブレーション日',

    // IQ4 Text for parameter PERFORMAX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter EASION_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter PERFORMAX_EX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter THUNDERION2_LAST_CLEAN_BAR_WARNING,
    'Last clean bar warning date-time': '前回のクリーンバーワーニング時刻',

    // IQ4 Text for parameter CM_TINY_LAST_WARNING,IQ4 Text for parameter CMME_LAST_WARNING,IQ4 Text for parameter IQ_COM_LAST_WARNING,IQ4 Text for parameter GATEWAY_LAST_WARNING,IQ4 Text for parameter GATEWAY_EX_LAST_WARNING,IQ4 Text for parameter PERFORMAX_LAST_WARNING,IQ4 Text for parameter EASION_LAST_WARNING,IQ4 Text for parameter THUNDERION2_LAST_WARNING,IQ4 Text for parameter VICINION_LAST_WARNING,IQ4 Text for parameter CLEANFLEX_LAST_WARNING,IQ4 Text for parameter BLOWFLEX_LAST_WARNING,IQ4 Text for parameter MPM_LAST_WARNING,
    'Last warning date-time': '前回のワーニング時刻',

    // ../src/templates/user-edit.html,src/templates/user-new.html,
    'Level': 'レベル',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,src/templates/debug.html,
    'Log': 'ログ',

    // ../src/templates/log.html,
    'Log for': 'ログ対象',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Loop selection': 'ループ選択',

    // IQ4 Text for parameter CM_TINY_MACHINE_POSITION,IQ4 Text for parameter CMME_MACHINE_POSITION,IQ4 Text for parameter IQ_COM_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_EX_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_MACHINE_POSITION,IQ4 Text for parameter EASION_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_EX_MACHINE_POSITION,IQ4 Text for parameter THUNDERION2_MACHINE_POSITION,IQ4 Text for parameter VICINION_MACHINE_POSITION,IQ4 Text for parameter CLEANFLEX_MACHINE_POSITION,IQ4 Text for parameter BLOWFLEX_MACHINE_POSITION,IQ4 Text for parameter MPM_MACHINE_POSITION,
    'Machine position (location)': '設置個所(位置)',

    // IQ4 Text for event CLFB_INPUT_WATCHDOG_ELAPSED,
    'Make sure the CLFB is being updated. Sensor is connected and running.': 'CLFBがアップデートされセンサーが動作していることを確認下さい。',

    // IQ4 Text for event CLFB_INPUT_DEVICE_NOT_READY,
    'Make sure the input device is available and in RUN mode': 'インプットデバイスが動作中であることを確認下さい',

    // IQ4 Text for event CLFB_OUTPUT_DEVICE_NOT_READY,
    'Make sure the output device is available and in run mode': 'アウトプットデバイスが動作中であることを確認下さい',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MAX_TIME,
    'Max. Charge pulse duration': '最大チャージングパルス時間',

    // IQ4 Text for parameter CM_TINY_MAX_INTERNAL_TEMP,IQ4 Text for parameter CMME_MAX_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter EASION_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter VICINION_MAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter MPM_MAX_INTERNAL_TEMP,
    'Max. internal temperature': '最大内部温度',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_MAX_OLD,
    'Max. primary current': '最大１次側電流',

    // IQ4 Text for parameter CM_TINY_MAX_QUANTITY_ARCS,IQ4 Text for parameter IQ_COM_MAX_QUANTITY_ARCS,
    'Max. quantity arcs per minute': '最大アーク数/分',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,
    'Maximum value': '最大値',

    // ../src/templates/log.html,
    'Message': 'メッセージ',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MIN_TIME,
    'Min. Charge pulse duration': '最小チャージングパルス時間',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,
    'Minimum value': '最小値',

    // IQ4 Text for parameter SENSOR_MOUNTING_DISTANCE,IQ4 Text for parameter SENSOR_EX_MOUNTING_DISTANCE,IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_MOUNTING_DISTANCE,IQ4 Text for parameter EASION_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_EX_MOUNTING_DISTANCE,IQ4 Text for parameter THUNDERION2_MOUNTING_DISTANCE,IQ4 Text for parameter VICINION_MOUNTING_DISTANCE,
    'Mounting distance': '設置距離',

    // ../src/templates/user-edit.html,src/templates/user-new.html,src/templates/settings-updates.html,src/templates/settings.html,
    'Name': '名前',

    // ../src/templates/related-add.html,
    'New Closed Loop Feedback': '新CLFB',

    // ../src/../src/views/log.js,
    'New log entries have been received. Click on the "refresh log" button to update the list.': '新たなログエントリーを受け付けました。"ログ リフレッシュ"でリストをアップデートして下さい。',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'No': 'いいえ',

    // ../src/../src/core/gui.js,
    'Off': 'オフ',

    // ../src/../src/core/gui.js,
    'On': 'オン',

    // IQ4 Text for parameter CM_TINY_OPERATION_MODE,IQ4 Text for parameter CMME_OPERATION_MODE,IQ4 Text for parameter IQ_COM_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_BAR_OPERATION_MODE,IQ4 Text for parameter EASION_BAR_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_EX_BAR_OPERATION_MODE,IQ4 Text for parameter THUNDERION2_BAR_OPERATION_MODE,IQ4 Text for parameter VICINION_BAR_OPERATION_MODE,
    'Operation mode': 'オペレーションモード',

    // IQ4 Text for parameter CMME_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_ALARM_CURRENT_PEAK_MNGR4,
    'Output current alarm limit': '出力電流アラームリミット',

    // IQ4 Text for parameter CMME_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_WARNING_CURRENT_PEAK_MNGR4,
    'Output current peak alarm limit': 'ピーク出力電流アラームリミット',

    // IQ4 Text for parameter EASION_OUTPUT_FREQUENCY_SETPOINT,
    'Output frequency setpoint': '出力周波数セットポイント',

    // ../src/templates/clfb.html,src/templates/clfb-io.html,src/templates/clfb-chart.html,
    'Output value': '出力値',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_MAX,
    'Output voltage max setpoint': '最大出力電圧セットポイント',

    // IQ4 Text for parameter PERFORMAX_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter PERFORMAX_EX_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter PERFORMAX_VSENSE_NEG,
    'Output voltage negative emitters': '負極出力電圧',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_NEGATIVE_SETPOINT,
    'Output voltage negative setpoint': '負極出力電圧セットポイント',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_PEAK_MNGR4,
    'Output voltage peak per cycle': 'ピーク出力電圧/サイクル',

    // IQ4 Text for parameter PERFORMAX_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter PERFORMAX_EX_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter PERFORMAX_VSENSE_POS,
    'Output voltage positive emitters': '正極出力電圧',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_POSITIVE_SETPOINT,
    'Output voltage positive setpoint': '正極出力電圧セットポイント',

    // ../src/templates/404.html,
    'Page could not be found': 'ページが見つかりません',

    // ../src/templates/macros.html,
    'Parameter locked': 'パラメータロック中',

    // ../src/templates/user-edit.html,src/templates/login.html,src/templates/user-new.html,
    'Password': 'パスワード',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Password reset': 'パスワードリセット',

    // ../src/../src/views/parameter.js,
    'Please input a valid number': '有効な値を入力下さい',

    // ../src/templates/parameter.html,
    'Plot 48h chart': '48Hチャートのプロット',

    // ../src/templates/parameter.html,src/templates/clfb-io.html,
    'Plot realtime chart': 'リアルタイムチャートのプロット',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Position': 'ポジション',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CMME_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter THUNDERION2_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CLEANFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter BLOWFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE,
    'Power supply voltage': '供給電圧',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter CMME_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE_WRN_LEVEL,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE_WARNING,
    'Power supply warning level': '供給電圧ワーニングレベル',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter THUNDERION2_PRIMARY_CURRENT,IQ4 Text for parameter CLEANFLEX_PRIMARY_CURRENT,IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT,
    'Primary current': '１次側電流',

    // IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT_CALIBRATION_OFFSET,
    'Primary current calibration offset': '１次側電流補正オフセット',

    // IQ4 Text for setting CLFB_PROPORTIONALGAIN,
    'Proportional Gain': '比例ゲイン',

    // ../src/templates/chart.html,
    'Realtime chart': 'リアルタイムチャート',

    // Action title for reboot,
    'Reboot': 'リブート',

    // ../src/templates/password-reset.html,
    'Recovery key': 'リカバリーキー',

    // ../src/templates/log.html,
    'Refresh log': 'ログのリフレッシュ',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_VALUE,
    'Relative Humidity': '相対湿度',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_AVAILABLE,
    'Relative Humidity sensor available': '湿度センサー利用可能',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Release notes': 'リリースノート',

    // ../src/templates/customize-view.html,
    'Reorder': '並び替え',

    // ../src/templates/debug-report.html,src/templates/debug.html,
    'Report': 'レポート',

    // ../src/templates/password-reset.html,
    'Reset password': 'パスワード リセット',

    // ../src/templates/chart.html,src/templates/clfb-chart.html,
    'Reset zoom': 'ズーム リセット',

    // ../src/templates/debug.html,
    'Restart': '再起動',

    // ../src/../src/core/state.js,
    'Restarting the system, please wait': 'システム再起動中。お待ち下さい。',

    // ../src/../src/core/state.js,
    'Restarting, please wait': '再起動中 お待ち下さい',

    // Action title for restore-factory,
    'Restore factory settings': '設定を工場出荷時に戻す',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Result': '結果',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Run': '作動',

    // ../src/templates/debug-test.html,
    'Run test selection': '',

    // ../src/templates/user-edit.html,src/templates/parameter.html,src/templates/time.html,src/templates/time.html,src/templates/time.html,src/templates/user-new.html,src/templates/setting.html,
    'Save': '保存',

    // IQ4 Text for setting CLFB_SEGMENTSELECTION,
    'Segment Selection Mode': 'セグメント選択モード',

    // ../src/templates/related-select-slot.html,
    'Select fieldbus slot': 'Fieldbusスロットの選択',

    // ../src/templates/debug-report.html,
    'Send': '送る',

    // ../src/templates/debug-test.html,
    'Sequences': '順序',

    // IQ4 Text for event CLFB_SETPOINT_NOT_READY,
    'Set all requested setpoint settings and relations.': '全ての要求されたセットポイントと関連情報を書き込む。',

    // Action title for run,
    'Set device in RUN': 'デバイスを作動させる',

    // ../src/templates/time.html,
    'Set local settings': 'ローカルセッティングを設定する',

    // ../src/templates/time.html,
    'Set new system time in UTC': '新UTCシステム時間の設定',

    // IQ4 Text for parameter BLOWFLEX_SETPOINT_CURRENT,
    'Setpoint current': '電流セットポイント',

    // IQ4 Text for parameter CM_TINY_SETPOINT_VOLTAGE_CLFB,IQ4 Text for parameter IQ_COM_SETPOINT_VOLTAGE_CLFB,IQ4 Text for parameter CMME_SETPOINT_VOLTAGE_CLFB,
    'Setpoint voltage balance for CLFB': 'CLFBの電位バランスセットポイント',

    //     // ../src/templates/macros.html,
    'Setting locked': '設定がロックされました',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Settings': '設定',

    // ../src/templates/log.html,
    'Show all ': '全て表示する',

    // ../src/templates/settings-updates.html,
    'Show current versions': '現在のバージョンを表示する',

    // ../src/templates/log.html,
    'Show dismissed': '',

    // ../src/templates/related-delete.html,src/templates/related-delete.html,src/templates/related-add.html,src/templates/related-select-slot.html,Action title for show-related,
    'Show related objects': '関連するオブジェクトを表示',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Show release notes': 'リリースノートを表示',

    // ../src/../src/views/chart.js,../src/../src/views/clfb-io-chart.js,src/templates/chart.html,src/templates/clfb-chart.html,
    'Show table': 'テーブルを表示',

    // ../src/templates/related-select-slot.html,
    'Slot ': 'スロット',

    // Action title for standby,
    'Standby': 'スタンバイ',

    // ../src/../src/core/state.js,
    'Starting the system, please wait': 'システム起動中 お待ち下さい',

    // ../src/templates/debug-state.html,src/templates/debug.html,
    'State': '状態',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Steps': 'ステップ',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Test': 'テスト',

    // ../src/templates/debug.html,
    'Tests': 'テスト',

    // ../src/../src/views/time.js,
    'The local time settings were changed successfully': 'ローカル時刻設定は変更されました',

    // ../src/templates/password-reset.html,
    'The new password is': '新パスワードは',

    // ../src/templates/password-reset.html,
    'The password for ': 'パスワードの対象',

    // ../src/../src/views/time.js,
    'The system time was changed successfully': 'システム時刻は変更されました',

    // ../src/templates/related-add.html,
    'There are no objects to add.': '追加するオブジェクトがありません。',

    // ../src/templates/related-delete.html,
    'There are no objects to delete.': '消去するオブジェクトがありません。',

    // ../src/templates/related.html,
    'There are no related objects.': '関連するオブジェクトがありません。',

    // ../src/templates/settings-updates.html,
    'There are no updates available': '利用可能なアップデートがありません',

    // ../src/templates/settings-install-updates.html,
    'This might take a while and the system will reboot.': '時間を要す場合がありますがシステムがリブートします。',

    // ../src/templates/settings-confirm-update.html,
    'This will reboot the entire system and cause some downtime.': 'システム全体をリブートするため少々ダウンタイムを生じます。',

    // ../src/templates/time.html,
    'Time': '時間',

    // IQ4 Text for parameter CM_TINY_TIME_RUNNING,IQ4 Text for parameter CMME_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TIME_RUNNING,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_UPTIME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_UPTIME,IQ4 Text for parameter PERFORMAX_TIME_RUNNING,IQ4 Text for parameter EASION_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TIME_RUNNING,IQ4 Text for parameter VICINION_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TIME_RUNNING,IQ4 Text for parameter BLOWFLEX_TIME_RUNNING,IQ4 Text for parameter MPM_TIME_RUNNING,
    'Time running since power up': '電源ON以降の稼働時間',

    // IQ4 Text for parameter CMME_TIME_TO_CYCLE_OK,
    'Time to Cycle OK': 'サイクルOKまでの時間',

    // ../src/templates/time.html,
    'Timezone': 'タイムゾーン',

    // IQ4 Text for parameter GATEWAY_4_QID_TOTAL_RUNNING_TIME,IQ4 Text for parameter SEGMENT_4_QID_TOTAL_RUNNING_TIME,
    'Total running time': 'トータル動作時間',

    // IQ4 Text for parameter CM_TINY_TOTAL_TIME_RUNNING,IQ4 Text for parameter CMME_TOTAL_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TOTAL_TIME_RUNNING,IQ4 Text for parameter VICINION_TOTAL_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TOTAL_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TOTAL_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_TOTAL_TIME_RUNNING,IQ4 Text for parameter EASION_TOTAL_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TOTAL_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TOTAL_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TOTAL_TIME_RUNNING,IQ4 Text for parameter MPM_TOTAL_TIME_RUNNING,
    'Total time running': 'トータル動作時間',

    // IQ4 Text for parameter CM_TINY_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter CMME_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter THUNDERION2_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter VICINION_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter IQ_COM_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter CLEANFLEX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter PERFORMAX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter EASION_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter PERFORMAX_EX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter GATEWAY_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter GATEWAY_EX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter MPM_TOTAL_TIMES_POWERED_ON,
    'Total times powered on': '全パワーON回数',

    // ../src/templates/log.html,src/templates/settings-updates.html,src/templates/settings.html,
    'Type': 'タイプ',

    // ../src/../src/views/device.js,
    'Unexpected error occured while disabling': '無効化中に予期しないエラー発生',

    // ../src/../src/views/device.js,
    'Unexpected error occured while enabling': '有効化中に予期しないエラー発生',

    // IQ4 Text for parameter CM_TINY_UNIQUE_DEVICE_ID,IQ4 Text for parameter CMME_UNIQUE_DEVICE_ID,IQ4 Text for parameter IQ_COM_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter SEGMENT_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_UNIQUE_DEVICE_ID,IQ4 Text for parameter EASION_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter THUNDERION2_UNIQUE_DEVICE_ID,IQ4 Text for parameter VICINION_UNIQUE_DEVICE_ID,IQ4 Text for parameter CLEANFLEX_UNIQUE_DEVICE_ID,IQ4 Text for parameter BLOWFLEX_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFOMASTER_UNIQUE_DEVICE_ID,
    'Unique device ID': '個別デバイスID',

    // ../src/../src/core/gui.js,
    'Unknown': '不明',

    // ../src/../src/views/setting.js,../src/../src/views/setting.js,
    'Unknown IQ Object Type': '不明なIQオブジェクトタイプ',

    // ../src/templates/error.html,
    'Unknown error': '不明なエラー',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Unknown object': '不明なオブジェクト',

    // ../src/templates/settings-confirm-update.html,Action title for update,
    'Update': 'アップデート',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,../src/../src/views/settings.js,src/templates/settings.html,
    'Updating': 'アップデート中',

    // ../src/../src/core/state.js,
    'Updating the system, please wait': 'システムのアップデート中 お待ち下さい',

    // ../src/templates/related.html,src/templates/related.html,
    'Use the + button to add a related object.': '+ で関連するオブジェクトを追加。',

    // ../src/templates/user.html,
    'User': 'ユーザー',

    // ../src/../src/views/user.js,
    'User created successfully': 'ユーザーが作成されました',

    // ../src/../src/views/user.js,
    'User deleted successfully': 'ユーザーが消去されました',

    // ../src/templates/user.html,
    'User list': 'ユーザーリスト',

    // ../src/../src/views/user.js,
    'User updated successfully': 'ユーザーのアップデート完了',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Username': 'ユーザー名',

    // ../src/templates/settings.html,
    'Versions': 'バージョン',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_LIMIT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_LIMIT_SETPOINT,
    'Voltage Control output current limit': '電圧制御出力電流リミット',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter VICINION_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_SETPOINT,
    'Voltage Control voltage setpoint': '電圧制御電圧セットポイント',

    // ../src/../src/main.js,../src/../src/core/websocket.js,
    'Waiting for server connection, please wait': 'サーバー接続大気中 お待ち下さい',

    // IQ4 Text for parameter GATEWAY_WARNING_SETPOINT,IQ4 Text for parameter GATEWAY_EX_WARNING_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_WARNING_SETPOINT,
    'Warning level, measured value over maximum': '最大限度値超過ワーニングレベル',

    // IQ4 Text for parameter GATEWAY_WARNING_MINIMUM,IQ4 Text for parameter GATEWAY_EX_WARNING_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_WARNING_MINIMUM,
    'Warning level, measured value under minimum': '最小限度値未満ワーニングレベル',

    // IQ4 Text for parameter GATEWAY_WARNING_ERROR_DELAY,IQ4 Text for parameter GATEWAY_EX_WARNING_ERROR_DELAY,
    'Warning/alarm delay': 'ワーニング/アラームディレイ',

    // ../src/templates/log.html,
    'Warnings': 'ワーニング',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_SPEED,IQ4 Text for parameter EASION_WEB_SPEED,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_SPEED,IQ4 Text for parameter PERFORMAX_WEB_SPEED,
    'Web speed': 'ウエブスピード',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,IQ4 Text for parameter GATEWAY_EX_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,
    'Web voltage average (all)': 'ウエブ電位(全平均)',

    // IQ4 Text for setting CLFB_WEBVOLTAGEOFFSET,
    'Web voltage offset': 'ウエブ電位オフセット',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG1,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG1,
    'Web voltage seg1': 'seg1 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG10,
    'Web voltage seg10': 'seg10 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG11,
    'Web voltage seg11': 'seg11 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG12,
    'Web voltage seg12': 'seg12 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG13,
    'Web voltage seg13': 'seg13 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG14,
    'Web voltage seg14': 'seg14 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG15,
    'Web voltage seg15': 'seg15 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG16,
    'Web voltage seg16': 'seg16 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG2,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG2,
    'Web voltage seg2': 'seg2 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG3,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG3,
    'Web voltage seg3': 'seg3 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG4,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG4,
    'Web voltage seg4': 'seg4 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG5,
    'Web voltage seg5': 'seg5 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG6,
    'Web voltage seg6': 'seg6 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG7,
    'Web voltage seg7': 'seg7 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG8,
    'Web voltage seg8': 'seg8 ウエブ電位',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG9,
    'Web voltage seg9': 'seg9 ウエブ電位',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_WIDTH,IQ4 Text for parameter EASION_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_WEB_WIDTH,
    'Web width': 'ウエブ幅',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'Yes': 'はい',

    // ../src/templates/user.html,
    'You are currently logged in as the user': 'ユーザーとしてログイン中です',

    // ../src/../src/views/user.js,
    'You can not delete your own user account': '自身のユーザーアカウントを消去できません',

    // IQ4 Text for event WARNING_MASK_ARC_DETECTED,IQ4 Text for event ALARM_MASK_ARC_DETECTED,
    'arc detected': 'アーク検知されました',

    // IQ4 Text for event WARNING_EFFICIENCY_LOW,
    'bar efficiency low': 'イオン化効率低下',

    // IQ4 Text for event WARNING_CALIBRATION_IN_PROGRESS,
    'calibration in progress': 'キャリブレーション実行中',

    // IQ4 Text for event WARNING_CALIBRATION_INVALID,
    'calibration invalid': 'キャリブレーション無効',

    // IQ4 Text for event WARNING_MASK_CHARGEPULSE_TOO_LONG,
    'chargepulse too long': 'チャージングパルスが長過ぎる',

    // IQ4 Text for event WARNING_MASK_CLEAN_BAR,
    'clean bar': 'バー クリーニング',

    // IQ4 Text for event ALARM_COMMUNICATION_LOST_SEGMENT,
    'communication lost with sensor segment': 'センサーセグメントとの通信ロスト',

    // IQ4 Text for event WARNING_MASK_HMI_COMM_LOST,
    'device communication lost': 'デバイスとの通信ロスト',

    // IQ4 Text for event WARNING_MASK_DEVICE_NEEDS_REBOOT,
    'device needs reboot': 'デバイスはリブートを必要とします',

    // IQ4 Text for event SYS_WARNING_MASK_DEVICE_PORT_CHANGED,IQ4 Text for event WARNING_PORT_SWAP,
    'device port changed': 'デバイスポートが変更されました',

    // IQ4 Text for event ALARM_MASK_EEPROM_DEFECTIVE,
    'eeprom defective': 'EEPROM不良',

    // IQ4 Text for event WARNING_MASK_EEPROM_PREV_SETTINGS_REST,
    'eeprom previous settings restored': '以前のEEPROM設定に復帰しました',

    // IQ4 Text for event WARNING_MASK_EEPROM_TOO_MANY_WRITES,
    'eeprom too many write cycles': 'EEPROM書き込み数過大',

    // IQ4 Text for event WARNING_MASK_GENERATOR_NOT_CONNECTED,
    'generator not connected': 'チャージング電源が非接続',

    // ../src/templates/password-reset.html,
    'has been reset': 'リセットされました',

    // IQ4 Text for event WARNING_TEMPERATURE_HIGH,IQ4 Text for event WARNING_MASK_HIGH_TEMP,IQ4 Text for event ALARM_HIGH_TEMPERATURE,
    'high temperature': '高温',

    // IQ4 Text for event WARNING_COMMUNICATION_LOST,
    'hmi communication lost': 'HMI通信がロスト',

    // ../src/templates/user.html,
    'id': 'ID',

    // ../src/templates/log-table.html,
    'inactive': '停止',

    // IQ4 Text for event SYS_WARNING_MASK_NEW_DEVICE_CONNECTED,IQ4 Text for event WARNING_NEW_DEVICE_CONNECTED,
    'new device connected': '新デバイスに接続しました',

    // IQ4 Text for event WARNING_MASK_NO_ACTIVE_SEGMENTS,IQ4 Text for event WARNING_LINKED_SENSOR_NO_ACTIVE_SEGMENTS,
    'no active sensor segments': '動作中のセンサーセグメントがありません',

    // IQ4 Text for event WARNING_MASK_NO_COMM_PAIRED_DEVICE,IQ4 Text for event WARNING_LINKED_SENSOR_NO_COMM_PAIRED_DEVICE,
    'no communication with paired device': 'ペア化されたデバイスとの通信無し',

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_LOW,IQ4 Text for event ALARM_MASK_LEVEL_TOO_LOW,
    'output curent too low': '出力電流が過少',

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_HIGH,IQ4 Text for event ALARM_MASK_LEVEL_TOO_HIGH,
    'output current too high': '出力電流が過大',

    // IQ4 Text for event WARNING_MASK_OVERLOAD_HIGH_VOLTAGE,IQ4 Text for event ALARM_MASK_OVERLOAD_HIGH_VOLTAGE,IQ4 Text for event WARNING_HVOUT_OVERLOAD,IQ4 Text for event ALARM_HVOUT_OVERLOAD,
    'overload high voltage': '高電圧出力過負荷',

    // IQ4 Text for event WARNING_MASK_PAIRED_DEVICE_MISSING,IQ4 Text for event WARNING_LINKED_SENSOR_PAIRED_DEVICE_MISSING,
    'paired device missing': 'ペア化されたデバイスが見つかりません',

    // IQ4 Text for event WARNING_MASK_SENSOR_MISSING,IQ4 Text for event WARNING_LINKED_SENSOR_SEGMENT_MISSING,
    'paired sensor missing': 'ペア化されたセンサーが見つかりません',

    // IQ4 Text for event WARNING_MASK_POWER_SUPPLY_LOW,
    'power supply low': '供給電圧過少',

    // IQ4 Text for event ALARM_SENSOR_MALFUNCTION,
    'sensor malfunction': 'センサー異常',

    //     // IQ4 Text for event ALARM_SUPPLY_CURRENT_HIGH,IQ4 Text for event ALARM_MASK_HIGH_PRIM_CUR,IQ4 Text for event ALARM_PRIMARY_CURRENT_HIGH,
    'supply current too high': '供給電流過大',

    // IQ4 Text for event WARNING_SUPPLY_VOLTAGE_LOW,IQ4 Text for event WARNING_INPUT_VOLTAGE_LOW,
    'supply voltage too low': '供給電圧過少',

    //     // IQ4 Text for event ALARM_MASK_HIGH_TEMP,IQ4 Text for event ALARM_TEMPERATURE_HIGH,
    'too high temperature': '温度過大',

    // ../src/templates/user.html,
    'userlevel': 'ユーザーレベル',

    // ../src/templates/user.html,
    'username': 'ユーザー名',

    // IQ4 Text for event ALARM_WEB_VOLTAGE_OVERLOAD,
    'web voltage overload': 'ウエブ電位 過負荷',

    // IQ4 Text for event WARNING_WEB_VOLTAGE_HIGH_WARNING,IQ4 Text for event ALARM_WEB_VOLTAGE_HIGH_ERROR,
    'web voltage too high': 'ウエブ電位過大',

    // IQ4 Text for event WARNING_WEB_VOLTAGE_LOW_WARNING,IQ4 Text for event ALARM_WEB_VOLTAGE_LOW_ERROR,
    'web voltage too low': 'ウエブ電位過少',

    // ====================================================
    // TODO: translate these
    // ====================================================
    // ../src/../src/core/state.js, 
    'Error saving the user preferences': 'Error saving the user preferences', 

    // ../src/../src/core/gui.js, 
    'Started': 'Started', 

    // ../src/../src/core/helpers.js, 
    'device': 'device', 

    // ../src/../src/core/helpers.js, 
    'fieldbus': 'fieldbus', 

    // ../src/../src/core/helpers.js, 
    'CLFB': 'CLFB', 

    // ../src/../src/core/helpers.js, 
    'object': 'object', 

    // ../src/../src/debugger/log.js, 
    'Retrieving logs, please wait': 'Retrieving logs, please wait', 

    // ../src/../src/debugger/log.js, ../src/../src/debugger/log.js, 
    'Failed to download logs': 'Failed to download logs', 

    // ../src/../src/debugger/testrunner.js, 
    'Error: could not find test': 'Error: could not find test', 

    // ../src/../src/debugger/debugger.js, 
    'A test is already being recorded': 'A test is already being recorded', 

    // ../src/../src/debugger/debugger.js, src/templates/debug-test.html, 
    'Stop recording': 'Stop recording', 

    // ../src/../src/debugger/debugger.js, src/templates/debug-test.html, 
    'Record new test': 'Record new test', 

    // ../src/../src/debugger/debugger.js, 
    'Are you sure you want to delete this test?': 'Are you sure you want to delete this test?', 

    // ../src/../src/views/device-dashboard.js, 
    'Could not find Device': 'Could not find Device', 

    // ../src/../src/views/device.js, ../src/../src/views/table.js, 
    'Could not find device': 'Could not find device', 

    // ../src/../src/views/setting.js, 
    'Setting not found': 'Setting not found', 

    // ../src/../src/views/setting.js, ../src/../src/views/log.js, 
    'not found': 'not found', 

    // ../src/../src/views/setting.js, 
    'Failed to retrieve this CLFB\'s input device': 'Failed to retrieve this CLFB\'s input device', 

    // ../src/../src/views/action.js, 
    'Invalid action': 'Invalid action', 

    // ../src/../src/views/action.js, 
    'Before exporting the Fieldbus configuration you first have to enable it.': 'Before exporting the Fieldbus configuration you first have to enable it.', 

    // ../src/../src/views/action.js, 
    ' Please check the device help for more information.': ' Please check the device help for more information.', 

    // ../src/../src/views/action.js, 
    ' in use': ' in use', 

    // ../src/../src/views/action.js, 
    ' not found or does not support the requested action': ' not found or does not support the requested action', 

    // ../src/../src/views/action.js, 
    'Error': 'Error', 

    // ../src/../src/views/action.js, 
    'Action not found': 'Action not found', 

    // ../src/../src/views/action.js, 
    ' could not be not found': ' could not be not found', 

    // ../src/../src/views/clfb.js, ../src/../src/views/clfb-io-chart.js, ../src/../src/views/clfb-io.js, 
    'Could not find CLFB': 'Could not find CLFB', 

    // ../src/../src/views/chart.js, ../src/../src/views/parameter.js, 
    'Parameter not found': 'Parameter not found', 

    // ../src/../src/views/chart.js, ../src/../src/views/parameter.js, 
    'Device not found': 'Device not found', 

    // ../src/../src/views/time.js, 
    'Clear selection': 'Clear selection', 

    // ../src/../src/views/time.js, 
    'Decrement Hour': 'Decrement Hour', 

    // ../src/../src/views/time.js, 
    'Decrement Minute': 'Decrement Minute', 

    // ../src/../src/views/time.js, 
    'Decrement Second': 'Decrement Second', 

    // ../src/../src/views/time.js, 
    'Increment Hour': 'Increment Hour', 

    // ../src/../src/views/time.js, 
    'Increment Minute': 'Increment Minute', 

    // ../src/../src/views/time.js, 
    'Increment Second': 'Increment Second', 

    // ../src/../src/views/time.js, 
    'Next Century': 'Next Century', 

    // ../src/../src/views/time.js, 
    'Next Decade': 'Next Decade', 

    // ../src/../src/views/time.js, 
    'Next Month': 'Next Month', 

    // ../src/../src/views/time.js, 
    'Next Year': 'Next Year', 

    // ../src/../src/views/time.js, 
    'Pick Hour': 'Pick Hour', 

    // ../src/../src/views/time.js, 
    'Pick Minute': 'Pick Minute', 

    // ../src/../src/views/time.js, 
    'Pick Second': 'Pick Second', 

    // ../src/../src/views/time.js, 
    'Previous Century': 'Previous Century', 

    // ../src/../src/views/time.js, 
    'Previous Decade': 'Previous Decade', 

    // ../src/../src/views/time.js, 
    'Previous Month': 'Previous Month', 

    // ../src/../src/views/time.js, 
    'Previous Year': 'Previous Year', 

    // ../src/../src/views/time.js, 
    'Select Date': 'Select Date', 

    // ../src/../src/views/time.js, 
    'Select Decade': 'Select Decade', 

    // ../src/../src/views/time.js, 
    'Select Month': 'Select Month', 

    // ../src/../src/views/time.js, 
    'Select Time': 'Select Time', 

    // ../src/../src/views/time.js, 
    'Select Year': 'Select Year', 

    // ../src/../src/views/time.js, 
    'Go to today': 'Go to today', 

    // ../src/../src/views/time.js, 
    'Toggle Meridiem': 'Toggle Meridiem', 

    // ../src/../src/views/related.js, ../src/../src/views/related.js, 
    'You can not add a related object to a fieldbus while the fieldbus is running': 'You can not add a related object to a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'You can not remove a related object from a fieldbus while the fieldbus is running': 'You can not remove a related object from a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'You can not delete a related object from a fieldbus while the fieldbus is running': 'You can not delete a related object from a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'Can not delete unknown related object': 'Can not delete unknown related object', 

    // ../src/../src/views/parameter.js, 
    'Your number is less than the minimum value: ': 'Your number is less than the minimum value: ', 

    // ../src/../src/views/parameter.js, 
    'Your number is greater than the maximum value: ': 'Your number is greater than the maximum value: ', 

    // ../src/../src/views/login.js, 
    'You have logged in with an empty password. Click here to change it.': 'You have logged in with an empty password. Click here to change it.', 

    // ../src/../src/views/fieldbus.js, 
    'The fieldbus has unknown objects. It might help to remove them.': 'The fieldbus has unknown objects. It might help to remove them.', 

    // ../src/../src/views/fieldbus.js, 
    'Failed to enable fieldbus': 'Failed to enable fieldbus', 

    // ../src/../src/views/fieldbus.js, 
    'Failed to disable fieldbus': 'Failed to disable fieldbus', 

    // ../src/../src/views/fieldbus.js, 
    'Could not find fieldbus': 'Could not find fieldbus', 

    // ../src/../src/views/settings.js, 
    'Could not retrieve list of versions': 'Could not retrieve list of versions', 

    // ../src/../src/views/settings.js, 
    'Could not retrieve list of updates': 'Could not retrieve list of updates', 

    // ../src/../src/widgets/sensor-segments-chart.js, 
    'Can not find segments for this device': 'Can not find segments for this device', 

    // ../src/../src/widgets/sensor-segments-chart.js, 
    'Could not retrieve recent history for parameter': 'Could not retrieve recent history for parameter', 

    // ../src/templates/user-edit.html, 
    'Id': 'Id', 

    // ../src/templates/debug-test.html, src/templates/debug-test.html, 
    'New test name': 'New test name', 

    // ../src/templates/debug-test.html, 
    'Rename': 'Rename', 

    // ../src/templates/debug-test.html, 
    'Run recorded test selection': 'Run recorded test selection', 

    // ../src/templates/help.html, src/templates/legal.html, 
    'Legal summary': 'Legal summary', 

    // ../src/templates/debug-report.html, 
    'You can send a report to our central database. This will include a snapshot of the current state and the full log. Please add a little description so we know how to interpret this.': 'You can send a report to our central database. This will include a snapshot of the current state and the full log. Please add a little description so we know how to interpret this.', 

    // ../src/templates/parameter.html, src/templates/table.html, 
    'Plot realtime table': 'Plot realtime table', 

    // ../src/templates/parameter.html, src/templates/table.html, 
    'Plot 48h table': 'Plot 48h table', 

    // ../src/templates/parameter.html, src/templates/clfb-io.html, 
    'Record changes': 'Record changes', 

    // ../src/templates/clfb-io.html, 
    'CLFB I/O': 'CLFB I/O', 

    // ../src/templates/table.html, 
    'Realtime': 'Realtime', 

    // ../src/templates/table.html, 
    '48h': '48h', 

    // ../src/templates/macros.html, 
    'Unknown input': 'Unknown input', 

    // ../src/templates/macros.html, 
    'Unknown output': 'Unknown output', 

    // ../src/templates/time.html, 
    '24H or AM/PM': '24H or AM/PM', 

    // ../src/templates/time.html, 
    '24H': '24H', 

    // ../src/templates/active-users.html, 
    'Active user sessions': 'Active user sessions', 

    // ../src/templates/active-users.html, 
    'User name': 'User name', 

    // ../src/templates/active-users.html, 
    'User level': 'User level', 

    // ../src/templates/active-users.html, 
    'Go to dashboard': 'Go to dashboard', 

    // ../src/templates/log.html, 
    'Info': 'Info', 

    // ../src/templates/customize-view.html, 
    'You can choose one of the pre-defined orders, or you can sort manually by dragging and dropping after selecting "sort manually".': 'You can choose one of the pre-defined orders, or you can sort manually by dragging and dropping after selecting "sort manually".', 

    // ../src/templates/settings-updates.html, src/templates/settings.html, 
    'UID': 'UID', 

    // IQ4 Text for parameter CM_TINY_EEPROM_WRITE_COUNTS, IQ4 Text for parameter MPM_EEPROM_WRITE_COUNTS, 
    'Eeprom write counts': 'Eeprom write counts', 

    // IQ4 Text for parameter MPM_OPERATION_MODE, 
    'Operation mode (dipswitch 3)': 'Operation mode (dipswitch 3)', 

    // IQ4 Text for parameter MPM_REMOTE_ON_OFF_SOURCE, 
    'Remote on/off source (dipswitch 1)': 'Remote on/off source (dipswitch 1)', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT, 
    'Analog input (dipswitch 2)': 'Analog input (dipswitch 2)', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT_FUNCTION, 
    'Analog input function': 'Analog input function', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT_FEEDBACK, 
    'Analog input': 'Analog input', 

    // IQ4 Text for parameter MPM_CALIBRATE_ANALOG_INPUT_OFFSET, 
    'Calibrate analog input offset': 'Calibrate analog input offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_ANALOG_INPUT_GAIN, 
    'Calibrate analog input gain': 'Calibrate analog input gain', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_FEEDBACK, 
    'High voltage output': 'High voltage output', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_FEEDBACK_OFFSET, 
    'Calibrate output voltage feedback offset': 'Calibrate output voltage feedback offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_FEEDBACK_GAIN, 
    'Calibrate output voltage feedback gain': 'Calibrate output voltage feedback gain', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_FEEDBACK_OFFSET, 
    'Calibrate output current offset': 'Calibrate output current offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_FEEDBACK_GAIN, 
    'Calibrate output current gain': 'Calibrate output current gain', 

    // IQ4 Text for parameter MPM_MAX_QUANTITY_ARCS, 
    'Max. arcs per minute': 'Max. arcs per minute', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_SETPOINT_MAX, 
    'Max. output voltage': 'Max. output voltage', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_SETPOINT, 
    'Output voltage setpoint': 'Output voltage setpoint', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_SETPOINT_OFFSET, 
    'Calibrate output voltage setpoint offset': 'Calibrate output voltage setpoint offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_SETPOINT_GAIN, 
    'Calibrate output voltage setpoint gain': 'Calibrate output voltage setpoint gain', 

    // IQ4 Text for parameter MPM_CURRENT_LIMIT_SETPOINT, 
    'Max. output current': 'Max. output current', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_SETPOINT_OFFSET, 
    'Calibrate output current setpoint offset': 'Calibrate output current setpoint offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_SETPOINT_GAIN, 
    'Calibrate output current setpoint gain': 'Calibrate output current setpoint gain', 

    // IQ4 Text for parameter MPM_BALANCE_OFFSET, 
    'Balance offset': 'Balance offset', 

    // IQ4 Text for parameter GATEWAY_IQ4_WEB_DISTANCE_SEG1, 
    'Web distance segment 1': 'Web distance segment 1', 

    // IQ4 Text for parameter MPM_UNIQUE_DEVICE_ID, 
    'Unique device id': 'Unique device id', 

    // IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE_MIN, 
    'Power supply warning level min.': 'Power supply warning level min.', 

    // IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE_MAX, 
    'Power supply warning level max.': 'Power supply warning level max.', 

    // IQ4 Text for parameter MPM_AUTORUN, 
    'Autorun': 'Autorun', 

    // IQ4 Text for parameter MPM_FREE_RAM_CNTR, 
    'Free RAM counter': 'Free RAM counter', 

    // IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_MAX, 
    'Output voltage max': 'Output voltage max', 

    // IQ4 Text for parameter PERFOMASTER_VOLTAGE_SETPOINT, 
    'Voltage setpoint': 'Voltage setpoint', 

    // IQ4 Text for parameter PERFOMASTER_HV_CURRENT, 
    'Current in HV module': 'Current in HV module', 

    // IQ4 Text for parameter PERFOMASTER_VOLTAGE, 
    'Voltage out of the HV supply': 'Voltage out of the HV supply', 

    // IQ4 Text for setting CLFB_ACTIVESEGMENTS, 
    'Active Segments': 'Active Segments', 

    // Action title for save-config, 
    'Save configuration': 'Save configuration', 

    // IQ4 Text for event WARNING_NO_SEGMENTS_SELECTED, 
    'No sensor segments selected': 'No sensor segments selected', 

    // IQ4 Text for event DEVICE_DISCONNECTED, 
    'Device disconnected': 'Device disconnected', 

    // IQ4 Text for event DEVICE_DISABLED, 
    'Device disabled': 'Device disabled', 

    // IQ4 Text for event ALARM_INPUT_VOLTAGE_HIGH, 
    'supply voltage too high': 'supply voltage too high', 

    // IQ4 Text for event ALARM_AIR_PRESSURE_HIGH, 
    'air pressure too high': 'air pressure too high', 

};

export function getTranslations() {
    return translations;
}