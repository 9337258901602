/**
 * Dom and Ajax handling
 * @module helpers
 */

"use strict";

/**
 * @param {object} settings - Ajax settings, comparable to jQuery's
 *     AjaxSettings object
 */
export function ajax(settings) {
    $.ajax(settings);
}
