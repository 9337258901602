/**
 * Debug Test Assert Module
 * @module assert
 */

"use strict";


import * as State from '../core/state';
import * as Api from '../core/api';
import * as Helpers from '../core/helpers';

/*
 Flags
 -----
 - running
 - not-running
 - standby
 - not-standby
 - disabled
 - not-disabled
 - disconnected
 - not-disconnected
 - stopped
 - not-stopped

 - warnings
 - no-warnings
 - errors
 - no-errors

 Values
 ------
 - relation
 - no-relation

 - equals
 - not-equals
 - lt
 - gt
 - lte
 - gte
*/

export function assert(ev, callback) {
    let log = '';

    let iqObject = State.getIqObject(ev.args.uid);

    let apiCall = (uid) => {
        return new Promise((resolve, reject) => {
            reject();
        });
    };

    if(Helpers.isDevice(iqObject)) {
        apiCall = Api.getDevice;
    } else if(Helpers.isFieldbus(iqObject)) {
        apiCall = Api.getFieldbus;
    } else if(Helpers.isClfb(iqObject)) {
        apiCall = Api.getClfb;
    }

    // Retrieve IQ Object from API
    apiCall(iqObject.uid).then(data => {
        let flagResults = ev.args.flags.map(x => checkFlag(iqObject, x));

        /**
         * @type Array<[boolean, string]>
         */
        let valueResults = [];
        for(let name in ev.args.values) {
            if(!ev.args.values.hasOwnProperty(name)) {
                continue;
            }

            let [result, msg] = checkValue(data, ev, name, ev.args.values[name]);
            valueResults.push([
                typeof result === 'boolean' ? result : false,
                typeof msg === 'string' ? msg : '',
            ]);
        }
        let result = true;
        log += '\n';
        for(let [success, msg] of flagResults) {
            result = result && success;
            log += msg + '\n';
        }

        for(let [success, msg] of valueResults) {
            result = result && success;
            log += msg + '\n';
        }
        return callback(result, log);
    }).catch(err => {
        console.log(err);
        log = ('Unable to retrieve IQ Object of type ' +
            Helpers.iqObjectType(iqObject) +
            ' with uid ' + iqObject.uid + ' from API\n'
        );
        return callback(false, log);
    });
}

const EQ_MODES = ['running', 'standby', 'disabled', 'stopped', 'disconnected'];
const NEQ_MODES = ['not-running', 'not-standby', 'not-disabled', 'not-disconnected', 'not-stopped'];
const COMPARISONS = ['equals', 'not-equals', 'lt', 'gt', 'lte', 'gte'];

/**
 * @param {Object} iqObject
 * @param {string} flag
 * @returns [boolean, string]
 */
function checkFlag(iqObject, flag) {
    let logHeader = (
        'IQ Object with type ' +
        Helpers.iqObjectType(iqObject) +
        ' and UID ' +
        iqObject.uid
    );

    if(EQ_MODES.indexOf(flag) !== -1) {
        if(iqObject.status.mode === flag) {
            return [
                true,
                '✓ ' + logHeader + ' is in expected mode ' + flag
            ];
        } else {
            return [
                false,
                '❌' + logHeader +
                ' is NOT in expected mode ' + flag + ', but ' +
                iqObject.status.mode
            ];
        }
    }

    if(NEQ_MODES.indexOf(flag) !== -1) {
        flag = flag.replace('not-', '');
        if(iqObject.status.mode !== flag) {
            return [
                true,
                '✓ ' + logHeader + ' is not in mode ' + flag
            ];
        } else {
            return [
                false,
                '❌ ' + logHeader + ' is in mode ' + flag
            ];
        }
    }

    switch(flag) {
        case 'warnings':
            if(iqObject.status.warnings) {
                return [
                    true,
                    '✓ ' + logHeader + ' has warnings'
                ];
            } else {
                return [
                    false,
                    '❌ ' + logHeader + ' has no warnings'
                ];
            }
            break;
        case 'no-warnings':
            if(!iqObject.status.warnings) {
                return [
                    true,
                    '✓ ' + logHeader + ' has no warnings'
                ];
            } else {
                return [
                    false,
                    '❌ ' + logHeader + ' has warnings'
                ];
            }
            break;
        case 'errors':
            if(iqObject.status.errors) {
                return [
                    true,
                    '✓ ' + logHeader + ' has errors'
                ];
            } else {
                return [
                    false,
                    '❌ ' + logHeader + ' has no errors'
                ];
            }
            break;
        case 'no-errors':
            if(!iqObject.status.errors) {
                return [
                    true,
                    '✓ ' + logHeader + ' has no errors'
                ];
            } else {
                return [
                    false,
                    '❌ ' + logHeader + ' has errors'
                ];
            }
            break;
        default:
            return [false, '❌ Unknow assertion flag ' + flag];
    }
}

function getParameter(iqObject, qid) {
    qid = parseInt(qid);
    for(let param of iqObject.parameters) {
        if(param.qid === qid) {
            return param;
        }
    }
    return false;
}


function getSettingValue(iqObject, settingName) {
    if(iqObject.hasOwnProperty('settings') && iqObject.settings.hasOwnProperty(settingName)) {
        if(typeof iqObject.settings[settingName] !== 'object') {
            return iqObject.settings[settingName];
        }
        return iqObject.settings[settingName].currentValue;
    }
    return false;
}

/**
 * @param {Object} iqObject
 * @param {Object} ev - the event
 * @param {string} name
 * @param {string} value
 * @returns [boolean, string]
 */
function checkValue(iqObject, ev, name, value) {
    let logHeader = (
        'IQ Object with type ' +
        Helpers.iqObjectType(iqObject) +
        ' and UID ' +
        iqObject.uid
    );

    if(name === 'relation') {
        let success = false;
        if(Helpers.isDevice(iqObject) || Helpers.isClfb(iqObject)) {
            success = Helpers.clone(iqObject.relatedObjects).filter(x => x.uid === value).length > 0;
            if(Helpers.isClfb(iqObject)) {
                success = (
                    success ||
                    iqObject.settings.inputUid.currentValue === value ||
                    iqObject.settings.outputUid.currentValue === value
                );
            }
        } else if(Helpers.isFieldbus(iqObject)) {
            success = Helpers.clone(iqObject.modules).filter(x => x.uid === value).length > 0;
        }

        let target = State.getIqObject(value);

        if(!target) {
            return [false, '❌ Could not find object with UID ' + value];
        }

        let logFooter = (
            'IQ Object with type ' +
            Helpers.iqObjectType(target) +
            ' and UID ' +
            target.uid
        );

        if(success) {
            return [true, '✓ ' + logHeader + ' is related to ' + logFooter];
        } else {
            return [false, '❌  ' + logHeader + ' is NOT related to ' + logFooter];
        }
    }

    else if(name === 'no-relation') {
        let success = false;
        if(Helpers.isDevice(iqObject) || Helpers.isClfb(iqObject)) {
            success = Helpers.clone(iqObject.relatedObjects).filter(x => x.uid === value).length === 0;

            if(Helpers.isClfb(iqObject)) {
                success = (
                    success &&
                    iqObject.settings.inputUid.currentValue !== value &&
                    iqObject.settings.outputUid.currentValue !== value
                );
            }
        } else if(Helpers.isFieldbus(iqObject)) {
            success = Helpers.clone(iqObject.modules).filter(x => x.uid === value).length === 0;
        }

        let target = State.getIqObject(value);

        if(!target) {
            return [false, '❌ Could not find object with UID ' + value];
        }

        let logFooter = (
            'IQ Object with type ' +
            Helpers.iqObjectType(target) +
            ' and UID ' +
            target.uid
        );

        if(success) {
            return [true, '✓ ' + logHeader + ' is not related to ' + logFooter];
        } else {
            return [false, '❌  ' + logHeader + ' is related to ' + logFooter];
        }
    }

    else if(COMPARISONS.indexOf(name) !== -1) {
        const isNumeric = Helpers.isNumeric(value);

        /**
         * @type number | string
         */
        let currentValue = '';

        /**
         * @type number | string
         */
        let comparisonValue = value;

        if(ev.args.zone === 'parameter') {
            const param = getParameter(iqObject, ev.args.qid);
            if(!param) {
                return [false, '❌ Could not find parameter with QID ' + ev.args.qid + ' from ' + logHeader];
            }
            currentValue = param.currentValue;

            if(param.type === 'number') {
                if(typeof currentValue !== 'number') {
                    currentValue = parseFloat(currentValue);
                }
                comparisonValue = parseFloat(comparisonValue);
            } else {
                currentValue = currentValue.toString();
            }

            logHeader += ' and parameter ' + param.name;
        } else if(ev.args.zone === 'setting') {
            const settingValue = getSettingValue(iqObject, ev.args.setting);
            const setting = Helpers.getSetting(Helpers.iqObjectType(iqObject), ev.args.setting);
            if(settingValue === false) {
                return [false, '❌ Could not find setting "' + ev.args.setting + ' from ' + logHeader];
            }
            currentValue = settingValue;

            if(setting.settingType === 'number' && typeof currentValue === 'string') {
                currentValue = parseFloat(currentValue);
                if(typeof comparisonValue !== 'number') {
                    comparisonValue = parseFloat(comparisonValue);
                }
            } else {
                currentValue = currentValue.toString();
            }

            if(setting.settingType === 'number') {
                if(typeof currentValue !== 'number') {
                    currentValue = parseFloat(currentValue);
                }
                if(typeof comparisonValue !== 'number') {
                    comparisonValue = parseFloat(comparisonValue);
                }
            } else {
                currentValue = currentValue.toString();
            }

            logHeader += ' and setting ' + ev.args.setting;
        } else if(ev.args.zone === 'clfb-io') {
            currentValue = parseFloat(
                $('section.parameter.assert[data-uid=' + iqObject.uid + '][data-setting=' + ev.args.setting + ']')
                .attr('data-raw-value')
            );
            if(typeof comparisonValue === 'string') {
                comparisonValue = parseFloat(comparisonValue);
            }

            logHeader += ' and CLFB ' + ev.args.setting;
        }

        if(name === 'equals') {
            if(comparisonValue === currentValue) {
                return [true, '✓ ' + logHeader + ' equals "' + comparisonValue + '"'];
            } else {
                return [false, '❌ ' + logHeader + ' does not equal "' + comparisonValue + '"'];
            }
        } else if(name === 'not-equals') {
            if(comparisonValue !== currentValue) {
                return [true, '✓ ' + logHeader + ' does not equal "' + comparisonValue + '"'];
            } else {
                return [false, '❌ ' + logHeader + ' equals "' + comparisonValue + '"'];
            }
        } else if(name === 'lt') {
            if(currentValue < comparisonValue) {
                return [true, '✓ ' + logHeader + ' is smaller than ' + comparisonValue];
            } else {
                return [false, '❌ ' + logHeader + ' is not smaller than ' + comparisonValue];
            }
        } else if(name === 'gt') {
            if(currentValue > comparisonValue) {
                return [true, '✓ ' + logHeader + ' is bigger than ' + comparisonValue];
            } else {
                return [false, '❌ ' + logHeader + ' is not bigger than ' + comparisonValue];
            }
        } else if(name === 'lte') {
            if(currentValue <= comparisonValue) {
                return [true, '✓ ' + logHeader + ' is smaller than or equal to ' + comparisonValue];
            } else {
                return [false, '❌ ' + logHeader + ' is not smaller than or equal to ' + comparisonValue];
            }
        } else if(name === 'gte') {
            if(currentValue >= comparisonValue) {
                return [true, '✓ ' + logHeader + ' is bigger than or equal to ' + comparisonValue];
            } else {
                return [false, '❌ ' + logHeader + ' is not bigger than or equal to ' + comparisonValue];
            }
        }
    }


    return [false, '❌ Unknow assertion value ' + name];
}
