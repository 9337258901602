/**
 * Record module
 * @module record
 */

"use strict";
import * as Debug from '../core/debug';
import * as DebugLog from './log';
import * as State from '../core/state';
import * as Helpers from '../core/helpers';
import * as Routing from '../routing';
import { RelatedView } from '../views/related';

let recording = false;
let log = [];
let testName = '';
let events = [];
let startTime = -1;
let startUrl = '';
let assertTarget = null;
let assertData = {};

export function getTestName() {
    return testName;
}

export function startRecording(name) {
    log = [];
    events = [];
    startUrl = window.location.pathname + window.location.hash;
    testName = name;
    recording = true;
    startTime = Date.now();
    $('body').addClass('recording');
    document.addEventListener('keydown', keyEvent);
    $(document).on('mouseenter', 'body.recording .assert', setAssertTarget);
    $(document).on('mouseleave', 'body.recording .assert', removeAssertTarget);
    $('button.cancel-assertion').on('click', cancelAssertion);
    $('button.add-assertion').on('click', addAssertion);
    $('button.assert-current-parameter').on('click', assertCurrentValue);
    $('button.assert-current-setting').on('click', assertCurrentValue);
    $('button.assert-current-clfb-io').on('click', assertCurrentValue);
}

function setAssertTarget(ev) {
    assertTarget = $(ev.currentTarget);
}

function assertCurrentValue(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    const rawValue = assertData.target.attr('data-raw-value');
    $('input#assert-' + assertData.zone + '-equals').val(rawValue);
}

function removeAssertTarget() {
    assertTarget = null;
}

function resetAssertion() {
    $('section.assertions div.assert-form').hide();
    $('section.assertions input[type=checkbox]').prop('checked', false);
    $('section.assertions input[type=text]').val('');
    $('section.assertions select').val('');
    assertData = {};
}

function cancelAssertion() {
    $('section.assertions').hide();
    resetAssertion();
}

function addAssertion(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    $('section.assertions').hide();

    let idToName = (id) => {
        return id.replace('assert-' + assertData.zone + '-', '');
    };

    $('div.assert-' + assertData.zone + ' input[type=checkbox]').each((index, elem) => {
        // @ts-ignore
        if($(elem).prop('checked')) {
            assertData.flags.push(
                idToName($(elem).attr('id'))
            );
        }
    });

    $('div.assert-' + assertData.zone + ' input[type=text]').each((index, elem) => {
        const value = $(elem).val().toString().trim();
        if(value) {
            const name = idToName($(elem).attr('id'));
            assertData.values[name] = value;
        }
    });

    $('div.assert-' + assertData.zone + ' select').each((index, elem) => {
        const value = $(elem).val().toString().trim();
        if(value) {
            const name = idToName($(elem).attr('id'));
            assertData.values[name] = value;
        }
    });

    const args = {
        zone: assertData.zone,
        uid: assertData.uid,
        qid: assertData.qid,
        setting: assertData.setting,
        flags: assertData.flags,
        values: assertData.values,
    };

    recordEvent('assert', args);
    console.log('Recorded assert');
    console.log(assertData);

    resetAssertion();
}

function keyEvent(ev) {
    if(ev.keyCode !== 65 || !assertTarget) {
        return;
    }

    $('section.assertions input[type=checkbox]').prop('checked', false);
    $('section.assertions input[type=text]').val('');
    $('section.assertions select').val('');

    assertData = {
        zone: assertTarget.attr('data-assert-zone'),
        uid: assertTarget.attr('data-uid'),
        qid: assertTarget.attr('data-qid'),
        setting: assertTarget.attr('data-setting'),
        target: assertTarget,
        flags: [],
        values: {},
    };

    $('section.assertions div.assert-' + assertData.zone).fadeIn();

    const zoneMap = {
        'device': 'device',
        'clfb': 'CLFB',
        'fieldbus': 'fieldbus',
        'clfb-io': 'CLFB I/O',
        'parameter': 'parameter',
        'setting': 'setting',
    };

    $('section.assertions span.assert-target').html(zoneMap[assertData.zone]);

    if(['device', 'clfb', 'fieldbus'].indexOf(assertData.zone) !== -1) {
        let relatedSelection = [];

        State.getIqObjects().map(obj => {
            relatedSelection.push([obj.uid, obj.name]);
        });

        /* @ts-ignore */
        relatedSelection.sort((a, b) => {
            return a[1].toLowerCase() > b[1].toLowerCase();
        });

        let selectionHtml = '<option value="" selected>-------------</option>';
        for(let item of relatedSelection) {
            const uid = item[0];
            const name = item[1];
            if(uid === assertData.uid) {
                continue;
            }

            selectionHtml += '<option value="' + uid + '">' + name + '</option>';
        }
        $('div.assert-form.assert-' + assertData.zone + ' select').html(selectionHtml);
    }

    $('section.assertions').fadeIn();
}

export function stopRecording() {
    recording = false;
    $('body').removeClass('recording');
    document.removeEventListener('keydown', keyEvent);
    removeAssertTarget();
    $(document).off('mouseleave', '.assert');
    $('section.assertions div.assert-form').hide();
    $('button.cancel-assertion').off('click');

    const testData = {
        id: Helpers.generateUuid(),
        name: testName,
        startTime: startTime,
        startUrl: startUrl,
        events: events,
    };

    let tests = State.getRecordedTests();
    tests.push(testData);
    State.setRecordedTests(tests);

    events = [];
    startTime = -1;
    startUrl = '';
    log = [];
    testName = '';
}

export function isRecording() {
    return recording;
}

/**
 * @param {string} type - one of:
 *     - "action"
 *     - "back-button"
 *     - "link-click"
 *     - "start-screensaver"
 *     - "stop-screensaver",
 *     - "hide-screensaver",
 *     - "form-submit" (Only for forms that aren't handled by the Actions system)
 *     - "assert"
 */
export function recordEvent(type, args={}) {
    if(!recording) {
        return;
    }

    const unixTime = Date.now();
    events.push({
        uuid: Helpers.generateUuid(),
        at: unixTime - startTime,
        type: type,
        args: args,
        unixTime: unixTime,
        location: Routing.getCurrentUrlString(),
    });
}
