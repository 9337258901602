/**
 * Virtual keyboard module.
 * @module keyboard
 */

"use strict";

import * as Actions from './actions';
import * as Session from './session';
import * as Dah from './dah';

let keyboardEnabled = true;
let active = false;
let focussedElement = null;
let keyboard;
let capslock = false;
let shift = false;

/**
 * Initialize the Simple Keyboard
 */
export function initKeyboard() {
    const Keyboard = window.SimpleKeyboard.default;

    if(!Session.usingTouchScreen()) {
        keyboardEnabled = false;
        return;
    }

    $('a.toggle-keyboard').show();

    keyboard = new Keyboard({
        onChange: input => onChange(input),
        onKeyPress: button => onKeyPress(button),
        layout: {
            default: [
                "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                "{tab} q w e r t y u i o p [ ] \\",
                "{lock} a s d f g h j k l ; ' {enter}",
                "{shift} z x c v b n m , . / {shift}",
                "{space}",
            ],
            shift: [
                "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                "{tab} Q W E R T Y U I O P { } |",
                '{lock} A S D F G H J K L : " {enter}',
                "{shift} Z X C V B N M < > ? {shift}",
                "{space}",
            ],
        }
    });
}

function onChange(input) {
  focussedElement.val(input);
}

function onKeyPress(button) {
    if(!focussedElement) {
        hideKeyboard();
        return;
    }

    if(button === "{lock}"){
        capslock = !capslock;
        shift = false;
        keyboard.setOptions({
            layoutName: capslock ? 'shift' : 'default'
        });
    } else if(button === "{shift}") {
        capslock = false;
        shift = !shift;
        keyboard.setOptions({
            layoutName: shift ? 'shift' : 'default'
        });
    } else if(button === "{enter}") {
        if(focussedElement) {
            const form = focussedElement.closest('form');
            if(form.length > 0) {
                form.submit();
            }
        }
    } else {
        if(shift) {
            shift = false;
            keyboard.setOptions({
                layoutName: 'default'
            });
        }
    }
}

/**
 * Toggle the simple keyboard
 * @param {Event} [ev] - the optional DOM event
 */
export function toggleKeyboard(ev) {
    if(typeof ev !== 'undefined') {
        ev.preventDefault();
        ev.stopPropagation();
    }

    if(!active) {
        $('div.simple-keyboard').show();
    } else {
        $('div.simple-keyboard').hide();
    }
    active = !active;
}

/**
 * Hide the keyboard
 */
export function hideKeyboard() {
    active = false;
    $('div.simple-keyboard').hide();
}

/**
 * Called whenever an input element with type text or number is focussed
 * @param {Event} ev
 */
export function focusElement(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    if(!keyboardEnabled) {
        return;
    }

    if(!active) {
        toggleKeyboard();
    }

    focussedElement = $(ev.currentTarget);
    if(!focussedElement) {
        hideKeyboard();
        return;
    }

    const keyboardElement = $('div.simple-keyboard');

    keyboard.setOptions({
        inputName: focussedElement.attr('id'),
    });
    keyboard.setInput(focussedElement.val());


    // Only scroll the screen when the keyboard overlaps the element we want to
    // edit
    if(!Dah.elementsCollide(
        focussedElement[0],
        keyboardElement[0]
    )) {
        return;
    }

    let scrollY = focussedElement.offset().top - 100;
    const keyboardTopY = $('div.simple-keyboard').offset().top;

    window.scrollTo(0, scrollY);
}
