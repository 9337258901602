"use strict";

/**
 * Value Widgetmodule
 * @module valuewidget
 */

import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as State from '../core/state';
import {Widget} from './widget';

export class ValueWidget extends Widget {
    render() {
        super.render();
        const html = Gui.renderToString('widgets/value.html', this.context);
        this.addToDom(html);
    }
}
