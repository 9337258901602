/**
 * Test Sequence module.
 * @module test
 */

"use strict";

import * as Helpers from '../core/helpers';
import {Expect} from './expect';
import {Wait, WaitUnless} from './wait';

// TODO: if this system is ever converted to Ecmascript 8 we can seriously
// refactor this script, using await/async syntax, making things a lot easier
// and also preventing the need for supplying the tests to run as an array

export class TestSequence {
    /**
     * @param {array} sequence - an array with functions, which are all run in
     * order without overlap
     */
    constructor(name, sequence) {
        this.name = name;
        this.sequence = sequence;
        this.ajaxCallLog = [];
        this.messages = [];
        this.maxExecutionTimePerTest = 5000;
    }

    getStepsCount() {
        return this.sequence.length;
    }

    getName() {
        return this.name;
    }

    getMessages() {
        return this.messages;
    }

    getAjaxCallLog() {
        return this.ajaxCallLog;
    }

    /**
     * This function runs the test. The callback is triggered when the tests are
     * done running, after the next tick and/or after the last ajax call has
     * been executed.
     * @param {function} callback - this callback is triggered when the test is
     * done running, it will have a boolean indicating whether tests have
     * succeeded
     **/
    run(callback) {
        console.log('Run test sequence: ' + this.name);
        console.log('===============================');

        // This counter maintains the number of open ajax calls
        let openAjaxCounter = 0;
        let ajaxTotal = 0;

        // This handler is executed before an Ajax request is sent
        $(document).ajaxSend((event, request, settings) => {
            // We flag the settings object with our own debug property, so we
            // can recognize it when it completes
            settings.iq4Debug = true;

            openAjaxCounter++;
            ajaxTotal++;

            this.ajaxCallLog.push({
                'dir': 'out',
                'url': settings.url,
                'type': settings.type,
                'data': settings.data,
            });
        });

        // This handler is executed after an Ajax request has completed
        $(document).ajaxComplete((event, jqXHR, ajaxOptions) => {
            if(ajaxOptions.iq4Debug) {
                openAjaxCounter--;

                this.ajaxCallLog.push({
                    'dir': 'in',
                    'status': jqXHR.status,
                    'statusText': jqXHR.statusText,
                    'responseText': jqXHR.responseText,
                });
            }
        });

        let currentStep = 0;
        let runNextTest = (doneCallback) => {
            console.log('Run test in sequence. Tests left: ' + this.sequence.length);
            try {
                if(this.sequence.length === 0) {
                    return doneCallback(true);
                }
                currentStep++;
                $('span.step-nr').html(currentStep.toString());

                const next = this.sequence.shift();

                // If the item in the sequence is a function then we can simply execute it
                if(typeof next === 'function') {
                    next();
                } else if(next instanceof Expect) {
                    // The next item in the sequence is an Expectation so we
                    // need to evaluate it. This evaluation can return either a
                    // boolean or a [boolean,string] tuple where string are additional messages.
                    const result = next.getValue();
                    if(Helpers.checkType('boolean', result) && result !== true) {
                        this.messages.push(
                            'This failed because an expectation that was not met'
                        );
                        return doneCallback(false);
                    } else if(Helpers.checkType('array', result) && result[0] !== true) {
                        this.messages.push(
                            'This failed because of an expectation that was not met:\n' +
                            result[1]
                        );
                        return doneCallback(false);
                    }
                } else if(next instanceof Wait) {
                    return next.wait(() => {
                        return runNextTest(doneCallback);
                    });
                } else if(next instanceof WaitUnless) {
                    return next.waitUnless(() => {
                        return runNextTest(doneCallback);
                    });
                }

                let startTime = Date.now();
                let waitUntilDone = () => {
                    if(
                        openAjaxCounter <= 0 ||
                        ((Date.now() - startTime) >= this.maxExecutionTimePerTest)
                    ) {
                        return runNextTest(doneCallback);
                    }

                    Promise.resolve().then(() => {
                        window.setTimeout(waitUntilDone, 100);
                    });
                };

                waitUntilDone();
            } catch(error) {
                // Unhandled exception occurred in test block
                this.messages.push(
                    'This test failed because an unhandled exception occurred inside the test block: \n' +
                    error.toString()
                );
                doneCallback(false);
            }
        };

        runNextTest((result) => {
            // Remove ajax event handlers
            $(document).off('ajaxSend');
            $(document).off('ajaxComplete');
            callback(result);
        });
    }
}
