/**
 * Help module.
 * @module help
 */

"use strict";

import * as Dah from './dah';
import * as Helpers from './helpers';
import * as Session from './session';

// Set MarkedJS options
marked.use({
    async: false,
    pedantic: false,
    gfm: true,
    breaks: true,
});

/**
 * Try to retrieve a language appropriate help file, otherwise reverting to
 * English
 * @param {string} name
 * @param {string} [language] - ISO 2 letter language code
 * @returns {Promise}
 */
function getHelpFile(name, language=Session.getLanguage()) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: (
                '/help/context/' +
                language + '/' +
                name + '.md'
            ),
            type: 'GET',
            dataType: 'text',
            success: resolve,
            error: () => {
                if(language === 'en') {
                    return reject();
                }
                getHelpFile(name, 'en').then(resolve).catch(reject);
            },
        });
    });
}

/**
 * Try to retrieve a language appropriate global help file, otherwise reverting
 * to English
 * @param {string} section1
 * @param {string} section2
 * @param {string} [language] - ISO 2 letter language code
 * @returns {Promise}
 */
export function getGlobalHelpFile(section1, section2='', language=Session.getLanguage()) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: (
                '/help/global/' +
                language + '/' +
                Helpers.replaceAll(section1,' ','_') + '.json'
            ),
            type: 'GET',
            dataType: 'json',
            success: (data) => {
                if(!section2) {
                    return data;
                }
                for(let child of data.children) {
                    if(child.title === section2) {
                        return resolve(child);
                    }
                }
                return reject();

            },
            error: () => {
                if(language === 'en') {
                    return reject();
                }
                getGlobalHelpFile(section1, section2, 'en').then(resolve).catch(reject);
            },
        });
    });
}

/**
 * Get Device help. Resolves with markdown if found.
 * @param {object} device
 * @returns {Promise}
 */
export function device(device) {
    let file = Helpers.optChain(
        window.CONSTANTS.HELP_MAP,
        'device', device.type.toString(), 'general'
    );

    if(file) {
        return getHelpFile(file);
    }

    return Promise.reject();
}

/**
 * Check whether there's help available for a given device
 * @param {object} device
 * @returns {boolean}
 */
export function hasDeviceHelp(device) {
    return Helpers.optChain(
        window.CONSTANTS.HELP_MAP,
        'device', device.type.toString(), 'general'
    );
}

/**
 * @param {object} device - the parent object
 * @param {object} parameter
 * @returns {Promise}
 */
export function parameter(device, parameter) {
    let file = Helpers.optChain(
        window.CONSTANTS.HELP_MAP,
        'device', device.type.toString(), 'parameters', parameter.qid.toString(), 'help_text'
    );

    if(file) {
        return getHelpFile(file);
    }

    return Promise.reject();
}

/**
 * @param {object} device - the parent object
 * @param {object} parameter
 * @returns {string | undefined} - the fieldbus instruction or undefined if
 *     it's not found
 */
export function fieldbusInstruction(device, parameter) {
    let instructionName = Helpers.optChain(
        window.CONSTANTS.HELP_MAP,
        'device', device.type.toString(), 'parameters', parameter.qid.toString(), 'fieldbus_instruction'
    );
    return Helpers.optChain(
        window.CONSTANTS.HELP_MAP,
        'fieldbus', 'instructions', instructionName
    );
}

/**
 * @param {object} iqObject - the parent object
 * @param {string} settingName
 * @returns {Promise}
 */
export function setting(iqObject, settingName) {
    const objectType = Helpers.iqObjectType(iqObject);

    let file = Helpers.optChain(
        window.CONSTANTS.HELP_MAP,
        objectType, 'settings', settingName
    );

    if(file) {
        return getHelpFile(file);
    }

    return Promise.reject();
}

/**
 * Check whether help is available for the given event
 * @param {object} iqObject
 * @param {string} eventCode
 * @returns {boolean}
 */
export function hasEventHelp(iqObject, eventCode) {
    let root = [];
    if(Helpers.isDevice(iqObject)) {
        root = ['device', iqObject.type.toString()];
    } else if(Helpers.isFieldbus(iqObject)) {
        root = ['fieldbus'];
    } else if(Helpers.isClfb(iqObject)) {
        root = ['clfb'];
    }


    let file = Helpers.optChain(
        window.CONSTANTS.HELP_MAP,
        ...root, 'events', eventCode.toString()
    );

    if(file) {
        return true;
    }

    return false;
}

export function event(iqObject, eventCode) {
    let root = [];
    if(Helpers.isDevice(iqObject)) {
        root = ['device', iqObject.type.toString()];
    } else if(Helpers.isFieldbus(iqObject)) {
        root = ['fieldbus'];
    } else if(Helpers.isClfb(iqObject)) {
        root = ['clfb'];
    }

    let file = Helpers.optChain(
        window.CONSTANTS.HELP_MAP,
        ...root, 'events', eventCode.toString()
    );

    if(file) {
        return getHelpFile(file);
    }

    return Promise.reject();
}

/**
 * @returns {Promise}
 */
export function clfb() {
    return getHelpFile('clfb');
}

/**
 * @returns {Promise}
 */
export function fieldbus() {
    return getHelpFile('fieldbus');
}

/**
 * @param {string} actionName
 * @returns {Promise}
 */
export function action(actionName) {
    return getHelpFile('action-' + actionName);
}

/**
 * @param {string} helpText - the markdown text
 * @returns {string} the html
 */
export function render(helpText) {
    return DOMPurify.sanitize(marked.parse(Helpers.replaceAll(helpText, '\n', '\n')));
}
