/**
 * Device view module.
 * @module device
 */

"use strict";

import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Debug from '../core/debug';
import * as Messages from '../core/messages';
import * as Permissions from '../core/permissions';
import * as Signals from '../core/signals';
import * as State from '../core/state';
import * as Help from '../core/help';
import {RelatedView} from './related';

export class DeviceView {
    constructor() {
        this.viewName = 'DeviceView';
        this.device = null;
        this.stateListenerId = -1;
        this.listenerIds = [];
        this.fieldbusListenerId = -1;
        this.actionHandlerIds = [];
        this.fieldbusId = -1;
        this.loadingTimeout = -1;
        this.relatedView = null;
    }

    destructor() {
        if(this.stateListenerId !== -1){
            Signals.removeListener(this.stateListenerId);
        }

        if(this.relatedView !== null) {
            this.relatedView.destructor();
        }

        if(this.fieldbusListenerId !== -1) {
            Signals.removeListener(this.fieldbusListenerId);
        }
        Actions.removeActionHandlers(this.actionHandlerIds);
        Signals.removeListeners(this.listenerIds);

        window.clearTimeout(this.loadingTimeout);
    }

    relatedChange(msg) {
        if(msg.data.uid === this.device.uid) {
            this.refreshDevice();
        }
    }

    refreshFieldbusStatus() {
        if(this.device.relatedObjects === 0){
            if(this.fieldbusListener !== -1) {
                Signals.removeListener(this.fieldbusListener);
                this.fieldbusListener = -1;
                this.fieldbusId = -1;
            }
            return;
        }

        for(let i of this.device.relatedObjects) {
            let newFieldbusId = -1;

            if(i.type !== 'fieldbus') {
                continue;
            }

            newFieldbusId = i.uid;

            if(newFieldbusId !== -1 && newFieldbusId !== this.fieldbusId) {
                this.fieldbusId = newFieldbusId;

                if(this.fieldbusListener !== -1) {
                    Signals.removeListener(this.fieldbusListener);
                    this.fieldbusListener = -1;
                }

                this.fieldbusListenerId = Signals.addListener(
                    'fieldbus/status',
                    this.fieldbusStatusChanged.bind(this)
                );
            }
            break;
        }

    }

    fieldbusStatusChanged(msg) {
        if(msg.data.uid !== this.fieldbusId) {
            return;
        }
        this.refreshDevice();
    }

    refreshDevice() {
        if(this.device) {
            return window.visit('/#/device/' + this.device.uid);
        }
    }

    onMessage(msg) {
        if(msg.tag === 'device/parameter' && msg.data.device === this.device.uid){

            /** @type {false|object} */
            let parameter = false;
            for(let i = 0; i < this.device.parameters.length; i++){
                const p = this.device.parameters[i];
                if(p.qid === parseInt(msg.data.qid)){
                    parameter = p;
                    break;
                }
            }

            if(parameter === false){
                return;
            }

            parameter.currentValue = msg.data.value;

            Gui.switchDeviceParameter(
                this.device.uid,
                parameter,
                msg.data.value
            );
        }
    }

    onStatusChange(msg) {
        if(msg.data.uid !== this.device.uid){
            return;
        }
        this.device.status = msg.data.status;
        Gui.disableLoadingIcons();
        window.clearTimeout(this.loadingTimeout);
    }

    deviceRun(actionId, args, elem) {
        if(!Permissions.canPerformAction(this.device, 'run')) {
            return;
        }

        Gui.disableLoadingIcons();
        window.clearTimeout(this.loadingTimeout);

        Gui.enableLoadingIcon(elem);
        Api.deviceRun(actionId).then(() => {
            this.loadingTimeout = window.setTimeout(
                Gui.disableLoadingIcons,
                10000
            );
        }).catch((err) => {
            Gui.disableLoadingIcon(elem);
        });
    }

    deviceRunStandby(actionId, args, elem) {
        Gui.disableLoadingIcons();
        window.clearTimeout(this.loadingTimeout);

        Gui.enableLoadingIcon(elem);

        let apiFunc = Api.deviceStandby;

        if(this.device.status.mode === 'standby') {
            if(!Permissions.canPerformAction(this.device, 'run')) {
                return;
            }
            apiFunc = Api.deviceRun;
        } else {
            if(!Permissions.canPerformAction(this.device, 'standby')) {
                return;
            }
        }

        apiFunc(actionId).then(() => {
            this.loadingTimeout = window.setTimeout(
                Gui.disableLoadingIcons,
                10000
            );
        }).catch((err) => {
            Gui.disableLoadingIcon(elem);
        });
    }

    deviceEnableDisable(actionId, args, elem) {
        Gui.disableLoadingIcons();
        window.clearTimeout(this.loadingTimeout);

        /** @type {((uid: string) => Promise<any>)} */
        let apiFunc;
        let actionName = '';

        if(
            this.device.status.mode === 'disabled' ||
            this.device.status.mode === 'disconnected'
        ) {
            // TODO: Add check if user is allowed to enable this device
            apiFunc = Api.deviceEnable;
            actionName = 'enable';
        } else {
            if(!Permissions.canPerformAction(this.device, 'disable')) {
                return;
            }
            apiFunc = Api.deviceDisable;
            actionName = 'disable';
        }

        Gui.enableLoadingIcon(elem);
        apiFunc(actionId).then(() => {
            this.loadingTimeout = window.setTimeout(
                Gui.disableLoadingIcons,
                10000
            );
        }).catch((err) => {
            if(actionName === 'enable') {
                Messages.addError(120, _('Unexpected error occured while enabling'));
            } else if(actionName === 'disable') {
                Messages.addError(121, _('Unexpected error occured while disabling'));
            }
            Gui.disableLoadingIcon(elem);
        });
    }

    toggleHelp() {
        Gui.toggleHelpSection();
    }

    closeHelp() {
        Gui.closeHelpSection();
    }

    registerActionHandlers() {
        Debug.log('Register action handlers', 0, false);

        const id = this.device.uid;

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
               id, 'device-run-standby', this.deviceRunStandby.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'device-enable-disable', this.deviceEnableDisable.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'toggle-help', this.toggleHelp.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'close-help', this.closeHelp.bind(this)
            )
        );

    }

    objectRemoved(msg) {
        if(
            !msg.hasOwnProperty('data') ||
            !msg.data.hasOwnProperty('uid') ||
            msg.data.uid !== this.device.uid
        ) {
            return false;
        }
        window.visit('/#/');
    }

    help(event, args) {
        this.render(event, args, () => {
            this.toggleHelp();
        });
    }

    registerListeners() {
        this.listenerIds.push(Signals.listenToDevice(
            this.device.uid,
            this.onMessage.bind(this)
        ));

        this.stateListenerId = Signals.addListener(
            'device/status',
            this.onStatusChange.bind(this)
        );

        this.listenerIds.push(Signals.addListener(
            'object/relatedchange',
            this.relatedChange.bind(this)
        ));

        this.listenerIds.push(Signals.addListener(
            'object/removed',
            this.objectRemoved.bind(this)
        ));
    }

    /**
     * @param {Event} event
     * @param {object} args
     * @param {function | false} doneCallback
     */
    render(event, args, doneCallback=false) {
        Debug.log('Render view for device: ' + args.uid, 0);

        if(!args.hasOwnProperty('uid')){
            return Gui.show404();
        }

        Api.getDevice(args.uid).then((device) => {
            this.device = device;
            this.relatedView = new RelatedView(this.device);
            let deviceState = State.getDevice(device.uid);

            this.registerActionHandlers();
            this.registerListeners();

            device.position = deviceState.position;
            device.name = deviceState.name;

            Debug.log('Full device object:', 0);
            Debug.log(this.device, 0);

            let params = this.device.parameters;
            params.sort((param1, param2) => {
                return Helpers.parameterSort(device, param1, param2);
            });

            this.device.parameters = params;
            this.refreshFieldbusStatus();

            let context = {
                'device': this.device,
                'helpPath': 'Operation/Parameter_screen',
                'helpText': '',
            };

            Help.device(this.device).then((helpText) => {
                context.helpText = helpText;
            }).catch(() => {}).finally(() => {
                Gui.render('device.html', context);
                this.relatedView.render();
                if(doneCallback) {
                    doneCallback();
                }
            });

        }).catch((e) => {
            Debug.log('Error while rendering device screen', 3);
            Debug.log(e, 3);
            return Gui.show404();
        });
    }
}
