/**
 * Debug Expect module
 * @module DebugExpect
 */

"use strict";

export class Expect {
    constructor(func) {
        this.func = func;
    }

    getValue() {
        return this.func();
    }

    toString() {
        return this.func.toString();
    }
}
