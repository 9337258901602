/**
 * Legal view module.
 * @module help
 */

"use strict";

import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Help from '../core/help';
import * as Helpers from '../core/helpers';
import {View} from '../core/view';

export class LegalView extends View {
    constructor() {
        super();
        this.viewName = 'LegalView';
    }

    destructor() {
        super.destructor();
    }

    /**
     * Render this help view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args, editing=false) {
        let helpItem = false;

        Help.getLegalSummary().then((html) => {
            Gui.render('legal.html', {html: html});
        }).catch((e) => {
            Gui.render('404.html');
        });
    }
}
