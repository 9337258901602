/**
 * Settings view module.
 */

"use strict";
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as Gui from '../core/gui';
import * as Debug from '../core/debug';
import * as Permissions from '../core/permissions';
import * as Signals from '../core/signals';
import * as State from '../core/state';

/**
 * Settings view class
 */
export class SettingsView {
    constructor() {
        this.viewName = 'SettingsView';
        this.actionHandlerIds = [];
        this.listenerId = Signals.addListener('manager/status', this.updateManagerStatus.bind(this));

        this.updateCommenced = false;
        this.showingUpdatingScreen = false;
        this.currentUpdateTarget = 1;
    }

    destructor() {
        Actions.removeActionHandlers(this.actionHandlerIds);
        if(this.listenerId !== -1){
            Signals.removeListener(this.listenerId);
        }
    }

    updateManagerStatus(msg={}) {
        if(!msg.hasOwnProperty('data') || !msg.data.hasOwnProperty('status')) {
            return;
        }

        const data = msg.data;
        const status = msg.data.status;

        this.updateCommenced = status === 'updating';

        if(status === 'running' && this.updateCommenced) {
            return window.visit('/#/settings');
        }

        if(
            status === 'updating' && (
                data.update.status === 'device' ||
                data.update.status === 'slc'
            )
        ) {
            const newTarget = data.update.target;
            if(newTarget !== this.currentUpdateTarget) {
                this.currentUpdateTarget = data.update.target;
                this.updateShownVersions();
            }
        } else {
            this.currentUpdateTarget = -1;
        }
    }

    /**
     * Register action handlers, specifically the "release notes" buttons for
     * all the objects
     * @param {Array} ids
     */
    registerActionHandlers(ids){
        Debug.log('Register action handlers', 0, false);

        for(let id of ids) {
            this.actionHandlerIds.push(
                Actions.registerActionHandler(
                   id, 'show-release-notes', this.showReleaseNotes.bind(this)
                )
            );

            this.actionHandlerIds.push(
                Actions.registerActionHandler(
                   id, 'hide-release-notes', this.hideReleaseNotes.bind(this)
                )
            );
        }
    }

    showReleaseNotes(uid, args){
        $('tr.release-notes[data-id=' + uid + ']').removeClass('hidden');
        $('a.hide-release-notes[data-id=' + uid + ']').removeClass('hidden');
        $('a.show-release-notes[data-id=' + uid + ']').addClass('hidden');
    }

    hideReleaseNotes(uid, args) {
        $('tr.release-notes[data-id=' + uid + ']').addClass('hidden');
        $('a.hide-release-notes[data-id=' + uid + ']').addClass('hidden');
        $('a.show-release-notes[data-id=' + uid + ']').removeClass('hidden');
    }

    updateShownVersions() {
        Api.getVersions().then((versions) => {
            for(let item of versions) {
                if(item.uid !== '' && item.uid === this.currentUpdateTarget) {
                    $('td.version[data-uid=' + item.uid + ']').html(
                        _('Updating') +
                        Gui.loadingSpinnerHtml()
                    );
                } else if(item.uid !== '') {
                    $('td.version[data-uid=' + item.uid + ']').html(item.version);
                }
            }
        });
    }

    render(event, args) {
        Api.getVersions().then((versions) => {
            Debug.log('Render settings view', 0);
            Debug.log(State.getIqObjects(), 0);
            let ids = [];
            let noUidCount = 0;

            for(let item of versions) {
                // We add a name property to the version items so we can
                // display this properly in the GUI.
                item.name = '-';
                item.position = '-';
                item.factoryName = '-';

                if(window.CONSTANTS.PID_MAP.hasOwnProperty(item.type)) {
                    item.factoryName = window.CONSTANTS.PID_MAP[item.type];
                }

                const iqObject = State.getIqObject(item.uid);
                if(iqObject !== false) {
                    item.name = iqObject.name;
                    item.factoryName = iqObject.info;
                    if(iqObject.hasOwnProperty('position')) {
                        item.position = iqObject.position;
                    }
                }

                if(!item.hasOwnProperty('uid') || item.uid === '') {
                    noUidCount++;
                    item.id = 'nouid-' + noUidCount;
                } else {
                    item.id = item.uid;
                }

                ids.push(item.id);
            }

            Debug.log('List of versions: ', 0);
            Debug.log(versions, 0);
            let context = {
                'versions': versions,
                'currentUpdateTarget': this.currentUpdateTarget,
            };

            this.registerActionHandlers(ids);
            Gui.render('settings.html', context);
        }).catch((e) => {
            Debug.log('Error rendering settings screen', 3);
            Debug.log(e, 3);
            return Gui.soft404(_('Could not retrieve list of versions'));
        });
    }

    renderUpdates(event, args) {
        Api.getUpdates().then((updates) => {
            Debug.log('Render settings view');
            let ids = [];
            let noUidCount = 0;

            for(let item of updates) {
                if(window.CONSTANTS.PID_MAP.hasOwnProperty(item.type)) {
                    item.info = window.CONSTANTS.PID_MAP[item.type];
                    item.name = '-';
                    item.position = '-';
                } else {
                    const iqObject = State.getIqObject(item.uid);
                    if(iqObject !== false) {
                        item.name = iqObject.name;
                        item.info = iqObject.info;
                        item.position = iqObject.position;
                    }
                }

                if(!item.hasOwnProperty('uid') || item.uid === '') {
                    noUidCount++;
                    item.id = 'nouid-' + noUidCount;
                    item.nouid = true;
                } else {
                    item.id = item.uid;
                    item.nouid = false;
                }

                ids.push(item.id);
            }

            this.registerActionHandlers(ids);

            Debug.log('Available updates: ', 0);
            Debug.log(updates, 0);

            let context = {
                'updates': updates,
            };
            Gui.render('settings-updates.html', context);
        }).catch((e) => {
            Debug.log('Error rendering settings/updates screen', 3);
            Debug.log(e, 3);
            return Gui.soft404(_('Could not retrieve list of updates'));
        });
    }

    confirmInstall(event, args) {
        if(!Permissions.hasGlobalExecutePermission('update-firmware')) {
            return window.visit('/#/');
        }
        Gui.render('settings-confirm-update.html', {id: args.id});
    }

    install(event, args) {
        if(!Permissions.hasGlobalExecutePermission('update-firmware')) {
            return window.visit('/#/');
        }

        Api.update().then(() => {
            window.visit('/#/settings');
        }).catch((e) => {
            const response = e.responseJSON;
            Gui.render(
                'settings-install-updates-error.html',
                {message: response.error ?  response.error : 'Unknown error'}
            );
        });
    }

    updating(event, args) {
        this.showingUpdatingScreen = true;
        Gui.render('settings-install-updates.html');
    }
}
