/**
 * Customize view module.
 * @module customize-view
 */

"use strict";

import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Permissions from '../core/permissions';
import * as State from '../core/state';
import {DeviceView} from './device';
import {FieldbusView} from './fieldbus';
import {View} from '../core/view';

export class CustomizeView extends View {
    constructor() {
        super();
        this.viewName = 'CustomizeView';
        this.loadingTimeout = -1;

        this.addListener(
            'device/status',
            this.stateChange.bind(this)
        );

        this.addListener(
            'fieldbus/status',
            this.fieldbusStateChange.bind(this)
        );

        this.addListener(
            'internal/new-device-list',
            this.refresh.bind(this)
        );

        this.addListener(
            'internal/new-fieldbus-list',
            this.refresh.bind(this)
        );

        this.addListener(
            'internal/new-clfb-list',
            this.refresh.bind(this)
        );

        this.registerActionHandler(
            'sorter', 'sort-name', this.sortByName.bind(this)
        );

        this.registerActionHandler(
            'sorter', 'sort-position', this.sortByPosition.bind(this)
        );

        this.registerActionHandler(
            'sorter', 'sort-type', this.sortByType.bind(this)
        );

        this.registerActionHandler(
            'sorter', 'sort-manual', this.sortManual.bind(this)
        );

        this.hasFilters = false;
        this.mode = '';
        this.hasWarnings = null;
        this.hasErrors = null;

        this.dragSource = null;
        this.dropTarget = null;

        this.dropMiddle = null; // X coords of midpoint of drop target
        this.dropLeft = false; // whether to drop to the left of target
    }

    destructor() {
        super.destructor();

        let items = document.querySelectorAll('.customize-view section.device');
        items.forEach((item) => {
            item.removeEventListener('dragstart', this.handleDragStart);
            item.removeEventListener('dragend', this.handleDragEnd);
            item.removeEventListener('dragover', this.handleDragOver);
            item.removeEventListener('dragenter', this.handleDragEnter);
            item.removeEventListener('dragleave', this.handleDragLeave);
            item.removeEventListener('drop', this.handleDrop);
        });
    }

    refresh() {
        window.refresh();
    }

    stateChange(msg) {
        const mode = msg.data.status.mode;
        const uid = msg.data.uid;
        const name = msg.data.name;
        const position = msg.data.position;

        Gui.disableLoadingIcon($('a.toggle-mode-small[data-param=' + uid + ']'));

        if(!this.hasFilters){
            return;
        }

        let hideDevice = true;

        if(this.mode !== '') {
            hideDevice = this.mode !== mode;
        }

        if(this.hasWarnings !== null) {
            hideDevice = this.hasWarnings !== msg.data.status.warnings;
        }

        if(this.hasErrors !== null) {
            hideDevice = this.hasErrors !== msg.data.status.errors;
        }

        if(hideDevice) {
            Gui.hideDevice(uid);
        } else {
            Gui.showDevice(uid);
        }
    }

    fieldbusStateChange(msg) {
        const mode = msg.data.status.mode;
        const uid = msg.data.uid;
        const name = msg.data.name;
        const position = msg.data.position;

        if(!this.hasFilters){
            return;
        }

        let hideFieldbus = true;

        if(this.mode !== '') {
            hideFieldbus = this.mode !== mode;
        }

        if(this.hasErrors !== null) {
            hideFieldbus = msg.data.status.mode === 'error';
        }

        if(hideFieldbus) {
            Gui.hideDevice(uid);
        } else {
            Gui.showDevice(uid);
        }
    }

    saveOrder() {
        let items = document.querySelectorAll('.customize-view section.device');
        let newOrder = [];
        items.forEach((item) => {
            newOrder.push(item.getAttribute('data-uid'));
        });
        State.setCustomObjectOrder(newOrder);
    }

    sortByName(event) {
        State.setObjectOrderMode('name');
        this.refresh();
    }

    sortByType(event) {
        State.setObjectOrderMode('type');
        this.refresh();
    }

    sortByPosition(event) {
        State.setObjectOrderMode('position');
        this.refresh();
    }

    sortManual(event) {
        State.setObjectOrderMode('manual');
        this.refresh();
    }

    handleDragStart(event) {
        if(State.getObjectOrderMode() !== 'manual') {
            return false;
        }
        this.dragSource = event.currentTarget;
        this.dragSource.style.opacity = '0.4';
        this.dragSource.classList.add('drag-source');
        event.dataTransfer.dropEffect = 'move';
    }

    handleDragEnd(event) {
        if(State.getObjectOrderMode() !== 'manual') {
            return false;
        }
        event.currentTarget.style.opacity = '1';
        this.dragSource.classList.remove('drag-source');
        this.dragSource = null;
    }

    handleDragOver(event) {
        event.preventDefault();
        if(State.getObjectOrderMode() !== 'manual') {
            return false;
        }
        event.dataTransfer.dropEffect = 'move';

        if(event.clientX < this.dropMiddle && !this.dropLeft) {
            event.currentTarget.classList.remove('drop-right');
            this.dropLeft = true;
        } else if(event.clientX >= this.dropMiddle && this.dropLeft) {
            event.currentTarget.classList.add('drop-right');
            this.dropLeft = false;
        }
    }

    handleDragLeave(event) {
        event.currentTarget.classList.remove('drop-target');
        if(State.getObjectOrderMode() !== 'manual') {
            return false;
        }
        if(this.dropTarget === event.currentTarget) {
            this.dropTarget = null;
        }
    }

    handleDrop(event) {
        event.stopPropagation();
        if(State.getObjectOrderMode() !== 'manual') {
            return false;
        }
        event.currentTarget.classList.remove('drop-target');
        event.currentTarget.classList.remove('drop-right');

        if(event.clientX < this.dropMiddle) {
            $(event.currentTarget).before(this.dragSource);
        } else if(event.clientX >= this.dropMiddle) {
            $(event.currentTarget).after(this.dragSource);
        }

        this.saveOrder();
    }

    handleDragEnter(event) {
        if(State.getObjectOrderMode() !== 'manual') {
            return false;
        }
        if(this.dropTarget !== null && this.dropTarget !== event.currentTarget) {
            this.dropTarget.classList.remove('drop-target');
            this.dropTarget = null;
        }

        if(this.dropTarget !== event.currentTarget && this.dropTarget !== this.dragSource) {
            this.dropTarget = event.currentTarget;
            this.dropTarget.classList.add('drop-target');
            const rect = event.currentTarget.getBoundingClientRect();
            this.dropMiddle = rect.left + rect.width / 2;
        }

        if(event.clientX < this.dropMiddle) {
            event.currentTarget.classList.remove('drop-right');
            this.dropLeft = true;
        } else if(event.clientX >= this.dropMiddle) {
            event.currentTarget.classList.add('drop-right');
            this.dropLeft = false;
        }
    }

    render(event, args) {
        let context = {
            'helpPath': 'Operation/Getting_to_know_the_system',
            'objectOrderMode': State.getObjectOrderMode(),
        };

        context.iqObjects = State.getOrderedIqObjects();

        Gui.render('customize-view.html', context);

        let items = document.querySelectorAll('.customize-view section.device');
        items.forEach((item) => {
            item.addEventListener('dragstart', this.handleDragStart.bind(this));
            item.addEventListener('dragend', this.handleDragEnd.bind(this));
            item.addEventListener('dragover', this.handleDragOver.bind(this));
            item.addEventListener('dragenter', this.handleDragEnter.bind(this));
            item.addEventListener('dragleave', this.handleDragLeave.bind(this));
            item.addEventListener('drop', this.handleDrop.bind(this));
        });
    }
}
