/**
 * Debug Report Module
 * @module DebugReport
 */

"use strict";

import * as State from '../core/state';
import * as Log from './log';
import * as Constants from '../core/constants';

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export function recur(obj) {
  let result = {}, _tmp;
  for (var i in obj) {
    // enabledPlugin is too nested, also skip functions
    if (i === 'enabledPlugin' || typeof obj[i] === 'function') {
        continue;
    } else if (typeof obj[i] === 'object') {
        // get props recursively
        _tmp = recur(obj[i]);
        // if object is not {}
        if (Object.keys(_tmp).length) {
            result[i] = _tmp;
        }
    } else {
        // string, number or boolean
        result[i] = obj[i];
    }
  }
  return result;
}

function _getReportData() {
    let reportData = {};

    reportData.devices = State.getDevices();
    reportData.fieldbuses = State.getFieldbuses();
    reportData.logHistory = Log.getLogHistory();
    reportData.hmiVersion = Constants.VERSION;
    reportData.navigator = recur(window.navigator);

    return reportData;
}

export function submitAutomaticReport(message) {
    $.post(Constants.REPORT_URL, {
        'description': message,
        'automatic': true,
        'json': JSON.stringify(_getReportData()),
    }).done(() => {
    }).fail(() => {
    }).always(function(){
    });
}

export function submitReport() {
    $('input#submit-report').prop('disabled', true);

    let reportData = _getReportData();

    $.post(Constants.REPORT_URL, {
        'description': $('textarea.debug-description').val(),
        'automatic': false,
        'json': JSON.stringify(_getReportData()),
    }).done(() => {
        alert('Report is sent successfully');
    }).fail(() => {
        alert('Failed to send report.');
    }).always(function(){
        $('input#submit-report').prop('disabled', false);
    });
}
