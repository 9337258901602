/* istanbul ignore file */

/**
 * Parameter view module.
 * @module parameter
 */

"use strict";

import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Messages from '../core/messages';
import * as Permissions from '../core/permissions';
import * as State from '../core/state';
import {DeviceView} from './device';
import {LineChartWidget} from '../widgets/linechart';
import {View} from '../core/view';

export class ChartView extends View {
    constructor() {
        super();
        this.viewName = 'ChartView';
        this.device = null;
        this.parameter = null;
        this.realtime = false;
        this.maximized = false;
        this.chart = null;
    }

    destructor() {
        super.destructor();
        if(this.chart) {
            this.chart.destructor();
        }
    }

    onMessage(msg) {
        if(
            msg.tag === 'device/parameter' &&
            parseInt(msg.data.device) === parseInt(this.device.uid) &&
            parseInt(msg.data.qid) === parseInt(this.parameter.qid)
        ){
            this.parameter.currentValue = msg.data.value;
            if(this.realtime && this.chart !== null) {
                this.chart.addPoint(0, msg.data.timestamp, parseFloat(msg.data.value));
            }

            Gui.switchDeviceParameter(
                this.device.uid,
                this.parameter,
                msg.data.value
            );
        }
    }

    /**
     * Initialization, retrieves the device info from the api
     * and sets up all the instance variables.
     * @param {string} uid - the device UID
     * @param {string} qid - the parameter QID
     * @param {function} callback - called when everything is
     *                              initialized successfully
     * @private
     */
    initialize(uid, qid, callback) {
        this.Api.getDevice(uid).then((device) => {
            this.device = device;
            let numericQid = parseInt(qid);
            Debug.log('Device info:', 0);
            Debug.log(this.device, 0);

            for(let i = 0; i < this.device.parameters.length; i++){
                const p = this.device.parameters[i];
                if(p.qid === numericQid){
                    this.parameter = p;
                    break;
                }
            }

            if(this.parameter === null){
                return Gui.soft404(_('Parameter not found'));
            }

            let deviceState = State.getDevice(device.uid);

            device.position = deviceState.position;
            device.name = deviceState.name;

            this.addListener(
                this.device.uid,
                this.onMessage.bind(this)
            );

            callback();
        }).catch((e) => {
            Debug.log('Error initializing device for chart display', 3);
            Debug.log(e, 3);
            return Gui.soft404(_('Device not found'));
        });
    }

    initRealtimeChart(data=[]) {
        let initialData = [];

        for(const item in data){
            if(!data.hasOwnProperty(item)) {
                continue;
            }

            initialData.push({
                x: data[item].timestamp,
                y: data[item].average,
            });
        }

        this.chart.addDataSet(
            Gui.parameterName(this.parameter.name),
            initialData
        );
    }

    initHistoricChart(data=[], interval=60) {
        let datasetAverage = [];
        let datasetMax = [];
        let datasetMin = [];

        let lastTimestamp = -1;

        data = data.sort(Helpers.timeDataSort);

        for(const item in data){
            if(!data.hasOwnProperty(item)) {
                continue;
            }
            const timestamp = data[item].timestamp;

            if(lastTimestamp !== -1 && timestamp > (lastTimestamp + interval * 60000)){
                for(let i = lastTimestamp + interval * 60000; i < timestamp; i += interval * 60000){
                    datasetAverage.push({
                        x: i,
                        y: NaN,
                    });

                    datasetMax.push({
                        x: i,
                        y: NaN,
                    });

                    datasetMin.push({
                        x: i,
                        y: NaN,
                    });
                }
            }

            lastTimestamp = timestamp;

            datasetAverage.push({
                x: timestamp,
                y: data[item].average,
            });

            datasetMax.push({
                x: timestamp,
                y: data[item].max,
            });

            datasetMin.push({
                x: timestamp,
                y: data[item].min,
            });
        }

        this.chart.addDataSet('Average', datasetAverage);
        this.chart.addDataSet('Max', datasetMax, '#943131', 1);
        this.chart.addDataSet('Min', datasetMin, '#456b24', 1);
    }

    renderHistoricChart(callback) {
        const now = Math.round(Date.now() / 1000);
        const from = now - 48 * 3600;
        const until = now;
        const interval = 1;

        this.Api.getHistory(
            this.device.uid,
            this.parameter.name,
            from,
            until,
            interval
        ).then((data) => {
            this.initHistoricChart(data.values, interval);
            this.chart.render();
            callback();
        }).catch((error) => {
            Debug.log('Error retrieving history', 3);
            Debug.log(error, 3);
            Messages.addError(101, _('Error retrieving history'));
            this.initHistoricChart();
            this.chart.render();
            callback();
        });
    }

    /**
     * Render the chart using recent history data from the API.
     */
    renderRealtimeChart(callback) {
        this.Api.getRecentHistory(this.device.uid, this.parameter.name)
        .then((data) => {
            this.initRealtimeChart(data.values);
            this.chart.render();
            callback();
        }).catch((error) => {
            Debug.log('Error retrieving recent history', 3);
            Debug.log(error, 3);
            this.initRealtimeChart();
            this.chart.render();
            callback();
            Messages.addError(102, _('Error retrieving recent history'));
        });
    }

    /**
     * Show or hide the table that is shown above the chart
     */
    toggleTable(actionId, args, elem) {
        const table = $('section.chart-table');
        if(table.length === 0) {
            Debug.log('Tried showing chart table, but there is none', 2);
            return;
        }
        if(table.hasClass('show')) {
            table.removeClass('show');
            $(elem).html(_('Show table'));
        } else {
            table.addClass('show');
            $(elem).html(_('Hide table'));
        }
    }

    /**
     * Toggle maximization of the chart
     */
    toggleMaximize(actionId, args, elem) {
        if(this.maximized) {
            $('section.chart').removeClass('maximized');
        } else {
            $('section.chart').addClass('maximized');
        }
        this.maximized = !this.maximized;
        this.chart.resetSize();
    }

    initActionHandlers() {
        this.registerActionHandler(
            'chart', 'toggle-table', this.toggleTable.bind(this)
        );

        this.registerActionHandler(
            'chart', 'toggle-maximize', this.toggleMaximize.bind(this)
        );

        this.registerActionHandler(
            'chart', 'reset-zoom', this.chart.resetZoom.bind(this.chart)
        );

        this.registerActionHandler(
            'chart', 'zoom-in', this.chart.zoomIn.bind(this.chart)
        );

        this.registerActionHandler(
            'chart', 'zoom-out', this.chart.zoomOut.bind(this.chart)
        );
    }

    objectRemoved(msg) {
        if(
            !msg.hasOwnProperty('data') ||
            !msg.data.hasOwnProperty('uid') ||
            msg.data.uid !== this.device.uid
        ) {
            return false;
        }
        window.visit('/#/');
    }

    initSignalListeners() {
        this.addListener(
            'screen/resize',
            this.chart.resetSize.bind(this.chart)
        );

        this.addListener(
            'object/removed',
            this.objectRemoved.bind(this)
        );
    }

    /**
     * Render this chart view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     * @param {Object | false} data
     * @param {boolean} realtime
     */
    render(event, args, data=false, realtime=true) {
        if(!args.hasOwnProperty('uid') || !args.hasOwnProperty('qid')){
            return Gui.show404();
        }

        Debug.log(
            'Render chart view for device ' +
            args.uid +
            ', and parameter ' +
            args.qid,
            0
        );

        this.realtime = realtime;

        this.initialize(args.uid, args.qid, () => {
            this.chart = new LineChartWidget(this.parameter.name, 'line', realtime);

            let context = {
                'device': this.device,
                'parameter': this.parameter,
                'realtime': this.realtime,
                'widget': this.chart,
            };

            Gui.render('chart.html', context);

            Debug.log('Parameter details', 0);
            Debug.log(this.parameter, 0);

            if(!this.realtime) {
                this.renderHistoricChart(() => {
                    this.initSignalListeners();
                    this.initActionHandlers();
                });
            } else {
                this.renderRealtimeChart(() => {
                    this.initSignalListeners();
                    this.initActionHandlers();
                });
            }
        });
    }

    /**
     * Renders the chart with a larger history
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    renderHistory(event, args) {
        this.render(event, args, false, false);
    }
}
