/**
 * Parameter view module.
 * @module parameter
 */

"use strict";

import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Messages from '../core/messages';
import * as Permissions from '../core/permissions';
import * as State from '../core/state';
import {DeviceView} from './device';
import {TableWidget} from '../widgets/table';
import {View} from '../core/view';

export class TableView extends View {
    constructor() {
        super();
        this.viewName = 'TableView';
        this.device = null;
        this.parameter = null;
        this.realtime = false;
        this.table = null;
    }

    destructor() {
        super.destructor();
        if(this.table) {
            this.table.destructor();
        }
    }

    onMessage(msg) {
        if(
            msg.tag === 'device/parameter' &&
            parseInt(msg.data.device) === parseInt(this.device.uid) &&
            parseInt(msg.data.qid) === parseInt(this.parameter.qid)
        ){
            this.parameter.currentValue = msg.data.value;
            if(this.realtime && this.table !== null) {
                this.table.addPoint(msg.data.timestamp, [parseFloat(msg.data.value)]);
            }

            Gui.switchDeviceParameter(
                this.device.uid,
                this.parameter,
                msg.data.value
            );
        }
    }

    /**
     * Initialization, retrieves the device info from the api
     * and sets up all the instance variables.
     * @param {string} uid - the device UID
     * @param {string} qid - the parameter QID
     * @param {function} callback - called when everything is
     *                              initialized successfully
     * @private
     */
    initialize(uid, qid, callback) {
        this.Api.getDevice(uid).then((device) => {
            this.device = device;
            let numericQid = parseInt(qid);
            Debug.log('Device info:', 0);
            Debug.log(this.device, 0);

            for(let i = 0; i < this.device.parameters.length; i++){
                const p = this.device.parameters[i];
                if(p.qid === numericQid){
                    this.parameter = p;
                    break;
                }
            }

            if(this.parameter === null){
                return Gui.show404();
            }

            let deviceState = State.getDevice(device.uid);

            device.position = deviceState.position;
            device.name = deviceState.name;

            this.addDeviceListener(
                this.device.uid,
                this.onMessage.bind(this)
            );

            callback();
        }).catch((e) => {
            Debug.log('Error initializing device for table display', 3);
            Debug.log(e, 3);
            return Gui.soft404(_('Could not find device'));
        });
    }

    /**
     * Removes repeating values, keeping only one for each repetition
     * @param {array} dataSet - array of pairs => [timestamp, [values...]]
     * @returns {array}
     */
    removeRepeatingValues(dataSet) {
        let occurenceIndex = {};

        return dataSet.filter((val, index) => {
            // String value for all the values for this timestamp
            const strVal = val[1].reduce(
                (acc, cur) => acc + '-' + cur.toString(),
                ''
            );

            // Check if it occures in the index and the last occurence was the
            // previous entry
            const equalsPrev = (
                occurenceIndex.hasOwnProperty(strVal) &&
                occurenceIndex[strVal] === index - 1
            );

            occurenceIndex[strVal] = index;

            return !equalsPrev;
        });
    }

    initRealtimeTable(values=[]) {
        this.table.initDataSets(
            [Gui.parameterName(this.parameter.name)],
            this.removeRepeatingValues(
                values.map(val => [
                    val.timestamp,
                    [val.average],
                ])
            )
        );
    }

    initHistoricTable(values=[]) {
        this.table.initDataSets(
            ['Average', 'Min', 'Max'],
            this.removeRepeatingValues(
                values.sort(Helpers.timeDataSort).map(val => [
                    val.timestamp,
                    [val.average, val.min, val.max]
                ])
            )
        );

    }

    renderHistoricTable(callback) {
        const now = Math.round(Date.now() / 1000);
        const from = now - 48 * 3600;
        const until = now;
        const interval = 1;

        this.Api.getHistory(
            this.device.uid,
            this.parameter.name,
            from,
            until,
            interval
        ).then((data) => {
            this.initHistoricTable(data.values);
            this.table.render();
            callback();
        }).catch((error) => {
            Debug.log('Error retrieving history', 3);
            Debug.log(error, 3);
            Messages.addError(101, _('Error retrieving history'));
            this.initHistoricTable();
            this.table.render();
            callback();
        });
    }

    /**
     * Render the table using recent history data from the API.
     */
    renderRealtimeTable(callback) {
        this.Api.getRecentHistory(this.device.uid, this.parameter.name)
        .then((data) => {
            this.initRealtimeTable(data.values);
            this.table.render();
            callback();
        }).catch((error) => {
            Debug.log('Error retrieving recent history', 3);
            Debug.log(error, 3);
            this.initRealtimeTable();
            this.table.render();
            callback();
            Messages.addError(102, _('Error retrieving recent history'));
        });
    }

    objectRemoved(msg) {
        if(
            !msg.hasOwnProperty('data') ||
            !msg.data.hasOwnProperty('uid') ||
            msg.data.uid !== this.device.uid
        ) {
            return false;
        }
        window.visit('/#/');
    }

    initActionHandlers() {}

    initSignalListeners() {
        this.addListener(
            'object/removed',
            this.objectRemoved.bind(this)
        );
    }

    /**
     * Render this table view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     * @param {Object | false} data
     * @param {boolean} realtime
     */
    render(event, args, data=false, realtime=true) {
        if(!args.hasOwnProperty('uid') || !args.hasOwnProperty('qid')){
            return Gui.show404();
        }

        Debug.log(
            'Render table view for device ' +
            args.uid +
            ', and parameter ' +
            args.qid,
            0
        );

        this.realtime = realtime;

        this.initialize(args.uid, args.qid, () => {
            this.table = new TableWidget(this.parameter.name, realtime);
            const paramMeta = Helpers.getParameter(this.device, this.parameter.qid);

            this.table.addContext({
                iqObject: this.device,
                property: this.parameter,
                propertyMeta: paramMeta,
            });

            let context = {
                'device': this.device,
                'parameter': this.parameter,
                'realtime': this.realtime,
                'widget': this.table,
                'hasRealtimeVisualization': Helpers.optChain(paramMeta, 'hasRealtimeVisualization'),
                'hasHistoricVisualization': Helpers.optChain(paramMeta, 'hasHistoricVisualization'),
            };

            Gui.render('table.html', context);

            Debug.log('Parameter details', 0);
            Debug.log(this.parameter, 0);

            if(!this.realtime) {
                this.renderHistoricTable(() => {
                    this.initSignalListeners();
                    this.initActionHandlers();
                });
            } else {
                this.renderRealtimeTable(() => {
                    this.initSignalListeners();
                    this.initActionHandlers();
                });
            }
        });
    }

    /**
     * Renders the table with a larger history
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    renderHistory(event, args) {
        this.render(event, args, false, false);
    }
}
