/**
 * Routing module.
 * @module routing
 */

"use strict";

import * as Gui from './core/gui';
import {createNewEvent} from './core/helpers';
import {log} from './core/debug';
import {Url} from './core/url';
import {ActionView} from './views/action';
import {ChartView} from './views/chart';
import {TableView} from './views/table';
import {CustomizeView} from './views/customize-view';
import {DeviceView} from './views/device';
import {DeviceDashboardView} from './views/device-dashboard';
import {FieldbusView} from './views/fieldbus';
import {ClfbView} from './views/clfb';
import {HelpView} from './views/help';
import {HomeView} from './views/home';
import {LogView} from './views/log';
import {LoginView} from './views/login';
import {LogoutView} from './views/logout';
import {ParameterView} from './views/parameter';
import {SettingView} from './views/setting';
import {SettingsView} from './views/settings';
import {UserView} from './views/user';
import {TimeView} from './views/time';
import {ScreensaverView} from './views/screensaver';
import {ClfbIoView} from './views/clfb-io';
import {ClfbIoChartView} from './views/clfb-io-chart';
import {LegalView} from './views/legal';
import * as Session from './core/session';

/**
 * List of Urls.
 * @private
 */
let urls = [];

/**
 * The current Url object
 * @private
 */
let currentUrl;

/**
 * The current Url string, including parameters
 */
let currentUrlString;

/**
 * Current overlay url (used for the screensaver)
 * @private
 */
let currentOverlayUrl;

let initialized = false;

/**
 * Initialize the routing, setting up all the urls.
 * @code Example:
 * <pre>
 * urls = [
 *     new Url('/form/', TestView, 'render'),
 *     new Url('/form/result/', TestView, 'formSubmit'),
 * ];
 * </pre>
 */
export function initRouting() {
    if(initialized) {
        return false;
    }

    log('Initializing Routing');
    urls = [
        new Url('/', HomeView),
        new Url('/#/', HomeView),
        new Url('/#/refresh', HomeView, 'refreshBrowser'),
        new Url('/#/login', LoginView, 'render', false),
        new Url('/#/password-reset', LoginView, 'renderPasswordReset', false),
        new Url('/#/logout/silent', LogoutView, 'silentLogout'),
        new Url('/#/logout', LogoutView),
        new Url('/#/user', UserView),
        new Url('/#/user/new', UserView, 'createUser'),
        new Url('/#/user/delete/:id', UserView, 'deleteUser'),
        new Url('/#/user/:id', UserView, 'edit'),
        new Url('/#/settings', SettingsView),
        new Url('/#/settings/updates', SettingsView, 'renderUpdates'),
        new Url('/#/settings/updates/install/confirm', SettingsView, 'confirmInstall'),
        new Url('/#/settings/updates/install', SettingsView, 'install'),
        new Url('/#/settings/updating', SettingsView, 'updating'),
        new Url('/#/device-dashboard/:uid', DeviceDashboardView),
        new Url('/#/device/:uid', DeviceView),
        new Url('/#/device/:uid/help', DeviceView, 'help'),
        new Url('/#/device/:uid/parameters/:qid', ParameterView),
        new Url('/#/device/:uid/parameters/:qid/edit', ParameterView, 'edit'),
        new Url('/#/device/:uid/parameters/:qid/chart', ChartView, 'render'),
        new Url('/#/device/:uid/parameters/:qid/historic-chart', ChartView, 'renderHistory'),
        new Url('/#/device/:uid/parameters/:qid/table', TableView, 'render'),
        new Url('/#/device/:uid/parameters/:qid/historic-table', TableView, 'renderHistory'),
        new Url('/#/device/:uid/actions/:id', ActionView, 'render'),
        new Url('/#/device/:uid/actions/:id/perform', ActionView, 'perform'),
        new Url('/#/fieldbus/:uid', FieldbusView),
        new Url('/#/fieldbus/:uid/settings/:name', SettingView),
        new Url('/#/fieldbus/:uid/settings/:name/edit', SettingView, 'edit'),
        new Url('/#/fieldbus/:uid/actions/:id', ActionView, 'render'),
        new Url('/#/fieldbus/:uid/actions/:id/perform', ActionView, 'perform'),
        new Url('/#/clfb/:uid', ClfbView),
        new Url('/#/clfb/:uid/io', ClfbIoView),
        new Url('/#/clfb/:uid/io/chart', ClfbIoChartView),
        new Url('/#/clfb/:uid/settings/:name', SettingView),
        new Url('/#/clfb/:uid/settings/:name/edit', SettingView, 'edit'),
        new Url('/#/clfb/:uid/actions/:id', ActionView, 'render'),
        new Url('/#/clfb/:uid/actions/:id/perform', ActionView, 'perform'),
        new Url('/#/help/:section1', HelpView),
        new Url('/#/help/:section1/:section2', HelpView),
        new Url('/#/help', HelpView),
        new Url('/#/log/:uid', LogView),
        new Url('/#/time', TimeView),
        new Url('/#/screensaver', ScreensaverView),
        new Url('/#/customize-view', CustomizeView),
        new Url('/#/legal', LegalView),
        new Url('/#/:filter', HomeView),
    ];


    /**
     * @typedef {Object} Window
     * @property {function} visit
     * @property {function} refresh
     */

    /**
     * Add new properties to the global window object.
     * @type {Window}
     */
    window.visit = visit;
    /**
     * Add new properties to the global window object.
     * @type {Window}
     */
    window.refresh = refresh;
    initialized = true;
}

/**
 * Try to visit the given url. Sequentially tries to match the given url to
 * all the Urls provided in [[Routing.urls]]. If there's a match it will
 * visit that url by calling its [[Url.visit]]
 * @param {string} url - the url, without the domain name
 * @param {Event} event - the event triggering this visit call
 * @param {object} data - optional form data object
 * @param {boolean} addToHistory - whether to push the current state
 * @param {boolean} overlayOnly - show new url only as an overlay, don't change
 *                                current url
 * @global
 */
export function visit(url, event = createNewEvent(''), data=false, addToHistory=true, overlayOnly=false) {
    log(`Try to visit ${url}`);
    let targetUrl;

    try {
        let matchedUrl = matchUrl(url);
        targetUrl = matchedUrl;
    } catch (e){
        Gui.show404();
        return log(`Could not match "${url}"`);
    }

    if(currentUrl && currentUrlString === url) {
        log('Refreshing current url, skip adding it to the history');
        addToHistory = false;
    }

    if(addToHistory) {
        window.history.pushState({}, '', url);
    }

    if(currentUrl && !overlayOnly){
        currentUrl.destructor();
        currentUrl = null;
    }

    if(currentOverlayUrl) {
        currentOverlayUrl.destructor();
        currentOverlayUrl = null;
    }

    if(!overlayOnly) {
        currentUrlString = url;
        currentUrl = targetUrl;
    } else {
        currentOverlayUrl = targetUrl;
    }

    targetUrl.visit(url, event, data);
    window.scrollTo(0, 0);
}

/**
 * Refresh the current page. This function properly destructs the current view
 * before visiting it again. This function is useful after doing custom
 * rendering (i.e in case of error / connection drops)
 */
export function refresh() {
    if(currentUrlString){
        visit(currentUrlString);
    }
}

/**
 * matchUrl takes an url and tries to match it with the current list of urls,
 * if it matches one it returns the url it matches with, otherwise it throws a
 * new UrlNotFound Exception
 * @param {string} url - the url, without the domain name
 * @private
 */
function matchUrl(url) {
    for(let i = 0; i < urls.length; i++){
        let targetUrl = urls[i];
        if(targetUrl.match(url)) {
            return targetUrl;
        }
    }
    throw {
        'name': 'UrlNotFound',
        'message': `Could not find url: "${url}"`
    };
}

export function getCurrentUrlString() {
    return currentUrlString;
}
