/**
 * Home view module.
 */

"use strict";

import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Permissions from '../core/permissions';
import * as State from '../core/state';
import {DeviceView} from './device';
import {FieldbusView} from './fieldbus';
import {View} from '../core/view';

/**
 * Home view class
 */
export class HomeView extends View {
    constructor() {
        super();
        this.viewName = 'HomeView';
        this.loadingTimeout = -1;
        this.fieldbusMappings = {}; // Fieldbus uid => array of related uids

        this.addListener(
            'device/status',
            this.stateChange.bind(this)
        );

        this.addListener(
            'fieldbus/status',
            this.fieldbusStateChange.bind(this)
        );

        this.addListener(
            'internal/new-device-list',
            this.refresh.bind(this)
        );

        this.addListener(
            'internal/new-fieldbus-list',
            this.refresh.bind(this)
        );

        this.addListener(
            'internal/new-clfb-list',
            this.refresh.bind(this)
        );

        this.addListener(
            'object/relatedchange',
            this.relatedChange.bind(this)
        );

        this.registerActionHandler(
            'general', 'device-run-standby', this.deviceRunStandby.bind(this)
        );

        this.hasFilters = false;
        this.mode = '';
        this.hasWarnings = null;
        this.hasErrors = null;
    }

    destructor() {
        super.destructor();
    }

    refresh() {
        Debug.log('New object registered, refreshing Home screen', 0, false);
        Debug.log(State.getDevices(), 0, false);
        Debug.log(State.getFieldbuses(), 0, false);
        Debug.log(State.getClfbs(), 0, false);
        this.render(null,{});
    }

    stateChange(msg) {
        const mode = msg.data.status.mode;
        const uid = msg.data.uid;
        const name = msg.data.name;
        const position = msg.data.position;

        Gui.disableLoadingIcon($('a.toggle-mode-small[data-param=' + uid + ']'));

        if(!this.hasFilters){
            return;
        }

        let hideDevice = true;

        if(this.mode !== '') {
            hideDevice = this.mode !== mode;
        }

        if(this.hasWarnings !== null) {
            hideDevice = this.hasWarnings !== msg.data.status.warnings;
        }

        if(this.hasErrors !== null) {
            hideDevice = this.hasErrors !== msg.data.status.errors;
        }

        if(hideDevice) {
            Gui.hideDevice(uid);
        } else {
            Gui.showDevice(uid);
        }
    }

    relatedChange(msg) {
        if(
            msg.data.hasOwnProperty('uid') &&
            this.fieldbusMappings.hasOwnProperty(msg.data.uid)
        ) {
            this.initModeButtons();
        }
    }

    deviceRunStandby(actionId, args, elem) {
        const uid = args.param;

        Gui.disableLoadingIcons();
        window.clearTimeout(this.loadingTimeout);

        Gui.enableLoadingIcon(elem);

        const device = State.getDevice(uid);
        if(!(device.status.mode === 'standby' || device.status.mode === 'running')) {
            if(Helpers.hasDashboard(device)) {
                return window.visit('/#/device-dashboard/' + uid);
            } else {
                return window.visit('/#/device/' + uid);
            }
        }

        let apiFunc = this.Api.deviceStandby;

        if(device.status.mode === 'standby') {
            if(!Permissions.canPerformAction(device, 'run')) {
                return;
            }
            apiFunc = this.Api.deviceRun;
        } else {
            if(!Permissions.canPerformAction(device, 'standby')) {
                return;
            }
        }

        apiFunc(uid).then(() => {
            this.loadingTimeout = window.setTimeout(
                Gui.disableLoadingIcons,
                10000
            );
        }).catch((err) => {
            Gui.disableLoadingIcon(elem);
        });
    }

    fieldbusStateChange(msg) {
        const mode = msg.data.status.mode;
        const uid = msg.data.uid;
        const name = msg.data.name;
        const position = msg.data.position;

        this.toggleModeButtons();

        if(!this.hasFilters){
            return;
        }

        let hideFieldbus = true;

        if(this.mode !== '') {
            hideFieldbus = this.mode !== mode;
        }

        if(this.hasErrors !== null) {
            hideFieldbus = msg.data.status.mode === 'error';
        }

        if(hideFieldbus) {
            Gui.hideDevice(uid);
        } else {
            Gui.showDevice(uid);
        }
    }

    toggleModeButtons() {
        let processedDeviceUids = [];

        for(let fieldbusUid in this.fieldbusMappings) {
            if(!this.fieldbusMappings.hasOwnProperty(fieldbusUid)) {
                continue;
            }

            let fieldbus = State.getIqObject(fieldbusUid);
            let fieldbusMode = fieldbus.status.mode;
            let showButton = (
                fieldbusMode === 'stopped' ||
                fieldbusMode === 'disabled'
            );

            for(let deviceUid of this.fieldbusMappings[fieldbusUid]) {
                if(showButton) {
                    Gui.showSmallModeButton(deviceUid);
                } else {
                    Gui.hideSmallModeButton(deviceUid);
                }
                processedDeviceUids.push(deviceUid);
            }
        }

        const devices = State.getDevices();
        for(let device of State.getDevices()){
            if(processedDeviceUids.indexOf(device.uid) !== -1) {
                continue;
            }
            Gui.showSmallModeButton(device.uid);
            processedDeviceUids.push(device.uid);
        }
    }

    /**
     * Initialize the small run/standby buttons that appear in device items.
     */
    initModeButtons() {
        const fieldbuses = State.getFieldbuses();

        this.fieldbusMappings = {};
        let processedFieldbuses = 0;

        for(let fieldbus of fieldbuses) {
            this.fieldbusMappings[fieldbus.uid] = [];
            this.Api.getFieldbus(fieldbus.uid).then((fieldbusData) => {
                for(let module of fieldbusData.modules) {
                    this.fieldbusMappings[fieldbus.uid].push(module.uid);
                }
            }).catch(()=>{
                Debug.log('Failed to retrieve fieldbus with UID: ' + fieldbus.uid, 3);
            }).finally(()=>{
                processedFieldbuses++;
                if(processedFieldbuses === fieldbuses.length) {
                    this.toggleModeButtons();
                }
            });
        }

        if(fieldbuses.length === 0) {
            this.toggleModeButtons();
        }
    }

    refreshBrowser(event, args) {
        return window.location.assign('/');
    }

    render(event, args) {
        let context = {
            'helpPath': 'Operation/Getting_to_know_the_system'
        };

        if(args.hasOwnProperty('filter')){
            this.hasFilters = true;
            context.filter = args.filter;
            switch(args.filter){
                case 'error':
                    this.hasErrors = true;
                    break;
                case 'warning':
                    this.hasWarnings = true;
                    break;
                default:
                    this.mode = args.filter;
                    break;
            }

            const filteredDevices = State.filterDevices(
                this.mode,
                this.hasWarnings,
                this.hasErrors
            );

            const filteredFieldbuses = State.filterFieldbuses(
                this.mode,
                this.hasWarnings,
                this.hasErrors
            );

            const filteredClfbs = State.filterClfbs(
                this.mode,
                this.hasWarnings,
                this.hasErrors
            );
            context.iqObjects = State.getOrderedIqObjects(
                filteredDevices.concat(filteredFieldbuses).concat(filteredClfbs)
            );
        } else {
            context.iqObjects = State.getOrderedIqObjects();
        }

        Gui.render('home.html', context);
        this.initModeButtons();
    }
}
