/**
 * Log view module.
 * @module log
 */

"use strict";

import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Help from '../core/help';
import * as Helpers from '../core/helpers';
import * as Messages from '../core/messages';
import * as Session from '../core/session';
import * as State from '../core/state';
import {DeviceView} from './device';
import {View} from '../core/view';

export class LogView extends View {
    constructor() {
        super();
        this.viewName = 'LogView';
        this.iqObject = null;
        this.listenerIds = [];
        this.actionHandlerIds = [];
        this.currentFilter = '';
        this.lastTimestamp = -1;
        this.showFromTimestamp = -1;
    }

    destructor() {
        super.destructor();
    }

    onMessage(msg) {}

    onLogEvent(msg) {
        if(msg.data.origin !== this.iqObject.uid){
            return;
        }
        Debug.log('Log event received, updating log table', 0, false);
        Debug.log(msg, 0, false);
        if($('tr.event-help:visible').length === 0) {
            this.updateLogTable();
        } else {
            Messages.addMessage(_('New log entries have been received. Click on the "refresh log" button to update the list.'), 'neutral', 3000);
        }
    }

    dismissLog(actionId, args) {
        let callback = () => {
            if(this.lastTimestamp > -1) {
                this.showFromTimestamp = this.lastTimestamp;
                window.sessionStorage.setItem(
                    'showFromTimestamp-' + this.iqObject.uid, this.showFromTimestamp.toString()
                );
            }
            Gui.clearTable('log');
            $('a.show-dismissed').show();
        };

        if(Helpers.iqObjectType(this.iqObject) === 'device') {
            this.Api.deviceClear(actionId).then(callback).catch((err) => {
                Messages.addError(103, _('Failed to clear the log'));
            });
        } else {
            callback();
        }
    }

    showDismissedLog(actionId, args) {
        this.showFromTimestamp = -1;
        window.sessionStorage.removeItem('showFromTimestamp-' + this.iqObject.uid);
        $('a.show-dismissed').hide();
        this.updateLogTable();
    }

    refreshLog(actionId, args) {
        $('tr.event-help:visible').fadeOut();
        this.updateLogTable();
    }

    filter(actionId, args, elem) {
        let value = $(elem).val();
        if(typeof value === 'string') {
            this.currentFilter = value;
            Gui.filterTable('log', 'data-type', value);
        }
    }

    updateLogTable() {
        let context = {
            'iqObject': this.iqObject,
            'logItems': [],
            'error': false,
        };

        if(this.showFromTimestamp > -1){
            $('a.show-dismissed').show();
        }

        this.Api.getLogHistory(this.iqObject).then((rawLogItems) => {
            Debug.log('Received log items', 0, false);
            Debug.log(rawLogItems, 0, false);
            let logItems = [];

            rawLogItems = rawLogItems.sort(Helpers.timeDataSort).reverse();
            Debug.log('Updating log table', 0, false);
            Debug.log(rawLogItems, 0, false);
            for(let i = 0; i < rawLogItems.length; i++){
                let item = rawLogItems[i];
                if(item.timestamp <= this.showFromTimestamp) {
                    continue;
                }

                item.prettyDate = moment.unix(
                    Math.floor(item.timestamp / 1000)
                ).format('MMMM Do YYYY, HH:mm:ss');

                if(item.timestamp > this.lastTimestamp) {
                    this.lastTimestamp = item.timestamp;
                }

                item.hasHelpText = Help.hasEventHelp(this.iqObject, item.code);

                logItems.push(item);
            }
            context.logItems = logItems;
            $('table.log tbody').html(Gui.renderToString('log-table.html', context));
            Gui.filterTable('log', 'data-type', this.currentFilter);
        }).catch((err) => {
            context.error = true;
            Debug.log('Error retrieving event log history', 3);
            Debug.log(err, 3);
            $('table.log tbody').html(Gui.renderToString('log-table.html', context));
        });
    }

    showEventHelp(actionId, args, elem) {
        $('tr.event-help:visible').fadeOut();
        const helpElem = $(elem).parent().parent().next();

        if(helpElem.length === 0) {
            return;
        }

        if(!helpElem.hasClass('event-help')) {
            return;
        }

        let code = $(elem).parent().parent().attr('data-code');

        Help.event(this.iqObject, code).then((helpText) => {
            $('.help-contents').html(Help.render(helpText));
        }).catch(() => {}).finally(() => {
            helpElem.fadeIn();
        });
    }

    closeEventHelp(actionId, args, elem) {
        const helpElem = $(elem).parent().parent();

        if(helpElem.length > 0) {
            helpElem.fadeOut();
        }
    }

    objectRemoved(msg) {
        if(
            !msg.hasOwnProperty('data') ||
            !msg.data.hasOwnProperty('uid') ||
            msg.data.uid !== this.iqObject.uid
        ) {
            return false;
        }
        window.visit('/#/');
    }

    render(event, args){
        Debug.log('Render log page for iqObject: ' + args.uid);
        Gui.render('loading.html');

        if(!args.hasOwnProperty('uid')){
            return Gui.show404();
        }

        this.iqObject = State.getIqObject(args.uid);
        if(this.iqObject === false) {
            Debug.log('Showing log page for IQ Object with uid ' + args.uid + ' failed. Object not found.', 3);
            return Gui.soft404(
                Helpers.capitalize(Helpers.prettyIqObjectType(this.iqObject)) +
                ' ' + _('not found')
            );
        }

        if(Helpers.iqObjectType(this.iqObject) === 'device') {
            this.addDeviceListener(
                this.iqObject.uid,
                this.onMessage.bind(this)
            );
        }

        let showFromTimestamp = window.sessionStorage.getItem('showFromTimestamp-' + this.iqObject.uid);
        if(showFromTimestamp !== null){
            this.showFromTimestamp = parseInt(showFromTimestamp);
            if(isNaN(this.showFromTimestamp)) {
                this.showFromTimestamp = -1;
            }
        }

        this.addListener(
            Helpers.iqObjectType(this.iqObject) + '/event',
            this.onLogEvent.bind(this)
        );

        this.addListener(
            'object/removed',
            this.objectRemoved.bind(this)
        );

        this.registerActionHandler(
           this.iqObject.uid, 'dismiss-log', this.dismissLog.bind(this)
        );

        this.registerActionHandler(
           this.iqObject.uid, 'refresh-log', this.refreshLog.bind(this)
        );

        this.registerActionHandler(
           this.iqObject.uid, 'filter-log', this.filter.bind(this)
        );

        this.registerActionHandler(
           this.iqObject.uid, 'show-dismissed', this.showDismissedLog.bind(this)
        );

        this.registerActionHandler(
            this.iqObject.uid, 'show-event-help', this.showEventHelp.bind(this)
        );

        this.registerActionHandler(
            this.iqObject.uid, 'close-event-help', this.closeEventHelp.bind(this)
        );

        let context = {
            'iqObject': this.iqObject,
            'error': false,
        };

        Gui.render('log.html', context);
        this.updateLogTable();
    }
}
