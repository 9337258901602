/**
 * Helpers module.
 * @module helpers
 */

"use strict";

import * as Constants from './constants';

/**
 * Checks the type of a given value
 * @param {any} type - can be 'string', 'number', or 'boolean', 'array'
 *                       or 'object'
 * @param {any} value - the value to check the type of
 * @returns {boolean} whether the type matches
 */
export function checkType(type, value) {
    switch(type){
        case 'string': {
            return typeof value === 'string';
        }
        case 'number': {
            return typeof value === 'number';
        }
        case 'boolean': {
            return typeof value === 'boolean';
        }
        case 'array': {
            return Array.isArray(value);
        }
        case 'object': {
            return typeof value === 'object';
        }
        default: {
            // Unrecognized type
            return false;
        }
    }
}

/**
 * Check whether given variable isn't undefined
 * @param {any} o
 * @returns {boolean}
 */
export function isDefined(o) {
    return typeof o !== 'undefined';
}

/**
 * Checks the nodetype of a given event target
 * @param {Event} event - the event of a click
 * @returns {boolean} whether currentTarget is a form
 */
export function eventIsForm(event) {
    return $(event.currentTarget).is('form');
}

/**
 * Checks the nodetype of a given event target
 * @param {Event} event - the event of a click
 * @returns {boolean} whether currentTarget is a link
 */
export function eventIsLink(event) {
    return $(event.currentTarget).is('a');
}

/**
 * Create a new event
 * @param {string} eventName
 */
export function createNewEvent(eventName = '') {
    let event;

    // Polyfill for IE
    if(typeof(Event) === 'function') {
        event = new Event(eventName);
    } else {
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
    }
    return event;
}


/**
 * Pad a string with zeros until it becomes given size
 * @param {string} str
 * @param {number} size - the size
 * @returns {string} the padded string
 */
export function padZero(str, size) {
    str = String(str);
    while (str.length < size) {
        str = "0" + str;
    }
    return str;
}

/**
  * Deep clones all properties except functions and regexes
  * @param {object} obj
  * @returns {object}
  */
export function clone(obj) {
    if (typeof obj === 'function') {
        return obj;
    }
    var result = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
        if(!obj.hasOwnProperty(key)) {
            continue;
        }
        let value = obj[key];
        let type = {}.toString.call(value).slice(8, -1);

        if (type === 'Array' || type === 'Object') {
            result[key] = clone(value);
        } else if (type === 'Date') {
            result[key] = new Date(value.getTime());
        }else {
            result[key] = value;
        }
    }
    return result;
}

/**
 * Returns the minimum of two numbers
 * @param {number} x
 * @param {number} y
 * @returns {number} the minimum
 */
export function minimum(x, y){
    return x < y ? x : y;
}

/**
 * Returns true if the given value is not undefined and not null
 * @param {any} val
 * @returns {boolean}
 */
export function definedNotNull(val){
    return typeof val !== 'undefined' && val !== null;
}

/**
 * Returns true if level1 is lower than or equal to level2
 * @param {string} level1
 * @param {string} level2
 * @returns {boolean}
 */
export function equalOrLowerUserLevel(level1, level2){
    const userLevels = Constants.USER_LEVELS;
    if(
        userLevels.indexOf(level1) === -1 ||
        userLevels.indexOf(level2) === -1){
        return false;
    }

    return userLevels.indexOf(level1) <= userLevels.indexOf(level2);
}

/**
 * Get the value for DOM input element with given name
 * @param {string} name
 * @returns {string | number | string[] | false}
 */
export function inputVal(name) {
    let elem = $('[name=' + name + ']');
    if(elem.length === 0) {
        return false;
    }

    return elem.val();
}


/**
 * Sorts chart data in ascending order using the timestamp
 */
export function timeDataSort(val1, val2){
    return val1.timestamp - val2.timestamp;
}

/**
 * Generate random integer within range (inclusive)
 * {number} min
 * {number} max
 * @returns number
 */
export function randomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function iqObjectType(iqObject) {
    if(
        iqObject.hasOwnProperty('_meta') &&
        iqObject._meta.hasOwnProperty('type')
    ) {
        return iqObject._meta.type;
    }
    return 'unknown';
}

/**
 * Check whether given IQ Object is a fieldbus
 * @param {Object} iqObject - the iq object
 * @returns boolean
 */
export function isFieldbus(iqObject){
    return (
        iqObject.hasOwnProperty('_meta') &&
        iqObject._meta.hasOwnProperty('type') &&
        iqObject._meta.type === 'fieldbus'
    );
}

/**
 * Check whether given IQ Object is an "unknown" object
 * @param {Object} iqObject - the iq object
 * @returns boolean
 */
export function isUnknown(iqObject){
    return (
        iqObject.hasOwnProperty('_meta') &&
        iqObject._meta.hasOwnProperty('type') &&
        iqObject._meta.type === 'unknown'
    );
}

/**
 * Check whether given IQ Object is a CLFB
 * @param {Object} iqObject - the iq object
 * @returns boolean
 */
export function isClfb(iqObject) {
    return (
        iqObject.hasOwnProperty('_meta') &&
        iqObject._meta.hasOwnProperty('type') &&
        iqObject._meta.type === 'clfb'
    );
}

/**
 * Check whether given IQ Object is a device
 * @param {Object} iqObject - the iq object
 * @returns boolean
 */
export function isDevice(iqObject){
    return (
        iqObject.hasOwnProperty('_meta') &&
        iqObject._meta.hasOwnProperty('type') &&
        iqObject._meta.type === 'device'
    );
}

/**
 * Returns a human readable type name for the given IQ object
 * @param {Object} iqObject
 * @returns string
 */
export function prettyIqObjectType(iqObject) {
    if(isDevice(iqObject)) {
        return _('device');
    } else if(isFieldbus(iqObject)) {
        return _('fieldbus');
    } else if(isClfb(iqObject)) {
        return _('CLFB');
    } else {
        return _('object');
    }
}

/**
 * Check whether device has given role
 * @param {Object} device
 * @param {string} role
 */
export function deviceHasRole(device, role) {
    // TODO: Remove dependancy on device.roles here, utilizing only the
    // constants
    if(!device.hasOwnProperty('roles')){
        if(device.hasOwnProperty('type')) {
            return (
                window.CONSTANTS.ROLES.hasOwnProperty(device.type) &&
                window.CONSTANTS.ROLES[device.type].includes(role)
            );
        }
        return false;
    }

    for(let deviceRole of device.roles) {
        if(role === deviceRole.name) {
            return true;
        }
        return false;
    }
}


export function arraysContainSame(a, b) {
    a = Array.isArray(a) ? a : [];
    b = Array.isArray(b) ? b : [];
    return a.length === b.length && a.every(el => b.includes(el));
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * Replace all occurences of "find" to "replace" in given string
 * @param {string} str
 * @param {string} find
 * @param {string} replace
 */
export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

/**
 * Capitalizes given string, ie "name" is changed into "Name"
 * @param {string} str - the string to capitalize
 * @returns {string}
 */
export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Retrieve data attribute from given DOM element
 * @param {object} elem - the DOM element
 * @param {string} attr - the attribute name, interpreted as 'data-[attr]'
 * @returns {false|string}
 */
export function data(elem, attr) {
    const result = $(elem).attr('data-' + attr);
    if(!checkType('string', result)) {
        return false;
    }
    return result;
}

/**
 * Join the given path parts into one big path without a trailing slash
 * @param {array} parts
 * @returns {string}
 */
export function joinPaths(parts) {
    let path = '';
    let components = [];
    for(let i = 0; i < parts.length; i++) {
        let part = parts[i];
        if(part.length === 0) {
            continue;
        }
        if(part.endsWith('/')) {
            part = part.substring(0, part.length - 1);
        }
        components.push(part);
    }
    return components.join('/');
}

export function arrayContains(needle, haystack) {
    return haystack.indexOf(needle) > -1;
}

/**
 * Retrieve a setting from the constants
 * @param {string} iqObjectType - i.e "clfb" or "fieldbus"
 * @param {string} settingName
 * @returns {false|object}
 */
export function getSetting(iqObjectType, settingName) {
    if(!window.CONSTANTS.SETTINGS.hasOwnProperty(iqObjectType)) {
        return false;
    }

    for(const setting of window.CONSTANTS.SETTINGS[iqObjectType]) {
        if(setting.name === settingName) {
            return setting;
        }
    }

    return false;
}

/**
 * Check whether a given variable is numeric
 * @returns {boolean}
 */
export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Convert a given number to an bit array of booleans
 * @param {number | string | boolean} number
 * @returns {array}
 */
export function numberToBitArray(number, minWidth=0) {
    if(!isNumeric(number)) {
        number = 0;
    }

    if(typeof number === 'string') {
        number = parseInt(number);
    } else if(typeof number === 'boolean') {
        number = + number;
    }

    let base2 = (number).toString(2);
    let output = [];
    for(let i = 0; i < base2.length; i++) {
        output.push(base2[i] === '1');
    }
    if(minWidth > 0) {
        while(output.length < minWidth) {
            output.unshift(false);
        }
    }
    return output;
}

/**
 * Render given timestamp as a nice time string
 * @param {number} timestamp
 * @param {number} timeZoneOffset
 * @param {boolean} use24Hours
 * @returns {string}
 */
export function formatTime(timestamp, timeZoneOffset=0, use24Hours=false, addHtml=true) {
    const date = new Date(timestamp + (timeZoneOffset * 60000));
    const hours24 = date.getUTCHours();
    let minutes = date.getUTCMinutes().toString();
    let ampm = 'AM';
    let hours = hours24;

    if (hours24 > 12) {
        hours = hours24 - 12;
        ampm = 'PM';
    } else if (hours24 === 12) {
        hours = 12;
        ampm = 'PM';
    } else if (hours24 === 0) {
        hours = 12;
    }

    if(isNaN(hours) || !isNumeric(minutes)) {
        return '';
    }

    if(minutes.length < 2) {
        minutes = '0' + minutes;
    }

    if(!use24Hours) {
        if(addHtml) {
            return hours + ':' + minutes + ' <small class="am-pm">' + ampm + '</small>';
        } else {
            return hours + ':' + minutes + ' ' + ampm;
        }
    }
    return hours24 + ':' + minutes;
}

/**
 * Render given timestamp as a nice datetime string
 * @param {number} timestamp
 * @param {number} timeZoneOffset
 * @param {boolean} use24Hours
 * @returns {string}
 */
export function formatDateTime(timestamp, timeZoneOffset=0, use24Hours=false, addHtml=true) {
    const date = new Date(timestamp + (timeZoneOffset * 60000));
    return (
        date.getUTCFullYear() + '-' +
        (date.getUTCMonth() + 1).toString() + '-' +
        date.getUTCDate() + ' ' +
        formatTime(timestamp, timeZoneOffset, use24Hours, addHtml)
    );
}

/**
 * Reverse a given string
 * @param {string} str
 * @returns {string}
 */
export function reverseString(str) {
    return str.split("").reverse().join("");
}

/**
 * Convert a string from camel case to snake case
 * @param {string} str
 * @returns {string}
 */
export function camelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

/**
 * Round given number to n decimals
 * @param {number} input - the input number
 * @param {number} decimals - amount of decimals to round to
 */
export function round(input, decimals) {
    return Math.round(input * (Math.pow(10, decimals))) / Math.pow(10, decimals);
}

/**
 * Check whether given input is undefined
 * @param {any} input
 * @returns {boolean}
 */
export function isUndefined(input) {
    return typeof input === 'undefined';
}

/**
 * Optional chain (just like es2020's ?. operator)
 * @param {object} obj
 * @param {array} args
 * @returns {any | undefined}
 */
export function optChain(obj, ...args) {
    if(!obj) {
        return undefined;
    }
    for(let item of args) {
        if(!obj.hasOwnProperty(item)) {
            return undefined;
        }
        obj = obj[item];
    }
    return obj;
}

/**
 * Retrieve a parameter from the constants
 * @param {object} device
 * @param {string} qid
 * @returns {false|object}
 */
export function getParameter(device, qid) {
    const param = optChain(window.CONSTANTS.PARAMETERS, device.type, qid);
    if(!param) {
        return false;
    }
    return param;
}

/**
 * Retrieve an action from the constants
 * @param {object} iqObject
 * @param {string} actionName
 * @returns {false|object}
 */
export function getAction(iqObject, actionName) {
    if(isDevice(iqObject)) {
        const action = optChain(window.CONSTANTS.ACTIONS.device, iqObject.type, actionName);
        if(!action) {
            return false;
        }
        return action;
    } else if(isFieldbus(iqObject)) {
        const action = optChain(window.CONSTANTS.ACTIONS.fieldbus, actionName);
        if(!action) {
            return false;
        }
        return action;
    } else if(isClfb(iqObject)) {
        const action = optChain(window.CONSTANTS.ACTIONS.clfb, actionName);
        if(!action) {
            return false;
        }
        return action;
    }

    return false;
}

/**
 * Sorts two parameters by HmiGroup, HmiLocation or else it uses the
 * current order
 * @param {object} device - device owning both parameters
 * @param {object} param1 - first parameter
 * @param {object} param2 - second parameter
 * @returns {number}
 */
export function parameterSort(device, param1, param2){
    const p1 = getParameter(device, param1.qid.toString());
    const p2 = getParameter(device, param2.qid.toString());

    if(p1 === false || p2 === false) {
        return -1;
    }

    if(
        p1.hmiGroup !== null &&
        p2.hmiGroup !== null &&
        p1.hmiLocation !== null &&
        p2.hmiLocation !== null

    ){
        if(p1.hmiGroup !== p2.hmiGroup){
            return p1.hmiGroup - p2.hmiGroup;
        }
        return p1.hmiLocation - p2.hmiLocation;
    }

    if(p1.hmiGroup !== null && p2.hmiGroup !== null) {
        return p1.hmiGroup - p2.hmiGroup;
    }

    if(
        p1.hmiGroup !== null &&
        p2.hmiGroup === null
    ) {
        return -1;
    }

    if(
        p1.hmiGroup === null &&
        p2.hmiGroup !== null
    ) {
        return 1;
    }

    return param1.qid - param2.qid;
}


/**
 * Returns a setting sorting function for a given iqObject
 * @param {object} iqObject - object owning these settings
 * @returns {function(string, string) : number}
 */
function settingsSorter(iqObject){
    const objectType = iqObjectType(iqObject);

    /**
     * Sorting function for settings
     * @param {string} settingName1
     * @param {string} settingName2
     * @returns {number}
     */
    return (settingName1, settingName2) => {
        const s1 = getSetting(objectType, settingName1);
        const s2 = getSetting(objectType, settingName2);

        if(s1 === false || s2 === false) {
            return -1;
        }

        if(
            s1.hmiGroup !== null &&
            s2.hmiGroup !== null &&
            s1.hmiLocation !== null &&
            s2.hmiLocation !== null

        ){
            if(s1.hmiGroup !== s2.hmiGroup){
                return s1.hmiGroup - s2.hmiGroup;
            }
            return s1.hmiLocation - s2.hmiLocation;
        }

        if(s1.hmiGroup !== null && s2.hmiGroup !== null) {
            return s1.hmiGroup - s2.hmiGroup;
        }

        if(
            s1.hmiGroup !== null &&
            s2.hmiGroup === null
        ) {
            return -1;
        }

        if(
            s1.hmiGroup === null &&
            s2.hmiGroup !== null
        ) {
            return 1;
        }

        if(settingName1 < settingName2) {
            return -1;
        } else {
            return 1;
        }
    };
}

/**
 * Sorts an object with settings by HmiGroup, HmiLocation or else it uses the
 * current order
 * @param {object} iqObject - object owning both parameters
 * @param {object} settings - the object with the settings, key = setting name
 * @returns {object} the ordered settings object
 */
export function sortSettings(iqObject, settings) {
    /** @type {function(string, string) : number} */
    const sorterFunc = settingsSorter(iqObject);
    return Object.keys(settings).sort(sorterFunc).reduce(
        (obj, key) => {
            obj[key] = settings[key];
            return obj;
        },
      {}
    );
}

/**
 * Generate a CSV file as a string given a header array and an array of rows
 * @param {Array<string>} header
 * @param {Array<Array<string|number>>} rows
 * @returns {string}
 */
export function generateCsv(header, rows) {
    let csv = header.map(
        x => '"' + replaceAll(x.toString(), '"', '""') + '"'
    ).join(',') + '\n';

    for(let row of rows) {
        csv += row.map(
            x => '"' + replaceAll(x.toString(), '"', '""') + '"'
        ).join(',') + '\n';
    }

    return csv;
}

/**
 * Make a proper filename from an arbitrary string
 * @param {string} str
 * @returns {string}
 */
export function makeFileName(str) {
    return str.replace(/[ &\/\\#,+()$~%.'":*?<>{}]/g, "_");
}

/**
 * @returns {string}
 */
export function generateUuid() {
    /* jshint ignore:start */
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    /* jshint ignore:end */
}

/**
 * Generate a very short random id
 * @returns {string}
 */
export function shortRandomId() {
    return Date.now().toString(36) +
        Math.floor(
            Math.pow(10, 12) + Math.random() * 9*Math.pow(10, 12)
        ).toString(36);
}

/**
 * Converts a base64 encoded string to a Blob
 * @param {string} b64Data
 * @param {string} contentType
 * @param {number} sliceSize
 */
export function base64ToBlob(b64Data, contentType='', sliceSize=512) {
    const rawData = atob(b64Data);
    const parts = [];

    for (let offset = 0; offset < rawData.length; offset += sliceSize) {
        const slice = rawData.slice(offset, offset + sliceSize);

        const byteCodes = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteCodes[i] = slice.charCodeAt(i);
        }

        parts.push(new Uint8Array(byteCodes));
    }

    return new Blob(parts, {type: contentType});
}

export function isSensor(iqObject) {
    if(!isDevice(iqObject) || !iqObject.hasOwnProperty('type')) {
        return false;
    }
    return Constants.SENSORS.indexOf(iqObject.type) !== -1;
}

/**
 * Merges two objects, adding the properties of the second to the first.
 * Overriding properties that might already be present in the first.
 * @param {object} obj1
 * @param {object} obj2
 * @returns {object}
 */
export function mergeObjects(obj1, obj2) {
    const object1 = clone(obj1);
    const object2 = clone(obj2);
    for(let key in object2) {
        if(!object2.hasOwnProperty(key)) {
            continue;
        }
        object1[key] = object2[key];
    }
    return object1;
}

/**
 * Clamp a number within a range, and round it in the process
 * @param {number} number
 * @param {number} min
 * @param {number} max
 */
export function clamp(number, min, max) {
    return Math.min(Math.max(Math.round(number), min), max);
}

/**
 * Returns whether the given IQ object has a dashboard
 * @param {object} iqObject
 * @returns {boolean}
 */
export function hasDashboard(iqObject) {
    if(isDevice(iqObject)) {
        return Constants.HAS_DASHBOARD_DEFAULTS.devices.includes(
            iqObject.type
        );
    }
    return false;
}
