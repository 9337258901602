/**
 * Actions module.
 * The Actions module defines the logic for handling user invoked changes.
 * Actions are triggered from the Events module and are usually the result
 * of a click on a link or button with the 'action' class.
 * These links or buttons should have the following attributes defined:
 * - data-id: the unique identifier for this action, for example a combination
 *            of a device uid and a parameter qid
 * - data-name: the name of the action, for example 'edit-parameter'
 *
 * When triggering an action this module assembles all the arguments needed for
 * notifying the registered action handlers. It does so by searching DOM elements
 * (inputs, selects, textareas) with matching data-id and data-for. Data-id should
 * match the link or button's data-id, and data-for should match the name of the
 * action this field resembles an argument for.
 *
 * @module actions
 */

"use strict";

import * as Debug from './debug';

let actionHandlers = {};
let lastActionHandlerId = 0;

let initialized = false;

export function initActions(){
    if(initialized) {
        return;
    }

    actionHandlers = {};
    lastActionHandlerId = 0;
    initialized = true;
}

/**
 * Register an action handler for given event type and target
 * @param {string} id - id of the action (i.e UID-QID)
 * @param {string} name - name of the action (i.e edit-parameter)
 * @param {function} callback - called when action is triggered
 * @param {object | null} ownerView - optional owner view
 * @returns {number} - a handler id
 */
export function registerActionHandler(id, name, callback, ownerView=null){
    if(!actionHandlers.hasOwnProperty(id)) {
        actionHandlers[id] = {};
    }
    if(!actionHandlers[id].hasOwnProperty(name)){
        actionHandlers[id][name] = {};
        lastActionHandlerId++;
    }
    actionHandlers[id][name][lastActionHandlerId] = callback;
    return lastActionHandlerId;
}

/**
 * Removes an action handler for given event type and target
 * @param {number} actionHandlerId - actionHandlerId
 * @returns {boolean} - whether the handler is removed
 */
export function removeActionHandler(actionHandlerId){
    for(const id in actionHandlers){
        if(!actionHandlers.hasOwnProperty(id)){
            continue;
        }
        for(const name in actionHandlers[id]){
            if(!actionHandlers[id].hasOwnProperty(name)){
                continue;
            }
            if(actionHandlers[id][name].hasOwnProperty(actionHandlerId.toString())){
                delete actionHandlers[id][name][actionHandlerId.toString()];
                continue;
            }
        }
    }
    return true;
}

/**
 * Remove multiple action handlers
 * @param {array} actionHandlerIds - array with action handler ids
 * @returns {boolean} - whether all handlers have been found and removed
 */
export function removeActionHandlers(actionHandlerIds){
    let success = true;

    // NB: this is a relatively simple linear implementation, if it's too slow we
    // might need to use a different data structure for the handler id's
    //

    for(const id in actionHandlers){
        if(!actionHandlers.hasOwnProperty(id)){
            continue;
        }
        for(const name in actionHandlers[id]){
            if(!actionHandlers[id].hasOwnProperty(name)){
                continue;
            }
            for(let item in actionHandlers[id][name]){
                if(!actionHandlers[id][name].hasOwnProperty(item)){
                    continue;
                }

                /** @type {number} */
                const actionHandlerId = parseInt(item);

                if(actionHandlerIds.includes(actionHandlerId)){
                    delete actionHandlers[id][name][actionHandlerId];
                    const index = actionHandlerIds.indexOf(actionHandlerId);
                    if (index !== -1) {
                      actionHandlerIds.splice(index, 1);
                    }

                    if(Object.keys(actionHandlers[id][name]).length === 0) {
                        delete actionHandlers[id][name];
                    }

                    if(Object.keys(actionHandlers[id]).length === 0) {
                        delete actionHandlers[id];
                    }
                }
            }
        }
    }

    return success;
}

/**
 * Trigger the attached action handlers for the given DOM element
 * @param {Element|EventTarget|JQuery<EventTarget>} elem - the DOM element that was activated
 * @returns {Boolean} - whether the action was triggered successfully
 */
export function trigger(elem){
    const domEl = $(elem);

    const actionType = domEl.attr('data-action-type');
    const actionId = domEl.attr('data-id');

    let args = {};
    let triggered = false;

    if(
        !actionHandlers.hasOwnProperty(actionId) ||
        !actionHandlers[actionId].hasOwnProperty(actionType)
    ){
        return false;
    }

    $(
        'input.action[data-id=' + actionId + '], ' +
        'select.action[data-id=' + actionId + ']'
    ).each((i, el) => {
        let value = $(el).val();

        if($(el).attr('type') === 'radio') {
            if(!$(el).prop('checked')){
                return;
            }
        }

        if($(el).attr('type') === 'checkbox') {
            value = $(el).prop('checked');
        }

        const name = $(el).attr('data-name');
        args[name] = value;
    });

    if($(elem).attr('data-param')) {
        args.param = $(elem).attr('data-param');
    }

    Debug.recordEvent('action', {
        actionArgs: args,
        actionId: actionId,
        actionType: actionType
    });

    for(const actionHandlerId in actionHandlers[actionId][actionType]){
        if(!actionHandlers[actionId][actionType].hasOwnProperty(actionHandlerId)){
            continue;
        }
        actionHandlers[actionId][actionType][actionHandlerId](actionId, args, elem);
        triggered = true;
    }

    return triggered;
}

/**
 * Trigger an action from an automated system, i.e a test runner
 * @param {string} actionId
 * @param {string} actionType
 * @param {object} args
 * @returns boolean - whether the action was triggered
 */
export function automatedTrigger(actionId, actionType, args) {
    const elem = $('.action[data-id=' + actionId + '][data-action-type=' + actionType + ']');
    if(elem.length === 0 || !elem) {
        return false;
    }

    let triggered = false;

    for(const actionHandlerId in actionHandlers[actionId][actionType]){
        if(!actionHandlers[actionId][actionType].hasOwnProperty(actionHandlerId)){
            continue;
        }
        actionHandlers[actionId][actionType][actionHandlerId](actionId, args, elem[0]);
        triggered = true;
    }

    return triggered;
}
