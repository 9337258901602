/**
 * API module. This module handles all the API calls
 */

"use strict";

import * as Helpers from './helpers';
import * as Debug from './debug';
import * as Session from './session';
import * as Dah from './dah';

// This is a general ajax error handler that handles 401 cases.
// We try to tag Ajax calls with the accessToken that is used at the time of
// the request. This allows us to check if a 401 response is still relevant or
// not. It might concern an older access token and in that case we can ignore
// the fact that the authentication isn't valid anymore.
$(document).ajaxError((event,xhr,options,exc) => {
    if(xhr.status !== 401 && xhr.status !== 403) {
        return;
    }

    if(xhr.status === 403) {
        if(!options.hasOwnProperty('settings')) {
            return;
        } else if(options.settings.hasOwnProperty('logoutIf403') &&
            options.settings.logoutIf403
        ) {
            return window.visit('/#/logout/silent');
        } else {
            return;
        }
    }

    // If the request wasn't tagged with an access token there's no way to know
    // if it's safe to ignore, so we logout the user and return them to the
    // login screen.
    if(
        !options.hasOwnProperty('settings') ||
        !options.settings.hasOwnProperty('accessToken')
    ) {
        return window.visit('/#/logout/silent');
    }

    // If the access token from this request doesn't match our current access
    // token anymore, we can safely ignore this 40x.
    if(options.settings.accessToken === Session.getAccessToken()) {
        return window.visit('/#/logout/silent');
    }
});

/**
 * Returns an AJAX settings object, which is used to track the possible error
 * state of this call.
 * @param {boolean} logoutIf403 - Log out the user if this call returns a 403.
 *     Usually you only want to do this in case of 401 errors, however some API
 *     calls should never return a 403 forbidden.
 * @returns {object}
 */
function ajaxSettings(logoutIf403=false) {
    return {
        'accessToken': Session.getAccessToken(),
        'logoutIf403': logoutIf403,
    };
}

/**
 * Returns headers object for usage in Ajax calls
 * @returns {Object}
 */
function getHeaders() {
    let headers = {
        'UUID': Session.getUuid(),
    };

    const accessToken = Session.getAccessToken();
    if(accessToken !== false){
        headers.authorization = 'Bearer ' + accessToken;
    }

    return headers;
}


/**
 * This function takes an Api success callback and wraps a function around it
 * adding a "_meta" property to the data that is provided as an argument to the
 * callback. This allows us to specify types for IQ Objects for example.
 * @param {function} callback
 * @param {Object} metaObj
 * @returns function
 */
function addMetaToObject(callback, metaObj) {
    return (data) => {
        data._meta = Helpers.clone(metaObj);
        callback(data);
    };
}

/**
 * This function takes an Api success callback and wraps a function around it
 * adding a "_meta" property to all the items in the array that is provided as
 * an argument to the callback. This allows us to specify types for IQ Objects
 * for example.
 * @param {function} callback
 * @param {Object} metaObj
 * @returns function
 */
function addMetaToList(callback, metaObj) {
    return (data) => {
        callback(data.map((item) => {
            item._meta = Helpers.clone(metaObj);
            return item;
        }));
    };
}


/**
 * Error handling sieve. Whenever the error object has a 401 status, it ignores
 * it and let's the main 401 handler pick it up.  Otherwise it calls the
 * supplied error handler function.
 * @param {function} handler
 * @returns {function}
 */
function errorSieve(handler) {
    return (err) => {
        if(err.status !== 401){
            return handler(err);
        }
    };
}

/**
 * Retrieve a list of devices from the server
 * @returns {Promise}
 */
export function getDevices(){
    Debug.log('Retrieve a list of devices from the api');
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices',
            type: 'GET',
            dataType: 'json',
            success: addMetaToList(resolve, {'type': 'device'}),
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(true),
        });
    });
}

/**
 * Retrieve a device from the server
 * @param {string} uid
 * @returns {Promise}
 */
export function getDevice(uid){
    Debug.log('Retrieve a device from the api, with uid=' + uid);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + uid,
            type: 'GET',
            success: addMetaToObject(resolve, {'type': 'device'}),
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Subscribe to a device
 * @param {string} deviceId
 * @returns {Promise}
 */
export function subscribe(deviceId){
    Debug.log('Subscribing to device ' + deviceId);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + deviceId + '/subscribe',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Unsubscribe from a device
 * @param {string} deviceId
 * @returns {Promise}
 */
export function unsubscribe(deviceId){
    Debug.log('Unsubscribing from device ' + deviceId);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + deviceId + '/unsubscribe',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Set parameter value
 * @param {string} deviceId
 * @param {string} parameterName
 * @param {number|string|boolean} value
 */
export function setParameter(deviceId, parameterName, value){
    Debug.log('Set parameter ' + parameterName + ' on device ' + deviceId + ' to ' + value, 1);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + deviceId + '/parameters/' + parameterName,
            type: 'PUT',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            data: JSON.stringify({value: value}),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Retrieve a list of users with a lower user level than the current
 * @returns {Promise}
 */
export function getUsers(){
    Debug.log('Get user list');
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'users',
            type: 'GET',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Retrieve a user
 * @param {number} id
 * @returns {Promise}
 */
export function getUser(id){
    Debug.log('Get user with id ' + id);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'users/' + id,
            type: 'GET',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Create a new user
 * @param {string} name
 * @param {string} level - User level: "basic", "advanced", "expert", "service"
 *                         ("factory" is restricted)
 * @param {string} password
 */
export function createUser(name, level, password){
    Debug.log('Create a new user ' + name, 1);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'users',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            data: JSON.stringify({
                name: name,
                level: level,
                password: password
            }),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Delete a user
 * @param {number} id
 * @returns {Promise}
 */
export function deleteUser(id){
    Debug.log('Delete user with id ' + id, 1);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'users/' + id,
            type: 'DELETE',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Update a user
 * @param {number} id
 * @param {Object} userData - Object with keys "name", "level" and "password"
 * @returns {Promise}
 */
export function updateUser(id, userData){
    Debug.log('Update a user ' + name, 1);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'users/' + id,
            type: 'PUT',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            data: JSON.stringify({
                name: userData.name,
                level: userData.level,
                password: userData.password
            }),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Login as a user, returns Promise, success data contains accessToken and tokenType
 * @param {string} username
 * @param {string} password
 * @returns {Promise}
 */
export function login(username, password){
    Debug.log('Login as user ' + username, 1);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'login',
            type: 'POST',
            success: resolve,
            error: reject,
            headers: getHeaders(),
            data: JSON.stringify({
                username: username,
                password: password
            }),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Logout the user
 * @param {string} token
 * @returns {Promise}
 */
export function logout(token){
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'logout',
            type: 'POST',
            success: resolve,
            error: reject,
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Change a user
 * @param {object} user
 * @returns {Promise}
 */
export function changeUser(user) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'users/' + user.id,
            type: 'PUT',
            success: resolve,
            error: reject,
            headers: getHeaders(),
            data: JSON.stringify({
                'id': parseInt(user.id),
                'name': user.name,
                'level': user.level,
                'password': user.password,
            }),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Generate a new password for given username using the recovery key of the system
 * @param {string} key - the recovery key of the system
 * @param {string} username
 * @returns {Promise}
 */
export function passwordReset(key, username) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'users/' + username + '/passwordreset',
            type: 'POST',
            success: resolve,
            error: reject,
            headers: getHeaders(),
            data: JSON.stringify({
                'key': key,
            }),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Retrieve recent parameter values
 * @param {string} uid
 * @param {string} paramName
 * @returns {Promise}
 */
export function getRecentHistory(uid, paramName) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: (
                window.CURRENT_CONFIG.restUrl +
                'devices/' + uid + '/parameters/' +
                paramName + '/recenthistory'
            ),
            type: 'GET',
            dataType: 'json',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}


/**
 * Retrieve history
 * @param {string} uid
 * @param {string} paramName
 * @param {number} from - timestamp start
 * @param {number} until - timestamp end
 * @param {number} interval - time interval for values in minutes
 * @returns {Promise}
 */
export function getHistory(uid, paramName, from, until, interval) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: (
                window.CURRENT_CONFIG.restUrl +
                'devices/' + uid + '/parameters/' +
                paramName + '/history/' + from + '/' + until + '/' + interval
            ),
            type: 'GET',
            dataType: 'json',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Execute the action with the given device uid and action name
 * @param {string} uid
 * @param {string} name
 * @returns {Promise}
 */
export function executeAction(uid, name) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + uid + '/actions/' + name,
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Execute the action with the given fieldbus uid and action name
 * @param {string} uid
 * @param {string} name
 * @returns {Promise}
 */
export function executeFieldbusAction(uid, name) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'fieldbuses/' + uid + '/actions/' + name,
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Put a device in running mode
 * @param {string} uid
 * @returns {Promise}
 */
export function deviceRun(uid) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + uid + '/actions/run',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}


/**
 * Put a device in standby mode
 * @param {string} uid
 * @returns {Promise}
 */
export function deviceStandby(uid) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + uid + '/actions/standby',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Enable a device
 * @param {string} uid
 * @returns {Promise}
 */
export function deviceEnable(uid) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + uid + '/actions/enable',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Disable a device
 * @param {string} uid
 * @returns {Promise}
 */
export function deviceDisable(uid) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + uid + '/actions/disable',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Clear a device's errors and warnings
 * @param {string} uid
 * @returns {Promise}
 */
export function deviceClear(uid) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + uid + '/actions/clear',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Identify device
 * @param {string} uid
 * @returns {Promise}
 */
export function deviceIdentify(uid) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'devices/' + uid + '/actions/identify',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Retrieve log history for given IQ Object within given timerange
 * @param {object} iqObject
 * @param {number} from - unix timestamp in seconds, defaults to 48 hours ago
 * @param {number} until - unix timestamp in seconds, defaults to now
 * @returns {Promise}
 */
export function getLogHistory(
    iqObject,
    from=Math.floor(Date.now() / 1000 - 48 * 3600),
    until=Math.ceil(Date.now() / 1000)
) {
    const iqObjectType = Helpers.iqObjectType(iqObject);

    let endpointPrefix = 'devices';
    if(iqObjectType === 'clfb') {
        endpointPrefix = 'clfbs';
    } else if(iqObjectType === 'fieldbus') {
        endpointPrefix = 'fieldbuses';
    }

    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: (
                window.CURRENT_CONFIG.restUrl +
                endpointPrefix + '/' + iqObject.uid + '/log/history/' +
                from + '/' + until
            ),
            type: 'GET',
            dataType: 'json',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Retrieve a list of fieldbuses from the API
 * @returns {Promise}
 */
export function getFieldbuses() {
    Debug.log('Retrieve a list of fieldbuses from the api');
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'fieldbuses',
            type: 'GET',
            dataType: 'json',
            success: addMetaToList(resolve, {'type': 'fieldbus'}),
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}


/**
 * Retrieve a fieldbus from the API
 * @param {string} uid
 * @returns {Promise}
 */
export function getFieldbus(uid) {
    Debug.log('Retrieving fieldbus with uid: ' + uid);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'fieldbuses/' + uid,
            type: 'GET',
            dataType: 'json',
            success: addMetaToObject(resolve, {'type': 'fieldbus'}),
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Enable a fieldbus
 * @param {string} uid
 * @returns {Promise}
 */
export function fieldbusEnable(uid) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'fieldbuses/' + uid + '/actions/enable',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Disable fieldbus
 * @param {string} uid
 * @returns {Promise}
 */
export function fieldbusDisable(uid) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'fieldbuses/' + uid + '/actions/disable',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/* Update fieldbus settings of given fieldbus
 * @param (string) uid
 * @param (object) settings - the settings is simply a map
 * @returns {Promise}
 * */
export function updateFieldbusSettings(uid, settings) {
    Debug.log('Update settings  on fieldbus ' + uid, 1);
    Debug.log(settings);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'fieldbuses/' + uid + '/settings',
            type: 'PUT',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            data: JSON.stringify(settings),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Map an IQ Object to a fieldbus
 * @param {string} fieldbusUid
 * @param {string} objectUid
 * @param {number} slot
 * @returns {Promise}
 */
export function fieldbusMap(fieldbusUid, objectUid, slot) {
    Debug.log('Mapping ' + objectUid + ' to fieldbus ' + fieldbusUid + ' on slot ' + slot, 1);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: (
                window.CURRENT_CONFIG.restUrl +
                'fieldbuses/' + fieldbusUid +
                '/map/' + objectUid
            ),
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            data: JSON.stringify({
                slot: slot
            }),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}


/**
 * Remove IQ Object to fieldbus mapping
 * @param {string} fieldbusUid
 * @param {string} objectUid
 * @returns {Promise}
 */
export function deleteFieldbusMap(fieldbusUid, objectUid) {
    Debug.log('Remove mapping from ' + objectUid + ' to fieldbus ' + fieldbusUid, 1);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: (
                window.CURRENT_CONFIG.restUrl +
                'fieldbuses/' + fieldbusUid +
                '/map/' + objectUid
            ),
            type: 'DELETE',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Retrieve a list of CLFB's from the API
 * @returns {Promise}
 */
export function getClfbs() {
    Debug.log('Retrieve list of clfb\'s from the API');
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'clfbs',
            type: 'GET',
            dataType: 'json',
            success: addMetaToList(resolve, {'type': 'clfb'}),
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Retrieve a CLFB from the API
 * @param {string} uid
 * @returns {Promise}
 */
export function getClfb(uid) {
    Debug.log('Retrieving clfb with uid: ' + uid);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'clfbs/' + uid,
            type: 'GET',
            dataType: 'json',
            success: addMetaToObject(resolve, {'type': 'clfb'}),
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Create a new CLFB. If successfull it hands the uid and name as an object to
 * the promise's resolve functio
 * @return {Promise}
 */
export function createClfb(outputUid){
    Debug.log('Create new CLFB');
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'clfbs',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            data: JSON.stringify({
                outputUid: outputUid,
            }),
            contentType: 'application/json',
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Delete a CLFB.
 * the promise's resolve function
 * @return {Promise}
 */
export function deleteClfb(uid){
    Debug.log('Delete CLFB with uid: ' + uid);
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'clfbs/' + uid,
            type: 'DELETE',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}


/**
 * Execute the action with the given clfb uid and action name
 * @param {string} uid
 * @param {string} name
 * @returns {Promise}
 */
export function executeClfbAction(uid, name) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'clfbs/' + uid + '/actions/' + name,
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/* Update settings of given CLFB
 * @param (string) uid
 * @param (object) settings - the settings are simply a map
 * @returns {Promise}
 */
export function updateClfbSettings(uid, settings) {
    Debug.log('Update settings  of clfb ' + uid, 1);
    Debug.log(settings);
    let newSettings = {};
    for(let setting in settings) {
        if(!settings.hasOwnProperty(setting)) {
            continue;
        }
        newSettings[setting] = settings[setting].currentValue;
    }
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'clfbs/' + uid + '/settings',
            type: 'PUT',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            data: JSON.stringify(newSettings),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Get a list of version numbers for the current Smart SLC, Devices and SLC's
 * @returns {Promise}
 */
export function getVersions() {
    Debug.log('Retrieve list of versions from API');
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'settings/versions',
            type: 'GET',
            dataType: 'json',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Get a list of available updates for the current Smart SLC, Devices and SLC's
 * @returns {Promise}
 */
export function getUpdates() {
    Debug.log('Retrieve list of updates from API');
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'settings/updates',
            type: 'GET',
            dataType: 'json',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}

/**
 * Apply all updates
 * @returns {Promise}
 */
export function update() {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'settings/actions/update-firmware',
            type: 'POST',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Update a single device
 * @param {string} uid - the parent uid
 * @param {number} productId
 * @returns {Promise}
 */
export function updateSingle(uid, productId) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'settings/actions/update-single',
            type: 'POST',
            success: resolve,
            data: JSON.stringify({
                "uid": uid,
                "productId": productId
            }),
            error: errorSieve(reject),
            headers: getHeaders(),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}


/**
 * Retrieve list of global permissions
 * @returns {Promise}
 */
export function getGlobalPermissions() {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'globalpermissions',
            type: 'GET',
            dataType: 'json',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}


/**
 * Retrieve global status
 * @returns {Promise}
 */
export function getManagerStatus() {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'manager/status',
            type: 'GET',
            dataType: 'json',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            settings: ajaxSettings(),
        });
    });
}


/**
 * Set system time
 * @param {number} time - unix timestamp in seconds
 * @returns {Promise}
 */
export function setSystemTime(time) {
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'settings/actions/set-system-time',
            type: 'POST',
            success: resolve,
            data: JSON.stringify({
                "timestamp": time,
            }),
            error: errorSieve(reject),
            headers: getHeaders(),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}


/**
 * Retrieve user preferences
 * @returns {Promise}
 */
export function getPreferences(){
    Debug.log('Retrieve user preferences');
    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'preferences',
            type: 'GET',
            dataType: 'json',
            success: resolve,
            error: reject,
            headers: getHeaders(),
            settings: ajaxSettings(true),
        });
    });
}


/**
 * Update user preferences
 * @param {object} preferences
 * @returns {Promise}
 */
export function updatePreferences(preferences){
    Debug.log('Update user preferences', 1);
    Debug.log(preferences);

    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'preferences',
            type: 'PUT',
            success: resolve,
            error: errorSieve(reject),
            headers: getHeaders(),
            data: JSON.stringify(preferences),
            contentType: 'application/json',
            settings: ajaxSettings(),
        });
    });
}

/**
 * Download logs as base64 encoded gzipped tar ball
 * @param {'data'|'system'} logType
 * @returns {Promise}
 */
export function initiateLogDownload(logType='data') {
    if(logType !== 'data' && logType !== 'system') {
        return new Promise((resolve, reject) => reject('Invalid log type'));
    }

    return new Promise((resolve, reject) => {
        Dah.ajax({
            url: window.CURRENT_CONFIG.restUrl + 'logs/' + logType + '/actions/export',
            type: 'POST',
            success: resolve,
            error: reject,
            headers: getHeaders(),
            settings: ajaxSettings(true),
        });
    });
}

/**
 * Download logs as base64 encoded zip
 * @returns {Promise}
 */
export function getLogs(logType='data') {
    if(logType !== 'data' && logType !== 'system') {
        return new Promise((resolve, reject) => reject('Invalid log type'));
    }
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open("GET", window.CURRENT_CONFIG.restUrl + 'logs/' + logType + '/actions/export', true);
        req.responseType = "arraybuffer";

        const headers = getHeaders();
        for(let header in headers) {
            if(headers.hasOwnProperty(header)) {
                req.setRequestHeader(header, headers[header]);
            }
        }

        req.onload = (event) => {
            if(req.status === 425) {
                return reject(425);
            } else if(req.status === 200) {
                const arrayBuffer = req.response;
                const byteArray = new Uint8Array(arrayBuffer);
                const blob = new Blob([arrayBuffer], {type: "application/gzip"});
                return resolve(blob);
            } else {
                return reject(req.status);
            }
        };

        req.onerror = (event) => {
            return reject(req.status);
        };

        req.send();
    });
}

/**
 * Send a log message to the Manager API. This message is timestamped and the
 * message itself is prefixed by the user's UUID. This logging is of the "fire
 * and forget" variety, meaning that this function will not return anything.
 *
 * @param {number} level
 *    0 - Debug message
 *    1 - Info message
 *    2 - Warning message
 *    3 - Error message
 * @param {string | object} message
 */
export function sendLogMessage(level, message, timestamp = Date.now()) {
    if(!Session.loggedin) {
        return;
    }

    if(Helpers.checkType('object', message)) {
        message = JSON.stringify(message);
        if(message.length > 100) {
            message = message.substring(0, 100) + '...';
        }
    }

    const body = {
        level: level,
        tag: 'HMI',
        timestamp: timestamp,
        message: '[' + Session.getUuid() + '] ' + message,
    };

    Dah.ajax({
        url: window.CURRENT_CONFIG.restUrl + 'logs/system',
        type: 'POST',
        success: ()=>{},
        error: ()=>{},
        headers: getHeaders(),
        data: JSON.stringify([body]),
        contentType: 'application/json',
        settings: ajaxSettings(),
        timeout: 2000,
    });
}


/**
 * Send a log message to the Manager API. This message is timestamped and the
 * message itself is prefixed by the user's UUID. This logging is of the "fire
 * and forget" variety, meaning that this function will not return anything.
 *
 * @param {array} messages - array containing the messages in the proper
 *  format:
 *      {
 *          level: log level: 0,1,2,3
 *          tag: "HMI"
 *          timestamp: milliseconds unix time
 *          message: string
 *      }
 */
export function sendLogMessages(messages) {
    if(!Session.loggedin) {
        return;
    }
    Dah.ajax({
        url: window.CURRENT_CONFIG.restUrl + 'logs/system',
        type: 'POST',
        success: ()=>{},
        error: ()=>{},
        headers: getHeaders(),
        data: JSON.stringify(messages),
        contentType: 'application/json',
        settings: ajaxSettings(),
        timeout: 2000,
    });
}
