/**
 * Dom and Ajax handling
 * @module helpers
 */

"use strict";

/**
 * @param {object} settings - Ajax settings, comparable to jQuery's
 *     AjaxSettings object
 */
export function ajax(settings) {
    $.ajax(settings);
}

/**
 * Check whether both elements are colliding
 * @param {object} el1 - DOM element
 * @param {object} el2 - DOM Element
 * @returns {boolean}
 */
export function elementsCollide(el1, el2) {
    const rect1 = el1.getBoundingClientRect();
    const rect2 = el2.getBoundingClientRect();

    return !(
        rect1.top > rect2.bottom ||
        rect1.right < rect2.left ||
        rect1.bottom < rect2.top ||
        rect1.left > rect2.right
    );
}
