"use strict";

/**
 * Chart Widgetmodule
 * @module linechartwidget
 */

import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as State from '../core/state';
import * as Signals from '../core/signals';
import {Widget} from './widget';

export class TableWidget extends Widget {
    constructor(title, realtime=false) {
        super();
        this.dataQueue = [];
        this.labels = [];
        this.parentElem = null;
        this.realtime = realtime;
        this.title = title;
        this.listenerId = -1;
        this.table = null;
        this.tbody = null;
        this.timeZoneOffset = State.getTimeZoneOffset();
        this.use24Hours = State.get24HoursIsPreferred();
        this.lastUpdate = -1;
        this.updateTimeoutId = -1;
    }

    destructor() {
        super.destructor();
        window.clearTimeout(this.updateTimeoutId);
        if(this.listenerId !== -1) {
            Signals.removeListener(this.listenerId);
        }
    }

    /*
     * Initialize the datasets
     * @param {string[]} labels
     * @param {array} data
     */
    initDataSets(labels, data) {
        this.labels = labels;
        this.dataQueue = data;
    }

    /**
     * Add a datapoint to a given dataset
     * @param {number} timestamp
     * @param {number[]} value(s)
     */
    addPoint(timestamp, value) {
        this.dataQueue.push([timestamp, value]);
        this.scheduleUpdate();
    }

    scheduleUpdate() {
        window.clearTimeout(this.updateTimeoutId);
        this.updateTimeoutId = -1;

        const update = (() => {
            this.lastUpdate = Date.now();
            this.renderQueue();
        }).bind(this);

        if(this.lastUpdate < Date.now() - 900) {
            update();
        } else {
            this.updateTimeoutId = window.setTimeout(update, 1000);
        }
    }

    getTimeFormat(unit) {
        const use24 = State.get24HoursIsPreferred();
        if(unit === 'hour') {
            if(use24) {
                return 'HH';
            } else {
                return 'hh A';
            }
        } else if(unit === 'minute') {
            if(use24) {
                return 'HH:mm';
            } else {
                return 'hh:mm A';
            }
        } else {
            if(use24) {
                return 'HH:mm:ss';
            } else {
                return 'hh:mm:ss A';
            }
        }
    }

    onMessage(msg) {
        if(
            msg.tag === 'device/parameter' &&
            parseInt(msg.data.device) === parseInt(this.context.iqObject.uid) &&
            parseInt(msg.data.qid) === parseInt(this.context.property.qid)
        ){
            this.context.property.currentValue = msg.data.value;

            if(this.realtime && this.table !== null) {
                this.addPoint(
                    msg.data.timestamp,
                    [parseFloat(msg.data.value)]
                );
            }
        }
    }

    /**
     * Render the entire dataQueue
     * @param {boolean} clear - clear the table first
     */
    renderQueue(clear=false) {
        if(clear) {
            this.tbody.html('');
        }

        let html = '';

        while(this.dataQueue.length > 0) {
            const item = this.dataQueue.shift();

            const prettyTime = Helpers.formatDateTime(
                item[0],
                this.timeZoneOffset,
                this.use24Hours
            );

            html += (
                '<tr>' +
                '<td>' + prettyTime + '</td>' +
                item[1].reduce((acc, cur) => acc + '<td>' +
                    Gui.paramToString(
                        this.context.property, cur, this.context.iqObject
                    ) + '</td>', ''
                ) +
                '</tr>'
            );
        }

        this.tbody.prepend(html);
        $('tr:gt(100)', this.tbody).remove();
    }

    render() {
        super.render();

        const tableHtml = Gui.renderToString(
            'widgets/table.html',
            {id: this.id}
        );

        this.addToDom(tableHtml);
        this.table = $('#table-' + this.id);

        if(this.context.hasOwnProperty('iqObject') && this.context.iqObject) {
            const listenerId = Signals.listenToDeviceParameter(
                this.context.iqObject.name,
                this.context.property.name,
                this.onMessage.bind(this)
            );

            if(typeof listenerId === 'number') {
                this.listenerId = listenerId;
            }
        }

        let header = '<thead>';
        header += '<th>Time</th>';
        header += this.labels.map((label) => '<th>' + label + '</th>');
        header += '</thead>';

        this.table.append(header);
        this.table.append('<tbody></tbody>');

        this.tbody = $('tbody', this.table);
        this.renderQueue(true);
    }
}
