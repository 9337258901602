/**
 * Dashboard view module.
 * @module help
 */

"use strict";

import * as Constants from '../core/constants';
import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Help from '../core/help';
import * as Helpers from '../core/helpers';
import * as State from '../core/state';
import * as WidgetFactory from '../widgets/widgetfactory';
import {DeviceView} from './device';
import {RelatedView} from './related';
import {SensorSegmentsChartWidget} from '../widgets/sensor-segments-chart';

export class DeviceDashboardView extends DeviceView {
    constructor() {
        super();
        this.viewName = 'DeviceDashboardView';
        this.widgets = [];
        this.args = {};
    }

    destructor() {
        super.destructor();
        for(let widget of this.widgets) {
            widget.destructor();
        }
        this.widgets = [];
    }

    initWidgets() {
        // For Sensors we have a special "sensor segments chart widget"
        // however, it looks like spaghetti when we use it
        // let widget = new SensorSegmentsChartWidget('Segments');

        // Clean up any pre-existing widgets
        for(let widget of this.widgets) {
            widget.destructor();
        }
        this.widgets = [];

        this.device.parameters.forEach((param) => {
            const paramMeta = Helpers.getParameter(this.device, param.qid);
            if(paramMeta && paramMeta.dashboard.showOnDashboard) {
                const widget = WidgetFactory.create(
                    this.device,
                    'parameter',
                    param.qid,
                    param,
                    paramMeta
                );

                if(widget) {
                    this.widgets.push(widget);
                }
            }
        });

        this.device.actions.forEach((action) => {
            const actionMeta = Helpers.getAction(this.device, action.name);
            if(actionMeta && actionMeta.dashboard.showOnDashboard) {
                const widget = WidgetFactory.create(
                    this.device,
                    'action',
                    action.name,
                    action,
                    actionMeta
                );

                if(widget) {
                    this.widgets.push(widget);
                }
            }
        });
    }

    renderWidgets() {
        for(let widget of this.widgets) {
            widget.render();
        }
    }

    /**
     * @param {Event} event
     * @param {object} args
     * @param {function | false} doneCallback
     */
    render(event, args=this.args, doneCallback=false) {
        Debug.log('Render view for device: ' + args.uid);
        this.args = args;

        if(!args.hasOwnProperty('uid')){
            return Gui.show404();
        }

        this.Api.getDevice(args.uid).then((device) => {
            this.device = device;
            let deviceState = State.getDevice(device.uid);

            if(event.type !== 'refresh') {
                this.relatedView = new RelatedView(this.device);
                this.registerActionHandlers();
                this.registerListeners();
            }

            device.position = deviceState.position;
            device.name = deviceState.name;

            Debug.log('Full device object:');
            Debug.log(this.device);

            this.refreshFieldbusStatus();
            this.initWidgets();

            let context = {
                'device': this.device,
                'helpPath': 'Operation/Parameter_screen',
                'helpText': '',
                'widgets': this.widgets,
            };

            Help.device(this.device).then((helpText) => {
                context.helpText = helpText;
            }).catch(() => {}).finally(() => {
                Gui.render('device-dashboard.html', context);
                this.renderWidgets();
                if(event.type !== 'refresh') {
                    this.relatedView.render();
                } else {
                    this.relatedView.refresh(this.device);
                }
                if(doneCallback) {
                    doneCallback();
                }
            });

        }).catch((e) => {
            Debug.log(e);
            return Gui.soft404(_('Could not find Device'));
        });
    }
}
