"use strict";

/**
 * API Proxy module. This wraps a Proxy around the API module. This helps
 * prevent the API calls from returning results (either successfull or
 * failures) whenever the object that instantiates this proxy is tagged as
 * "dead".
 *
 * You can use this by importing `apiProxy` from this file and then in the
 * constructor of the class in which you want to use this you can instantiate
 * the proxy by doing:
 * `this.Api = apiProxy(this);`
 *
 * Now you can use `this.Api` as if you have imported the regular Api module.
 * Whenever you destruct the object uses this proxy you have to tag it by
 * adding the property "zombie" with the boolean value "true".
 */

import * as BaseApi from './api';

let apiSkeleton = {};
const emptyPromise = new Promise(() => {});

for(let prop of Object.keys(BaseApi)) {
   apiSkeleton[prop] = ()=>{};
}

export function apiProxy(caller) {
    return new Proxy(apiSkeleton, {
        get(target, prop, receiver) {
            if(caller.zombie === true) {
                return ()=>{
                    return emptyPromise;
                };
            }

            const value = BaseApi[prop];
            if (value instanceof Function) {
                return function (...args) {
                    const result = value.apply(BaseApi, args);
                    if(result instanceof Promise) {
                        return new Promise((resolve, reject) => {
                            result.then((...successArgs) => {
                                if(caller.zombie === true) {
                                    return emptyPromise;
                                }
                                return resolve(...successArgs);
                            }).catch((...failArgs) => {
                                if(caller.zombie === true) {
                                    return emptyPromise;
                                }
                                return reject(...failArgs);
                            });
                        });
                    } else {
                        return result;
                    }
                };
            }
            return value;
        },
    });
}
