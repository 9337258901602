/**
 * Debug Test Runner Module
 * @module DebugTestRunner
 */

"use strict";

import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as Routing from '../routing';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as State from '../core/state';
import {getTestBundles} from './testbundles';
import {assert} from './assert';

let testLogs = {};
let playing = false;
let clfbMap = {};
let oldClfb = '';
let newClfb = '';
let runningTestId = '';
let hasErrors = false;

export function getTestLogs() {
    return testLogs;
}

export function initTests() {
    let html = '';

    const testBundles = getTestBundles();
    for(let bundleName in testBundles) {
        if(!testBundles.hasOwnProperty(bundleName)) {
            continue;
        }

        let stepsCount = 0;
        for(const seq of testBundles[bundleName]) {
            stepsCount += seq.getStepsCount();
        }
        html += '<tr data-bundle="' + bundleName + '">';
        html += '<td><input type="checkbox" class="select-test" data-bundle="' + bundleName + '" /></td>';
        html += '<td>' + bundleName + '</td>';
        html += '<td>' + testBundles[bundleName].length + '</td>';
        html += '<td>' + stepsCount + '</td>';
        html += '<td class="test-status">-</td>';
        html += '<td><button onclick="showTestLog(' + "'" + bundleName + "');" + '">Show log</button></td>';
        html += '<td><button class="run-test" onclick="runTest(' + "'" + bundleName + "');" + '">Run</button></td>';
        html += '</tr>';
    }

    $('table.tests tbody').html(html);
}

export function runTest(bundleName, callback=(failCount)=>{}) {
    // Reset the global test state, which is used to carry test state
    // information across sequence parts
    window.testState = {};
    playing = true;

    $('div.test-log').html(
        '<pre>Run test: ' + bundleName + '</pre>'
    );

    let bundle = getTestBundles()[bundleName];
    let totalCount = bundle.length;
    let doneCount = 0;
    let current = 0;
    let successCount = 0;
    let failCount = 0;

    if(bundle.length === 0) {
        return alert('There are no tests defined for this bundle');
    }

    const row = $('tr[data-bundle="' + bundleName + '"]');
    row.addClass('running');
    $('td.test-status', row).removeClass('failed').removeClass('success');
    $('td.test-status', row).html('<span class="has-loading-icon"><span class="icon-spin-6"></span></span> Running <span class="test-status"></span>');

    testLogs[bundleName] = '';
    testLogs[bundleName] += 'Running test "' + bundleName + '"\n';
    testLogs[bundleName] += Date().toLocaleString() + '\n\n';

    let runSequence = (index) => {
        if(index >= bundle.length) {
            return;
        }
        let seq = bundle[index];
        $('span.test-status').html('<small>Sequence: ' + (index + 1) + ' (' + seq.getName() + '), step: <span class="step-nr"></span></small>');
        seq.run((success) => {
            if(success) {
                testLogs[bundleName] += 'SUCCESS: ' + seq.getName() + '\n';
                successCount++;
            } else {
                testLogs[bundleName] += 'FAIL: ' + seq.getName() + '\n';
                failCount++;
            }
            doneCount++;

            $('td.test-status', row).html('Running:' + successCount + '/' + totalCount);
            $('td.test-status', row).removeClass('failed').removeClass('success');

            const messages = seq.getMessages();
            if(messages.length > 0) {
                testLogs[bundleName] += 'Sequence: ' + seq.getName() + '\n';
                testLogs[bundleName] += '------------------------' + '\n';
                testLogs[bundleName] += seq.getMessages() + '\n\n';
            }

            if(doneCount === totalCount) {
                testLogs[bundleName] += 'Test done\n\nSummary\n------\n';
                testLogs[bundleName] += 'Success: ' + successCount + '\n';
                testLogs[bundleName] += 'Fail: ' + failCount + '\n';
                testLogs[bundleName] += '--------------------------------\n\n';
                testLogs[bundleName] += 'AJAX Call Log:\n';
                testLogs[bundleName] += JSON.stringify(seq.getAjaxCallLog(), null, 2);

                row.removeClass('running');
                $('td.test-status', row).html(successCount + '/' + totalCount);
                if(failCount === 0) {
                    $('td.test-status', row).addClass('success');
                } else {
                    $('td.test-status', row).addClass('failed');
                }

                playing = false;
                callback(failCount === 0);
            }
            showTestLog(bundleName);
            runSequence(index + 1);
        });
    };

    runSequence(0);
}

export function showTestLog(id) {
    let log = testLogs[id];
    if(typeof log === 'undefined') {
        log = 'This test hasn\'t run yet';
    }
    $('div.test-log').html(
        '<hr/>' +
        '<h2>Test log</h2>' +
        '<pre>' + log + '</pre>'
    );
    $('div.test-log').show();

    $('section.debug-panel').animate({
        scrollTop: $('div.test-log').offset().top
    }, 1000);
}

/**
 * This function runs a series of test bundles, and calls the callback function
 * when done or if one has failed
 * @param {function} callback - a callback with a boolean parameter denoting success
 * @param {boolean} success - if this is false then this function calls the
 *                            callback immediately with false as an argument
 */
export function runTestSelection(bundles, callback, success=true) {
    if(success === false) {
        playing = false;
        return callback(false);
    }
    if(bundles.length === 0) {
        playing = false;
        return callback(true);
    }
    const bundle = bundles.shift();
    runTest(bundle, (success) => runTestSelection(bundles, callback, success));
}

export function runRecordedTestSelection(selectedTests, callback, success=true) {
    if(success === false) {
        playing = false;
        return callback(false);
    }
    if(selectedTests.length === 0) {
        playing = false;
        return callback(true);
    }
    const selectedTest = selectedTests.shift();
    runRecordedTest(selectedTest, (success) => runRecordedTestSelection(selectedTests, callback, success));
}

function replaceClfb(originalEvent) {
    let ev = Helpers.clone(originalEvent);
    for(let oldClfb in clfbMap) {
        if(!clfbMap.hasOwnProperty(oldClfb)) {
            continue;
        }
        for(let arg in ev.args) {
            if(!ev.args.hasOwnProperty(arg)) {
                continue;
            }

            if(typeof ev.args[arg] === 'string') {
                ev.args[arg] = Helpers.replaceAll(ev.args[arg], oldClfb, clfbMap[oldClfb]);
            }

            if(arg === 'values') {
                if(ev.args.values.hasOwnProperty('relation')) {
                    ev.args.values.relation = Helpers.replaceAll(ev.args.values.relation, oldClfb, clfbMap[oldClfb]);
                }
                if(ev.args.values.hasOwnProperty('no-relation')) {
                    ev.args.values['no-relation'] = Helpers.replaceAll(ev.args.values['no-relation'], oldClfb, clfbMap[oldClfb]);
                }
            }
        }
    }
    return ev;
}

export function runRecordedTest(id, callback=(a)=>{}) {
    const tests = State.getRecordedTests().filter(x => x.id === id);
    if(tests.length === 0) {
        alert(_('Error: could not find test') + ' ' + id);
        return callback(false);
    }
    const test = tests[0];

    console.log('-----------------------------------');
    console.log(test);
    console.log('-----------------------------------');

    let events = Helpers.clone(test.events);
    runningTestId = id;

    oldClfb = '';
    newClfb = '';
    testLogs[id] = 'Starting test\n\n';
    hasErrors = false;

    const row = $('tr[data-test-id="' + id + '"]');
    row.addClass('running');
    $('td.test-status', row).removeClass('failed').removeClass('success');
    $('td.test-status', row).html('<span class="has-loading-icon"><span class="icon-spin-6"></span></span> Running <span class="test-status"></span>');

    window.visit(test.startUrl);

    const testDone = () => {
        playing = false;

        if(!hasErrors) {
            $('td.test-status', row).addClass('success').html('Success');
        } else {
            $('td.test-status', row).addClass('failed').html('Failed');
        }
        row.removeClass('running');
        runningTestId = '';
        return callback(!hasErrors);
    };

    let stepNumber = 0;
    const totalCount = events.length;

    const step = () => {
        stepNumber++;

        $('td.test-status', row).html('Running:' + stepNumber + '/' + totalCount);

        const ev = replaceClfb(events.shift());

        testLogs[id] += 'Executing step ' + stepNumber + '\n\n';

        let nextStep = () => {
            if(events.length === 0) {
                return testDone();
            }

            const interval = events[0].at - ev.at;
            console.log('Executing next in ' + interval);
            window.setTimeout(step, interval);
        };

        for(oldClfb in clfbMap) {
            if(!clfbMap.hasOwnProperty(oldClfb)) {
                continue;
            }
            ev.location = Helpers.replaceAll(ev.location, oldClfb, clfbMap[oldClfb]);
        }

        if(Routing.getCurrentUrlString() !== ev.location) {
            testLogs[id] += 'Not on the right screen to continue test. We are on ' +
                Routing.getCurrentUrlString() + ' but expect to be on ' +
                ev.location + '\n\n';
            hasErrors = true;
            return testDone();
        }

        if(ev.type === 'link-click') {
            testLogs[id] += 'Link clicked: ' + ev.args.href + '\n\n';
            window.visit(ev.args.href);
            nextStep();
        }

        else if(ev.type === 'back-button') {
            testLogs[id] += 'Back button pressed \n\n';
            window.history.back();
            nextStep();
        }

        else if(ev.type === 'start-screensaver') {
            testLogs[id] += 'Screensaver started \n\n';
            $('a.start-screensaver').trigger('click');
            nextStep();
        }

        else if(ev.type === 'stop-screensaver') {
            testLogs[id] += 'Screensaver stopped \n\n';
            $('a.action.stop-screensaver').trigger('click');
            nextStep();
        }

        else if(ev.type === 'hide-screensaver') {
            testLogs[id] += 'Screensaver hidden \n\n';
            Gui.hideScreenSaver();
            nextStep();
        }

        else if(ev.type === 'action') {
            testLogs[id] += 'Action triggered: \n';
            testLogs[id] += '   - Type: ' + ev.args.actionType + '\n';
            testLogs[id] += '   - Id: ' + ev.args.actionId + '\n';
            testLogs[id] += '   - Args: ' + JSON.stringify(ev.args.actionArgs, null, '    ') + '\n\n';

            let result = Actions.automatedTrigger(
                ev.args.actionId,
                ev.args.actionType,
                ev.args.actionArgs
            );
            if(!result) {
                testLogs[id] += '\n Error: Could not trigger action ' + ev.args.actionType + ' with id: ' + ev.args.actionId;
                hasErrors = true;
                return testDone();
            }
            nextStep();
        }

        else if(ev.type === 'form-submit') {
            testLogs[id] += 'Form submitted: \n';
            testLogs[id] += '    - action: ' + ev.args.href + '\n';
            testLogs[id] += '    - values: ' + ev.args.data + '\n\n';

            const form = $('form[action=\'' + ev.args.href + '\']');
            if(form.length === 0 || !form) {
                testLogs[id] += '\n' + 'Error: Could not find form with action attribute: ' + ev.args.href;
                hasErrors = true;
                return testDone();
            } else {
                // @ts-ignore
                $(form).deserialize(ev.args.data);
                $(form).trigger('submit');
            }
            nextStep();
        }

        else if(ev.type === 'new-clfb') {
            testLogs[id] += 'New CLFB added: \n';
            testLogs[id] += 'UID: ' + ev.args.uid + '\n\n';

            if(newClfb !== '') {
                clfbMap[ev.args.uid] = newClfb;
                oldClfb = '';
                newClfb = '';
            } else {
                oldClfb = ev.args.uid;
            }
            nextStep();
        }

        else if(ev.type === 'assert' && ev.hasOwnProperty('args') && Object.keys(ev.args).length > 0) {
            assert(ev, (result, log) => {
                if(!result) {
                    testLogs[id] += 'ERROR assert failed: ' + log;
                    hasErrors = true;
                    return testDone();
                } else {
                    testLogs[id] += 'Assert holds: ' + log;
                    nextStep();
                }
            });
        }

        else {
            nextStep();
        }
    };

    window.setTimeout(step, 1000);
}

export function isPlaying() {
    return playing;
}

/**
 * The Testrunner gets notified whenever an event intended for recording
 * occurs. Sometimes it's necessary to check those for keeping track of dynamic
 * stateful changes, like new clfb's with unique uids.
 */
export function recordEvent(type, args={}) {
    if(type === 'new-clfb') {
        if(oldClfb !== '') {
            clfbMap[oldClfb] = args.uid;
            oldClfb = '';
            newClfb = '';
        } else {
            newClfb = args.uid;
        }
    }
}

export function flagError(msg) {
    if(!runningTestId) {
        return;
    }

    testLogs[runningTestId] += 'Error: ' + msg + '\n\n';
    hasErrors = true;
}
