"use strict";

/**
 * Widgetfactory
 * @module widgetfactory
 */

import * as Helpers from '../core/helpers';
import * as I8n from '../core/i8n';
import {ActionWidget} from './action';
import {LineChartWidget} from './linechart';
import {ValueWidget} from './value';
import {Widget} from './widget';

/**
 * @param {object} iqObject
 * @param {'parameter' | 'setting' | 'action'} propertyType
 * @param {string | number} propertyId
 * @param {null | object} property - potentially pre-populated property
 * @param {null | object} propertyMeta - potentially pre-populated property
 *                                       meta info
 * @returns Widget | false
 */
export function create(iqObject, propertyType, propertyId, property = null, propertyMeta = null) {
    const objectType = Helpers.iqObjectType(iqObject);

    if(objectType === 'device') {
        if(propertyType === 'parameter') {
            if(!propertyMeta) {
                propertyMeta = Helpers.getParameter(iqObject, propertyId.toString());
            }

            if(!property) {
                for(let p of iqObject.parameters) {
                    if(p.qid === propertyId){
                        property = p;
                        break;
                    }
                }
            }
        } else if(propertyType === 'action') {
            propertyMeta = Helpers.getAction(iqObject, propertyId.toString());

            if(!property) {
                for(let a of iqObject.actions) {
                    if(a.name === propertyId){
                        property = a;
                        break;
                    }
                }
            }
        }
    } else if(objectType === 'fieldbus') {
        // TODO
        return false;
    } else if(objectType === 'clfb') {
        // TODO
        return false;
    }

    if(!propertyMeta || !propertyMeta.dashboard.showOnDashboard) {
        return false;
    }

    let widget = null;

    switch(propertyMeta.dashboard.viewAsType) {
        /*
        case 'DEFAULT':
        case 'VALUE':
        case 'EDITABLE_FIELD':
        case 'ACTION_BUTTON':
        case 'LINE_CHART':
        case 'SEGMENTS':
        case 'EQUALIZER_CHART':
        */
        case 'ACTION_BUTTON':
            widget = new ActionWidget();
            break;
        case 'LINE_CHART':
            if(propertyType === 'parameter') {
                const label = I8n.getParameterName(property.name);
                widget = new LineChartWidget(label, 'line', true);
                widget.addDataSet(label);
            }
            break;
        default:
            widget = new ValueWidget();
    }

    if(widget === null) {
        return false;
    }

    widget.addContext({
        iqObject: iqObject,
        propertyType: propertyType,
        property: property,
        propertyMeta: propertyMeta
    });

    return widget;
}
