/**
 * Setting view module.
 * @module setting
 */

"use strict";

import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Help from '../core/help';
import * as Helpers from '../core/helpers';
import * as Messages from '../core/messages';
import * as Permissions from '../core/permissions';
import * as State from '../core/state';
import {View} from '../core/view';

export class SettingView extends View {
    constructor() {
        super();
        this.viewName = 'SettingView';
        this.iqObject = null;
        this.iqObjectType = 'unknown';
        this.editing = false;
        this.settingName = null;
    }

    destructor() {
        super.destructor();
    }

    onStatusChange(msg) {
        if(msg.data.uid !== this.iqObject.uid){
            return;
        }
        this.iqObject.status = msg.data.status;
        Gui.disableLoadingIcons();
    }

    toggleHelp() {
        Gui.toggleHelpSection();
    }

    closeHelp() {
        Gui.closeHelpSection();
    }

    editSetting(actionId, args) {
        Debug.log('Start editing setting with: ' + actionId);
        Gui.editParameter(actionId, this.iqObject.settings[this.settingName]);
        this.editing = true;
    }

    onSettingsChange(msg) {
        if(
            (this.iqObjectType === 'fieldbus' && (msg.data.fieldbus !== this.iqObject.uid)) ||
            (this.iqObjectType === 'clfb' && (msg.data.uid !== this.iqObject.uid))
        ) {
            return;
        }

        this.iqObject.settings = msg.data.settings;

        for(let setting in this.iqObject.settings) {
            if(!this.iqObject.settings.hasOwnProperty(setting)) {
                continue;
            }

            if(this.iqObjectType === 'fieldbus') {
                Gui.switchSetting(
                    this.iqObject.uid,
                    setting,
                    this.iqObject.settings[setting]
                );
            } else {
                Gui.switchSetting(
                    this.iqObject.uid,
                    setting,
                    this.iqObject.settings[setting].currentValue
                );
            }
        }
    }

    saveSetting(actionId, args) {
        Debug.log(this.iqObject, 0, false);

        const name = actionId.split('-')[1];
        if(name !== this.settingName){
            Gui.showError(actionId, _('Invalid setting'));
            return;
        }

        let value = args.value;
        let newSettings = Helpers.clone(this.iqObject.settings);

        let setting = Helpers.getSetting(this.iqObjectType, this.settingName);

        // If this is a custom setting we need to do some custom setting value
        // retrieval
        if(setting.viewAsType === 'segments') {
            value = [];
            let bitString = '';
            for(let i = 0; i < 16; i++) {
                let checkBox = $('input#segment-' + i);
                if(checkBox.length === 0) {
                    bitString += '0';
                    value.push(false);
                } else {
                    if(checkBox.prop('checked')) {
                        bitString += '1';
                    } else {
                        bitString += '0';
                    }
                }
            }
            value = parseInt(Helpers.reverseString(bitString), 2);
        }

        let newValue = null;

        if(setting.settingType === 'str' || setting.settingType === 'enum') {
            newValue = value.toString();
        } else if(setting.settingType === 'number') {
            newValue = parseFloat(value);
        }

        /** @type {(uid: string, settings: object) => (Promise<any>)} */
        let apiCall;

        if(this.iqObjectType === 'fieldbus') {
            newSettings[name] = newValue;
            apiCall = this.Api.updateFieldbusSettings;
        } else if(this.iqObjectType === 'clfb') {
            newSettings[name].currentValue = newValue;
            apiCall = this.Api.updateClfbSettings;
        } else {
            Messages.addError(117, _('Unknown IQ Object Type'));
            return;
        }

        apiCall(this.iqObject.uid, newSettings).then(() => {
            if(this.iqObjectType === 'fieldbus') {
                this.iqObject.settings[name] = value;
            } else {
                this.iqObject.settings[name].currentValue = value;
            }
            Gui.switchSetting(
                this.iqObject.uid,
                this.settingName,
                value
            );
            Gui.stopEditingParameter(actionId);
            Gui.hideError(actionId);
        }).catch((err) => {
            Debug.log('Error when updating settings', 3);
            Debug.log(err, 3);
            Gui.showError(actionId, _('Could not save the new settings'));
        });
    }

    cancelEditSetting(actionId, args){
        Debug.log('Cancel setting edit with: ' + actionId);
        Gui.stopEditingParameter(actionId);
    }

    registerActionHandlers(){
        Debug.log('Register action handlers');

        if(!Permissions.canEditSetting(this.iqObjectType, this.settingName)){
            return;
        }

        const id = this.iqObject.uid + '-' + this.settingName;

        this.registerActionHandler(
           id, 'edit-setting', this.editSetting.bind(this)
        );

        this.registerActionHandler(
           id, 'save-setting', this.saveSetting.bind(this)
        );

        this.registerActionHandler(
            id, 'cancel-setting', this.cancelEditSetting.bind(this)
        );

        this.registerActionHandler(
            id, 'toggle-help', this.toggleHelp.bind(this)
        );

        this.registerActionHandler(
            id, 'close-help', this.closeHelp.bind(this)
        );
    }

    /**
     * Initialization, retrieves the IQ Object info from the api
     * and sets up all the instance variables. This function is used
     * in the render and edit functions
     * @param {string} uid - the fieldbus UID
     * @param {string} name - the setting name
     * @param {function} callback - called when everything is
     *                              initialized successfully
     * @private
     */
    initialize(uid, name, callback) {
        let iqObject = State.getIqObject(uid);

        /** @type {(uid: string) => Promise<any>} */
        let apiCall;
        if(Helpers.iqObjectType(iqObject) === 'fieldbus') {
            apiCall = this.Api.getFieldbus;
        } else if(Helpers.iqObjectType(iqObject) === 'clfb') {
            apiCall = this.Api.getClfb;
        } else {
            return Messages.addError(100, _('Unknown IQ Object Type'));
        }

        apiCall(uid).then((iqObject) => {
            this.iqObject = iqObject;
            this.iqObjectType = Helpers.iqObjectType(iqObject);
            this.settingName = name;
            Debug.log('IQ Object info:');
            Debug.log(this.iqObject);

            if(this.settingName === null){
                return Gui.soft404(_('Setting not found'));
            }

            // TODO: add listeners to status/device
            this.registerActionHandlers();
            callback();
        }).catch((e) => {
            Debug.log(e);
            return Gui.soft404(
                Helpers.capitalize(Helpers.prettyIqObjectType(iqObject)) +
                ' ' + _('not found')
            );
        });
    }

    objectRemoved(msg) {
        if(
            !msg.hasOwnProperty('data') ||
            !msg.data.hasOwnProperty('uid') ||
            msg.data.uid !== this.iqObject.uid
        ) {
            return false;
        }
        window.visit('/#/');
    }

    /**
     * Render this parameter view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args, editing=false) {
        if(!args.hasOwnProperty('uid') || !args.hasOwnProperty('name')){
            return Gui.show404();
        }

        this.editing = editing;

        Debug.log(
            'Render setting view for IQ Object ' +
            args.uid +
            ', and setting ' +
            args.name
        );

        this.initialize(args.uid, args.name, () => {
            Debug.log('Setting details');
            Debug.log(this.settingName);
            const setting = Helpers.getSetting(this.iqObjectType, this.settingName);
            let settingValue = this.iqObject.settings[this.settingName];

            let context = {
                'iqObject': this.iqObject,
                'settingName': this.settingName,
                'settingValue': settingValue,
                'setting': setting,
                'edit': editing,
                'helpText': '',
            };

            this.addListener(
                this.iqObjectType + '/status',
                this.onStatusChange.bind(this)
            );

            this.addListener(
                this.iqObjectType + '/settings',
                this.onSettingsChange.bind(this)
            );

            this.addListener(
                'object/removed',
                this.objectRemoved.bind(this)
            );

            const show = (availableSegments = Helpers.numberToBitArray(65535)) => {
                if(setting.viewAsType === 'segments') {
                    context.enabledSegments = Helpers.numberToBitArray(
                       settingValue.currentValue
                    );

                    context.availableSegments = availableSegments;

                    // The value is saved as big endian, but shown as little
                    // endian, so we need to reverse it before passing it to the
                    // template
                    context.enabledSegments.reverse();
                    context.availableSegments.reverse();
                }

                Help.setting(this.iqObject, this.settingName).then((helpText) => {
                    context.helpText = helpText;
                }).catch(() => {}).finally(() => {
                    Gui.render('setting.html', context);

                    if(editing) {
                        $('form.parameter').show();
                        $('form.parameter input').each((i, el) => {
                            if($(el).attr('autofocus')){
                                const tmpStr = $(el).val();
                                $(el).focus().val('').val(tmpStr);
                            }
                        });
                    }
                });
            };

            // If we're editing a segment setting for a CLFB then we first need
            // to check the linked input device's available segments parameter
            // so we can know which segments can be enabled
            if(this.iqObject._meta.type === 'clfb') {
                const inputDevice = State.getDevice(this.iqObject.settings.inputUid.currentValue);
                if(inputDevice === false) {
                    return show();
                }
                this.Api.getDevice(inputDevice.uid).then((input) => {
                    for(const param of input.parameters) {
                        if(param.name.endsWith('AVAILABLE_SEGMENTS')) {
                            let availableSegmentsInt = param.currentValue;
                            let minWidth = Helpers.numberToBitArray(param.maxValue).length;
                            return show(
                                Helpers.numberToBitArray(
                                    availableSegmentsInt,
                                    minWidth
                                )
                            );
                        }
                    }
                }).catch(() => {
                    Messages.addError(122, _('Failed to retrieve this CLFB\'s input device'));
                    return show();
                });
            } else {
                show();
            }
        });
    }

    /**
     * Render the edit view for this setting
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    edit(event, args) {
        this.render(event, args, true);
    }
}
