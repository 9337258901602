/**
 * Messages module.
 * @module messages
 */

"use strict";

import * as Debug from './debug';

// The Maximum amount of messages to show, after adding more the oldest one is
// removed
const MAXIMUM_MESSAGES = 5;
let lastMessageId = 0;

function messageHtml(messageId, text, messageClass, url='') {
    let messageHtml = '<div class="message ' + messageClass + '" id="message-' + messageId + '">';

    messageHtml += '<a href="';
    if(url !== '') {
        messageHtml += url + '" class="close-message message"';
    } else {
        messageHtml += '#" class="no-link"';
    }
    messageHtml += '>' + text + '</a>';

    messageHtml += '<a class="close-message no-link"><span class="icon-cancel-2"></span></a>';

    return messageHtml;
}

/**
 * Add a message to the message bar with the given text, an optional timeout
 * after which it automatically disappears and an optional link
 * @param {string} text - The text to show in the message
 * @param {string} messageClass - error, warning, success or neutral
 * @param {number} timeout - optional timeout in milliseconds
 * @param {string} url - optional url to link to
 */
export function addMessage(text, messageClass='neutral', timeout=0, url='') {
    const currentMessageCount = $('section.messages div.message:not(.persistent)').length;

    if(currentMessageCount > MAXIMUM_MESSAGES) {
        $('section.messages div.message:not(.presistent)').first().hide();
    }

    lastMessageId++;
    const messageId = lastMessageId;
    $('section.messages').append(messageHtml(messageId, text, messageClass, url));

    let debugMessageType = 1;
    if(messageClass === 'warning') {
        debugMessageType = 2;
    } else if(messageClass === 'error') {
        debugMessageType = 3;
    }

    Debug.log('User message: ' + text, debugMessageType);

    if(Debug.isPlayingBack()) {
        timeout = 1000;
    }

    if(timeout > 0) {
        window.setTimeout(() => {
            $('section.messages div.message#message-' + messageId).fadeOut(400, 'swing', () => {
                $('section.messages div.message#message-' + messageId).remove();
            });
        }, timeout);
    }
}

/**
 * Show error message
 * @param {number} errorNumber
 * @param {string} text
 * @param {string | false} url
 */
export function addError(errorNumber, text, url=false) {
    // Debug.flagError('[E' + errorNumber.toString() + '] ' + text);
    if(url) {
        addMessage('[E' + errorNumber.toString() + '] ' + text, 'error', 0, url);
    } else {
        addMessage('[E' + errorNumber.toString() + '] ' + text, 'error');
    }
}

/**
 * Add a persistent message to the message bar with the given text
 * and an optional link. This message only disappears when the user clicks the
 * link or the close button.
 * @param {string} text - The text to show in the message
 * @param {string} messageClass - error, warning, success or neutral
 * @param {string} url - optional url to link to
 * @returns {number} message id
 */
export function addPersistentMessage(text, messageClass='neutral', url='') {
    lastMessageId++;
    const messageId = lastMessageId;
    $('section.messages').append(
        messageHtml(messageId, text, 'persistent ' + messageClass, url)
    );
    return messageId;
}
