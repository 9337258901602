/* istanbul ignore file */

/**
 * Parameter view module.
 * @module parameter
 */

"use strict";

import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Debug from '../core/debug';
import * as Permissions from '../core/permissions';
import * as Signals from '../core/signals';
import * as State from '../core/state';
import * as Messages from '../core/messages';
import {LineChartWidget} from '../widgets/linechart';

export class ClfbIoChartView {
    constructor() {
        this.viewName = 'ClfbIoChartView';
        this.clfb = null;
        this.listenerIds = [];
        this.parameter = null;
        this.actionHandlerIds = [];
        this.maximized = false;

        this.chart = null;

        this.inputDataset = null;
        this.outputDataset = null;
        this.messageQueue = [];
    }

    destructor() {
        if(this.listenerIds.length > 0){
            Signals.removeListeners(this.listenerIds);
        }

        Actions.removeActionHandlers(this.actionHandlerIds);

        if(this.chart) {
            this.chart.destructor();
            this.chart = null;
        }
    }

    onMessage(msg) {
        if(msg.data.uid !== this.clfb.uid){
            return;
        }

        this.clfb.status = msg.data.status;

        if(this.chart !== null) {
            this.messageQueue.push([
                Date.now(),
                parseFloat(this.clfb.status.inputValue),
                parseFloat(this.clfb.status.outputValue)
            ]);

            if(this.chart !== null) {
                this.chart.addPoint(0, Date.now(), parseFloat(this.clfb.status.inputValue));
                this.chart.addPoint(1, Date.now(), parseFloat(this.clfb.status.outputValue));
            }
        }

        Gui.switchClfbValue(
            this.clfb.uid,
            'input',
            this.clfb.status.inputValue
        );

        Gui.switchClfbValue(
            this.clfb.uid,
            'output',
            this.clfb.status.outputValue
        );
    }

    initChart() {
        this.chart = new LineChartWidget('CLFB I/O', 'line', true);
        this.chart.addDataSet('Input');
        this.chart.addDataSet('Output', [], '#00bb02');
    }


    toggleMaximize(actionId, args, elem) {
        if(this.maximized) {
            $('section.chart').removeClass('maximized');
        } else {
            $('section.chart').addClass('maximized');
        }
        this.maximized = !this.maximized;
        this.chart.resetSize();
    }

    /**
     * Show or hide the table that is shown above the chart
     */
    toggleTable(actionId, args, elem) {
        const table = $('section.chart-table');
        if(table.length === 0) {
            Debug.log('Tried showing chart table, but there is none');
            return;
        }
        if(table.hasClass('show')) {
            table.removeClass('show');
            $(elem).html(_('Show table'));
        } else {
            table.addClass('show');
            $(elem).html(_('Hide table'));
        }
    }

    objectRemoved(msg) {
        if(
            !msg.hasOwnProperty('data') ||
            !msg.data.hasOwnProperty('uid') ||
            msg.data.uid !== this.clfb.uid
        ) {
            return false;
        }
        window.visit('/#/');
    }

    initSignalListeners() {
        this.listenerIds.push(Signals.addListener(
            'clfb/status',
            this.onMessage.bind(this)
        ));

        this.listenerIds.push(
            Signals.addListener(
                'screen/resize',
                this.chart.resetSize.bind(this.chart)
            )
        );

        this.listenerIds.push(Signals.addListener(
            'object/removed',
            this.objectRemoved.bind(this)
        ));
    }

    initActionHandlers() {
        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'chart', 'reset-zoom', this.chart.resetZoom.bind(this.chart)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'chart', 'zoom-in', this.chart.zoomIn.bind(this.chart)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'chart', 'zoom-out', this.chart.zoomOut.bind(this.chart)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'chart', 'toggle-table', this.toggleTable.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'chart', 'toggle-maximize', this.toggleMaximize.bind(this)
            )
        );
    }

    /**
     * Render this chart view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args, largeHistory=false) {
        if(!args.hasOwnProperty('uid')){
            return Gui.show404();
        }

        Debug.log(
            'Render i/o chart view for clfb ' +
            args.uid
        );


        Api.getClfb(args.uid).then((clfb) => {
            this.clfb = clfb;
            this.initChart();
            Debug.log('CLFB info:', 0);
            Debug.log(this.clfb, 0);

            let context = {
                'clfb': this.clfb,
                'widget': this.chart,
            };

            Gui.render('clfb-chart.html', context);
            this.chart.render();
            this.initSignalListeners();
            this.initActionHandlers();
        }).catch((e) => {
            Debug.log('Error rendering CLFB I/O Chart', 3);
            Debug.log(e, 3);
            return Gui.soft404(_('Could not find CLFB'));
        });
    }
}
