/**
 * The I8n module handles all translations. It offers a handy function "_" that
 * can quickly be used to translate a string. This function is available in the
 * Global namespace
 * @module i8n
 */

"use strict";

import * as Session from './session';
import * as Debug from './debug';

import * as De from '../locale/de';
import * as Es from '../locale/es';
import * as Fr from '../locale/fr';
import * as It from '../locale/it';
import * as Ja from '../locale/ja';
import * as Nl from '../locale/nl';
import * as Pl from '../locale/pl';

/**
 * A mapping for all translations
 * @private
 */
let translations = {};
let actionNames = {};

/**
 * Initialize the translations. Setup global underscore function (window._)
 * pointing to the _ function in this module
 */
export function initTranslations() {
    window._ = _;
    addTranslation('de', De.getTranslations());
    addTranslation('es', Es.getTranslations());
    addTranslation('fr', Fr.getTranslations());
    addTranslation('it', It.getTranslations());
    addTranslation('ja', Ja.getTranslations());
    addTranslation('nl', Nl.getTranslations());
    addTranslation('pl', Pl.getTranslations());

    for(const i in window.CONSTANTS.ACTION_TITLES) {
        if(window.CONSTANTS.ACTION_TITLES.hasOwnProperty(i)){
            const title = window.CONSTANTS.ACTION_TITLES[i];
            actionNames[i] = title;
        }
    }
}

/**
 * Adds a translation to the translations mapping
 * @param language the language
 * @param translation the translation dictionary
 */
export function addTranslation(language, translation){
    Debug.log("Adding translation for " + language);
    translations[language] = translation;
}

/**
 * Transforms the first character of a given string to lowercase
 * @param {string} input
 * @return {string}
 */
export function deCapitalizeFirstLetter(input) {
    if(input.length === 0){
        return '';
    }
    return input.charAt(0).toLowerCase() + input.slice(1);
}


/**
 * Transforms the first character of a given string to uppercase
 * @param {string} input
 * @return {string}
 */
export function capitalizeFirstLetter(input) {
    if(input.length === 0){
        return '';
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
}

/**
 * Translate a given source string into the target language. The target
 * language is retrieved from the [[Session]]
 * @param source the original English string
 * @returns string the translation if it's available, otherwise it returns
 *                 the original string
 * @global
 */
export function _(source) {
    if(source === '') {
        return '';
    }

    let language = Session.getLanguage();
    let translation;
    if(language === 'en'){
        translation = source;
    } else {
        if(
            translations.hasOwnProperty(language) &&
            translations[language].hasOwnProperty(source) &&
            translations[language][source] !== ''
        ){
            translation = translations[language][source];
        } else {

            let decap = deCapitalizeFirstLetter(source);

            if(
                translations.hasOwnProperty(language) &&
                translations[language].hasOwnProperty(decap)
            ){
                translation = capitalizeFirstLetter(translations[language][decap]);
            } else {
                Debug.log(`Translation for "${source}" not found, for language ${language}`);
                translation = source;
            }
        }
    }
    return translation;
}

export function translate(source) {
    return _(source);
}

/**
 * Retrieves the parameter name
 * @param {string} name - for example "PARAM_QID_CM_TINY_DEVICE_NAME"
 * @returns {string} - i.e "Device name"
 */
export function getParameterName(name){
    if(window.CONSTANTS.NAMES.hasOwnProperty(name)){
        return window.CONSTANTS.NAMES[name];
    } else {
        return name.replace('_MNGR4', '');
    }
}

/**
 * Retrieves the action name
 * @param {string} name - for example "restore-factory"
 * @returns {string} - i.e "Restore factory settings"
 */
export function getActionName(name){
    name = name.replace('_MNGR4', '');
    if(actionNames.hasOwnProperty(name)){
        return actionNames[name];
    } else {
        return name;
    }
}
