"use strict";

let translations = {
    // ../src/templates/time.html,
    '24H': '24h',

    // ../src/templates/time.html,
    '24H or AM/PM': '24h oder AM/PM',

    // IQ4 Text for event WARNING_MASK_24V_OUTPUT_OVERLOAD,
    '24v output overload': 'Überlastung des 24-V-Ausgangs',

    // ../src/templates/table.html,
    '48h': 'chart","48h-Diagramm',

    // ../src/templates/chart.html,
    '48h chart': '48h Grafik',

    // ../src/templates/time.html,
    'AM/PM': 'AM/ PM',

    // ../src/templates/log.html,
    'Actions': 'Aktionen',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,src/templates/setting.html,
    'Actual value': 'Aktueller Wert',

    // ../src/templates/user.html,
    'Add new user': 'Neuen Benutzer anlegen',

    // ../src/templates/related-add.html,
    'Add related objects': 'Verknüpfte Objekte hinzufügen',

    // Action title for add-to-clfb,
    'Add to CLFB': 'Zu CLFB hinzufügen',

    // IQ4 Text for parameter CLEANFLEX_AIR_PRESSURE,IQ4 Text for parameter BLOWFLEX_AIR_PRESSURE,
    'Air pressure': 'Luftdruck',

    // IQ4 Text for parameter CLEANFLEX_TRIGGER_LEVEL,IQ4 Text for parameter BLOWFLEX_TRIGGER_LEVEL,
    'Air pressure trigger level high voltage': 'Luftdruckauslösepegel Hochspannung',

    // IQ4 Text for parameter GATEWAY_ALARM_SETPOINT,IQ4 Text for parameter GATEWAY_EX_ALARM_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_ALARM_SETPOINT,
    'Alarm level, measured value over maximum': 'Alarmstufe, Messwert über Maximum',

    // IQ4 Text for parameter GATEWAY_ALARM_MINIMUM,IQ4 Text for parameter GATEWAY_EX_ALARM_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_ALARM_MINIMUM,
    'Alarm level, measured value under minimum': 'Alarmstufe, Messwert unter Minimum',

    // ../src/templates/log.html,
    'Alarms': 'Alarm',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_VALUE,
    'Ambient temperature': 'Umgebungstemperatur',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_AVAILABLE,
    'Ambient temperature sensor available': 'Umgebungstemperatursensor verfügbar',

    // ../src/../src/views/related.js,
    'An error occured while creating a new CLFB': 'Beim Erstellen eines neuen CLFB ist ein Fehler aufgetreten',

    // IQ4 Text for parameter PERFORMAX_ANALOG_CLEANBAR_ENABLED,IQ4 Text for parameter EASION_ANALOG_CLEANBAR_ENABLED,IQ4 Text for parameter EASION_ANALOG_CLEANBAR_MODE,IQ4 Text for parameter PERFORMAX_ANALOG_CLEANBAR_MODE,
    'Analog cleanbar enabled': 'Analoge Cleanbar aktiviert',

    // IQ4 Text for parameter CM_TINY_ARC_DETECT_COUNTER,IQ4 Text for parameter IQ_COM_ARC_DETECT_COUNTER,IQ4 Text for parameter MPM_ARC_DETECT_COUNTER,
    'Arcs per minute counter': 'Zähler Funkenüberschläge je Minute',

    // ../src/templates/user-delete.html,
    'Are you sure you want to delete the user': 'Sind Sie sicher dass Sie den Benutzer löschen möchten?',

    // IQ4 Text for parameter GATEWAY_AVAILABLE_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_AVAILABLE_SEGMENTS,
    'Available Sensor segments': 'Verfügbare Sensorsegmente',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Available updates': 'Verfügbare Updates',

    // ../src/templates/settings-updates.html,
    'Available version': 'Verfügbare Version',

    // ../src/templates/device.html,
    'Back to top': 'Zurück zum Seitenanfang',

    // IQ4 Text for setting CLFB_NAME,
    'CLFB name': 'CLFB-Name',

    // Action title for calibrate,
    'Calibrate bar': 'Stab kalibrieren',

    // ../src/../src/views/related.js,
    'Can not delete CLFB output': 'CLFB-Ausgabe kann nicht gelöscht werden',

    // ../src/templates/parameter.html,src/templates/setting.html,
    'Cancel': 'Abbrechen',

    // IQ4 Text for parameter CMME_CHARGE_SINGLE_SHOT_TIME,IQ4 Text for parameter IQ_COM_CHARGE_SINGLE_SHOT_TIME,
    'Charge pulse single shot': 'Einzelner Ladeimpuls',

    // ../src/templates/settings-install-updates-error.html,src/templates/settings.html,
    'Check available updates': 'Nach verfügbaren Updates suchen',

    // IQ4 Text for parameter PERFORMAX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter EASION_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter THUNDERION2_CLEAN_BAR_WARNING_LEVEL,
    'Clean bar warning level': 'Warnstufe „Stab reinigen“',

    // Action title for clear,
    'Clear action log': 'Aktionsprotokoll löschen',

    // ../src/templates/clfb.html,src/templates/parameter.html,src/templates/device-dashboard.html,src/templates/dashboard.html,src/templates/device.html,src/templates/fieldbus.html,src/templates/setting.html,src/templates/action.html,src/templates/log-table.html,
    'Close': 'Schließen',

    // ../src/templates/connecting.html,
    'Connecting to websocket, please wait.': 'Verbindung zum WebSocket wird hergestellt, bitte warten.',

    // ../src/templates/log-table.html,
    'Could not retrieve log': 'Daten konnten nicht abgerufen werden',

    // ../src/../src/views/setting.js,
    'Could not save the new settings': 'Die neuen Einstellungen konnten nicht gespeichert werden',

    // ../src/../src/views/parameter.js,
    'Could not save the new value': 'Der neue Wert konnte nicht gespeichert werden',

    // Action title for create-related,
    'Create related object': 'Erstellen Sie ein zugehöriges Objekt',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_SETPOINT,
    'Current Control current setpoint': 'Sollwert Konstantstrom',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_LIMIT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_LIMIT_SETPOINT,
    'Current Control output voltage limit': 'Grenzwert der Ausgangsspannung bei Konstantstrom',

    // ../src/templates/time.html,
    'Current system time in UTC': 'Aktuelle Systemzeit in UTC',

    // ../src/templates/time.html,
    'Current time zone': 'Aktuelle Zeitzone',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Current version': 'Aktuelle Version',

    // IQ4 Text for parameter CMME_CYCLE_OK_HIGH_DELAY_THRESHOLD,
    'Cycle OK high delay': 'Zyklus OK, hohe Verzögerung',

    // IQ4 Text for parameter CMME_CYCLE_OK_LOW_LEVEL_THRESHOLD_MNGR4,
    'Cycle OK low level': 'Zyklus OK, niedriges Niveau',

    // ../src/templates/time.html,src/templates/log.html,
    'Date': 'Datum',

    // ../src/templates/debug.html,
    'Debug': 'Fehlersuche',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,
    'Default value': 'Standardwert',

    // ../src/templates/debug-test.html,src/templates/user.html,
    'Delete': 'Löschen',

    // Action title for delete-related,
    'Delete related object': 'Zugehöriges Objekt löschen',

    // ../src/templates/user-delete.html,
    'Delete user': 'Benutzer löschen',

    // ../src/templates/debug-report.html,
    'Description': 'Beschreibung',

    // IQ4 Text for parameter CM_TINY_DEVICE_NAME,IQ4 Text for parameter CMME_DEVICE_NAME,IQ4 Text for parameter IQ_COM_DEVICE_NAME,IQ4 Text for parameter GATEWAY_DEVICE_NAME,IQ4 Text for parameter GATEWAY_EX_DEVICE_NAME,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_NAME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_DEVICE_NAME,IQ4 Text for parameter EASION_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_EX_DEVICE_NAME,IQ4 Text for parameter THUNDERION2_DEVICE_NAME,IQ4 Text for parameter VICINION_DEVICE_NAME,IQ4 Text for parameter CLEANFLEX_DEVICE_NAME,IQ4 Text for parameter BLOWFLEX_DEVICE_NAME,IQ4 Text for parameter MPM_DEVICE_NAME,
    'Device name': 'Gerätename',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE,IQ4 Text for parameter CMME_DEVICE_TYPE,IQ4 Text for parameter IQ_COM_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_EX_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE,IQ4 Text for parameter EASION_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE,IQ4 Text for parameter VICINION_DEVICE_TYPE,IQ4 Text for parameter CLEANFLEX_DEVICE_TYPE,IQ4 Text for parameter BLOWFLEX_DEVICE_TYPE,IQ4 Text for parameter MPM_DEVICE_TYPE,
    'Device type': 'Gerätetyp',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE_VERSION,IQ4 Text for parameter CMME_DEVICE_TYPE_VERSION,IQ4 Text for parameter IQ_COM_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE_VERSION,IQ4 Text for parameter EASION_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE_VERSION,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE_VERSION,
    'Device type version': 'Gerätetyp-Version',

    // Action title for disable,
    'Disable': 'Deaktivieren',

    // Action title for remove,
    'Disconnect': 'Verbindung trennen',

    // ../src/templates/log.html,
    'Dismiss log': 'Protokoll verwerfen',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_AVAILABLE,
    'Distance sensor available': 'Abstandssensor vorhanden',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_MALFUNCTION,
    'Distance sensor malfunction': 'Fehlfunktion des Abstandssensors',

    // IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE_OVERRIDE,
    'Distance sensor override': 'Übersteuerung des Abstandssensors',

    //     // ../src/templates/user.html,
    'Edit': 'Bearbeiten',

    // ../src/templates/macros.html,
    'Edit parameter': 'Parameter bearbeiten',

    // Action title for edit-related,
    'Edit related objects': 'Ausgewählte Objekte bearbeiten',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Edit setting': 'Einstellung bearbeiten',

    // ../src/templates/user-edit.html,
    'Edit user': 'Benutzer bearbeiten',

    // Action title for enable,
    'Enable': 'Aktivieren',

    // IQ4 Text for parameter GATEWAY_ENABLED_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_ENABLED_SEGMENTS,
    'Enabled Sensor segments': 'Verfügbare Sensorsegmente',

    // ../src/../src/views/time.js,
    'Error changing the system time': 'Fehler beim Ändern der Systemzeit',

    // ../src/../src/views/user.js,
    'Error deleting user': 'Fehler beim Löschen des Benutzers',

    // ../src/templates/settings-install-updates-error.html,
    'Error installing updates': 'Fehler beim Installieren des Updates',

    // ../src/../src/views/chart.js,../src/../src/views/table.js,
    'Error retrieving history': 'Fehler beim Abrufen der Historie',

    // ../src/../src/views/chart.js,../src/../src/views/table.js,
    'Error retrieving recent history': 'Fehler beim Abrufen der Historie',

    // ../src/../src/views/user.js,../src/../src/views/user.js,
    'Error: failed to create user': 'Fehler: Benutzer konnte nicht erstellt werden',

    // ../src/../src/views/user.js,
    'Error: failed to delete user': 'Fehler: Benutzer konnte nicht gelöscht werden',

    // ../src/../src/views/user.js,
    'Error: failed to edit user': 'Fehler: Benutzer konnte nicht bearbeitet werden',

    // ../src/../src/views/user.js,
    'Error: failed to update user': 'Fehler: Benutzer konnte nicht aktualisiert werden',

    // ../src/templates/log.html,
    'Events': 'Ereignisse',

    // Action title for export,
    'Export configuration': 'Konfiguration exportieren',

    // ../src/templates/settings.html,
    'Factory name': 'Werksname',

    // ../src/../src/views/log.js,
    'Failed to clear the log': 'Löschen der Historie fehlgeschlagen',

    // ../src/templates/failed-to-connect.html,
    'Failed to connect to the websocket, please check your settings.': 'Die Verbindung zum Websocket konnte nicht hergestellt werden. Bitte überprüfen Sie Ihre Einstellungen.',

    // ../src/../src/views/action.js,
    'Failed to perform the action ': 'Die Aktion konnte nicht ausgeführt werden',

    // ../src/../src/views/login.js,../src/../src/views/login.js,
    'Failed to reset the password. Please check the username and recovery key.': 'Das Passwort konnte nicht zurückgesetzt werden. Bitte überprüfen Sie den Benutzernamen und den Wiederherstellungsschlüssel.',

    // ../src/../src/views/related.js,
    'Failed to retrieve CLFB': 'CLFB konnte nicht abgerufen werden',

    // ../src/../src/views/related.js,
    'Failed to retrieve device': 'CLFB konnte nicht abgerufen werden',

    // ../src/../src/views/related.js,
    'Failed to retrieve fieldbus': 'Abruf des Feldbusses fehlgeschlagen',

    // ../src/../src/views/related.js,../src/../src/views/related.js,../src/../src/views/related.js,
    'Failed to retrieve the CLFB': 'CLFB konnte nicht abgerufen werden',

    // ../src/../src/views/related.js,
    'Failed to retrieve the fieldbus': 'Abruf des Feldbusses fehlgeschlagen',

    // ../src/../src/views/related.js,../src/../src/views/related.js,../src/../src/views/related.js,
    'Failed to update the CLFB settings': 'Die CLFB-Einstellungen konnten nicht aktualisiert werden',

    // ../src/../src/views/related.js,
    'Failed to update the Fieldbus Map': 'Aktualisierung der Feldbuskarte fehlgeschlagen',

    // IQ4 Text for setting FIELDBUS_NAME,
    'Fieldbus name': 'Feldbus Name',

    // IQ4 Text for parameter CM_TINY_FIRMWARE_VERSION,IQ4 Text for parameter CMME_FIRMWARE_VERSION,IQ4 Text for parameter IQ_COM_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter SEGMENT_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_FIRMWARE_VERSION,IQ4 Text for parameter EASION_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_EX_FIRMWARE_VERSION,IQ4 Text for parameter THUNDERION2_FIRMWARE_VERSION,IQ4 Text for parameter VICINION_FIRMWARE_VERSION,IQ4 Text for parameter CLEANFLEX_FIRMWARE_VERSION,IQ4 Text for parameter BLOWFLEX_FIRMWARE_VERSION,IQ4 Text for parameter MPM_FIRMWARE_VERSION,
    'Firmware version': 'Firmware Version',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG1,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG1,
    'Firmware version seg1': 'Firmware-Version Seg1',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG10,
    'Firmware version seg10': 'Firmware-Version Seg10',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG11,
    'Firmware version seg11': 'Firmware-Version Seg11',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG12,
    'Firmware version seg12': 'Firmware-Version Seg12',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG13,
    'Firmware version seg13': 'Firmware-Version Seg13',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG14,
    'Firmware version seg14': 'Firmware-Version Seg14',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG15,
    'Firmware version seg15': 'Firmware-Version Seg15',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG16,
    'Firmware version seg16': 'Firmware-Version Seg16',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG2,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG2,
    'Firmware version seg2': 'Firmware-Version Seg2',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG3,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG3,
    'Firmware version seg3': 'Firmware-Version Seg3',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG4,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG4,
    'Firmware version seg4': 'Firmware-Version Seg4',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG5,
    'Firmware version seg5': 'Firmware-Version Seg5',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG6,
    'Firmware version seg6': 'Firmware-Version Seg6',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG7,
    'Firmware version seg7': 'Firmware-Version Seg7',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG8,
    'Firmware version seg8': 'Firmware-Version Seg8',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG9,
    'Firmware version seg9': 'Firmware-Version Seg9',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Hide release notes': 'Versionshinweise ausblenden',

    // ../src/../src/views/chart.js,../src/../src/views/clfb-io-chart.js,
    'Hide table': 'Tabelle ausblenden',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter CMME_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter MPM_OUTPUT_CURRENT_FEEDBACK,
    'High voltage output current': 'Ausgangsstrom Hochspannung',

    // IQ4 Text for parameter CMME_OUTPUT_CURRENT_LIMIT_MNGR4,
    'High voltage output current limit': 'Strombegrenzung Hochspannung',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_CURRENT_PEAK_MNGR4,
    'High voltage output current peak per cycle': 'Hochspannungs-Ausgangsstromspitze pro Zyklus',

    // IQ4 Text for parameter THUNDERION2_OUTPUT_FREQUENCY_SETPOINT,IQ4 Text for parameter VICINION_OUTPUT_FREQUENCY_SETPOINT,
    'High voltage output frequency': 'Frequenz Hochspannung',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter VICINION_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_FEEDBACK,
    'High voltage output voltage': 'Ausgangsspannung',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter MPM_HV_OVERLOAD_LEVEL_ALARM,
    'High voltage overload alarm level': 'Alarmpegel Hochspannung',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter MPM_HV_OVERLOAD_LEVEL_WARNING,
    'High voltage overload warning level': 'Warnlevel Überlast der Hochspannung',

    // ../src/templates/user-edit.html,src/templates/settings-updates.html,
    'Id': 'ID',

    // Action title for identify,
    'Identify': 'Identifizieren',

    // ../src/templates/log.html,
    'Info': 'Information',

    // IQ4 Text for setting CLFB_INPUTSEGMENTS,
    'Input Segments Selection': 'Auswahl der Eingabesegmente',

    // ../src/templates/clfb.html,src/templates/clfb-io.html,src/templates/clfb-chart.html,
    'Input value': 'Eingangswert',

    // ../src/templates/settings-updates.html,
    'Install all updates': 'Installieren Sie alle Updates',

    // IQ4 Text for parameter CM_TINY_INTERNAL_TEMP,IQ4 Text for parameter CMME_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter QID_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE,IQ4 Text for parameter THUNDERION2_INTERNAL_TEMPERATURE,IQ4 Text for parameter VICINION_INTERNAL_TEMPERATURE,IQ4 Text for parameter EASION_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter BLOWFLEX_INTERNAL_TEMPERATURE,IQ4 Text for parameter MPM_INTERNAL_TEMP,
    'Internal temperature': 'Interne Temperatur',

    // ../src/templates/macros.html,
    'Internal use': 'Interner Gebrauch',

    // ../src/../src/views/login.js,../src/../src/views/login.js,
    'Invalid login, please try again': 'Ungültige Anmeldung, versuchen Sie es erneut',

    // ../src/../src/views/parameter.js,
    'Invalid parameter': 'Ungültiger Parameter',

    // ../src/../src/views/related.js,
    'Invalid related object target': 'Ungültiges verwandtes Objektziel',

    // ../src/../src/views/setting.js,
    'Invalid setting': 'Ungültige Einstellung',

    // IQ4 Text for parameter PERFORMAX_BALANCE_OFFSET,IQ4 Text for parameter EASION_BALANCE_OFFSET,IQ4 Text for parameter PERFORMAX_EX_BALANCE_OFFSET,IQ4 Text for parameter THUNDERION2_BALANCE_OFFSET,
    'Ionisation balance offset': 'Ionisationsbalance Offset',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter EASION_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter THUNDERION2_IONISATION_CURRENT_NEGATIVE,IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEG,
    'Ionisation current negative emitters': 'Ionisationsstrom negative Emitter',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter EASION_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter THUNDERION2_IONISATION_CURRENT_POSITIVE,IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POS,
    'Ionisation current positive emitters': 'Ionisationsstrom positive Emitter',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_EFFICIENCY,IQ4 Text for parameter EASION_IONIZATION_EFFICIENCY,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_EFFICIENCY,IQ4 Text for parameter THUNDERION2_IONISATION_EFFICIENCY,
    'Ionisation efficiency': 'Ionisierungs-Effizienz',

    // ../src/templates/login.html,
    'Language': 'Sprache',

    // IQ4 Text for parameter CM_TINY_LAST_ALARM,IQ4 Text for parameter CMME_LAST_ALARM,IQ4 Text for parameter IQ_COM_LAST_ALARM,IQ4 Text for parameter GATEWAY_LAST_ALARM,IQ4 Text for parameter GATEWAY_EX_LAST_ALARM,IQ4 Text for parameter PERFORMAX_LAST_ALARM,IQ4 Text for parameter EASION_LAST_ALARM,IQ4 Text for parameter PERFORMAX_EX_LAST_ALARM,IQ4 Text for parameter THUNDERION2_LAST_ALARM,IQ4 Text for parameter VICINION_LAST_ALARM,IQ4 Text for parameter CLEANFLEX_LAST_ALARM,IQ4 Text for parameter BLOWFLEX_LAST_ALARM,IQ4 Text for parameter MPM_LAST_ALARM,
    'Last alarm date-time': 'Datum und Uhrzeit des letzten Alarms',

    // IQ4 Text for parameter SEGMENT_4_QID_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_LAST_CALIBRATION_DATE,IQ4 Text for parameter EASION_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_EX_LAST_CALIBRATION_DATE,
    'Last calibration date': 'Letztes Kalibrierungsdatum',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG1,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG1,
    'Last calibration date seg1': 'Letztes Kalibrierungsdatum Seg1',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG10,
    'Last calibration date seg10': 'Letztes Kalibrierungsdatum, Seg 10',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG11,
    'Last calibration date seg11': 'Letztes Kalibrierungsdatum, Seg 11',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG12,
    'Last calibration date seg12': 'Letztes Kalibrierungsdatum Seg12',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG13,
    'Last calibration date seg13': 'Letztes Kalibrierungsdatum Seg13',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG14,
    'Last calibration date seg14': 'Letztes Kalibrierungsdatum seg14',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG15,
    'Last calibration date seg15': 'Letztes Kalibrierungsdatum Seg15',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG16,
    'Last calibration date seg16': 'Letztes Kalibrierungsdatum Seg16',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG2,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG2,
    'Last calibration date seg2': 'Letztes Kalibrierungsdatum Seg2',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG3,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG3,
    'Last calibration date seg3': 'Letztes Kalibrierungsdatum Seg3',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG4,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG4,
    'Last calibration date seg4': 'Letztes Kalibrierungsdatum Seg4',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG5,
    'Last calibration date seg5': 'Letztes Kalibrierungsdatum Seg5',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG6,
    'Last calibration date seg6': 'Letztes Kalibrationsdatum Seg6',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG7,
    'Last calibration date seg7': 'Letztes Kalibrierungsdatum Seg7',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG8,
    'Last calibration date seg8': 'Letztes Kalibrierungsdatum Seg8',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG9,
    'Last calibration date seg9': 'Letztes Kalibrierungsdatum Seg9',

    // IQ4 Text for parameter PERFORMAX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter EASION_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter PERFORMAX_EX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter THUNDERION2_LAST_CLEAN_BAR_WARNING,
    'Last clean bar warning date-time': 'Datum und Uhrzeit der letzten Clean-Bar-Warnung',

    // IQ4 Text for parameter CM_TINY_LAST_WARNING,IQ4 Text for parameter CMME_LAST_WARNING,IQ4 Text for parameter IQ_COM_LAST_WARNING,IQ4 Text for parameter GATEWAY_LAST_WARNING,IQ4 Text for parameter GATEWAY_EX_LAST_WARNING,IQ4 Text for parameter PERFORMAX_LAST_WARNING,IQ4 Text for parameter EASION_LAST_WARNING,IQ4 Text for parameter THUNDERION2_LAST_WARNING,IQ4 Text for parameter VICINION_LAST_WARNING,IQ4 Text for parameter CLEANFLEX_LAST_WARNING,IQ4 Text for parameter BLOWFLEX_LAST_WARNING,IQ4 Text for parameter MPM_LAST_WARNING,
    'Last warning date-time': 'Datum und Uhrzeit der letzten Warnung',

    // ../src/templates/log.html,
    'Log for': 'Log für',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Loop selection': 'Loop-Auswahl',

    // IQ4 Text for parameter CM_TINY_MACHINE_POSITION,IQ4 Text for parameter CMME_MACHINE_POSITION,IQ4 Text for parameter IQ_COM_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_EX_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_MACHINE_POSITION,IQ4 Text for parameter EASION_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_EX_MACHINE_POSITION,IQ4 Text for parameter THUNDERION2_MACHINE_POSITION,IQ4 Text for parameter VICINION_MACHINE_POSITION,IQ4 Text for parameter CLEANFLEX_MACHINE_POSITION,IQ4 Text for parameter BLOWFLEX_MACHINE_POSITION,IQ4 Text for parameter MPM_MACHINE_POSITION,
    'Machine position (location)': 'Maschinenposition (Standort)',

    // IQ4 Text for event CLFB_INPUT_WATCHDOG_ELAPSED,
    'Make sure the CLFB is being updated. Sensor is connected and running.': 'Stellen Sie sicher, dass der CLFB aktualisiert wird. Der Sensor ist verbunden und läuft.',

    // IQ4 Text for event CLFB_INPUT_DEVICE_NOT_READY,
    'Make sure the input device is available and in RUN mode': 'Stellen Sie sicher, dass das Eingabegerät verfügbar ist und sich im RUN-Modus befindet',

    // IQ4 Text for event CLFB_OUTPUT_DEVICE_NOT_READY,
    'Make sure the output device is available and in run mode': 'Stellen Sie sicher, dass das Ausgabegerät verfügbar ist und sich im RUN-Modus befindet',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MAX_TIME,
    'Max. Charge pulse duration': 'Max. Dauer des Ladeimpulses',

    // IQ4 Text for parameter CM_TINY_MAX_INTERNAL_TEMP,IQ4 Text for parameter CMME_MAX_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter EASION_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter VICINION_MAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter MPM_MAX_INTERNAL_TEMP,
    'Max. internal temperature': 'Max. interne Temperatur',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_MAX_OLD,
    'Max. primary current': 'Max. Primärstrom',

    // IQ4 Text for parameter CM_TINY_MAX_QUANTITY_ARCS,IQ4 Text for parameter IQ_COM_MAX_QUANTITY_ARCS,
    'Max. quantity arcs per minute': 'Max. Anzahl Funken pro Minute',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,
    'Maximum value': 'Maximalwert',

    // ../src/templates/log.html,
    'Message': 'Nachricht',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MIN_TIME,
    'Min. Charge pulse duration': 'Mindest. Dauer des Ladeimpulses',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,
    'Minimum value': 'Minimalwert',

    // IQ4 Text for parameter SENSOR_MOUNTING_DISTANCE,IQ4 Text for parameter SENSOR_EX_MOUNTING_DISTANCE,IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_MOUNTING_DISTANCE,IQ4 Text for parameter EASION_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_EX_MOUNTING_DISTANCE,IQ4 Text for parameter THUNDERION2_MOUNTING_DISTANCE,IQ4 Text for parameter VICINION_MOUNTING_DISTANCE,
    'Mounting distance': 'Montageabstand',

    // ../src/templates/related-add.html,
    'New Closed Loop Feedback': 'Neues Closed-Loop-Feedback',

    // ../src/../src/views/log.js,
    'New log entries have been received. Click on the "refresh log" button to update the list.': 'Neue Logeinträge wurden empfangen. Klicken Sie auf den "Log aktualisieren"-Button, um die Liste zu aktualisieren.',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'No': 'Nein',

    // ../src/../src/core/gui.js,
    'Off': 'Aus',

    // ../src/../src/core/gui.js,
    'On': 'Ein',

    // IQ4 Text for parameter CM_TINY_OPERATION_MODE,IQ4 Text for parameter CMME_OPERATION_MODE,IQ4 Text for parameter IQ_COM_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_BAR_OPERATION_MODE,IQ4 Text for parameter EASION_BAR_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_EX_BAR_OPERATION_MODE,IQ4 Text for parameter THUNDERION2_BAR_OPERATION_MODE,IQ4 Text for parameter VICINION_BAR_OPERATION_MODE,
    'Operation mode': 'Betriebsmodus',

    // IQ4 Text for parameter CMME_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_ALARM_CURRENT_PEAK_MNGR4,
    'Output current alarm limit': 'Alarmgrenze für den Ausgangsstrom',

    // IQ4 Text for parameter CMME_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_WARNING_CURRENT_PEAK_MNGR4,
    'Output current peak alarm limit': 'Alarmgrenze für den Spitzenstrom des Ausgangs',

    // IQ4 Text for parameter EASION_OUTPUT_FREQUENCY_SETPOINT,
    'Output frequency setpoint': 'Sollwert Ausgangsfrequenz',

    // ../src/templates/clfb.html,src/templates/clfb-io.html,src/templates/clfb-chart.html,
    'Output value': 'Ausgabewert',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_MAX,
    'Output voltage max setpoint': 'Maximaler Sollwert der Ausgangsspannung',

    // IQ4 Text for parameter PERFORMAX_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter PERFORMAX_EX_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter PERFORMAX_VSENSE_NEG,
    'Output voltage negative emitters': 'Ausgangsspannung negative Emitter',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_NEGATIVE_SETPOINT,
    'Output voltage negative setpoint': 'Negativer Sollwert der Ausgangsspannung',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_PEAK_MNGR4,
    'Output voltage peak per cycle': 'Ausgangsspannungsspitze pro Zyklus',

    // IQ4 Text for parameter PERFORMAX_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter PERFORMAX_EX_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter PERFORMAX_VSENSE_POS,
    'Output voltage positive emitters': 'Ausgangsspannung positive Emitter',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_POSITIVE_SETPOINT,
    'Output voltage positive setpoint': 'Ausgangsspannung positiver Sollwert',

    // ../src/templates/404.html,
    'Page could not be found': 'Hoppla! Wir konnten die Seite nicht finden.',

    // ../src/templates/macros.html,
    'Parameter locked': 'Parameter gesperrt',

    // ../src/templates/user-edit.html,src/templates/login.html,src/templates/user-new.html,
    'Password': 'Passwort',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Password reset': 'Passwort zurücksetzen',

    // ../src/../src/views/parameter.js,
    'Please input a valid number': 'Bitte geben Sie eine gültige Zahl an.',

    // ../src/templates/parameter.html,
    'Plot 48h chart': '48h-Diagramm zeichnen',

    // ../src/templates/parameter.html,src/templates/clfb-io.html,
    'Plot realtime chart': 'Echtzeit-Diagramm zeichnen',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CMME_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter THUNDERION2_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CLEANFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter BLOWFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE,
    'Power supply voltage': 'Versorgungsspannung',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter CMME_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE_WRN_LEVEL,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE_WARNING,
    'Power supply warning level': 'Warnstufe für die Stromversorgung',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter THUNDERION2_PRIMARY_CURRENT,IQ4 Text for parameter CLEANFLEX_PRIMARY_CURRENT,IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT,
    'Primary current': 'Primärstrom',

    // IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT_CALIBRATION_OFFSET,
    'Primary current calibration offset': 'Offset der Primärstromkalibrierung',

    // ../src/templates/chart.html,
    'Realtime chart': 'Echtzeitdiagramm',

    // Action title for reboot,
    'Reboot': 'Neustart',

    // ../src/templates/password-reset.html,
    'Recovery key': 'Wiederherstellungs-Schlüssel',

    // ../src/templates/log.html,
    'Refresh log': 'Protokoll aktualisieren',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_VALUE,
    'Relative Humidity': 'Relative Luftfeuchtigkeit',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_AVAILABLE,
    'Relative Humidity sensor available': 'Sensor für relative Luftfeuchtigkeit verfügbar',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Release notes': 'Versionshinweise',

    // ../src/templates/debug-report.html,src/templates/debug.html,
    'Report': 'Bericht',

    // ../src/templates/password-reset.html,
    'Reset password': 'Passwort zurücksetzen',

    // ../src/templates/chart.html,src/templates/clfb-chart.html,
    'Reset zoom': 'Zoom zurücksetzen',

    // ../src/templates/debug.html,
    'Restart': 'Neustart',

    // ../src/../src/core/state.js,
    'Restarting the system, please wait': 'System wird neu gestartet, bitte warten',

    // ../src/../src/core/state.js,
    'Restarting, please wait': 'Neustart, bitte warten',

    // Action title for restore-factory,
    'Restore factory settings': 'Zurücksetzen auf Werkseinstellungen',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Result': 'Ergebnis',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Run': 'Ausführen',

    // ../src/templates/debug-test.html,
    'Run test selection': 'Testauswahl durchführen',

    // ../src/templates/user-edit.html,src/templates/parameter.html,src/templates/time.html,src/templates/time.html,src/templates/user-new.html,src/templates/setting.html,
    'Save': 'Speichern',

    // IQ4 Text for setting CLFB_SEGMENTSELECTION,
    'Segment Selection Mode': 'Segment-Auswahlmodus',

    // ../src/templates/related-select-slot.html,
    'Select fieldbus slot': 'Feldbussteckplatz auswählen',

    // ../src/templates/debug-report.html,
    'Send': 'Senden',

    // ../src/templates/debug-test.html,
    'Sequences': 'Reihenfolgen',

    // IQ4 Text for event CLFB_SETPOINT_NOT_READY,
    'Set all requested setpoint settings and relations.': 'Setze alle angeforderten Einstellungen und Beziehungen ein.',

    // Action title for run,
    'Set device in RUN': 'Gerät in RUN setzen',

    // ../src/templates/time.html,
    'Set local settings': 'Lokale Einstellungen festlegen',

    // ../src/templates/time.html,
    'Set new system time in UTC': 'Neue Systemzeit in UTC festlegen',

    // IQ4 Text for parameter BLOWFLEX_SETPOINT_CURRENT,
    'Setpoint current': 'Sollwert Strom',

    //     // ../src/templates/macros.html,
    'Setting locked': 'Einstellung gesperrt',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Settings': 'Einstellungen',

    // ../src/templates/log.html,
    'Show all ': 'Alle anzeigen',

    // ../src/templates/settings-updates.html,
    'Show current versions': 'Aktuelle Versionen anzeigen',

    // ../src/templates/log.html,
    'Show dismissed': 'Show abgewiesen',

    // ../src/templates/related-delete.html,src/templates/related-delete.html,src/templates/related-add.html,src/templates/related-select-slot.html,Action title for show-related,
    'Show related objects': 'Verwandte Objekte anzeigen',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Show release notes': 'Zeige Release Notes',

    // ../src/../src/views/chart.js,../src/../src/views/clfb-io-chart.js,src/templates/chart.html,src/templates/clfb-chart.html,
    'Show table': 'Tabelle anzeigen',

    // ../src/templates/related-select-slot.html,
    'Slot ': 'Slots',

    // ../src/../src/core/state.js,
    'Starting the system, please wait': 'System wird neu gestartet, bitte warten',

    // ../src/templates/debug-state.html,src/templates/debug.html,
    'State': 'Land',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Steps': 'Schritte',

    // ../src/../src/views/time.js,
    'The local time settings were changed successfully': 'Lokale Zeiteinstellungen wurden erfolgreich geändert',

    // ../src/templates/password-reset.html,
    'The new password is': 'Ihr neues Passwort lautet',

    // ../src/templates/password-reset.html,
    'The password for ': 'Das Passwort für',

    // ../src/../src/views/time.js,
    'The system time was changed successfully': 'Systemzeit wurde erfolgreich geändert',

    // ../src/templates/related-add.html,
    'There are no objects to add.': 'Es sind keine Objekte zum Hinzufügen vorhanden.',

    // ../src/templates/related-delete.html,
    'There are no objects to delete.': 'Es gibt keine zu löschenden Objekte.',

    // ../src/templates/related.html,
    'There are no related objects.': 'Es gibt keine verwandten Objekte.',

    // ../src/templates/settings-updates.html,
    'There are no updates available': 'Es sind keine Updates verfügbar.',

    // ../src/templates/settings-install-updates.html,
    'This might take a while and the system will reboot.': 'Dies kann eine Weile dauern und das System wird neu gestartet.',

    // ../src/templates/settings-confirm-update.html,
    'This will reboot the entire system and cause some downtime.': 'Dadurch wird das gesamte System neu gestartet und es kommt zu Ausfallzeiten.',

    // ../src/templates/time.html,
    'Time': 'Zeit',

    // IQ4 Text for parameter CM_TINY_TIME_RUNNING,IQ4 Text for parameter CMME_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TIME_RUNNING,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_UPTIME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_UPTIME,IQ4 Text for parameter PERFORMAX_TIME_RUNNING,IQ4 Text for parameter EASION_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TIME_RUNNING,IQ4 Text for parameter VICINION_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TIME_RUNNING,IQ4 Text for parameter BLOWFLEX_TIME_RUNNING,IQ4 Text for parameter MPM_TIME_RUNNING,
    'Time running since power up': 'Laufzeit seit dem Einschalten',

    // IQ4 Text for parameter CMME_TIME_TO_CYCLE_OK,
    'Time to Cycle OK': 'Zeit bis zum Zyklus OK',

    // ../src/templates/time.html,
    'Timezone': 'Zeitzone',

    // IQ4 Text for parameter GATEWAY_4_QID_TOTAL_RUNNING_TIME,IQ4 Text for parameter SEGMENT_4_QID_TOTAL_RUNNING_TIME,
    'Total running time': 'Gesamte Laufzeit',

    // IQ4 Text for parameter CM_TINY_TOTAL_TIME_RUNNING,IQ4 Text for parameter CMME_TOTAL_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TOTAL_TIME_RUNNING,IQ4 Text for parameter VICINION_TOTAL_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TOTAL_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TOTAL_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_TOTAL_TIME_RUNNING,IQ4 Text for parameter EASION_TOTAL_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TOTAL_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TOTAL_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TOTAL_TIME_RUNNING,IQ4 Text for parameter MPM_TOTAL_TIME_RUNNING,
    'Total time running': 'Gesamtlaufzeit',

    // IQ4 Text for parameter CM_TINY_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter CMME_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter THUNDERION2_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter VICINION_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter IQ_COM_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter CLEANFLEX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter PERFORMAX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter EASION_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter PERFORMAX_EX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter GATEWAY_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter GATEWAY_EX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter MPM_TOTAL_TIMES_POWERED_ON,
    'Total times powered on': 'Gesamteinschaltzeiten',

    // ../src/templates/log.html,src/templates/settings-updates.html,src/templates/settings.html,
    'Type': 'Typ',

    // ../src/../src/views/device.js,
    'Unexpected error occured while disabling': 'Beim Deaktivieren ist ein unerwarteter Fehler aufgetreten',

    // ../src/../src/views/device.js,
    'Unexpected error occured while enabling': 'Bei der Aktivierung ist ein unerwarteter Fehler aufgetreten',

    // IQ4 Text for parameter CM_TINY_UNIQUE_DEVICE_ID,IQ4 Text for parameter CMME_UNIQUE_DEVICE_ID,IQ4 Text for parameter IQ_COM_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter SEGMENT_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_UNIQUE_DEVICE_ID,IQ4 Text for parameter EASION_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter THUNDERION2_UNIQUE_DEVICE_ID,IQ4 Text for parameter VICINION_UNIQUE_DEVICE_ID,IQ4 Text for parameter CLEANFLEX_UNIQUE_DEVICE_ID,IQ4 Text for parameter BLOWFLEX_UNIQUE_DEVICE_ID,
    'Unique device ID': 'Eindeutige Geräte-ID',

    // ../src/../src/core/gui.js,
    'Unknown': 'Unbekannt',

    // ../src/../src/views/setting.js,../src/../src/views/setting.js,
    'Unknown IQ Object Type': 'Unbekannter IQ-Objekttyp',

    // ../src/templates/error.html,
    'Unknown error': 'Unbekannter Fehler',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Unknown object': 'Unbekanntes Objekt',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,../src/../src/views/settings.js,src/templates/settings.html,
    'Updating': 'Aktualisierung',

    // ../src/../src/core/state.js,
    'Updating the system, please wait': 'Das System wird aktualisiert. Bitte warten Sie',

    // ../src/templates/related.html,src/templates/related.html,
    'Use the + button to add a related object.': 'Verwenden Sie die Schaltfläche „+“, um ein zugehöriges Objekt hinzuzufügen.',

    // ../src/../src/views/user.js,
    'User created successfully': 'Benutzer erfolgreich angelegt',

    // ../src/../src/views/user.js,
    'User deleted successfully': 'Benutzer erfolgreich gelöscht',

    // ../src/templates/user.html,
    'User list': 'Nutzerliste',

    // ../src/../src/views/user.js,
    'User updated successfully': 'Benutzer erfolgreich aktualisiert',

    // ../src/templates/settings.html,
    'Versions': 'Versionen',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter VICINION_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_SETPOINT,
    'Voltage Control voltage setpoint': 'Spannung Steuerspannungssollwert',

    // ../src/../src/main.js,../src/../src/core/websocket.js,
    'Waiting for server connection, please wait': 'Warten auf Serververbindung, bitte warten',

    // IQ4 Text for parameter GATEWAY_WARNING_SETPOINT,IQ4 Text for parameter GATEWAY_EX_WARNING_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_WARNING_SETPOINT,
    'Warning level, measured value over maximum': 'Warnstufe, Messwert über Maximum',

    // IQ4 Text for parameter GATEWAY_WARNING_MINIMUM,IQ4 Text for parameter GATEWAY_EX_WARNING_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_WARNING_MINIMUM,
    'Warning level, measured value under minimum': 'Warnstufe, Messwert unter Minimum',

    // IQ4 Text for parameter GATEWAY_WARNING_ERROR_DELAY,IQ4 Text for parameter GATEWAY_EX_WARNING_ERROR_DELAY,
    'Warning/alarm delay': 'Warnungs-/Alarmverzögerung',

    // ../src/templates/log.html,
    'Warnings': 'Warnungen',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_SPEED,IQ4 Text for parameter EASION_WEB_SPEED,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_SPEED,IQ4 Text for parameter PERFORMAX_WEB_SPEED,
    'Web speed': 'Bahngeschwindigkeit',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,IQ4 Text for parameter GATEWAY_EX_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,
    'Web voltage average (all)': 'Durchschnitt der Ladung (alle)',

    // IQ4 Text for setting CLFB_WEBVOLTAGEOFFSET,
    'Web voltage offset': 'Bahnspannungsoffset',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG1,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG1,
    'Web voltage seg1': 'Ladung Seg1',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG10,
    'Web voltage seg10': 'Ladung Seg10',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG11,
    'Web voltage seg11': 'Ladung Seg11',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG12,
    'Web voltage seg12': 'Ladung Seg12',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG13,
    'Web voltage seg13': 'Ladung Seg13',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG14,
    'Web voltage seg14': 'Ladung Seg14',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG15,
    'Web voltage seg15': 'Ladung Seg15',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG16,
    'Web voltage seg16': 'Ladung Seg16',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG2,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG2,
    'Web voltage seg2': 'Ladung Seg2',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG3,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG3,
    'Web voltage seg3': 'Ladung Seg3',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG4,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG4,
    'Web voltage seg4': 'Ladung Seg4',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG5,
    'Web voltage seg5': 'Ladung Seg5',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG6,
    'Web voltage seg6': 'Ladung Seg6',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG7,
    'Web voltage seg7': 'Ladung Seg7',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG8,
    'Web voltage seg8': 'Ladung Seg8',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG9,
    'Web voltage seg9': 'Ladung Seg9',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_WIDTH,IQ4 Text for parameter EASION_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_WEB_WIDTH,
    'Web width': 'Bahnbreite',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'Yes': 'Ja',

    // ../src/templates/user.html,
    'You are currently logged in as the user': 'Sie sind derzeit als Benutzer angemeldet',

    // ../src/../src/views/user.js,
    'You can not delete your own user account': 'Sie können Ihr eigenes Benutzerkonto nicht löschen.',

    // IQ4 Text for event WARNING_MASK_ARC_DETECTED,IQ4 Text for event ALARM_MASK_ARC_DETECTED,
    'arc detected': 'Funkenüberschlag erkannt',

    // IQ4 Text for event WARNING_EFFICIENCY_LOW,
    'bar efficiency low': 'Bareffizienz niedrig',

    // IQ4 Text for event WARNING_CALIBRATION_IN_PROGRESS,
    'calibration in progress': 'Kalibrierung läuft',

    // IQ4 Text for event WARNING_CALIBRATION_INVALID,
    'calibration invalid': 'Kalibrierung ungültig',

    // IQ4 Text for event WARNING_MASK_CHARGEPULSE_TOO_LONG,
    'chargepulse too long': 'Ladeimpuls zu lang',

    // IQ4 Text for event WARNING_MASK_CLEAN_BAR,
    'clean bar': 'Stab Reinigen',

    // IQ4 Text for event ALARM_COMMUNICATION_LOST_SEGMENT,
    'communication lost with sensor segment': 'Kommunikation mit dem Sensorsegment verloren',

    // IQ4 Text for event WARNING_MASK_HMI_COMM_LOST,
    'device communication lost': 'Gerätekommunikation verloren',

    // IQ4 Text for event WARNING_MASK_DEVICE_NEEDS_REBOOT,
    'device needs reboot': 'Gerät muss neu gestartet werden',

    // IQ4 Text for event SYS_WARNING_MASK_DEVICE_PORT_CHANGED,IQ4 Text for event WARNING_PORT_SWAP,
    'device port changed': 'Geräteport geändert',

    // IQ4 Text for event ALARM_MASK_EEPROM_DEFECTIVE,
    'eeprom defective': 'Eeprom fehlerhaft',

    // IQ4 Text for event WARNING_MASK_EEPROM_PREV_SETTINGS_REST,
    'eeprom previous settings restored': 'EEPROM vorherige Einstellungen wiederhergestellt',

    // IQ4 Text for event WARNING_MASK_EEPROM_TOO_MANY_WRITES,
    'eeprom too many write cycles': 'EEPROM zu viele Schreibzyklen',

    // ../src/../src/core/helpers.js,
    'fieldbus': 'slot","Feldbussteckplatz',

    // IQ4 Text for event WARNING_MASK_GENERATOR_NOT_CONNECTED,
    'generator not connected': 'Generator nicht angeschlossen',

    // ../src/templates/password-reset.html,
    'has been reset': 'wurde zurückgesetzt',

    // IQ4 Text for event WARNING_TEMPERATURE_HIGH,IQ4 Text for event WARNING_MASK_HIGH_TEMP,IQ4 Text for event ALARM_HIGH_TEMPERATURE,
    'high temperature': 'Hohe Temperatur',

    // IQ4 Text for event WARNING_COMMUNICATION_LOST,
    'hmi communication lost': 'HMI-Kommunikation verloren',

    // ../src/templates/user.html,
    'id': 'ID',

    // ../src/templates/log-table.html,
    'inactive': 'inaktiv',

    // IQ4 Text for event SYS_WARNING_MASK_NEW_DEVICE_CONNECTED,IQ4 Text for event WARNING_NEW_DEVICE_CONNECTED,
    'new device connected': 'Neues Gerät verbunden',

    // IQ4 Text for event WARNING_MASK_NO_ACTIVE_SEGMENTS,IQ4 Text for event WARNING_LINKED_SENSOR_NO_ACTIVE_SEGMENTS,
    'no active sensor segments': 'keine aktiven Sensorsegmente',

    // IQ4 Text for event WARNING_MASK_NO_COMM_PAIRED_DEVICE,IQ4 Text for event WARNING_LINKED_SENSOR_NO_COMM_PAIRED_DEVICE,
    'no communication with paired device': 'keine Kommunikation mit gekoppeltem Gerät',

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_LOW,IQ4 Text for event ALARM_MASK_LEVEL_TOO_LOW,
    'output curent too low': 'Ausgangsstrom zu niedrig',

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_HIGH,IQ4 Text for event ALARM_MASK_LEVEL_TOO_HIGH,
    'output current too high': 'Ausgangsstrom zu hoch',

    // IQ4 Text for event WARNING_MASK_OVERLOAD_HIGH_VOLTAGE,IQ4 Text for event ALARM_MASK_OVERLOAD_HIGH_VOLTAGE,IQ4 Text for event WARNING_HVOUT_OVERLOAD,IQ4 Text for event ALARM_HVOUT_OVERLOAD,
    'overload high voltage': 'Hochspannung überlastet',

    // IQ4 Text for event WARNING_MASK_PAIRED_DEVICE_MISSING,IQ4 Text for event WARNING_LINKED_SENSOR_PAIRED_DEVICE_MISSING,
    'paired device missing': 'gekoppeltes Gerät fehlt',

    // IQ4 Text for event WARNING_MASK_SENSOR_MISSING,IQ4 Text for event WARNING_LINKED_SENSOR_SEGMENT_MISSING,
    'paired sensor missing': 'gekoppelter Sensor fehlt',

    // IQ4 Text for event WARNING_MASK_POWER_SUPPLY_LOW,
    'power supply low': 'Stromversorgung niedrig',

    // IQ4 Text for event ALARM_SENSOR_MALFUNCTION,
    'sensor malfunction': 'Fehlfunktion des Sensors',

    //     // IQ4 Text for event ALARM_SUPPLY_CURRENT_HIGH,IQ4 Text for event ALARM_MASK_HIGH_PRIM_CUR,IQ4 Text for event ALARM_PRIMARY_CURRENT_HIGH,
    'supply current too high': 'Versorgungsstrom zu hoch',

    // IQ4 Text for event WARNING_SUPPLY_VOLTAGE_LOW,IQ4 Text for event WARNING_INPUT_VOLTAGE_LOW,
    'supply voltage too low': 'Versorgungsspannung zu niedrig',

    //     // IQ4 Text for event ALARM_MASK_HIGH_TEMP,IQ4 Text for event ALARM_TEMPERATURE_HIGH,
    'too high temperature': 'zu hohe Temperatur',

    // ../src/templates/user.html,
    'userlevel': 'Benutzerlevel',

    // ../src/templates/user.html,
    'username': 'Benutzername',

    // IQ4 Text for event ALARM_WEB_VOLTAGE_OVERLOAD,
    'web voltage overload': 'Zu hohe Ladung',

    // IQ4 Text for event WARNING_WEB_VOLTAGE_HIGH_WARNING,IQ4 Text for event ALARM_WEB_VOLTAGE_HIGH_ERROR,
    'web voltage too high': 'Ladung zu hoch',

    // IQ4 Text for event WARNING_WEB_VOLTAGE_LOW_WARNING,IQ4 Text for event ALARM_WEB_VOLTAGE_LOW_ERROR,
    'web voltage too low': 'Ladung zu niedrig',

    // ====================================================
    // TODO: translate these
    // ====================================================
    // ../src/../src/core/state.js, 
    'Error saving the user preferences': 'Error saving the user preferences', 

    // ../src/../src/core/gui.js, 
    'Started': 'Started', 

    // ../src/../src/core/helpers.js, 
    'device': 'device', 

    // ../src/../src/core/helpers.js, 
    'CLFB': 'CLFB', 

    // ../src/../src/core/helpers.js, 
    'object': 'object', 

    // ../src/../src/debugger/log.js, 
    'Retrieving logs, please wait': 'Retrieving logs, please wait', 

    // ../src/../src/debugger/log.js, ../src/../src/debugger/log.js, 
    'Failed to download logs': 'Failed to download logs', 

    // ../src/../src/debugger/testrunner.js, 
    'Error: could not find test': 'Error: could not find test', 

    // ../src/../src/debugger/debugger.js, 
    'A test is already being recorded': 'A test is already being recorded', 

    // ../src/../src/debugger/debugger.js, src/templates/debug-test.html, 
    'Stop recording': 'Stop recording', 

    // ../src/../src/debugger/debugger.js, src/templates/debug-test.html, 
    'Record new test': 'Record new test', 

    // ../src/../src/debugger/debugger.js, 
    'Are you sure you want to delete this test?': 'Are you sure you want to delete this test?', 

    // ../src/../src/views/device-dashboard.js, 
    'Could not find Device': 'Could not find Device', 

    // ../src/../src/views/device.js, ../src/../src/views/table.js, 
    'Could not find device': 'Could not find device', 

    // ../src/../src/views/setting.js, 
    'Setting not found': 'Setting not found', 

    // ../src/../src/views/setting.js, ../src/../src/views/log.js, 
    'not found': 'not found', 

    // ../src/../src/views/setting.js, 
    'Failed to retrieve this CLFB\'s input device': 'Failed to retrieve this CLFB\'s input device', 

    // ../src/../src/views/action.js, 
    'Invalid action': 'Invalid action', 

    // ../src/../src/views/action.js, 
    'Before exporting the Fieldbus configuration you first have to enable it.': 'Before exporting the Fieldbus configuration you first have to enable it.', 

    // ../src/../src/views/action.js, 
    ' Please check the device help for more information.': ' Please check the device help for more information.', 

    // ../src/../src/views/action.js, 
    'Action not found': 'Action not found', 

    // ../src/../src/views/action.js, 
    ' could not be not found': ' could not be not found', 

    // ../src/../src/views/clfb.js, ../src/../src/views/clfb-io-chart.js, ../src/../src/views/clfb-io.js, 
    'Could not find CLFB': 'Could not find CLFB', 

    // ../src/../src/views/chart.js, ../src/../src/views/parameter.js, 
    'Parameter not found': 'Parameter not found', 

    // ../src/../src/views/chart.js, ../src/../src/views/parameter.js, 
    'Device not found': 'Device not found', 

    // ../src/../src/views/related.js, ../src/../src/views/related.js, 
    'You can not add a related object to a fieldbus while the fieldbus is running': 'You can not add a related object to a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'You can not remove a related object from a fieldbus while the fieldbus is running': 'You can not remove a related object from a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'You can not delete a related object from a fieldbus while the fieldbus is running': 'You can not delete a related object from a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'Can not delete unknown related object': 'Can not delete unknown related object', 

    // ../src/../src/views/parameter.js, 
    'Your number is less than the minimum value: ': 'Your number is less than the minimum value: ', 

    // ../src/../src/views/parameter.js, 
    'Your number is greater than the maximum value: ': 'Your number is greater than the maximum value: ', 

    // ../src/../src/views/fieldbus.js, 
    'Could not find fieldbus': 'Could not find fieldbus', 

    // ../src/../src/views/settings.js, 
    'Could not retrieve list of versions': 'Could not retrieve list of versions', 

    // ../src/../src/views/settings.js, 
    'Could not retrieve list of updates': 'Could not retrieve list of updates', 

    // ../src/../src/widgets/sensor-segments-chart.js, 
    'Can not find segments for this device': 'Can not find segments for this device', 

    // ../src/../src/widgets/sensor-segments-chart.js, 
    'Could not retrieve recent history for parameter': 'Could not retrieve recent history for parameter', 

    // ../src/templates/user-edit.html, src/templates/user-new.html, src/templates/settings-updates.html, src/templates/settings.html, 
    'Name': 'Name', 

    // ../src/templates/user-edit.html, src/templates/user-new.html, 
    'Level': 'Level', 

    // ../src/templates/debug-test.html, src/templates/debug-test.html, 
    'New test name': 'New test name', 

    // ../src/templates/debug-test.html, src/templates/debug-test.html, 
    'Test': 'Test', 

    // ../src/templates/debug-test.html, src/templates/debug-test.html, src/templates/debug.html, 
    'Log': 'Log', 

    // ../src/templates/debug-test.html, 
    'Rename': 'Rename', 

    // ../src/templates/debug-test.html, 
    'Run recorded test selection': 'Run recorded test selection', 

    // ../src/templates/debug-report.html, 
    'You can send a report to our central database. This will include a snapshot of the current state and the full log. Please add a little description so we know how to interpret this.': 'You can send a report to our central database. This will include a snapshot of the current state and the full log. Please add a little description so we know how to interpret this.', 

    // ../src/templates/parameter.html, src/templates/table.html, 
    'Plot realtime table': 'Plot realtime table', 

    // ../src/templates/parameter.html, src/templates/table.html, 
    'Plot 48h table': 'Plot 48h table', 

    // ../src/templates/parameter.html, src/templates/clfb-io.html, 
    'Record changes': 'Record changes', 

    // ../src/templates/clfb-io.html, 
    'CLFB I/O': 'CLFB I/O', 

    // ../src/templates/table.html, 
    'Realtime': 'Realtime', 

    // ../src/templates/user.html, 
    'User': 'User', 

    // ../src/templates/login.html, src/templates/password-reset.html, 
    'Username': 'Username', 

    // ../src/templates/macros.html, 
    'Unknown input': 'Unknown input', 

    // ../src/templates/macros.html, 
    'Unknown output': 'Unknown output', 

    // ../src/templates/settings-confirm-update.html, Action title for update, 
    'Update': 'Update', 

    // ../src/templates/active-users.html, 
    'Active user sessions': 'Active user sessions', 

    // ../src/templates/active-users.html, 
    'User name': 'User name', 

    // ../src/templates/active-users.html, 
    'User level': 'User level', 

    // ../src/templates/active-users.html, 
    'Go to dashboard': 'Go to dashboard', 

    // ../src/templates/log.html, 
    'Filter': 'Filter', 

    // ../src/templates/log.html, 
    'Code': 'Code', 

    // ../src/templates/debug.html, 
    'Tests': 'Tests', 

    // ../src/templates/customize-view.html, 
    'Reorder': 'Reorder', 

    // ../src/templates/customize-view.html, 
    'You can choose one of the pre-defined orders, or you can sort manually by dragging and dropping after selecting "sort manually".': 'You can choose one of the pre-defined orders, or you can sort manually by dragging and dropping after selecting "sort manually".', 

    // ../src/templates/settings-updates.html, src/templates/settings.html, 
    'UID': 'UID', 

    // ../src/templates/settings-updates.html, src/templates/settings.html, 
    'Position': 'Position', 

    // IQ4 Text for parameter CM_TINY_EEPROM_WRITE_COUNTS, IQ4 Text for parameter MPM_EEPROM_WRITE_COUNTS, 
    'Eeprom write counts': 'Eeprom write counts', 

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_LIMIT_SETPOINT, IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_LIMIT_SETPOINT, 
    'Voltage Control output current limit': 'Voltage Control output current limit', 

    // IQ4 Text for parameter CM_TINY_SETPOINT_VOLTAGE_CLFB, IQ4 Text for parameter IQ_COM_SETPOINT_VOLTAGE_CLFB, IQ4 Text for parameter CMME_SETPOINT_VOLTAGE_CLFB, 
    'Setpoint voltage balance for CLFB': 'Setpoint voltage balance for CLFB', 

    // IQ4 Text for parameter MPM_OPERATION_MODE, 
    'Operation mode (dipswitch 3)': 'Operation mode (dipswitch 3)', 

    // IQ4 Text for parameter MPM_REMOTE_ON_OFF_SOURCE, 
    'Remote on/off source (dipswitch 1)': 'Remote on/off source (dipswitch 1)', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT, 
    'Analog input (dipswitch 2)': 'Analog input (dipswitch 2)', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT_FUNCTION, 
    'Analog input function': 'Analog input function', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT_FEEDBACK, 
    'Analog input': 'Analog input', 

    // IQ4 Text for parameter MPM_CALIBRATE_ANALOG_INPUT_OFFSET, 
    'Calibrate analog input offset': 'Calibrate analog input offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_ANALOG_INPUT_GAIN, 
    'Calibrate analog input gain': 'Calibrate analog input gain', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_FEEDBACK, 
    'High voltage output': 'High voltage output', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_FEEDBACK_OFFSET, 
    'Calibrate output voltage feedback offset': 'Calibrate output voltage feedback offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_FEEDBACK_GAIN, 
    'Calibrate output voltage feedback gain': 'Calibrate output voltage feedback gain', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_FEEDBACK_OFFSET, 
    'Calibrate output current offset': 'Calibrate output current offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_FEEDBACK_GAIN, 
    'Calibrate output current gain': 'Calibrate output current gain', 

    // IQ4 Text for parameter MPM_MAX_QUANTITY_ARCS, 
    'Max. arcs per minute': 'Max. arcs per minute', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_SETPOINT_MAX, 
    'Max. output voltage': 'Max. output voltage', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_SETPOINT, 
    'Output voltage setpoint': 'Output voltage setpoint', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_SETPOINT_OFFSET, 
    'Calibrate output voltage setpoint offset': 'Calibrate output voltage setpoint offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_SETPOINT_GAIN, 
    'Calibrate output voltage setpoint gain': 'Calibrate output voltage setpoint gain', 

    // IQ4 Text for parameter MPM_CURRENT_LIMIT_SETPOINT, 
    'Max. output current': 'Max. output current', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_SETPOINT_OFFSET, 
    'Calibrate output current setpoint offset': 'Calibrate output current setpoint offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_SETPOINT_GAIN, 
    'Calibrate output current setpoint gain': 'Calibrate output current setpoint gain', 

    // IQ4 Text for parameter MPM_BALANCE_OFFSET, 
    'Balance offset': 'Balance offset', 

    // IQ4 Text for parameter GATEWAY_IQ4_WEB_DISTANCE_SEG1, 
    'Web distance segment 1': 'Web distance segment 1', 

    // IQ4 Text for parameter MPM_UNIQUE_DEVICE_ID, 
    'Unique device id': 'Unique device id', 

    // IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE_MIN, 
    'Power supply warning level min.': 'Power supply warning level min.', 

    // IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE_MAX, 
    'Power supply warning level max.': 'Power supply warning level max.', 

    // IQ4 Text for parameter MPM_AUTORUN, 
    'Autorun': 'Autorun', 

    // IQ4 Text for parameter MPM_FREE_RAM_CNTR, 
    'Free RAM counter': 'Free RAM counter', 

    // IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_MAX, 
    'Output voltage max': 'Output voltage max', 

    // IQ4 Text for setting CLFB_PROPORTIONALGAIN, 
    'Proportional Gain': 'Proportional Gain', 

    // IQ4 Text for setting CLFB_DERIVATIVEGAIN, 
    'Derivative Gain': 'Derivative Gain', 

    // IQ4 Text for setting CLFB_INTEGRALGAIN, 
    'Integral Gain': 'Integral Gain', 

    // IQ4 Text for setting CLFB_INTEGRALMIN, 
    'Integral Minimum': 'Integral Minimum', 

    // IQ4 Text for setting CLFB_INTEGRALDEADBAND, 
    'Integral Deadband': 'Integral Deadband', 

    // IQ4 Text for setting CLFB_INTEGRALMAX, 
    'Integral Maximum': 'Integral Maximum', 

    // IQ4 Text for setting CLFB_ACTIVESEGMENTS, 
    'Active Segments': 'Active Segments', 

    // Action title for standby, 
    'Standby': 'Standby', 

    // Action title for save-config, 
    'Save configuration': 'Save configuration', 

    // IQ4 Text for event WARNING_NO_SEGMENTS_SELECTED, 
    'No sensor segments selected': 'No sensor segments selected', 

    // IQ4 Text for event DEVICE_DISCONNECTED, 
    'Device disconnected': 'Device disconnected', 

    // IQ4 Text for event DEVICE_DISABLED, 
    'Device disabled': 'Device disabled', 

    // IQ4 Text for event ALARM_INPUT_VOLTAGE_HIGH, 
    'supply voltage too high': 'supply voltage too high', 

    // IQ4 Text for event ALARM_AIR_PRESSURE_HIGH, 
    'air pressure too high': 'air pressure too high', 

};

export function getTranslations() {
    return translations;
}