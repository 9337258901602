/**
 * Time view module.
 * @module time
 */

"use strict";

import * as Constants from '../core/constants';
import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Messages from '../core/messages';
import * as Permissions from '../core/permissions';
import * as Session from '../core/session';
import * as State from '../core/state';
import {View} from '../core/view';

export class TimeView extends View {
    constructor() {
        super();
        this.viewName = 'LoginView';
        this.dateTimePicker = null;
    }

    destructor() {
        super.destructor();

        if(this.dateTimePicker) {
            this.dateTimePicker.dispose();
        }
    }

    setTime(actionId, args) {
        const offset = '0000';

        const newDate = Helpers.optChain(this.dateTimePicker, 'dates', '_dates', 0);
        if(!newDate) {
            return Messages.addError(133, _('Error changing the system time'));
        }

        const timezoneOffsetMillis = new Date().getTimezoneOffset() * 60 * 1000;
        const newTime = newDate.getTime() - timezoneOffsetMillis;

        this.Api.setSystemTime(newTime).then(() => {
            Messages.addMessage(_('The system time was changed successfully'), 'success', 3000);
        }).catch((e) => {
            Messages.addError(132, _('Error changing the system time'));
        });
    }

    setLocalSettings(actionId, args) {
        State.setTimeZoneOffset(args.timezone);
        State.setUse24hours(args['ampm-24'] === '24h');
        Messages.addMessage(_('The local time settings were changed successfully'), 'success', 3000);
        $('p.pretty-timezone').html(Constants.TIME_ZONES[args.timezone]);
    }

    updateTime(msg) {
        $('time.system-time-utc').html(State.getPrettyTime(false));
    }

    initialize() {
        const canSetTime = Permissions.hasGlobalExecutePermission('set-system-time');
        this.addListener('internal/minute-passed', this.updateTime);

        const timezoneOffsetMillis = new Date().getTimezoneOffset() * 60 * 1000;
        const date = new Date(State.getTime() + timezoneOffsetMillis);

        if(canSetTime) {
            this.registerActionHandler(
               'set-time', 'set-time', this.setTime.bind(this)
            );
        }

        this.registerActionHandler(
           'set-local-time-settings', 'set-local-time-settings', this.setLocalSettings.bind(this)
        );

        const timeValue = (
            Helpers.padZero(date.getUTCHours().toString(), 2) +
            ':' +
            Helpers.padZero(date.getUTCMinutes().toString(), 2)
        );

        const dateValue = (
            date.getUTCFullYear() +
            '-' +
            Helpers.padZero((date.getUTCMonth() + 1).toString(), 2) +
            '-' +
            Helpers.padZero(date.getUTCDate().toString(), 2)
        );

        Gui.render('time.html', {
            'canSetTime': canSetTime,
            'currentTime': State.getPrettyTime(),
            'currentUtcTime': State.getPrettyTime(false),
            'timeValue': timeValue,
            'dateValue': dateValue,
            'currentTimezone': State.getTimeZoneOffset(),
            'use24hours': State.get24HoursIsPreferred(),
            'TIME_ZONES': Constants.TIME_ZONES,
        });

        let defaultDateFormat = 'yyyy/MM/dd h:mm T';

        let dateFormats = {
            LTS: 'h:mm:ss T',
            LT: 'h:mm T',
            L: 'MM/dd/yyyy',
            LL: 'MMMM d, yyyy',
            LLL: 'MMMM d, yyyy h:mm T',
            LLLL: 'dddd, MMMM d, yyyy h:mm T'
        };

        if(State.get24HoursIsPreferred()) {
            defaultDateFormat = 'yyyy/MM/dd H:mm';
            dateFormats = {
                LTS: 'H:mm:ss',
                LT: 'H:mm',
                L: 'MM/dd/yyyy',
                LL: 'MMMM d, yyyy',
                LLL: 'MMMM d, yyyy H:mm',
                LLLL: 'dddd, MMMM d, yyyy H:mm'
            };
        }

        // Setup Tempus Dominus
        this.dateTimePicker = new window.tempusDominus.TempusDominus(
            document.getElementById('datetimepicker'),
            {
                allowInputToggle: false,
                container: undefined,
                dateRange: false,
                debug: false,
                defaultDate: date,
                display: {
                    icons: {
                        type: 'icons',
                        time: 'icon-clock',
                        date: 'icon-calendar',
                        up: 'icon-up-open',
                        down: 'icon-down-open',
                        previous: 'icon-left-open',
                        next: 'icon-right-open',
                        today: 'icon-calendar-check-o',
                        clear: 'icon-trash-empty',
                        close: 'icon-cancel-1'
                    },
                    sideBySide: false,
                    calendarWeeks: false,
                    viewMode: 'clock',
                    toolbarPlacement: 'bottom',
                    keepOpen: false,
                    buttons: {
                        today: false,
                        clear: false,
                        close: true,
                    },
                    components: {
                        calendar: true,
                        date: true,
                        month: true,
                        year: true,
                        decades: false,
                        clock: true,
                        hours: true,
                        minutes: true,
                        seconds: false,
                    },
                    inline: false,
                    theme: 'auto'
                },
                keepInvalid: false,
                localization: {
                    clear: _('Clear selection'),
                    close: _('Close'),
                    dateFormats: dateFormats,
                    dayViewHeaderFormat: { month: 'long', year: '2-digit' },
                    decrementHour: _('Decrement Hour'),
                    decrementMinute: _('Decrement Minute'),
                    decrementSecond: _('Decrement Second'),
                    format: defaultDateFormat,
                    hourCycle: (
                        State.get24HoursIsPreferred() ? 'h23' : 'h12'
                    ),
                    incrementHour: _('Increment Hour'),
                    incrementMinute: _('Increment Minute'),
                    incrementSecond: _('Increment Second'),
                    // locale: DefaultFormatLocalization.locale,
                    nextCentury: _('Next Century'),
                    nextDecade: _('Next Decade'),
                    nextMonth: _('Next Month'),
                    nextYear: _('Next Year'),
                    // ordinal: DefaultFormatLocalization.ordinal,
                    pickHour: _('Pick Hour'),
                    pickMinute: _('Pick Minute'),
                    pickSecond: _('Pick Second'),
                    previousCentury: _('Previous Century'),
                    previousDecade: _('Previous Decade'),
                    previousMonth: _('Previous Month'),
                    previousYear: _('Previous Year'),
                    selectDate: _('Select Date'),
                    selectDecade: _('Select Decade'),
                    selectMonth: _('Select Month'),
                    selectTime: _('Select Time'),
                    selectYear: _('Select Year'),
                    startOfTheWeek: 0,
                    today: _('Go to today'),
                    toggleMeridiem: _('Toggle Meridiem')
                },
                meta: {},
                multipleDates: false,
                multipleDatesSeparator: '; ',
                promptTimeOnDateChange: false,
                promptTimeOnDateChangeTransitionDelay: 200,
                restrictions: {
                    minDate: undefined,
                    maxDate: undefined,
                    disabledDates: [],
                    enabledDates: [],
                    daysOfWeekDisabled: [],
                    disabledTimeIntervals: [],
                    disabledHours: [],
                    enabledHours: []
                },
                stepping: 1,
                useCurrent: false,
                // viewDate: new DateTime()
            });
    }

    /**
     * Render this time view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args) {
        // If we don't know the system time yet, we wait until we do
        let currentTime = State.getTime();
        if(currentTime === -1 || currentTime === null) {
            Gui.render('loading.html');
            const intervalId = window.setInterval(() => {
                currentTime = State.getTime();
                if(currentTime !== -1 && currentTime !== null) {
                    clearInterval(intervalId);
                    this.initialize();
                }
            }, 100);
        } else {
            this.initialize();
        }
    }
}
