/**
 * Debugger module
 * @module debugger
 */

"use strict";

import * as Debug from '../core/debug';
import * as DebugLog from './log';
import * as DebugReport from './report';
import * as DebugState from './state';
import * as DebugTestRunner from './testrunner';
import * as DebugView from './view';
import * as Record from './record';
import * as State from '../core/state';
import * as Helpers from '../core/helpers';
import * as Session from '../core/session';

let debugScreenVisible = false;
let initialized = false;
let currentScreen = '';
let state = {};

window.recordEvent = () => {};

function twoFunc(func1, func2) {
    return (...args) => {
        func1(...args);
        func2(...args);
    };
}

/**
 * Initialize the debug system (if debugging is enabled)
 */
export function init() {
    // The log handler is always initialized, regardless of whether debugging
    // is enabled. This allows us to always send log messages to the manager.
    // Whether they're also collected in the debug screen and/or printed to the
    // console does depend on the global debug flag however.
    Debug.setLogHandler(log);
    DebugLog.initLogFlusher();

    if(initialized || !window.DEBUG){
        return;
    }

    // Add debug css
    const head = document.getElementsByTagName('HEAD')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = '/css/debug.css';
    head.appendChild(link);

    $('a.toggle-debug').show();

    // Setup handlers
    $(document).on('click', 'a.toggle-debug', (event) => {
        event.preventDefault();
        event.stopPropagation();
        toggleDebugOverlay();
    });

    $(document).on('click', 'a.debug-action', debugAction);
    $(document).on('click', 'input.toggle-all-tests, input.toggle-all-recorded-tests', (event) => {
        let checked = $(event.currentTarget).prop('checked');
        const table = $(event.currentTarget).closest('table');

        const bundleCount = $('tr', table).length;
        const selectedCount = $('input.select-test:checked', table).length;

        if(!checked) {
            $('input.select-test', table).prop('checked', false);
        } else {
            $('input.select-test', table).prop('checked', true);
        }
    });
    $(document).on('click', 'input.save-data-logs', DebugLog.downloadLogs('data'));
    $(document).on('click', 'input.save-system-logs', DebugLog.downloadLogs('system'));
    $(document).on('change', 'input.console-log-enabled', DebugLog.toggleConsoleLog);
    $(document).on('change', 'input.full-log-enabled', DebugLog.toggleFullLog);

    Debug.setUpdateStateHandler(updateState);
    Debug.setEventRecorder(twoFunc(Record.recordEvent, DebugTestRunner.recordEvent));
    Debug.setPlayingBack(DebugTestRunner.isPlaying);
    Debug.setFlagError(DebugTestRunner.flagError);

    DebugView.init();

    if(Session.usingTouchScreen()) {
        $('a[data-debug=show-report').hide();
    }

    window.submitReport = submitReport;
    window.runTest = runTest;
    window.showTestLog = DebugTestRunner.showTestLog;
    window.runTestSelection = runTestSelection;
    window.runRecordedTestSelection = runRecordedTestSelection;
    window.runRecordedTest = runRecordedTest;
    window.recordTest = recordTest;
    window.stopTestRecording = stopTestRecording;
    window.deleteTest = deleteTest;
    window.renameTest = renameTest;
    window.confirmTestRename = confirmTestRename;
    window.cancelTestRename = cancelTestRename;
    initialized = true;
}


/**
 * Remove the debug system
 */
export function remove() {
    if(!initialized) {
        return;
    }

    $(document).off('click', 'a.toggle-debug');
    $(document).off('click', 'a.debug-action');
    $(document).off('click', 'input.toggle-all-tests');
    $(document).off('click', 'input.save-event-logs');
    $(document).off('click', 'input.save-parameters-history');
    $(document).off('click', 'input.console-log-enabled');
    $(document).off('click', 'input.save-data-logs');
    $(document).off('click', 'input.save-system-logs');

    Debug.setUpdateStateHandler(()=>{});
    $('section.debug').remove();
    $('a.toggle-debug').hide();
    window.submitReport = ()=>{};
    window.runTest = ()=>{};
    window.showTestLog = ()=>{};
    window.runTestSelection = ()=>{};
    window.runRecordedTestSelection = ()=>{};
    window.runRecordedTest = ()=>{};
    window.renameTest = ()=>{};
    window.confirmTestRename = ()=>{};
    window.cancelTestRename = ()=>{};
    window.stopTestRecording = ()=>{};
    $('link[rel=stylesheet][href="/css/debug.css"]').remove();
    initialized = false;
}

export function updateState(s) {
    state = s;
    if(currentScreen === 'state') {
        DebugState.printState(state);
    }
}

function debugAction(event) {
    event.preventDefault();
    event.stopPropagation();
    $('a.debug-action').removeClass('active');
    $(event.currentTarget).addClass('active');
    const action = $(event.currentTarget).attr('data-debug');

    DebugView.closeCurrentScreen();
    DebugLog.setInactive();

    if(action === 'show-log') {
        currentScreen = 'log';
        DebugView.showLog();
        DebugLog.printLog();
        DebugLog.setActive();
    } else if(action === 'show-state') {
        currentScreen = 'state';
        DebugView.showState();
        DebugState.printState(state);
    } else if(action === 'show-report') {
        currentScreen = 'report';
        DebugView.showReport();
    } else if(action === 'show-test') {
        currentScreen = 'test';
        DebugView.showTest();
        DebugTestRunner.initTests();
    } else if(action === 'restart') {
        if(confirm('Do you want to restart the HMI?')) {
            window.localStorage.clear();
            window.sessionStorage.clear();
            window.location.assign('/#/login');
            window.location.reload();
        }
    }
}

/**
 * Show a log message and add to log history if debugging is enabled
 * @param {any} msg - the log message
 * @param {boolean} record - whether to record this message in the debug log
 * @param {number} type - this log's type, used for filtering debug messages
 *    0 - Debug message
 *    1 - Info message
 *    2 - Warning message
 *    3 - Error message
 */
export function log(msg, record=true, type=0) {
    if(!window.DEBUG) {
        return;
    }
    DebugLog.log(msg, record, type);
}

export function submitReport() {
    DebugReport.submitReport();
}

export function runTest(bundleName) {
    $('div.test-log').hide();

    $('button.run-test').prop('disabled', true);
    $('button.run-test-selection').prop('disabled', true);
    $('tr[data-bundle="' + bundleName + '"] button.run-test').addClass('running').html('Running');

    DebugTestRunner.runTest(bundleName, () => {
        // After the test has run we re-enable all the test buttons
        $('button.run-test').prop('disabled', false);
        $('button.run-test-selection').prop('disabled', false);
        $('tr[data-bundle="' + bundleName + '"] button.run-test').removeClass('running').html('Run');
    });
}

export function showTestLog(bundleName) {
    DebugTestRunner.showTestLog(bundleName);
}

export function runTestSelection() {
    let selectedTests = [];
    $('table.tests td.test-status').removeClass('failed').removeClass('success').html('-');
    $('table.tests input.select-test:checked').each((index, element) => {
        selectedTests.push($(element).attr('data-bundle'));
    });
    if(selectedTests.length === 0) {
        return alert('Please select tests to run');
    }

    $('button.run-test').prop('disabled', true);
    $('button.run-test-selection').prop('disabled', true);
    $('button.run-test-selection.builtin-tests').addClass('running').html('Running tests');

    DebugTestRunner.runTestSelection(selectedTests, (done) => {
        $('button.run-test').prop('disabled', false);
        $('button.run-test-selection').prop('disabled', false);
        $('button.run-test-selection.builtin-tests').removeClass('running').html('Run test selection');

        if(done) {
            if($('input.loop-tests').prop('checked')) {
                $('td.test-status').removeClass('failed').removeClass('success').html('-');
                return $('button.run-test-selection').trigger('click');
            }
        }
    });
}

export function runRecordedTestSelection() {
    let selectedTests = [];
    $('table.recorded-tests td.test-status').removeClass('failed').removeClass('success').html('-');
    $('table.recorded-tests input.select-test:checked').each((index, element) => {
        selectedTests.push($(element).attr('data-test-id'));
    });
    if(selectedTests.length === 0) {
        return alert('Please select tests to run');
    }

    $('button.run-test').prop('disabled', true);
    $('button.run-test-selection').prop('disabled', true);
    $('button.run-test-selection.recorded-tests').addClass('running').html('Running tests');

    DebugTestRunner.runRecordedTestSelection(selectedTests, (success) => {
        $('button.run-test').prop('disabled', false);
        $('button.run-test-selection').prop('disabled', false);
        $('button.run-test-selection.recorded-tests').addClass('running').html('Run recorded test selection');

        // DebugReport.submitAutomaticReport(JSON.stringify(DebugTestRunner.getTestLogs()));
        if(success) {
            if($('input.loop-recorded-tests').prop('checked')) {
                $('td.test-status').removeClass('failed').removeClass('success').html('-');
                return $('button.run-test-selection.recorded-tests').trigger('click');
            }
        }
    });
}

/**
 * Show or hide the debug overlay overlay if debugging is enabled
 */
export function toggleDebugOverlay() {
    if(!window.DEBUG) {
        return;
    }
    DebugView.toggleDebugOverlay();
}

function runRecordedTest(testId) {
    DebugTestRunner.runRecordedTest(testId);
}

function renameTest(testId) {
    const nameElem = $('tr[data-test-id=' + testId + '] td.name');
    const oldValue = nameElem.text();
    nameElem.attr('data-old', oldValue);
    nameElem.html(
        '<input type="text" name="new-name" value="' + Helpers.replaceAll(oldValue, '"', '&quot;') + '" />' +
        '<input type="button" onClick="confirmTestRename(\'' + testId + '\')" value="Change" />' +
        '<input type="button" onClick="cancelTestRename(\'' + testId + '\')" value="Cancel" />'
    );
}

function confirmTestRename(testId) {
    const nameElem = $('tr[data-test-id=' + testId + '] td.name');
    nameElem.attr('data-old', '');
    const newName = $('input[name=new-name]', nameElem).val();
    nameElem.html(newName.toString());

    let tests = State.getRecordedTests();
    for(let test of tests) {
        if(test.id === testId) {
            test.name = newName;
        }
    }
    State.setRecordedTests(tests);
}

function cancelTestRename(testId) {
    const nameElem = $('tr[data-test-id=' + testId + '] td.name');
    const oldName = nameElem.attr('data-old');
    nameElem.html(oldName);
}

function recordTest() {
    if(Record.isRecording()) {
        return alert(_('A test is already being recorded'));
    }

    let nameElem = $('input[name=record-test-name]');
    nameElem.prop('disabled', true);
    const name = nameElem.val();

    const button = $('a.record-test');
    button
        .removeClass('btn-record')
        .addClass('btn-recording')
        .html(_('Stop recording'))
        .attr('onclick', 'stopTestRecording();');

    Record.startRecording(name);
}

function stopTestRecording() {
    if(!Record.isRecording()) {
        return;
    }

    Record.stopRecording();
    const button = $('a.record-test');

    let nameElem = $('input[name=record-test-name]');
    nameElem.prop('disabled', false);
    nameElem.val('');

    button
        .removeClass('btn-recording')
        .addClass('btn-record')
        .html(_('Record new test'))
        .attr('onclick', 'recordTest();');

    $('a.debug-action[data-debug=show-test]').trigger('click');
}

function deleteTest(testId) {
    if(!confirm(_('Are you sure you want to delete this test?'))) {
        return;
    }

    State.setRecordedTests(
        State.getRecordedTests().filter(x => x.id !== testId)
    );

    $('a.debug-action[data-debug=show-test]').trigger('click');
}
