/**
 * Debug Test Runner Module
 * @module DebugTestBundles
 */
"use strict";

import * as Helpers from '../core/helpers';
import * as State from '../core/state';
import * as Api from '../core/api';
import * as Debug from '../core/debug';
import * as Signals from '../core/signals';
import {TestSequence} from './testsequence';
import {Expect} from './expect';
import {Wait, WaitUnless} from './wait';

// This object is used to carry test state information across sequence parts
window.testState = {};

function getDevice(info) {
    for(const device of State.getDevices()) {
        if(device.info === info) {
            return device;
        }
    }
    return false;
}

/**
 * Get a list of generic device sequences that can be used for all devices.
 * These include actions like looking them up, visiting their page and turning
 * them on and off.
 * @param {string} deviceInfo - the device "info" name, as known by the system
 * @returns {array} the sequence list
 */
function genericDeviceSequence(deviceInfo) {
    return [
        new TestSequence(
            'Check if ' + deviceInfo + ' is available', [
                new Expect(() => {
                    return getDevice(deviceInfo) !== false;
                }),
            ]
        ),
        new TestSequence(
            'Go to ' + deviceInfo + ' screen', [
                new Expect(() => {
                    const device = getDevice(deviceInfo);
                    if(device === false) {
                        return false;
                    }
                    window.visit('/#/device/' + device.uid);
                }),
                new Expect(() => {
                    const device = getDevice(deviceInfo);
                    return window.location.hash === '#/device/' + device.uid;
                }),
            ]
        ),

        new TestSequence(
            'Switch ' + deviceInfo + ' to run/standby and check status', [
                () => {
                    const device = getDevice(deviceInfo);
                    const initialStatus = device.status.mode;
                    window.testState.initialStatus = initialStatus;
                    $('a.run-standby').trigger('click');
                },
                new Wait(5000),
                new Expect(() => {
                    const initialStatus = window.testState.initialStatus;
                    const device = getDevice(deviceInfo);
                    if(initialStatus !== 'running') {
                        return device.status.mode === 'running';
                    } else {
                        return device.status.mode === 'standby';
                    }
                }),
            ]
        ),

        new TestSequence(
            'Switch ' + deviceInfo + ' to run/standby and check status (second time)', [
                () => {
                    const device = getDevice(deviceInfo);
                    const initialStatus = device.status.mode;
                    window.testState.initialStatus = initialStatus;
                    $('a.run-standby').trigger('click');
                },
                new Wait(5000),
                new Expect(() => {
                    const initialStatus = window.testState.initialStatus;
                    const device = getDevice(deviceInfo);
                    if(initialStatus !== 'running') {
                        return device.status.mode === 'running';
                    } else {
                        return device.status.mode === 'standby';
                    }
                }),
            ]
        ),
    ];
}

export function getTestBundles() {
    return {
        // ---------------------
        // --- HMI Internals ---
        // ---------------------

        'HMI Internals': [
            new TestSequence(
                'Helpers.checkType', [
                    new Expect(() => {
                        return Helpers.checkType('string', 'hello');
                    }),
                    new Expect(() => {
                    return !Helpers.checkType('string', 10);
                    }),
                    new Expect(() => {
                    return Helpers.checkType('number', 10);
                    }),
                    new Expect(() => {
                        return !Helpers.checkType('number', '10');
                    }),
                    new Expect(() => {
                        return Helpers.checkType('boolean', true);
                    }),
                    new Expect(() => {
                        return !Helpers.checkType('unknown');
                    }),
                ]
            ),

            new TestSequence(
                'Helpers.isDefined', [
                    new Expect(() => {
                        return !Helpers.isDefined(undefined);
                    }),
                    new Expect(() => {
                        return Helpers.isDefined({});
                    })
                ]
            ),

            new TestSequence(
                'Helpers.checkType', [
                    new Expect(() => {
                        return Helpers.checkType('string', 'hello');
                    }),
                    new Expect(() => {
                    return !Helpers.checkType('string', 10);
                    }),
                ]
            ),

            new TestSequence(
                'jQuery is working', [
                    new Expect(() => {
                        return typeof $ === 'function';
                    })
                ]
            ),

            new TestSequence(
                'User level comparisons', [
                    new Expect(() => {return Helpers.equalOrLowerUserLevel('basic', 'advanced');}),
                    new Expect(() => {return Helpers.equalOrLowerUserLevel('advanced', 'expert');}),
                    new Expect(() => {return Helpers.equalOrLowerUserLevel('expert', 'admin');}),
                    new Expect(() => {return Helpers.equalOrLowerUserLevel('service', 'factory');}),
                    new Expect(() => {return Helpers.equalOrLowerUserLevel('factory', 'engineering');}),
                    new Expect(() => {return !Helpers.equalOrLowerUserLevel('advanced', 'basic');}),
                ]
            ),

            new TestSequence(
                'Helpers.definedNotNull', [
                    new Expect(() => {
                        return Helpers.definedNotNull(undefined) === false;
                    }),
                    new Expect(() => {
                        return Helpers.definedNotNull(null) === false;
                    }),
                    new Expect(() => {
                        return Helpers.definedNotNull(1) === true;
                    }),
                ]
            ),

            new TestSequence(
                'Helpers.minimum', [
                    new Expect(() => {
                        return Helpers.minimum(1, 10) === 1;
                    }),
                    new Expect(() => {
                        return Helpers.minimum(10, 1) === 1;
                    }),
                ]
            ),

            new TestSequence(
                'Helpers.clone', [
                    new Expect(() => {
                        let obj1 = {value: 1};
                        let obj2 = Helpers.clone(obj1);
                        obj2.value = 2;
                        return obj2.value === 2 && obj1.value === 1;
                    })
                ]
            ),

            new TestSequence(
                'Device roles',[
                    new Expect(() => {
                        let roleLessDevice = {};
                        return Helpers.deviceHasRole(roleLessDevice, 'clfb-input') === false;
                    }),
                    new Expect(() => {
                        let deviceWithRole = {
                            'roles': [
                                {
                                    'id': 0,
                                    'name': 'clfb-input',
                                },
                            ]
                        };
                        return (
                            Helpers.deviceHasRole(deviceWithRole, 'clfb-input') === true &&
                            Helpers.deviceHasRole(deviceWithRole, 'clfb-output') === false
                        );
                    })
                ]
            ),
        ],


        // ----------------------
        // --- Login system   ---
        // ----------------------
        'Login system': [
            new TestSequence(
                'Logout and fail logging in', [
                    () => {
                        // Make sure we are logged out to start with
                        window.visit('/#/logout');
                    },
                    () => {
                        window.visit('/#/login');
                    },
                    () => {
                        $('input#username').val('WILL_FAIL_ON_PURPOSE');
                        $('input#password').val('NO_PASS');
                        $('input[type=submit].btn-confirm').trigger('click');
                    },
                    new Expect(() => {
                        return window.location.hash === '#/login';
                    }),
                ]
            ),
            new TestSequence(
                'Pass login', [
                    () => {
                        window.visit('/#/login');
                    },
                    () => {
                        $('input#username').val('Admin');
                        $('input#password').val('');
                        $('input[type=submit].btn-confirm').trigger('click');
                    },
                    new Expect(() => {
                        return window.location.hash !== '#/login';
                    }),
                ]
            ),
        ],


        // -------------------
        // --- Device list ---
        // -------------------
        'Device list': [
            new TestSequence(
                'Show device overview', [
                    () => {
                        window.visit('/#/');
                    },
                    new Expect(() => {
                        return window.location.hash === '#/';
                    }),
                    new Expect(() => {
                        return $('a.device').length === State.getDevices().length + State.getFieldbuses().length;
                    }),
                ]
            ),
            new TestSequence(
                'Check if devices have right names', [
                    () => {
                        window.visit('/#/');
                    },
                    new Expect(() => {
                        return window.location.hash === '#/';
                    }),
                    new Expect(() => {
                        let devices = State.getDevices();
                        let incorrect = 0;
                        for(const device of devices) {
                            let dev = $('a.device[data-uid=' + device.uid + ']');
                            if(dev.html().indexOf(device.name) === -1) {
                                incorrect++;
                            }
                        }
                        return incorrect === 0;
                    }),
                ]
            ),
            new TestSequence(
                'Check if devices have right modi', [
                    new Expect(() => {
                        let devices = State.getDevices();
                        let incorrect = 0;
                        let messages = '';
                        for(const device of devices){
                            const domObj = $('a.device[data-uid=' + device.uid + ']');
                            if(device.status.mode === 'running' && $('span.circle.running', domObj).length === 0) {
                                incorrect++;
                                messages += 'Device ' + device.name + '(' + device.uid + ') should have the mode "running" but isn\'t displayed as such\n';
                            }
                            if(device.status.mode === 'disconnected' && $('span.circle.disconnected', domObj).length === 0) {
                                messages += 'Device ' + device.name + '(' + device.uid + ') should have the mode "disconnected" but isn\'t displayed as such\n';
                                incorrect++;
                            }
                            if(device.status.mode === 'disabled' && $('span.circle.disabled', domObj).length === 0) {
                                messages += 'Device ' + device.name + '(' + device.uid + ') should have the mode "disabled" but isn\'t displayed as such\n';
                                incorrect++;
                            }
                            if(device.status.mode === 'standby' && $('span.circle.standby', domObj).length === 0) {
                                messages += 'Device ' + device.name + '(' + device.uid + ') should have the mode "standby" but isn\'t displayed as such\n';
                                incorrect++;
                            }
                        }
                        return [incorrect === 0, messages];
                    }),
                ]
            ),
        ],

        // ---------------------
        // --- Fieldbus view ---
        // ---------------------
        'Fieldbus view': [
            new TestSequence(
                'There is at least one fieldbus connected', [
                    new Expect(() => {
                        return State.getFieldbuses().length > 0;
                    })
                ]
            ),
            new TestSequence(
                'Fieldbuses show up in the overview', [
                    new Expect(() => {
                        window.visit('/#/');
                        let fieldbuses = State.getFieldbuses();
                        let incorrect = 0;
                        let messages = '';
                        for(const fieldbus of fieldbuses) {
                            if($('a.device[data-uid=' + fieldbus.uid + ']').length === 0) {
                                incorrect += 1;
                                messages += 'Missing fieldbus (' + fieldbus.uid + ') from overview\n';
                            }
                        }
                        return [incorrect === 0, messages];
                    }),
                ]
            ),
            new TestSequence(
                'Single fieldbus view works', [
                    () => {
                        window.visit('/#/');
                        const fieldbus = State.getFieldbuses()[0];
                        console.log(fieldbus);
                        window.visit('/#/fieldbus/' + fieldbus.uid);
                    },
                    new Expect(() => {
                        const fieldbus = State.getFieldbuses()[0];
                        return window.location.hash === '#/fieldbus/' + fieldbus.uid;
                    })
                ]
            ),
        ],

        // ---------------
        // --- CM Tiny ---
        // ---------------
        'CM Tiny IQ': genericDeviceSequence('CM Tiny IQ'),

        // ---------------------
        // --- Easion IQ 4.0 ---
        // ---------------------
        'EasION IQ 4.0': genericDeviceSequence('EasION IQ 4.0'),

        // ----------------------
        // --- Sensor IQ Easy ---
        // ----------------------
        'Sensor IQ Easy': genericDeviceSequence('Sensor IQ Easy'),

        // -------------------------
        // --- Performax IQ Easy ---
        // -------------------------
        'Performax IQ Easy': genericDeviceSequence('Performax IQ Easy'),

        // -----------------------
        // --- ThunderIon 2    ---
        // -----------------------
        // TODO: This one is called "ThunderIon 2" (without the IQ) on the manager
        'ThunderIon 2 IQ': genericDeviceSequence('ThunderIon 2 IQ'),

        'SLC': [new TestSequence(
                'Update SLC\'s', [
                    () => {
                        window.testState.slc = {
                            'fail': false,
                            'uids': [],
                            'listener': -1,
                            'currentUid': -1,
                            'startedUids': [],
                            'updatedUids': [],
                            'updateQueue': [],
                        };

                        Api.getVersions().then((versions) => {
                            for(let version of versions) {
                                if(parseInt(version.type) === 116) {
                                    window.testState.slc.uids.push(version.uid);
                                    window.testState.slc.updateQueue.push(version.uid);
                                }
                            }
                            Debug.log('Retrieved list of SLC uids');
                            Debug.log(window.testState.slc.uids);
                        }).catch((e) => {
                            Debug.log(e, 3);
                            window.testState.slc.fail = true;
                        });
                    },
                    new Expect(() => {
                        return window.testState.slc.fail === false;
                    }),
                    () => {
                        function updateNext() {
                            if(!window.testState.slc.updateRunning && window.testState.slc.updateQueue.length > 0) {
                                const updateTargetUid = window.testState.slc.updateQueue.pop();
                                window.testState.slc.currentUid = updateTargetUid;
                                Api.updateSingle(updateTargetUid, 116).then(() => {
                                    window.testState.slc.currentUid = updateTargetUid;
                                    Debug.log('Update for slc ' + updateTargetUid + ' launched', 1);
                                }).catch((e) => {
                                    Debug.log('Failed to update SLC', 3);
                                    Debug.log(e, 3);
                                    window.testState.slc.fail = true;
                                });
                            } else {
                                Signals.removeListener(window.testState.slc.listener);
                                window.testState.slc.listener = null;
                            }
                        }


                        window.testState.slc.listener = Signals.addListener('manager/status', (msg) => {
                            const uid = msg.data.update.target;
                            const current = window.testState.slc.currentUid;

                            if(uid === current) {
                                if(window.testState.slc.startedUids.indexOf(current) === -1) {
                                    window.testState.slc.startedUids.push(current);
                                }
                            }

                            if(
                                uid === null &&
                                current !== -1 &&
                                window.testState.slc.startedUids.indexOf(current) !== -1 &&
                                window.testState.slc.updatedUids.indexOf(current) === -1
                            ) {
                                window.testState.slc.updatedUids.push(current);
                                if(window.testState.slc.updateQueue.length > 0) {
                                    updateNext();
                                }
                            }
                        });

                        updateNext();
                    },

                    new WaitUnless(3 * 60 * 1000, () => {
                        return window.testState.slc.uids.length === window.testState.slc.updatedUids.length;
                    }),

                    new Expect(() => {
                        if(window.testState.slc.listener !== null) {
                            Signals.removeListener(window.testState.slc.listener);
                            window.testState.slc.listener = null;
                        }
                        return window.testState.slc.fail === false;
                    }),
                    new Wait(30000),
                ]
            ),
        ],



        // Here's two example tests, one that always fails and one that fails
        // in 25% of the cases

        // ----------------------
        // --- Always fail    ---
        // ----------------------
        // This is purely for testing and therefor disabled right now
        /*
        'Always fail': [
            new TestSequence(
                'Always fail', [
                    new Expect(() => {
                        return false;
                    })
                ]
            )
        ]


        // ------------------------
        // --- Might fail (25%) ---
        // ------------------------
        'Might fail (25%)': [
            new TestSequence(
                'Might fail (25%)', [
                    new Expect(() => {
                        return Math.random() < 0.25;
                    })
                ]
            )
        ]
        */

    };
}
