"use strict";

let translations = {
    // ../src/templates/time.html,
    '24H or AM/PM': '24H of AM/PM',

    // IQ4 Text for event WARNING_MASK_24V_OUTPUT_OVERLOAD,
    '24v output overload': '24V-uitgang overbelasting',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    '48h': 'chart","Plot',

    // ../src/templates/chart.html,
    '48h chart': '48H grafiek',

    // ../src/templates/log.html,
    'Actions': 'Acties',

    // ../src/templates/parameter.html,src/templates/chart.html,src/templates/setting.html,
    'Actual value': 'Huidige waarde',

    // ../src/templates/user.html,
    'Add new user': 'Voeg nieuwe gebruiker toe',

    // ../src/templates/related-add.html,
    'Add related objects': 'Voeg gerelateerde objecten toe',

    // Action title for add-to-clfb,
    'Add to CLFB': 'Voeg toe aan CLFB',

    // IQ4 Text for parameter CLEANFLEX_AIR_PRESSURE,IQ4 Text for parameter BLOWFLEX_AIR_PRESSURE,
    'Air pressure': 'Luchtdruk',

    // IQ4 Text for parameter CLEANFLEX_TRIGGER_LEVEL,IQ4 Text for parameter BLOWFLEX_TRIGGER_LEVEL,
    'Air pressure trigger level high voltage': 'Luchtdruk trigger niveau hoog spanning aan',

    // IQ4 Text for parameter GATEWAY_ALARM_SETPOINT,IQ4 Text for parameter GATEWAY_EX_ALARM_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_ALARM_SETPOINT,
    'Alarm level, measured value over maximum': 'Alarm niveau, gemeten waarde over het maximum',

    // IQ4 Text for parameter GATEWAY_ALARM_MINIMUM,IQ4 Text for parameter GATEWAY_EX_ALARM_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_ALARM_MINIMUM,
    'Alarm level, measured value under minimum': 'Alarm niveau, gemeten waarde onder minimum',

    // ../src/templates/log.html,
    'Alarms': 'Alarmen',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_VALUE,
    'Ambient temperature': 'Omgevingstemperatuur',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_AVAILABLE,
    'Ambient temperature sensor available': 'Omgevingstemperatuursensor beschikbaar',

    // ../src/../src/views/related.js,
    'An error occured while creating a new CLFB': 'Er was een probleem bij het maken van een nieuwe CLFB',

    // IQ4 Text for parameter PERFORMAX_ANALOG_CLEANBAR_ENABLED,IQ4 Text for parameter EASION_ANALOG_CLEANBAR_ENABLED,IQ4 Text for parameter EASION_ANALOG_CLEANBAR_MODE,IQ4 Text for parameter PERFORMAX_ANALOG_CLEANBAR_MODE,
    'Analog cleanbar enabled': 'Analoge cleanbar geactiveerd',

    // IQ4 Text for parameter CM_TINY_ARC_DETECT_COUNTER,IQ4 Text for parameter IQ_COM_ARC_DETECT_COUNTER,IQ4 Text for parameter MPM_ARC_DETECT_COUNTER,
    'Arcs per minute counter': 'Overslagen per minuut teller',

    // ../src/templates/user-delete.html,
    'Are you sure you want to delete the user': 'Weet u zeker dat u de gebruiker wilt verwijderen?',

    // IQ4 Text for parameter GATEWAY_AVAILABLE_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_AVAILABLE_SEGMENTS,
    'Available Sensor segments': 'Beschikbare Sensorsegmenten',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Available updates': 'Beschikbare updates',

    // ../src/templates/settings-updates.html,
    'Available version': 'Beschikbare versie',

    // ../src/templates/device.html,
    'Back to top': 'Terug naar Boven',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Before exporting the Fieldbus configuration you first have to disable it.': 'Voordat je de fieldbus-configuratie exporteert, moet je deze eerst uitschakelen.',

    // IQ4 Text for setting CLFB_NAME,
    'CLFB name': 'CLFB naam',

    // Action title for calibrate,
    'Calibrate bar': 'Kalibreer staaf',

    // ../src/../src/views/related.js,
    'Can not delete CLFB output': 'Kan CLFB-uitvoer niet verwijderen',

    // ../src/templates/parameter.html,src/templates/setting.html,
    'Cancel': 'Annuleer',

    // IQ4 Text for parameter CMME_CHARGE_SINGLE_SHOT_TIME,IQ4 Text for parameter IQ_COM_CHARGE_SINGLE_SHOT_TIME,
    'Charge pulse single shot': 'Oplaadpuls single shot',

    // ../src/templates/settings-install-updates-error.html,src/templates/settings.html,
    'Check available updates': 'Controleer op beschikbare updates',

    // IQ4 Text for parameter PERFORMAX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter EASION_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter THUNDERION2_CLEAN_BAR_WARNING_LEVEL,
    'Clean bar warning level': 'Reinigen staaf waarschuwingsniveau',

    // Action title for clear,
    'Clear action log': 'Wis actielogboek',

    // ../src/templates/clfb.html,src/templates/parameter.html,src/templates/device.html,src/templates/fieldbus.html,src/templates/setting.html,src/templates/action.html,src/templates/log-table.html,
    'Close': 'Afsluiten',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Closed': 'Loop',

    // ../src/templates/connecting.html,
    'Connecting to websocket, please wait.': 'Verbinden met websocket, even geduld.',

    // ../src/templates/log-table.html,
    'Could not retrieve log': 'Kon logboek niet ophalen',

    // ../src/../src/views/setting.js,
    'Could not save the new settings': 'Kon de nieuwe instellingen niet opslaan',

    // ../src/../src/views/parameter.js,
    'Could not save the new value': 'Kon de nieuwe waarde niet opslaan',

    // Action title for create-related,
    'Create related object': 'Maak gerelateerd object',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_SETPOINT,
    'Current Control current setpoint': 'Stroomregeling instelpunt',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_LIMIT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_LIMIT_SETPOINT,
    'Current Control output voltage limit': 'Spanningslimiet voor stroomregeling',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Current system time': 'Huidige systeemtijd',

    // ../src/templates/time.html,
    'Current system time in UTC': 'Huidige systeemtijd in UTC',

    // ../src/templates/time.html,
    'Current time zone': 'Huidige tijdzone',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Current version': 'Huidige versie',

    // IQ4 Text for parameter CMME_CYCLE_OK_HIGH_DELAY_THRESHOLD,
    'Cycle OK high delay': 'Cycle OK vertraging',

    // IQ4 Text for parameter CMME_CYCLE_OK_LOW_LEVEL_THRESHOLD_MNGR4,
    'Cycle OK low level': 'Cycle OK laag niveau',

    // ../src/templates/time.html,src/templates/log.html,
    'Date': 'Datum',

    // ../src/templates/debug.html,
    'Debug': 'Debuggen',

    // ../src/templates/parameter.html,src/templates/chart.html,
    'Default value': 'Standaard waarde',

    // ../src/templates/debug-test.html,src/templates/user.html,
    'Delete': 'Verwijderen',

    // Action title for delete-related,
    'Delete related object': 'Verwijder gerelateerd object',

    // ../src/templates/user-delete.html,
    'Delete user': 'Verwijder gebruiker',

    // IQ4 Text for setting CLFB_DERIVATIVEGAIN,
    'Derivative Gain': 'Derivatieve Gain',

    // ../src/templates/debug-report.html,
    'Description': 'Beschrijving',

    // IQ4 Text for parameter CM_TINY_DEVICE_NAME,IQ4 Text for parameter CMME_DEVICE_NAME,IQ4 Text for parameter IQ_COM_DEVICE_NAME,IQ4 Text for parameter GATEWAY_DEVICE_NAME,IQ4 Text for parameter GATEWAY_EX_DEVICE_NAME,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_NAME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_DEVICE_NAME,IQ4 Text for parameter EASION_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_EX_DEVICE_NAME,IQ4 Text for parameter THUNDERION2_DEVICE_NAME,IQ4 Text for parameter VICINION_DEVICE_NAME,IQ4 Text for parameter CLEANFLEX_DEVICE_NAME,IQ4 Text for parameter BLOWFLEX_DEVICE_NAME,IQ4 Text for parameter MPM_DEVICE_NAME,
    'Device name': 'Apparaat naam',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE,IQ4 Text for parameter CMME_DEVICE_TYPE,IQ4 Text for parameter IQ_COM_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_EX_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE,IQ4 Text for parameter EASION_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE,IQ4 Text for parameter VICINION_DEVICE_TYPE,IQ4 Text for parameter CLEANFLEX_DEVICE_TYPE,IQ4 Text for parameter BLOWFLEX_DEVICE_TYPE,IQ4 Text for parameter MPM_DEVICE_TYPE,
    'Device type': 'Apparaat type',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE_VERSION,IQ4 Text for parameter CMME_DEVICE_TYPE_VERSION,IQ4 Text for parameter IQ_COM_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE_VERSION,IQ4 Text for parameter EASION_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE_VERSION,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE_VERSION,
    'Device type version': 'Apparaat type versie',

    // Action title for disable,
    'Disable': 'Deactiveer',

    // Action title for remove,
    'Disconnect': 'Loskoppelen',

    // ../src/templates/log.html,
    'Dismiss log': 'Log verwijderen',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_AVAILABLE,
    'Distance sensor available': 'Afstandssensor beschikbaar',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_MALFUNCTION,
    'Distance sensor malfunction': 'Afstandssensor storing',

    // IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE_OVERRIDE,
    'Distance sensor override': 'Afstandssensor override',

    // ../src/../src/core/gui.js,
    'Done': 'Voltooid',

    // ../src/templates/user.html,
    'Edit': 'Bewerken',

    // ../src/templates/macros.html,
    'Edit parameter': 'Parameter bewerken',

    // Action title for edit-related,
    'Edit related objects': 'Bewerk gerelateerde objecten',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Edit setting': 'Bewerk instelling',

    // ../src/templates/user-edit.html,
    'Edit user': 'Gebruiker aanpassen',

    // Action title for enable,
    'Enable': 'Activeer',

    // IQ4 Text for parameter GATEWAY_ENABLED_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_ENABLED_SEGMENTS,
    'Enabled Sensor segments': 'Geactiveerde sensorsegmenten',

    // ../src/../src/views/time.js,
    'Error changing the system time': 'Fout bij het wijzigen van de systeemtijd',

    // ../src/../src/views/user.js,
    'Error deleting user': 'Fout bij verwijderen gebruiker',

    // ../src/templates/settings-install-updates-error.html,
    'Error installing updates': 'Fout bij het installeren van updates',

    // ../src/../src/views/chart.js,
    'Error retrieving history': 'Probleem bij het ophalen van de geschiedenis',

    // ../src/../src/views/chart.js,
    'Error retrieving recent history': 'Fout bij het ophalen van recente geschiedenis',

    // ../src/../src/views/user.js,../src/../src/views/user.js,
    'Error: failed to create user': 'Fout: aanmaken gebruiker mislukt',

    // ../src/../src/views/user.js,
    'Error: failed to delete user': 'Fout: Verwijderen gebruiker mislukt',

    // ../src/../src/views/user.js,
    'Error: failed to edit user': 'Fout: bewerken van gebruiker mislukt',

    // ../src/../src/views/user.js,
    'Error: failed to update user': 'Fout: bijwerken gebruiker mislukt',

    // ../src/templates/log.html,
    'Events': 'Activiteiten',

    // Action title for export,
    'Export configuration': 'Exporteer configuratie',

    // ../src/templates/settings.html,
    'Factory name': 'Fabrieksnaam',

    // ../src/../src/views/log.js,
    'Failed to clear the log': 'Het is niet gelukt het logboek te wissen',

    // ../src/templates/failed-to-connect.html,
    'Failed to connect to the websocket, please check your settings.': 'Kan geen verbinding maken met de websocket, controleer je instellingen.',

    // ../src/../src/views/action.js,
    'Failed to perform the action ': 'De actie kon niet worden uitgevoerd',

    // ../src/../src/views/login.js,../src/../src/views/login.js,
    'Failed to reset the password. Please check the username and recovery key.': 'Het is niet gelukt om het wachtwoord te resetten. Controleer de gebruikersnaam en recovery key.',

    // ../src/../src/views/related.js,
    'Failed to retrieve CLFB': 'CLFB kon niet worden opgehaald',

    // ../src/../src/views/related.js,
    'Failed to retrieve device': 'Apparaat kon niet worden opgehaald',

    // ../src/../src/views/related.js,
    'Failed to retrieve fieldbus': 'Veldbus kon niet worden opgehaald',

    // ../src/../src/views/related.js,../src/../src/views/related.js,../src/../src/views/related.js,
    'Failed to retrieve the CLFB': 'Ophalen van CLFB is mislukt',

    // ../src/../src/views/related.js,
    'Failed to retrieve the fieldbus': 'Fieldbus kon niet worden opgehaald',

    // ../src/../src/views/related.js,../src/../src/views/related.js,../src/../src/views/related.js,
    'Failed to update the CLFB settings': 'Kan de CLFB instellingen niet bijwerken',

    // ../src/../src/views/related.js,
    'Failed to update the Fieldbus Map': 'Het bijwerken van de fieldbus mapping is mislukt',

    // IQ4 Text for setting FIELDBUS_NAME,
    'Fieldbus name': 'Fieldbus naam',

    // IQ4 Text for parameter CM_TINY_FIRMWARE_VERSION,IQ4 Text for parameter CMME_FIRMWARE_VERSION,IQ4 Text for parameter IQ_COM_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter SEGMENT_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_FIRMWARE_VERSION,IQ4 Text for parameter EASION_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_EX_FIRMWARE_VERSION,IQ4 Text for parameter THUNDERION2_FIRMWARE_VERSION,IQ4 Text for parameter VICINION_FIRMWARE_VERSION,IQ4 Text for parameter CLEANFLEX_FIRMWARE_VERSION,IQ4 Text for parameter BLOWFLEX_FIRMWARE_VERSION,IQ4 Text for parameter MPM_FIRMWARE_VERSION,
    'Firmware version': 'Firmware versie',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG1,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG1,
    'Firmware version seg1': 'Firmware versie seg1',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG10,
    'Firmware version seg10': 'Firmware versie seg10',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG11,
    'Firmware version seg11': 'Firmware versie seg11',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG12,
    'Firmware version seg12': 'Firmware versie seg12',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG13,
    'Firmware version seg13': 'Firmware versie seg13',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG14,
    'Firmware version seg14': 'Firmware versie seg14',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG15,
    'Firmware version seg15': 'Firmware versie seg15',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG16,
    'Firmware version seg16': 'Firmware versie seg16',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG2,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG2,
    'Firmware version seg2': 'Firmware versie seg2',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG3,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG3,
    'Firmware version seg3': 'Firmware versie seg3',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG4,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG4,
    'Firmware version seg4': 'Firmware versie seg4',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG5,
    'Firmware version seg5': 'Firmware versie seg5',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG6,
    'Firmware version seg6': 'Firmware versie seg6',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG7,
    'Firmware version seg7': 'Firmware versie seg7',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG8,
    'Firmware version seg8': 'Firmware versie seg8',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG9,
    'Firmware version seg9': 'Firmware versie seg9',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Hide release notes': 'Verberg release notes',

    // ../src/../src/views/chart.js,../src/../src/views/clfb-io-chart.js,
    'Hide table': 'Tabel verbergen',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter CMME_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter MPM_OUTPUT_CURRENT_FEEDBACK,
    'High voltage output current': 'Hoogspanning uitgangsstroom',

    // IQ4 Text for parameter CMME_OUTPUT_CURRENT_LIMIT_MNGR4,
    'High voltage output current limit': 'Hoogspanning uitgangsstroom limiet',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_CURRENT_PEAK_MNGR4,
    'High voltage output current peak per cycle': 'Hoogspanning uitgangsstroom piek per cyclus',

    // IQ4 Text for parameter THUNDERION2_OUTPUT_FREQUENCY_SETPOINT,IQ4 Text for parameter VICINION_OUTPUT_FREQUENCY_SETPOINT,
    'High voltage output frequency': 'Uitgangsfrequentie hoogspanning',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter VICINION_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_FEEDBACK,
    'High voltage output voltage': 'Uitgangsspanning hoogspanning',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter MPM_HV_OVERLOAD_LEVEL_ALARM,
    'High voltage overload alarm level': 'Alarm niveau overbelasting van hoogspanning',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter MPM_HV_OVERLOAD_LEVEL_WARNING,
    'High voltage overload warning level': 'Waarschuwingsniveau voor overbelasting hoogspanning',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'IQ': 'Object',

    // Action title for identify,
    'Identify': 'Identificeren',

    // ../src/templates/log.html,
    'Info': 'Informatie',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Input Segments': 'Invoersegmenten',

    // IQ4 Text for setting CLFB_INPUTSEGMENTS,
    'Input Segments Selection': 'Input Segments selectie',

    // ../src/templates/clfb.html,src/templates/clfb-io.html,src/templates/clfb-chart.html,
    'Input value': 'Input waarde',

    // ../src/templates/settings-updates.html,
    'Install all updates': 'Installeer alle updates',

    // IQ4 Text for setting CLFB_INTEGRALDEADBAND,
    'Integral Deadband': 'Integrale Deadband',

    // IQ4 Text for setting CLFB_INTEGRALGAIN,
    'Integral Gain': 'Integraal Gain',

    // IQ4 Text for setting CLFB_INTEGRALMAX,
    'Integral Maximum': 'Integraal maximum',

    // IQ4 Text for setting CLFB_INTEGRALMIN,
    'Integral Minimum': 'Integraal minimum',

    // IQ4 Text for parameter CM_TINY_INTERNAL_TEMP,IQ4 Text for parameter CMME_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter QID_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE,IQ4 Text for parameter THUNDERION2_INTERNAL_TEMPERATURE,IQ4 Text for parameter VICINION_INTERNAL_TEMPERATURE,IQ4 Text for parameter EASION_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter BLOWFLEX_INTERNAL_TEMPERATURE,IQ4 Text for parameter MPM_INTERNAL_TEMP,
    'Internal temperature': 'Interne temperatuur',

    // ../src/templates/macros.html,
    'Internal use': 'Intern gebruik',

    // ../src/../src/views/login.js,../src/../src/views/login.js,
    'Invalid login, please try again': 'Ongeldige login, probeer het opnieuw',

    // ../src/../src/views/parameter.js,
    'Invalid parameter': 'Ongeldige parameter',

    // ../src/../src/views/related.js,
    'Invalid related object target': 'Ongeldig gerelateerd objectdoel',

    // ../src/../src/views/setting.js,
    'Invalid setting': 'Ongeldige instelling',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Ionisation balace offset': 'Ionisatie balans offset',

    // IQ4 Text for parameter PERFORMAX_BALANCE_OFFSET,IQ4 Text for parameter EASION_BALANCE_OFFSET,IQ4 Text for parameter PERFORMAX_EX_BALANCE_OFFSET,IQ4 Text for parameter THUNDERION2_BALANCE_OFFSET,
    'Ionisation balance offset': 'Ionisatie balans offset',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter EASION_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter THUNDERION2_IONISATION_CURRENT_NEGATIVE,IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEG,
    'Ionisation current negative emitters': 'Ionisatiestroom negatieve emitters',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter EASION_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter THUNDERION2_IONISATION_CURRENT_POSITIVE,IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POS,
    'Ionisation current positive emitters': 'Ionisatiestroom positieve emitters',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_EFFICIENCY,IQ4 Text for parameter EASION_IONIZATION_EFFICIENCY,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_EFFICIENCY,IQ4 Text for parameter THUNDERION2_IONISATION_EFFICIENCY,
    'Ionisation efficiency': 'Ionisatie efficiëntie',

    // ../src/templates/login.html,
    'Language': 'Taal',

    // IQ4 Text for parameter CM_TINY_LAST_ALARM,IQ4 Text for parameter CMME_LAST_ALARM,IQ4 Text for parameter IQ_COM_LAST_ALARM,IQ4 Text for parameter GATEWAY_LAST_ALARM,IQ4 Text for parameter GATEWAY_EX_LAST_ALARM,IQ4 Text for parameter PERFORMAX_LAST_ALARM,IQ4 Text for parameter EASION_LAST_ALARM,IQ4 Text for parameter PERFORMAX_EX_LAST_ALARM,IQ4 Text for parameter THUNDERION2_LAST_ALARM,IQ4 Text for parameter VICINION_LAST_ALARM,IQ4 Text for parameter CLEANFLEX_LAST_ALARM,IQ4 Text for parameter BLOWFLEX_LAST_ALARM,IQ4 Text for parameter MPM_LAST_ALARM,
    'Last alarm date-time': 'Datum-tijd laatste alarm',

    // IQ4 Text for parameter SEGMENT_4_QID_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_LAST_CALIBRATION_DATE,IQ4 Text for parameter EASION_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_EX_LAST_CALIBRATION_DATE,
    'Last calibration date': 'Laatste kalibratiedatum',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG1,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG1,
    'Last calibration date seg1': 'Laatste kalibratiedatum seg1',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG10,
    'Last calibration date seg10': 'Laatste kalibratiedatum seg10',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG11,
    'Last calibration date seg11': 'Laatste kalibratiedatum seg11',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG12,
    'Last calibration date seg12': 'Laatste kalibratiedatum seg12',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG13,
    'Last calibration date seg13': 'Laatste kalibratiedatum seg13',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG14,
    'Last calibration date seg14': 'Laatste kalibratiedatum seg14',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG15,
    'Last calibration date seg15': 'Laatste kalibratiedatum seg15',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG16,
    'Last calibration date seg16': 'Laatste kalibratiedatum seg16',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG2,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG2,
    'Last calibration date seg2': 'Laatste kalibratiedatum seg2',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG3,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG3,
    'Last calibration date seg3': 'Laatste kalibratiedatum seg3',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG4,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG4,
    'Last calibration date seg4': 'Laatste kalibratiedatum seg4',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG5,
    'Last calibration date seg5': 'Laatste kalibratiedatum seg5',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG6,
    'Last calibration date seg6': 'Laatste kalibratiedatum seg6',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG7,
    'Last calibration date seg7': 'Laatste kalibratiedatum seg7',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG8,
    'Last calibration date seg8': 'Laatste kalibratiedatum seg8',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG9,
    'Last calibration date seg9': 'Laatste kalibratiedatum seg9',

    // IQ4 Text for parameter PERFORMAX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter EASION_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter PERFORMAX_EX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter THUNDERION2_LAST_CLEAN_BAR_WARNING,
    'Last clean bar warning date-time': 'Laatste waarschuwingsdatum staaf reinigen',

    // IQ4 Text for parameter CM_TINY_LAST_WARNING,IQ4 Text for parameter CMME_LAST_WARNING,IQ4 Text for parameter IQ_COM_LAST_WARNING,IQ4 Text for parameter GATEWAY_LAST_WARNING,IQ4 Text for parameter GATEWAY_EX_LAST_WARNING,IQ4 Text for parameter PERFORMAX_LAST_WARNING,IQ4 Text for parameter EASION_LAST_WARNING,IQ4 Text for parameter THUNDERION2_LAST_WARNING,IQ4 Text for parameter VICINION_LAST_WARNING,IQ4 Text for parameter CLEANFLEX_LAST_WARNING,IQ4 Text for parameter BLOWFLEX_LAST_WARNING,IQ4 Text for parameter MPM_LAST_WARNING,
    'Last warning date-time': 'Datum/tijd laatste waarschuwing',

    // ../src/templates/user-edit.html,src/templates/user-new.html,
    'Level': 'Niveau',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,src/templates/debug.html,
    'Log': 'Logboek',

    // ../src/templates/log.html,
    'Log for': 'Logboek voor',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Loop selection': 'Herhaal selectie',

    // IQ4 Text for parameter CM_TINY_MACHINE_POSITION,IQ4 Text for parameter CMME_MACHINE_POSITION,IQ4 Text for parameter IQ_COM_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_EX_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_MACHINE_POSITION,IQ4 Text for parameter EASION_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_EX_MACHINE_POSITION,IQ4 Text for parameter THUNDERION2_MACHINE_POSITION,IQ4 Text for parameter VICINION_MACHINE_POSITION,IQ4 Text for parameter CLEANFLEX_MACHINE_POSITION,IQ4 Text for parameter BLOWFLEX_MACHINE_POSITION,IQ4 Text for parameter MPM_MACHINE_POSITION,
    'Machine position (location)': 'Machine positie (locatie)',

    // IQ4 Text for event CLFB_INPUT_WATCHDOG_ELAPSED,
    'Make sure the CLFB is being updated. Sensor is connected and running.': 'Zorg ervoor dat de CLFB wordt bijgewerkt. Sensor is verbonden en in Run.',

    // IQ4 Text for event CLFB_INPUT_DEVICE_NOT_READY,
    'Make sure the input device is available and in RUN mode': 'Zorg ervoor dat het invoerapparaat beschikbaar is en in de RUN modus',

    // IQ4 Text for event CLFB_OUTPUT_DEVICE_NOT_READY,
    'Make sure the output device is available and in run mode': 'Zorg ervoor dat het uitvoerapparaat beschikbaar is en in RUN modus',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MAX_TIME,
    'Max. Charge pulse duration': 'Max. duur laadpuls',

    // IQ4 Text for parameter CM_TINY_MAX_INTERNAL_TEMP,IQ4 Text for parameter CMME_MAX_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter EASION_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter VICINION_MAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter MPM_MAX_INTERNAL_TEMP,
    'Max. internal temperature': 'Max. interne temperatuur',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_MAX_OLD,
    'Max. primary current': 'Max. primaire stroom',

    // IQ4 Text for parameter CM_TINY_MAX_QUANTITY_ARCS,IQ4 Text for parameter IQ_COM_MAX_QUANTITY_ARCS,
    'Max. quantity arcs per minute': 'Max. hoeveelheid overslagen per minuut',

    // ../src/templates/parameter.html,src/templates/chart.html,
    'Maximum value': 'Maximum waarde',

    // ../src/templates/log.html,
    'Message': 'Bericht',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MIN_TIME,
    'Min. Charge pulse duration': 'Min. duur ladingspuls',

    // ../src/templates/parameter.html,src/templates/chart.html,
    'Minimum value': 'Minimale waarde',

    // IQ4 Text for parameter SENSOR_MOUNTING_DISTANCE,IQ4 Text for parameter SENSOR_EX_MOUNTING_DISTANCE,IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_MOUNTING_DISTANCE,IQ4 Text for parameter EASION_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_EX_MOUNTING_DISTANCE,IQ4 Text for parameter THUNDERION2_MOUNTING_DISTANCE,IQ4 Text for parameter VICINION_MOUNTING_DISTANCE,
    'Mounting distance': 'Montageafstand',

    // ../src/templates/user-edit.html,src/templates/user-new.html,src/templates/settings-updates.html,src/templates/settings.html,
    'Name': 'Naam',

    // ../src/templates/related-add.html,
    'New Closed Loop Feedback': 'Nieuwe Closed Loop Feedback',

    // ../src/../src/views/log.js,
    'New log entries have been received. Click on the "refresh log" button to update the list.': 'Nieuwe logboekvermeldingen zijn ontvangen. Klik op de knop "Vernieuw log" om de lijst bij te werken.',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'No': 'Nee',

    // ../src/../src/core/gui.js,
    'Off': 'Uit',

    // ../src/../src/core/gui.js,
    'On': 'Aan',

    // IQ4 Text for parameter CM_TINY_OPERATION_MODE,IQ4 Text for parameter CMME_OPERATION_MODE,IQ4 Text for parameter IQ_COM_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_BAR_OPERATION_MODE,IQ4 Text for parameter EASION_BAR_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_EX_BAR_OPERATION_MODE,IQ4 Text for parameter THUNDERION2_BAR_OPERATION_MODE,IQ4 Text for parameter VICINION_BAR_OPERATION_MODE,
    'Operation mode': 'Bedrijfsmodus',

    // IQ4 Text for parameter CMME_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_ALARM_CURRENT_PEAK_MNGR4,
    'Output current alarm limit': 'Uitgangsstroom alarm limiet',

    // IQ4 Text for parameter CMME_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_WARNING_CURRENT_PEAK_MNGR4,
    'Output current peak alarm limit': 'Uitgangsstroom piek alarm limiet',

    // IQ4 Text for parameter EASION_OUTPUT_FREQUENCY_SETPOINT,
    'Output frequency setpoint': 'Uitgangsfrequentie setpoint',

    // ../src/templates/clfb.html,src/templates/clfb-io.html,src/templates/clfb-chart.html,
    'Output value': 'Output waarde',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_MAX,
    'Output voltage max setpoint': 'Max. setpoint uitgangsspanning',

    // IQ4 Text for parameter PERFORMAX_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter PERFORMAX_EX_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter PERFORMAX_VSENSE_NEG,
    'Output voltage negative emitters': 'Uitgangsspanning negatieve emitters',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_NEGATIVE_SETPOINT,
    'Output voltage negative setpoint': 'Uitgangsspanning negatief setpoint',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_PEAK_MNGR4,
    'Output voltage peak per cycle': 'Uitgangsspanning piek per cyclus',

    // IQ4 Text for parameter PERFORMAX_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter PERFORMAX_EX_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter PERFORMAX_VSENSE_POS,
    'Output voltage positive emitters': 'Uitgangsspanning positieve emitters',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_POSITIVE_SETPOINT,
    'Output voltage positive setpoint': 'Uitgangsspanning positief setpoint',

    // ../src/templates/404.html,
    'Page could not be found': 'Pagina kon niet worden gevonden',

    // ../src/templates/macros.html,
    'Parameter locked': 'Parameter vergrendeld',

    // ../src/templates/user-edit.html,src/templates/login.html,src/templates/user-new.html,
    'Password': 'Wachtwoord',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Password reset': 'Wachtwoord reset',

    // ../src/../src/views/parameter.js,
    'Please input a valid number': 'Voer een geldig getal in',

    // ../src/templates/parameter.html,
    'Plot 48h chart': 'Plot 48 uurs grafiek',

    // ../src/templates/parameter.html,src/templates/clfb-io.html,
    'Plot realtime chart': 'Plot realtime grafiek',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Position': 'Positie',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CMME_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter THUNDERION2_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CLEANFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter BLOWFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE,
    'Power supply voltage': 'Voedingsspanning',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter CMME_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE_WRN_LEVEL,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE_WARNING,
    'Power supply warning level': 'Voedingsspanning waarschuwingsniveau',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter THUNDERION2_PRIMARY_CURRENT,IQ4 Text for parameter CLEANFLEX_PRIMARY_CURRENT,IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT,
    'Primary current': 'Primaire stroom',

    // IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT_CALIBRATION_OFFSET,
    'Primary current calibration offset': 'Primaire stroomkalibratie-offset',

    // IQ4 Text for setting CLFB_PROPORTIONALGAIN,
    'Proportional Gain': 'Proportionele Gain',

    // ../src/templates/chart.html,
    'Realtime chart': 'Realtime grafiek',

    // Action title for reboot,
    'Reboot': 'Herstarten',

    // ../src/templates/password-reset.html,
    'Recovery key': 'Herstelsleutel',

    // ../src/templates/log.html,
    'Refresh log': 'Logboek vernieuwen',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_VALUE,
    'Relative Humidity': 'Relatieve vochtigheid',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_AVAILABLE,
    'Relative Humidity sensor available': 'Relatieve luchtvochtigheidssensor beschikbaar',

    // ../src/templates/customize-view.html,
    'Reorder': 'Reorganiseren',

    // ../src/templates/debug-report.html,src/templates/debug.html,
    'Report': 'Rapportage',

    // ../src/templates/password-reset.html,
    'Reset password': 'Wachtwoord resetten',

    // ../src/templates/chart.html,src/templates/clfb-chart.html,
    'Reset zoom': 'Zoom opnieuw instellen',

    // ../src/templates/debug.html,
    'Restart': 'Herstarten',

    // ../src/../src/core/state.js,
    'Restarting the system, please wait': 'Herstarten van het systeem, even geduld',

    // ../src/../src/core/state.js,
    'Restarting, please wait': 'Herstarten, even geduld',

    // Action title for restore-factory,
    'Restore factory settings': 'Herstel de fabrieksinstellingen',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Result': 'Resultaat',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Run': 'Start',

    // ../src/templates/debug-test.html,
    'Run test selection': 'Testselectie uitvoeren',

    // ../src/templates/user-edit.html,src/templates/parameter.html,src/templates/time.html,src/templates/time.html,src/templates/user-new.html,src/templates/setting.html,
    'Save': 'Opslaan',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Segment Selection': 'Segment Selectie',

    // IQ4 Text for setting CLFB_SEGMENTSELECTION,
    'Segment Selection Mode': 'Segment Selectie Mode',

    // ../src/templates/related-select-slot.html,
    'Select fieldbus slot': 'Fieldbus slot selecteren',

    // ../src/templates/debug-report.html,
    'Send': 'Verzend',

    // ../src/templates/debug-test.html,
    'Sequences': 'Vervolgstappen',

    // IQ4 Text for event CLFB_SETPOINT_NOT_READY,
    'Set all requested setpoint settings and relations.': 'Stel alle gewenste setpoint instellingen en relaties in.',

    // Action title for run,
    'Set device in RUN': 'Zet apparaat in RUN',

    // ../src/templates/time.html,
    'Set local settings': 'Lokale instellingen instellen',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Set new system time': 'Stel nieuwe systeemtijd in',

    // ../src/templates/time.html,
    'Set new system time in UTC': 'Nieuwe systeemtijd instellen in UTC',

    // IQ4 Text for parameter BLOWFLEX_SETPOINT_CURRENT,
    'Setpoint current': 'Setpoint stroom',

    // IQ4 Text for parameter CM_TINY_SETPOINT_VOLTAGE_CLFB,IQ4 Text for parameter IQ_COM_SETPOINT_VOLTAGE_CLFB,IQ4 Text for parameter CMME_SETPOINT_VOLTAGE_CLFB,
    'Setpoint voltage balance for CLFB': 'Instelling spanningsbalans voor CLFB',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Setpoint voltage percent': 'Setpoint voltage percentage',

    // ../src/templates/macros.html,
    'Setting locked': 'Instelling vergrendeld',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Settings': 'Instellingen',

    // ../src/templates/log.html,
    'Show all ': 'Alle tonen',

    // ../src/templates/settings-updates.html,
    'Show current versions': 'Toon huidige versies',

    // ../src/templates/log.html,
    'Show dismissed': 'Toon niet getoonde',

    // ../src/templates/related-delete.html,src/templates/related-delete.html,src/templates/related-add.html,src/templates/related-select-slot.html,Action title for show-related,
    'Show related objects': 'Toon gerelateerde objecten',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Show release notes': 'Toon release notes',

    // ../src/../src/views/chart.js,../src/../src/views/clfb-io-chart.js,src/templates/chart.html,src/templates/clfb-chart.html,
    'Show table': 'Tabel weergeven',

    // ../src/templates/related-select-slot.html,
    'Slot ': 'Slot',

    // Action title for standby,
    'Standby': 'Stand-by',

    // ../src/../src/core/state.js,
    'Starting the system, please wait': 'Systeem starten, even geduld a.u.b',

    // ../src/templates/debug-state.html,src/templates/debug.html,
    'State': 'Status',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Steps': 'Stappen',

    // ../src/templates/debug.html,
    'Tests': 'Testen',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Text': 'for',

    // ../src/../src/views/time.js,
    'The local time settings were changed successfully': 'De lokale tijdinstellingen zijn met succes gewijzigd',

    // ../src/templates/password-reset.html,
    'The new password is': 'Het nieuwe wachtwoord is',

    // ../src/templates/password-reset.html,
    'The password for ': 'Het wachtwoord voor',

    // ../src/../src/views/time.js,
    'The system time was changed successfully': 'De systeemtijd is succesvol gewijzigd',

    // ../src/templates/related-add.html,
    'There are no objects to add.': 'Er zijn geen objecten om toe te voegen.',

    // ../src/templates/related-delete.html,
    'There are no objects to delete.': 'Er zijn geen objecten om te verwijderen.',

    // ../src/templates/related.html,
    'There are no related objects.': 'Er zijn geen gerelateerde objecten.',

    // ../src/templates/settings-updates.html,
    'There are no updates available': 'Er zijn geen updates beschikbaar.',

    // ../src/templates/settings-install-updates.html,
    'This might take a while and the system will reboot.': 'Dit kan even duren en het systeem zal opnieuw opstarten.',

    // ../src/templates/settings-confirm-update.html,
    'This will reboot the entire system and cause some downtime.': 'Dit zal het hele systeem opnieuw opstarten en enige vertraging veroorzaken.',

    // ../src/templates/time.html,
    'Time': 'Tijd',

    // IQ4 Text for parameter CM_TINY_TIME_RUNNING,IQ4 Text for parameter CMME_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TIME_RUNNING,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_UPTIME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_UPTIME,IQ4 Text for parameter PERFORMAX_TIME_RUNNING,IQ4 Text for parameter EASION_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TIME_RUNNING,IQ4 Text for parameter VICINION_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TIME_RUNNING,IQ4 Text for parameter BLOWFLEX_TIME_RUNNING,IQ4 Text for parameter MPM_TIME_RUNNING,
    'Time running since power up': 'Tijd actief sinds power up',

    // IQ4 Text for parameter CMME_TIME_TO_CYCLE_OK,
    'Time to Cycle OK': 'Tijd tot cycle OK',

    // ../src/templates/time.html,
    'Timezone': 'Tijdzone',

    // IQ4 Text for parameter GATEWAY_4_QID_TOTAL_RUNNING_TIME,IQ4 Text for parameter SEGMENT_4_QID_TOTAL_RUNNING_TIME,
    'Total running time': 'Totale tijd actief',

    // IQ4 Text for parameter CM_TINY_TOTAL_TIME_RUNNING,IQ4 Text for parameter CMME_TOTAL_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TOTAL_TIME_RUNNING,IQ4 Text for parameter VICINION_TOTAL_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TOTAL_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TOTAL_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_TOTAL_TIME_RUNNING,IQ4 Text for parameter EASION_TOTAL_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TOTAL_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TOTAL_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TOTAL_TIME_RUNNING,IQ4 Text for parameter MPM_TOTAL_TIME_RUNNING,
    'Total time running': 'Totale tijd actief',

    // IQ4 Text for parameter CM_TINY_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter CMME_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter THUNDERION2_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter VICINION_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter IQ_COM_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter CLEANFLEX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter PERFORMAX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter EASION_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter PERFORMAX_EX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter GATEWAY_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter GATEWAY_EX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter MPM_TOTAL_TIMES_POWERED_ON,
    'Total times powered on': 'Totaal aantal keren voeding aan',

    // ../src/../src/views/device.js,
    'Unexpected error occured while disabling': 'Onverwachte fout opgetreden tijdens het uitschakelen',

    // ../src/../src/views/device.js,
    'Unexpected error occured while enabling': 'Onverwachte fout opgetreden tijdens het inschakelen',

    // IQ4 Text for parameter CM_TINY_UNIQUE_DEVICE_ID,IQ4 Text for parameter CMME_UNIQUE_DEVICE_ID,IQ4 Text for parameter IQ_COM_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter SEGMENT_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_UNIQUE_DEVICE_ID,IQ4 Text for parameter EASION_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter THUNDERION2_UNIQUE_DEVICE_ID,IQ4 Text for parameter VICINION_UNIQUE_DEVICE_ID,IQ4 Text for parameter CLEANFLEX_UNIQUE_DEVICE_ID,IQ4 Text for parameter BLOWFLEX_UNIQUE_DEVICE_ID,
    'Unique device ID': 'Unieke apparaat-ID',

    // ../src/../src/core/gui.js,
    'Unknown': 'Onbekend',

    // ../src/../src/views/setting.js,../src/../src/views/setting.js,
    'Unknown IQ Object Type': 'Onbekend IQ objecttype',

    // ../src/templates/error.html,
    'Unknown error': 'Onbekende fout',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Unknown object': 'Onbekend object',

    // ../src/templates/settings-confirm-update.html,Action title for update,
    'Update': 'Werk bij',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,../src/../src/views/settings.js,src/templates/settings.html,
    'Updating': 'Bijwerken',

    // ../src/../src/core/state.js,
    'Updating the system, please wait': 'Systeem wordt bijgewerkt, even geduld a.u.b',

    // ../src/templates/related.html,src/templates/related.html,
    'Use the + button to add a related object.': 'Gebruik de + knop om een verwant object toe te voegen.',

    // ../src/templates/user.html,
    'User': 'Gebruiker',

    // ../src/../src/views/user.js,
    'User created successfully': 'Gebruiker succesvol aangemaakt',

    // ../src/../src/views/user.js,
    'User deleted successfully': 'Gebruiker succesvol verwijderd',

    // ../src/templates/user.html,
    'User list': 'Gebruikers lijst',

    // ../src/../src/views/user.js,
    'User updated successfully': 'Gebruiker succesvol bijgewerkt',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Username': 'Gebruikersnaam',

    // ../src/templates/settings.html,
    'Versions': 'Versies',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_LIMIT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_LIMIT_SETPOINT,
    'Voltage Control output current limit': 'Spanningsregeling uitgangsstroombegrenzing',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter VICINION_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_SETPOINT,
    'Voltage Control voltage setpoint': 'Spanningsregeling instelpunt',

    // ../src/../src/main.js,../src/../src/core/websocket.js,
    'Waiting for server connection, please wait': 'Wachten op verbinding met de server. Een ogenblik geduld',

    // IQ4 Text for parameter GATEWAY_WARNING_SETPOINT,IQ4 Text for parameter GATEWAY_EX_WARNING_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_WARNING_SETPOINT,
    'Warning level, measured value over maximum': 'Waarschuwing niveau, gemeten waarde over het maximum',

    // IQ4 Text for parameter GATEWAY_WARNING_MINIMUM,IQ4 Text for parameter GATEWAY_EX_WARNING_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_WARNING_MINIMUM,
    'Warning level, measured value under minimum': 'Waarschuwing niveau, gemeten waarde onder minimum',

    // IQ4 Text for parameter GATEWAY_WARNING_ERROR_DELAY,IQ4 Text for parameter GATEWAY_EX_WARNING_ERROR_DELAY,
    'Warning/alarm delay': 'Waarschuwing/alarm vertraging',

    // ../src/templates/log.html,
    'Warnings': 'Waarschuwingen',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_SPEED,IQ4 Text for parameter EASION_WEB_SPEED,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_SPEED,IQ4 Text for parameter PERFORMAX_WEB_SPEED,
    'Web speed': 'Baansnelheid',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,IQ4 Text for parameter GATEWAY_EX_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,
    'Web voltage average (all)': 'Gemiddelde baanspanning (alles)',

    // IQ4 Text for setting CLFB_WEBVOLTAGEOFFSET,
    'Web voltage offset': 'Baanspanning offset',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG1,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG1,
    'Web voltage seg1': 'Baanspanning seg1',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG10,
    'Web voltage seg10': 'Baanspanning seg10',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG11,
    'Web voltage seg11': 'Baanspanning seg11',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG12,
    'Web voltage seg12': 'Baanspanning seg12',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG13,
    'Web voltage seg13': 'Baanspanning seg13',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG14,
    'Web voltage seg14': 'Baanspanning seg14',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG15,
    'Web voltage seg15': 'Baanspanning seg15',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG16,
    'Web voltage seg16': 'Baanspanning seg16',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG2,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG2,
    'Web voltage seg2': 'Baanspanning seg2',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG3,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG3,
    'Web voltage seg3': 'Baanspanning seg3',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG4,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG4,
    'Web voltage seg4': 'Baanspanning seg4',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG5,
    'Web voltage seg5': 'Baanspanning seg5',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG6,
    'Web voltage seg6': 'Baanspanning seg6',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG7,
    'Web voltage seg7': 'Baanspanning seg7',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG8,
    'Web voltage seg8': 'Baanspanning seg8',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG9,
    'Web voltage seg9': 'Baanspanning seg9',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_WIDTH,IQ4 Text for parameter EASION_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_WEB_WIDTH,
    'Web width': 'Baanbreedte',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'Yes': 'Ja',

    // ../src/templates/user.html,
    'You are currently logged in as the user': 'U bent momenteel aangemeld als gebruiker',

    // ../src/../src/views/user.js,
    'You can not delete your own user account': 'U kunt uw eigen gebruikersaccount niet verwijderen',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'all': '","Alle',

    // IQ4 Text for event WARNING_MASK_ARC_DETECTED,IQ4 Text for event ALARM_MASK_ARC_DETECTED,
    'arc detected': 'vonk gedetecteerd',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'are': 'currently',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'available': 'updates","Controleer',

    // IQ4 Text for event WARNING_EFFICIENCY_LOW,
    'bar efficiency low': 'ionisatiestaaf efficiëntie laag',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'been': 'reset","is',

    // IQ4 Text for event WARNING_CALIBRATION_IN_PROGRESS,
    'calibration in progress': 'kalibratie in uitvoering',

    // IQ4 Text for event WARNING_CALIBRATION_INVALID,
    'calibration invalid': 'kalibratie ongeldig',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'can': 'not',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'changing': 'the',

    // IQ4 Text for event WARNING_MASK_CHARGEPULSE_TOO_LONG,
    'chargepulse too long': 'oplaadpuls te lang',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'chart","48H': 'grafiek"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'chart","Realtime': 'grafiek"',

    // IQ4 Text for event WARNING_MASK_CLEAN_BAR,
    'clean bar': 'reinig ionisatiestaaf',

    // IQ4 Text for event ALARM_COMMUNICATION_LOST_SEGMENT,
    'communication lost with sensor segment': 'communicatie verloren met sensorsegment',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'could': 'not',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'created': 'successfully","Gebruiker',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'current': 'versions","Toon',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'deleted': 'successfully","Gebruiker',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'deleting': 'user","Fout',

    // IQ4 Text for event WARNING_MASK_HMI_COMM_LOST,
    'device communication lost': 'apparaat communicatie verloren',

    // IQ4 Text for event WARNING_MASK_DEVICE_NEEDS_REBOOT,
    'device needs reboot': 'apparaat vereist opnieuw opstarten',

    // IQ4 Text for event SYS_WARNING_MASK_DEVICE_PORT_CHANGED,IQ4 Text for event WARNING_PORT_SWAP,
    'device port changed': 'poort van apparaat gewijzigd',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'dismissed","Toon': 'niet',

    // IQ4 Text for event ALARM_MASK_EEPROM_DEFECTIVE,
    'eeprom defective': 'eeprom defect',

    // IQ4 Text for event WARNING_MASK_EEPROM_PREV_SETTINGS_REST,
    'eeprom previous settings restored': 'eeprom vorige instellingen teruggezet',

    // IQ4 Text for event WARNING_MASK_EEPROM_TOO_MANY_WRITES,
    'eeprom too many write cycles': 'eeprom te veel schrijfberichten',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'error': 'occured',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'error","Onbekende': 'fout"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'failed': 'to',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'fieldbus': 'slot","Fieldbus',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'for': 'server',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'for","Logboek': 'voor"',

    // IQ4 Text for event WARNING_MASK_GENERATOR_NOT_CONNECTED,
    'generator not connected': 'generator niet verbonden',

    // ../src/templates/password-reset.html,
    'has been reset': 'is opnieuw ingesteld',

    // IQ4 Text for event WARNING_TEMPERATURE_HIGH,IQ4 Text for event WARNING_MASK_HIGH_TEMP,IQ4 Text for event ALARM_HIGH_TEMPERATURE,
    'high temperature': 'hoge temperatuur',

    // IQ4 Text for event WARNING_COMMUNICATION_LOST,
    'hmi communication lost': 'hmi communicatie verloren',

    // ../src/templates/log-table.html,
    'inactive': 'inactief',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'input': 'a',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'installing': 'updates","Fout',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'list","Gebruikers': 'lijst"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'local': 'time',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'locked","Instelling': 'vergrendeld"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'locked","Parameter': 'vergrendeld"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'log': 'entries',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'log","Log': 'verwijderen"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'log","Logboek': 'vernieuwen"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'login,': 'please',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'might': 'take',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'new': 'password',

    // IQ4 Text for event SYS_WARNING_MASK_NEW_DEVICE_CONNECTED,IQ4 Text for event WARNING_NEW_DEVICE_CONNECTED,
    'new device connected': 'nieuw apparaat verbonden',

    // IQ4 Text for event WARNING_MASK_NO_ACTIVE_SEGMENTS,IQ4 Text for event WARNING_LINKED_SENSOR_NO_ACTIVE_SEGMENTS,
    'no active sensor segments': 'geen actieve sensorsegmenten',

    // IQ4 Text for event WARNING_MASK_NO_COMM_PAIRED_DEVICE,IQ4 Text for event WARNING_LINKED_SENSOR_NO_COMM_PAIRED_DEVICE,
    'no communication with paired device': 'geen communicatie met gekoppeld apparaat',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'not': 'save',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'notes","Release': 'notes"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'object","Onbekend': 'object"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'or': 'AM/PM","24H',

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_LOW,IQ4 Text for event ALARM_MASK_LEVEL_TOO_LOW,
    'output curent too low': 'output stroom te laag',

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_HIGH,IQ4 Text for event ALARM_MASK_LEVEL_TOO_HIGH,
    'output current too high': 'output stroom te hoog',

    // IQ4 Text for event WARNING_MASK_OVERLOAD_HIGH_VOLTAGE,IQ4 Text for event ALARM_MASK_OVERLOAD_HIGH_VOLTAGE,IQ4 Text for event WARNING_HVOUT_OVERLOAD,IQ4 Text for event ALARM_HVOUT_OVERLOAD,
    'overload high voltage': 'uitgangspanning overbelast',

    // IQ4 Text for event WARNING_MASK_PAIRED_DEVICE_MISSING,IQ4 Text for event WARNING_LINKED_SENSOR_PAIRED_DEVICE_MISSING,
    'paired device missing': 'gekoppelde apparaat ontbreekt',

    // IQ4 Text for event WARNING_MASK_SENSOR_MISSING,IQ4 Text for event WARNING_LINKED_SENSOR_SEGMENT_MISSING,
    'paired sensor missing': 'gekoppelde sensor ontbreekt',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'parameter","Ongeldige': 'parameter"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'parameter","Parameter': 'bewerken"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'password': 'for',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'password","Wachtwoord': 'resetten"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'please': 'wait","Herstarten,',

    // IQ4 Text for event WARNING_MASK_POWER_SUPPLY_LOW,
    'power supply low': 'voedingsspanning laag',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'realtime': 'chart","Plot',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'related': 'object',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'release': 'notes","Toon',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'reset","Wachtwoord': 'reset"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'retrieving': 'recent',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'selection","Herhaal': 'selectie"',

    // IQ4 Text for event ALARM_SENSOR_MALFUNCTION,
    'sensor malfunction': 'sensor defect',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'setting","Bewerk': 'instelling"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'setting","Ongeldige': 'instelling"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'source': 'target',

    // IQ4 Text for event ALARM_SUPPLY_CURRENT_HIGH,IQ4 Text for event ALARM_MASK_HIGH_PRIM_CUR,IQ4 Text for event ALARM_PRIMARY_CURRENT_HIGH,
    'supply current too high': 'voedingsstroom te hoog',

    // IQ4 Text for event WARNING_SUPPLY_VOLTAGE_LOW,IQ4 Text for event WARNING_INPUT_VOLTAGE_LOW,
    'supply voltage too low': 'voedingsspanning te laag',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'system': 'time',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'table","Tabel': 'weergeven"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'test': 'testen',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'the': '+',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'time': 'zone","Huidige',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'title': 'for',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'to': 'update',

    // IQ4 Text for event ALARM_MASK_HIGH_TEMP,IQ4 Text for event ALARM_TEMPERATURE_HIGH,
    'too high temperature': 'te hoge temperatuur',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'updated': 'successfully","Gebruiker',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'updates","Beschikbare': 'updates"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'use","Intern': 'gebruik"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'user","Gebruiker': 'aanpassen"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'user","Verwijder': 'gebruiker"',

    // ../src/templates/user.html,
    'userlevel': 'gebruikersniveau',

    // ../src/templates/user.html,
    'username': 'gebruikersnaam',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'value","Huidige': 'waarde"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'value","Input': 'waarde"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'value","Maximum': 'waarde"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'value","Minimale': 'waarde"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'value","Output': 'waarde"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'value","Standaard': 'waarde"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'version","Beschikbare': 'versie"',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'version","Huidige': 'versie"',

    // IQ4 Text for event ALARM_WEB_VOLTAGE_OVERLOAD,
    'web voltage overload': 'te hoge webspanning',

    // IQ4 Text for event WARNING_WEB_VOLTAGE_HIGH_WARNING,IQ4 Text for event ALARM_WEB_VOLTAGE_HIGH_ERROR,
    'web voltage too high': 'webspanning te hoog',

    // IQ4 Text for event WARNING_WEB_VOLTAGE_LOW_WARNING,IQ4 Text for event ALARM_WEB_VOLTAGE_LOW_ERROR,
    'web voltage too low': 'webspanning te laag',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'will': 'reboot',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'you': 'sure',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'zoom","Zoom': 'opnieuw',

    // ====================================================
    // TODO: translate these
    // ====================================================
    // ../src/../src/core/state.js, 
    'Error saving the user preferences': 'Error saving the user preferences', 

    // ../src/../src/debugger/log.js, 
    'Retrieving logs, please wait': 'Retrieving logs, please wait', 

    // ../src/../src/debugger/log.js, ../src/../src/debugger/log.js, 
    'Failed to download logs': 'Failed to download logs', 

    // ../src/../src/debugger/testrunner.js, 
    'Error: could not find test': 'Error: could not find test', 

    // ../src/../src/debugger/debugger.js, 
    'A test is already being recorded': 'A test is already being recorded', 

    // ../src/../src/debugger/debugger.js, src/templates/debug-test.html, 
    'Stop recording': 'Stop recording', 

    // ../src/../src/debugger/debugger.js, src/templates/debug-test.html, 
    'Record new test': 'Record new test', 

    // ../src/../src/debugger/debugger.js, 
    'Are you sure you want to delete this test?': 'Are you sure you want to delete this test?', 

    // ../src/../src/views/setting.js, 
    'Failed to retrieve this CLFB\'s input device': 'Failed to retrieve this CLFB\'s input device', 

    // ../src/../src/views/action.js, 
    'Before exporting the Fieldbus configuration you first have to enable it.': 'Before exporting the Fieldbus configuration you first have to enable it.', 

    // ../src/../src/views/action.js, 
    ' Please check the device help for more information.': ' Please check the device help for more information.', 

    // ../src/../src/views/related.js, ../src/../src/views/related.js, 
    'You can not add a related object to a fieldbus while the fieldbus is running': 'You can not add a related object to a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'You can not remove a related object from a fieldbus while the fieldbus is running': 'You can not remove a related object from a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'You can not delete a related object from a fieldbus while the fieldbus is running': 'You can not delete a related object from a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'Can not delete unknown related object': 'Can not delete unknown related object', 

    // ../src/../src/views/parameter.js, 
    'Your number is less than the minimum value: ': 'Your number is less than the minimum value: ', 

    // ../src/../src/views/parameter.js, 
    'Your number is greater than the maximum value: ': 'Your number is greater than the maximum value: ', 

    // ../src/templates/user-edit.html, src/templates/settings-updates.html, 
    'Id': 'Id', 

    // ../src/templates/debug-test.html, src/templates/debug-test.html, 
    'New test name': 'New test name', 

    // ../src/templates/debug-test.html, src/templates/debug-test.html, 
    'Test': 'Test', 

    // ../src/templates/debug-test.html, 
    'Rename': 'Rename', 

    // ../src/templates/debug-test.html, 
    'Run recorded test selection': 'Run recorded test selection', 

    // ../src/templates/debug-report.html, 
    'You can send a report to our central database. This will include a snapshot of the current state and the full log. Please add a little description so we know how to interpret this.': 'You can send a report to our central database. This will include a snapshot of the current state and the full log. Please add a little description so we know how to interpret this.', 

    // ../src/templates/parameter.html, src/templates/clfb-io.html, 
    'Record changes': 'Record changes', 

    // ../src/templates/clfb-io.html, 
    'CLFB I/O': 'CLFB I/O', 

    // ../src/templates/user.html, 
    'id': 'id', 

    // ../src/templates/macros.html, 
    'Unknown input': 'Unknown input', 

    // ../src/templates/macros.html, 
    'Unknown output': 'Unknown output', 

    // ../src/templates/time.html, 
    '24H': '24H', 

    // ../src/templates/time.html, 
    'AM/PM': 'AM/PM', 

    // ../src/templates/active-users.html, 
    'Active user sessions': 'Active user sessions', 

    // ../src/templates/active-users.html, 
    'User name': 'User name', 

    // ../src/templates/active-users.html, 
    'User level': 'User level', 

    // ../src/templates/active-users.html, 
    'Go to dashboard': 'Go to dashboard', 

    // ../src/templates/log.html, 
    'Filter': 'Filter', 

    // ../src/templates/log.html, src/templates/settings-updates.html, src/templates/settings.html, 
    'Type': 'Type', 

    // ../src/templates/log.html, 
    'Code': 'Code', 

    // ../src/templates/customize-view.html, 
    'You can choose one of the pre-defined orders, or you can sort manually by dragging and dropping after selecting "sort manually".': 'You can choose one of the pre-defined orders, or you can sort manually by dragging and dropping after selecting "sort manually".', 

    // ../src/templates/settings-updates.html, src/templates/settings.html, 
    'UID': 'UID', 

    // ../src/templates/settings-updates.html, src/templates/settings.html, 
    'Release notes': 'Release notes', 

    // IQ4 Text for parameter CM_TINY_EEPROM_WRITE_COUNTS, IQ4 Text for parameter MPM_EEPROM_WRITE_COUNTS, 
    'Eeprom write counts': 'Eeprom write counts', 

    // IQ4 Text for parameter MPM_OPERATION_MODE, 
    'Operation mode (dipswitch 3)': 'Operation mode (dipswitch 3)', 

    // IQ4 Text for parameter MPM_REMOTE_ON_OFF_SOURCE, 
    'Remote on/off source (dipswitch 1)': 'Remote on/off source (dipswitch 1)', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT, 
    'Analog input (dipswitch 2)': 'Analog input (dipswitch 2)', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT_FUNCTION, 
    'Analog input function': 'Analog input function', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT_FEEDBACK, 
    'Analog input': 'Analog input', 

    // IQ4 Text for parameter MPM_CALIBRATE_ANALOG_INPUT_OFFSET, 
    'Calibrate analog input offset': 'Calibrate analog input offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_ANALOG_INPUT_GAIN, 
    'Calibrate analog input gain': 'Calibrate analog input gain', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_FEEDBACK, 
    'High voltage output': 'High voltage output', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_FEEDBACK_OFFSET, 
    'Calibrate output voltage feedback offset': 'Calibrate output voltage feedback offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_FEEDBACK_GAIN, 
    'Calibrate output voltage feedback gain': 'Calibrate output voltage feedback gain', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_FEEDBACK_OFFSET, 
    'Calibrate output current offset': 'Calibrate output current offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_FEEDBACK_GAIN, 
    'Calibrate output current gain': 'Calibrate output current gain', 

    // IQ4 Text for parameter MPM_MAX_QUANTITY_ARCS, 
    'Max. arcs per minute': 'Max. arcs per minute', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_SETPOINT_MAX, 
    'Max. output voltage': 'Max. output voltage', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_SETPOINT, 
    'Output voltage setpoint': 'Output voltage setpoint', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_SETPOINT_OFFSET, 
    'Calibrate output voltage setpoint offset': 'Calibrate output voltage setpoint offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_SETPOINT_GAIN, 
    'Calibrate output voltage setpoint gain': 'Calibrate output voltage setpoint gain', 

    // IQ4 Text for parameter MPM_CURRENT_LIMIT_SETPOINT, 
    'Max. output current': 'Max. output current', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_SETPOINT_OFFSET, 
    'Calibrate output current setpoint offset': 'Calibrate output current setpoint offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_SETPOINT_GAIN, 
    'Calibrate output current setpoint gain': 'Calibrate output current setpoint gain', 

    // IQ4 Text for parameter MPM_BALANCE_OFFSET, 
    'Balance offset': 'Balance offset', 

    // IQ4 Text for parameter GATEWAY_IQ4_WEB_DISTANCE_SEG1, 
    'Web distance segment 1': 'Web distance segment 1', 

    // IQ4 Text for parameter MPM_UNIQUE_DEVICE_ID, 
    'Unique device id': 'Unique device id', 

    // IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE_MIN, 
    'Power supply warning level min.': 'Power supply warning level min.', 

    // IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE_MAX, 
    'Power supply warning level max.': 'Power supply warning level max.', 

    // IQ4 Text for parameter MPM_AUTORUN, 
    'Autorun': 'Autorun', 

    // IQ4 Text for parameter MPM_FREE_RAM_CNTR, 
    'Free RAM counter': 'Free RAM counter', 

    // IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_MAX, 
    'Output voltage max': 'Output voltage max', 

    // IQ4 Text for setting CLFB_ACTIVESEGMENTS, 
    'Active Segments': 'Active Segments', 

    // IQ4 Text for event WARNING_NO_SEGMENTS_SELECTED, 
    'No sensor segments selected': 'No sensor segments selected', 

    // IQ4 Text for event DEVICE_DISCONNECTED, 
    'Device disconnected': 'Device disconnected', 

    // IQ4 Text for event DEVICE_DISABLED, 
    'Device disabled': 'Device disabled', 

    // IQ4 Text for event ALARM_INPUT_VOLTAGE_HIGH, 
    'supply voltage too high': 'supply voltage too high', 

    // IQ4 Text for event ALARM_AIR_PRESSURE_HIGH, 
    'air pressure too high': 'air pressure too high', 

};

export function getTranslations() {
    return translations;
}