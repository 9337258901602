/**
 * Parameter view module.
 * @module parameter
 */

"use strict";

import * as Actions from '../core/actions';
import * as Api from '../core/api';
import {DeviceView} from './device';
import * as Gui from '../core/gui';
import * as Help from '../core/help';
import * as Helpers from '../core/helpers';
import * as Debug from '../core/debug';
import * as Permissions from '../core/permissions';
import * as Signals from '../core/signals';
import * as State from '../core/state';

export class ParameterView {
    constructor() {
        this.viewName = 'ParameterView';
        this.device = null;
        this.parameter = null;
        this.actionHandlerIds = [];
        this.listenerIds = [];
        this.attachedToFieldbus = false;
        this.recording = false;
        this.recordedData = [];
        this.paramMeta = null;
    }

    destructor() {
        Actions.removeActionHandlers(this.actionHandlerIds);
        Signals.removeListeners(this.listenerIds);
    }

    onMessage(msg) {
        if(
            msg.tag === 'device/parameter' &&
            parseInt(msg.data.device) === parseInt(this.device.uid) &&
            parseInt(msg.data.qid) === parseInt(this.parameter.qid)
        ){
            this.parameter.currentValue = msg.data.value;

            Gui.switchDeviceParameter(
                this.device.uid,
                this.parameter,
                msg.data.value
            );

            if(this.recording) {
                this.recordedData.push([
                    msg.data.timestamp,
                    msg.data.value,
                ]);
            }
        }
    }

    toggleHelp() {
        Gui.toggleHelpSection();
    }

    closeHelp() {
        Gui.closeHelpSection();
    }

    editParameter(actionId, args) {
        Debug.log('Start editing parameter with uid-qid: ' + actionId);
        const qid = parseInt(actionId.split('-')[1]);
        const currentValue = this.parameter.currentValue;
        Gui.editParameter(actionId, currentValue);
    }

    saveParameter(actionId, args) {
        const qid = parseInt(actionId.split('-')[1]);
        if(qid !== this.parameter.qid){
            Gui.showError(actionId, _('Invalid parameter'));
            return;
        }

        let value = args.value;

        if(this.parameter.type === 'number'){
            value = parseFloat(args.value);
            if(isNaN(value)){
                Gui.showError(actionId, _('Please input a valid number'));
                return;
            }

            if(
                Helpers.definedNotNull(this.parameter.minValue) &&
                value < this.parameter.minValue
            ){
               Gui.showError(actionId, _('Your number is less than the minimum value: ') + this.parameter.minValue);
               return;
            }

            if(
                Helpers.definedNotNull(this.parameter.maxValue) &&
                value > this.parameter.maxValue
            ){
               Gui.showError(actionId, _('Your number is greater than the maximum value: ') + this.parameter.maxValue);
               return;
            }
        }

        if(this.parameter.type === 'boolean') {
            if(value === 'true'){
                value = 1;
            } else {
                value = 0;
            }
        }

        Api.setParameter(this.device.uid, this.parameter.name, value).then(() => {
            this.parameter.currentValue = value;
            Gui.switchDeviceParameter(
                this.device.uid,
                this.parameter,
                value
            );
            Gui.stopEditingParameter(actionId);
            Gui.hideError(actionId);
        }).catch((err) => {
            Debug.log('Error when setting a new parameter value', 3);
            Debug.log(err, 3);
            Gui.showError(actionId, _('Could not save the new value'));
        });
    }

    cancelEditParameter(actionId, args) {
        Debug.log('Cancel parameter edit with uid-qid: ' + actionId, 3);
        Gui.stopEditingParameter(actionId);
    }

    record(actionId, args, elem) {
        if(this.recording) {
            Debug.log('Finish recording, uid-qid: ' + actionId);
            this.recording = false;
            Debug.log(this.recordedData);

            const timeZoneOffset = State.getTimeZoneOffset();
            const use24Hours = State.get24HoursIsPreferred();

            this.recordedData.map(x => {
                x.unshift(
                    Helpers.formatDateTime(
                        x[0], timeZoneOffset, use24Hours, false
                    )
                );
            });

            let csv = Helpers.generateCsv(
                ['Date/time', 'Timestamp', 'Value'],
                this.recordedData
            );

            this.recordedData = [];
            $(elem).html('Record changes');
            $(elem).removeClass('btn-recording').addClass('btn-record');

            return saveAs(
                new File([csv],
                    'changes-' + Helpers.makeFileName(this.parameter.name) + '.csv',
                    {type: 'text/csv;charset=utf-8'}
                )
            );
        } else {
            this.recording = true;
            Debug.log('Record parameter changes, uid-qid: ' + actionId);

            $(elem).html('Stop recording and download data');
            $(elem).removeClass('btn-record').addClass('btn-recording');
        }
    }

    registerActionHandlers(){
        Debug.log('Register action handlers', 0, false);

        const id = this.device.uid + '-' + this.parameter.qid;

        if(Permissions.canEditParameter(this.parameter)){
            this.actionHandlerIds.push(
                Actions.registerActionHandler(
                   id, 'edit-parameter', this.editParameter.bind(this)
                )
            );

            this.actionHandlerIds.push(
                Actions.registerActionHandler(
                   id, 'save-parameter', this.saveParameter.bind(this)
                )
            );

            this.actionHandlerIds.push(
                Actions.registerActionHandler(
                    id, 'cancel-parameter', this.cancelEditParameter.bind(this)
                )
            );
        }

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'toggle-help', this.toggleHelp.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'close-help', this.closeHelp.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'record', this.record.bind(this)
            )
        );

    }

    /**
     * Initialization, retrieves the device info from the api
     * and sets up all the instance variables. This function is used
     * in the render and edit functions
     * @param {string} uid - the device UID
     * @param {string} qid - the parameter QID
     * @param {function} callback - called when everything is
     *                              initialized successfully
     * @private
     */
    initialize(uid, qid, callback) {
        Api.getDevice(uid).then((device) => {
            this.device = device;
            const numericQid = parseInt(qid);
            Debug.log('Device info:', 0);
            Debug.log(this.device, 0);

            for(let i = 0; i < this.device.parameters.length; i++){
                const p = this.device.parameters[i];
                if(p.qid === numericQid){
                    this.parameter = p;
                    this.paramMeta = Helpers.getParameter(this.device, p.qid);
                    break;
                }
            }

            for(let relatedObject of this.device.relatedObjects) {
                if(relatedObject.type === 'fieldbus') {
                    this.attachedToFieldbus = true;
                    break;
                }
            }

            if(this.parameter === null){
                return Gui.show404();
            }

            let deviceState = State.getDevice(device.uid);

            device.position = deviceState.position;
            device.name = deviceState.name;

            this.listenerIds.push(Signals.listenToDevice(
                this.device.uid,
                this.onMessage.bind(this)
            ));

            this.listenerIds.push(Signals.addListener(
                'object/removed',
                this.objectRemoved.bind(this)
            ));

            this.registerActionHandlers();
            callback();
        }).catch((e) => {
            Debug.log('Failed to render parameter screen', 3);
            Debug.log(e, 3);
            return Gui.show404();
        });
    }

    /**
     * Handler for when this action's IQ object is removed
     */
    objectRemoved(msg) {
        if(
            !msg.hasOwnProperty('data') ||
            !msg.data.hasOwnProperty('uid') ||
            msg.data.uid !== this.device.uid
        ) {
            return false;
        }
        window.visit('/#/');
    }

    /**
     * Render this parameter view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args, editing=false) {
        if(!args.hasOwnProperty('uid') || !args.hasOwnProperty('qid')){
            return Gui.show404();
        }

        Debug.log(
            'Render parameter view for device ' +
            args.uid +
            ', and parameter ' +
            args.qid
        );

        this.initialize(args.uid, args.qid, () => {
            Debug.log('Parameter details');
            Debug.log(this.parameter);
            let helpPath = 'Operation/Parameter_info';
            if(editing) {
                helpPath = 'Operation/Parameter_edit';
            }

            let context = {
                'device': this.device,
                'parameter': this.parameter,
                'edit': editing,
                'attachedToFieldbus': this.attachedToFieldbus,
                'helpPath': helpPath,
                'helpText': '',
                'supportsVisualization': this.parameter.viewAs.type === 'number' || (!this.parameter.viewAs.type && this.parameter.type === 'number'),
                'hasRealtimeVisualization': Helpers.optChain(this.paramMeta, 'hasRealtimeVisualization'),
                'hasHistoricVisualization': Helpers.optChain(this.paramMeta, 'hasHistoricVisualization'),
                'visualizationType': Helpers.optChain(this.paramMeta, 'visualizationType'),
            };

            // Editing the Enabled Segments parameter requires some extra care.
            // We want to offer the user a list of toggleable checkboxes in
            // that case. Which checkboxes we show is based on the available
            // segments
            if(this.parameter.viewAs.type === 'segments') {
                context.enabledSegments = Helpers.numberToBitArray(
                    this.parameter.currentValue
                );

                if(this.parameter.name.endsWith('ENABLED_SEGMENTS')) {
                    for(const param of this.device.parameters) {
                        if(param.name.endsWith('AVAILABLE_SEGMENTS')) {
                            let availableSegmentsInt = param.currentValue;
                            let minWidth = Helpers.numberToBitArray(param.maxValue).length;
                            context.availableSegments = Helpers.numberToBitArray(
                                availableSegmentsInt,
                                minWidth
                            );
                            break;
                        }
                    }
                } else {
                    context.availableSegments = Helpers.numberToBitArray(this.parameter.maxValue);
                }

                // The value is saved as big endian, but shown as little
                // endian, so we need to reverse it before passing it to the
                // template
                context.enabledSegments.reverse();
                context.availableSegments.reverse();
            }

            Help.parameter(this.device, this.parameter).then((helpText) => {
                context.helpText = helpText;
            }).catch(() => {}).finally(() => {
                const fieldbusInstruction = Help.fieldbusInstruction(this.device, this.parameter);
                if(fieldbusInstruction) {
                    context.helpText += '\n\n---\n\n## Fieldbus instructions\n\n' + fieldbusInstruction;
                }
                Gui.render('parameter.html', context);

                if(
                    editing &&
                    (!this.attachedToFieldbus || !this.parameter.fieldbusAvailable)
                ) {
                    $('form.parameter').show();
                    $('form.parameter input').each((i, el) => {
                        if($(el).attr('autofocus')){
                            const tmpStr = $(el).val();
                            $(el).focus().val('').val(tmpStr);
                        }
                    });
                }
            });

        });
    }

    /**
     * Render the edit view for this parameter
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    edit(event, args) {
        this.render(event, args, true);
    }
}
