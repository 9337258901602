/**
 * Help view module.
 * @module help
 */

"use strict";

import * as Gui from '../core/gui';
import * as Help from '../core/help';
import * as Helpers from '../core/helpers';
import * as Debug from '../core/debug';

export class HelpView {
    constructor() {
        this.viewName = 'HelpView';
    }

    destructor() {}

    /**
     * Render this help view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args, editing=false) {
        let section1 = args.section1;
        let section2 = args.section2;

        if(section1) {
            section1 = Helpers.replaceAll(section1, '_', ' ');
        }

        if(section2) {
            section2 = Helpers.replaceAll(section2, '_', ' ');
        }
        let helpItem = false;

        Help.getGlobalHelpFile(section1, section2).then((data) => {
            helpItem = data;
            let context = {
                helpItem: helpItem,
                helpItems: window.CONSTANTS.GLOBAL_HELP,
                section1: section1,
                section2: section2,
            };
            Gui.render('help.html', context);
        }).catch((e) => {
            Gui.render('404.html');
        });
    }
}
