/**
 * Time view module.
 * @module time
 */

"use strict";

import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as Constants from '../core/constants';
import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Messages from '../core/messages';
import * as Permissions from '../core/permissions';
import * as Session from '../core/session';
import * as Signals from '../core/signals';
import * as State from '../core/state';

export class TimeView {
    constructor() {
        this.viewName = 'LoginView';
        this.actionHandlerIds = [];
        this.listenerIds = [];
    }

    destructor() {
        Actions.removeActionHandlers(this.actionHandlerIds);
        Signals.removeListeners(this.listenerIds);
    }

    setTime(actionId, args) {
        const offset = '0000';
        const newDate = Date.parse(args.date + 'T' + args.time + '+' + offset);

        Api.setSystemTime(newDate).then(() => {
            Messages.addMessage(_('The system time was changed successfully'), 'success', 3000);
        }).catch((e) => {
            Messages.addMessage(_('Error changing the system time'), 'error');
        });
    }

    setLocalSettings(actionId, args) {
        State.setTimeZoneOffset(args.timezone);
        State.setUse24hours(args['ampm-24'] === '24h');
        Messages.addMessage(_('The local time settings were changed successfully'), 'success', 3000);
        $('p.pretty-timezone').html(Constants.TIME_ZONES[args.timezone]);
    }

    updateTime(msg) {
        $('time.system-time-utc').html(State.getPrettyTime(false));
    }

    initialize() {
        const canSetTime = Permissions.hasGlobalExecutePermission('set-system-time');
        this.listenerIds.push(Signals.addListener('internal/minute-passed', this.updateTime));

        const date = new Date(State.getTime());

        if(canSetTime) {
            this.actionHandlerIds.push(
                Actions.registerActionHandler(
                   'set-time', 'set-time', this.setTime.bind(this)
                )
            );

        }

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
               'set-local-time-settings', 'set-local-time-settings', this.setLocalSettings.bind(this)
            )
        );

        const timeValue = (
            Helpers.padZero(date.getHours().toString(), 2) +
            ':' +
            Helpers.padZero(date.getMinutes().toString(), 2)
        );

        const dateValue = (
            date.getFullYear() +
            '-' +
            Helpers.padZero((date.getMonth() + 1).toString(), 2) +
            '-' +
            Helpers.padZero(date.getDate().toString(), 2)
        );

        Gui.render('time.html', {
            'canSetTime': canSetTime,
            'currentTime': State.getPrettyTime(),
            'currentUtcTime': State.getPrettyTime(false),
            'timeValue': timeValue,
            'dateValue': dateValue,
            'currentTimezone': State.getTimeZoneOffset(),
            'use24hours': State.get24HoursIsPreferred(),
            'TIME_ZONES': Constants.TIME_ZONES,
        });
    }

    /**
     * Render this time view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args) {
        // If we don't know the system time yet, we wait until we do
        if(State.getTime() === -1) {
            Gui.render('loading.html');
            const intervalId = window.setInterval(() => {
                if(State.getTime() !== -1) {
                    clearInterval(intervalId);
                    this.initialize();
                }
            }, 100);
        } else {
            this.initialize();
        }
    }
}
