/**
 * Widget
 * This is the base class for all widgets.
 * @module widget
 */

"use strict";

import * as Helpers from '../core/helpers';

export class Widget {
    constructor() {
        this.id = Helpers.shortRandomId();
        this.context = {
            iqObject: null,
            property: null,
            propertyMeta: null
        };
    }

    destructor() {
        delete this.context;
    }

    /**
     * @param {object} context - add context to this widget, specifying the
     *     iqObject, property and propertyMeta. The property is for example the
     *     setting, action or parameter. PropertyMeta is an object with metadata.
     *     You can find examples of these meta objects in public/js/constants.js
     *     window.CONSTANTS.PARAMETERS
     */
    addContext(context) {
        this.context = context;
    }

    render() {
        this.parentElem = $('section.widget[data-widget-id=' + this.id + ']');
    }

    addToDom(htmlStr) {
        this.parentElem.html(htmlStr);

        if(this.context.hasOwnProperty('propertyMeta') && this.context.propertyMeta !== null) {
            const width = Helpers.clamp(
                Helpers.optChain( this.context, 'propertyMeta', 'dashboard', 'width') || 2,
                1, 12
            );

            const height = Helpers.clamp(
                Helpers.optChain( this.context, 'propertyMeta', 'dashboard', 'width') || 2,
                1, 12
            );

            this.parentElem.attr('data-width', width);
            this.parentElem.attr('data-height', height);
            this.parentElem.attr('data-min-dimension', Helpers.minimum(width, height));
        }

    }

    resetSize() {}

    update() {}

}
