/**
 * Debug State Module
 * @module DebugState
 */

"use strict";

let internalsHtml = false;

export function printState(state) {
    let html = '';

    html += '<h2>Devices</h2>';
    html += '<table cellspacing="5" cellpadding="5" class="debug-table responsive">';
    html += '<thead><th>UID</th><th>Info</th><th>Name</th><th>Position</th><th>Mode</th><th>Warnings</th><th>Errors</th></thead>';
    html += '<tbody>';

    for(let device of state.devices) {
        html += '<tr>';
        html += '<td>' + device.uid + '</td>';
        html += '<td><a href="/#/device/' + device.uid + '">' + device.info + '</a></td>';
        html += '<td>' + device.name + '</td>';
        html += '<td>' + device.position + '</td>';
        html += '<td class="mode-' + device.status.mode + '">' + device.status.mode + '</td>';
        html += '<td class="warnings-' + device.status.warnings + '">' + device.status.warnings + '</td>';
        html += '<td class="errors-' + device.status.errors + '">' + device.status.errors + '</td>';
        html += '</tr>';
    }
    html += '</tbody></table>';

    html += '<h2>Fieldbuses</h2>';
    html += '<table cellspacing="5" cellpadding="5" class="debug-table debug-fieldbuses responsive">';
    html += '<thead><th>UID</th><th>Info</th><th>Name</th><th>Mode</th><th>Warnings</th><th>Errors</th></thead>';
    html += '<tbody>';

    for(let fieldbus of state.fieldbuses) {
        html += '<tr>';
        html += '<td>' + fieldbus.uid + '</td>';
        html += '<td><a href="/#/fieldbus/' + fieldbus.uid + '">' + fieldbus.info + '</a></td>';
        html += '<td>' + fieldbus.name + '</td>';
        html += '<td class="mode-' + fieldbus.status.mode + '">' + fieldbus.status.mode + '</td>';
        html += '<td class="warnings-' + fieldbus.status.warnings + '">' + fieldbus.status.warnings + '</td>';
        html += '<td class="errors-' + fieldbus.status.errors + '">' + fieldbus.status.errors + '</td>';
        html += '</tr>';
    }
    html += '</tbody></table>';


    html += '<h2>Clfbs</h2>';
    html += '<table cellspacing="5" cellpadding="5" class="debug-table debug-clfbs responsive">';
    html += '<thead><th>UID</th><th>Name</th><th>Mode</th><th>Warnings</th><th>Errors</th></thead>';
    html += '<tbody>';

    for(let clfb of state.clfbs) {
        html += '<tr>';
        html += '<td>' + clfb.uid + '</td>';
        html += '<td><a href="/#/clfb/' + clfb.uid + '">' + clfb.name + '</a></td>';
        html += '<td class="mode-' + clfb.status.mode + '">' + clfb.status.mode + '</td>';
        html += '<td class="warnings-' + clfb.status.warnings + '">' + clfb.status.warnings + '</td>';
        html += '<td class="errors-' + clfb.status.errors + '">' + clfb.status.errors + '</td>';
        html += '</tr>';
    }
    html += '</tbody></table>';

    html += '<h2>Internals</h2>';
    html += '<table cellspacing="5" cellpadding="5" class="debug-table debug-internals responsive">';
    html += '<thead><th>Source</th><th>Property</th><th>Value</th></thead>';
    html += '<tbody>';

    /* jshint ignore:start */
    for(let property in window.navigator){
        try {
            html += (
                '<tr><td>Navigator</td><td>' +
               property +
               '</td><td>' +
               navigator[property] +
               '</td></tr>'
            );
        } catch(e) {}
    }

    for(let property in window.screen){
        try {
            html += (
                '<tr><td>Screen</td><td>' +
               property +
               '</td><td>' +
               window.screen[property] +
               '</td></tr>'
            );
       } catch(e) {}
    }
    /* jshint ignore:end */

    html += '</tbody>';
    html += '</table>';

    $('div.debug-state').html(html);
}
