/**
 * Clfb view module.
 * @module clfb
 */

"use strict";

import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Help from '../core/help';
import * as Helpers from '../core/helpers';
import * as Permissions from '../core/permissions';
import * as Signals from '../core/signals';
import * as State from '../core/state';
import {RelatedView} from './related';

export class ClfbView {
    constructor() {
        this.viewName = 'ClfbView';
        this.clfb = null;
        this.listenerIds = [];
        this.actionHandlerIds = [];
        this.fieldbusListenerId = -1;
        this.fieldbusId = -1;
        this.loadingTimeout = -1;
        this.relatedView = null;
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);

        if(this.relatedView !== null) {
            this.relatedView.destructor();
        }

        if(this.fieldbusListenerId !== -1) {
            Signals.removeListener(this.fieldbusListenerId);
        }

        Actions.removeActionHandlers(this.actionHandlerIds);
        window.clearTimeout(this.loadingTimeout);
    }

    onStatusChange(msg) {
        if(msg.data.uid !== this.clfb.uid){
            return;
        }
        this.clfb.status = msg.data.status;
        Gui.disableLoadingIcons();
        window.clearTimeout(this.loadingTimeout);

        Gui.switchClfbValue(
            this.clfb.uid,
            'input',
            this.clfb.status.inputValue
        );

        Gui.switchClfbValue(
            this.clfb.uid,
            'output',
            this.clfb.status.outputValue
        );
    }

    relatedChange(msg) {
        if(msg.data.uid === this.clfb.uid) {
            this.refreshClfb();
        }
    }

    refreshFieldbusStatus() {
        if(this.clfb.relatedObjects === 0){
            if(this.fieldbusListener !== -1) {
                Signals.removeListener(this.fieldbusListener);
                this.fieldbusListener = -1;
                this.fieldbusId = -1;
            }
            return;
        }

        for(let i of this.clfb.relatedObjects) {
            let newFieldbusId = -1;

            if(i.type !== 'fieldbus') {
                continue;
            }

            newFieldbusId = i.uid;

            if(newFieldbusId !== -1 && newFieldbusId !== this.fieldbusId) {
                this.fieldbusId = newFieldbusId;

                if(this.fieldbusListener !== -1) {
                    Signals.removeListener(this.fieldbusListener);
                    this.fieldbusListener = -1;
                }

                this.fieldbusListenerId = Signals.addListener(
                    'fieldbus/status',
                    this.fieldbusStatusChanged.bind(this)
                );
            }
            break;
        }

    }

    fieldbusStatusChanged(msg) {
        if(msg.data.uid !== this.fieldbusId) {
            return;
        }
        this.refreshClfb();
    }

    refreshClfb() {
        return window.visit('/#/clfb/' + this.clfb.uid);
    }

    onSettingsChange(msg) {
        if(msg.data.uid !== this.clfb.uid) {
            return;
        }
        this.clfb.settings = msg.data.settings;

        for(let setting in this.clfb.settings) {
            if(!this.clfb.settings.hasOwnProperty(setting)) {
                continue;
            }

            Gui.switchSetting(
                this.clfb.uid,
                setting,
                this.clfb.settings[setting].currentValue
            );
        }
    }

    clfbEnableDisable(actionId, args, elem) {
        let actionName = '';

        if(this.clfb.status.mode === 'disabled') {
            if(!Permissions.canPerformAction(this.clfb, 'enable')){
                return;
            }
            actionName = 'enable';
        }
        else {
            if(!Permissions.canPerformAction(this.clfb, 'disable')){
                return;
            }
            actionName = 'disable';
        }

        Gui.disableLoadingIcons();
        window.clearTimeout(this.loadingTimeout);
        Gui.enableLoadingIcon(elem);

        Api.executeClfbAction(this.clfb.uid, actionName).then(() => {
            this.loadingTimeout = window.setTimeout(
                Gui.disableLoadingIcons,
                10000
            );
        }).catch((err) => {
            Gui.disableLoadingIcon(elem);
        });
    }

    toggleHelp() {
        Gui.toggleHelpSection();
    }

    closeHelp() {
        Gui.closeHelpSection();
    }

    registerActionHandlers() {
        Debug.log('Register action handlers', 0, false);

        const id = this.clfb.uid;

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
               id, 'clfb-enable-disable', this.clfbEnableDisable.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'toggle-help', this.toggleHelp.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                id, 'close-help', this.closeHelp.bind(this)
            )
        );
    }

    objectRemoved(msg) {
        if(
            !msg.hasOwnProperty('data') ||
            !msg.data.hasOwnProperty('uid') ||
            msg.data.uid !== this.clfb.uid
        ) {
            return false;
        }
        window.visit('/#/');
    }

    render(event, args) {
        Debug.log('Render view for clfb: ' + args.uid);

        if(!args.hasOwnProperty('uid')){
            Debug.log('Invalid clfb url, no UID given', 3);
            return Gui.show404();
        }

        Api.getClfb(args.uid).then((clfb) => {
            Debug.log('Retrieved clfb');
            Debug.log(clfb);
            this.clfb = clfb;
            this.relatedView = new RelatedView(this.clfb);
            this.registerActionHandlers();

            this.listenerIds.push(Signals.addListener(
                'clfb/status',
                this.onStatusChange.bind(this)
            ));

            this.listenerIds.push(Signals.addListener(
                'clfb/settings',
                this.onSettingsChange.bind(this)
            ));

            this.listenerIds.push(Signals.addListener(
                'object/removed',
                this.objectRemoved.bind(this)
            ));

            this.listenerIds.push(Signals.addListener(
                'object/relatedchange',
                this.relatedChange.bind(this)
            ));

            let connectedCount = 0;

            if(this.clfb.settings.inputUid.currentValue) {
                connectedCount++;
            }

            if(this.clfb.settings.outputUid.currentValue) {
                connectedCount++;
            }

            // Add fieldbuses to connected count
            connectedCount += this.clfb.relatedObjects.length;

            this.clfb.settings = Helpers.sortSettings(this.clfb, this.clfb.settings);
            this.refreshFieldbusStatus();

            let context = {
                'clfb': this.clfb,
                'connectedCount': connectedCount,
                'helpText': ''
            };

            Help.clfb().then((helpText) => context.helpText = helpText)
            .catch(() => {}).finally(() => {
                Gui.render('clfb.html', context);
                this.relatedView.render();
            });
        }).catch((e) => {
            Debug.log('Error while rendering CLFB screen', 3);
            Debug.log(e, 3);
            return Gui.soft404(_('Could not find CLFB'));
        });
    }
}
