"use strict";

let translations = {
    // ../src/templates/time.html,
    '24H or AM/PM': '24H o AM/PM',

    // IQ4 Text for event WARNING_MASK_24V_OUTPUT_OVERLOAD,
    '24v output overload': 'Sovraccarico uscita 24V',

    // ../src/templates/chart.html,
    '48h chart': 'Grafico a 48h',

    // ../src/templates/log.html,
    'Actions': 'Azioni',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,src/templates/setting.html,
    'Actual value': 'Valore attuale',

    // ../src/templates/user.html,
    'Add new user': 'Aggiungere un nuovo utente',

    // ../src/templates/related-add.html,
    'Add related objects': 'Aggiungi oggetti correlati',

    //     // IQ4 Text for parameter CLEANFLEX_AIR_PRESSURE,IQ4 Text for parameter BLOWFLEX_AIR_PRESSURE,
    'Air pressure': 'Pressione dell\'aria',

    // IQ4 Text for parameter CLEANFLEX_TRIGGER_LEVEL,IQ4 Text for parameter BLOWFLEX_TRIGGER_LEVEL,
    'Air pressure trigger level high voltage': 'Livello alta tensione attivazione pressione aria',

    // IQ4 Text for parameter GATEWAY_ALARM_SETPOINT,IQ4 Text for parameter GATEWAY_EX_ALARM_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_ALARM_SETPOINT,
    'Alarm level, measured value over maximum': 'Livello di allarme, valore misurato sul massimo',

    // IQ4 Text for parameter GATEWAY_ALARM_MINIMUM,IQ4 Text for parameter GATEWAY_EX_ALARM_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_ALARM_MINIMUM,
    'Alarm level, measured value under minimum': 'Livello di allarme, valore misurato sotto il minimo',

    // ../src/templates/log.html,
    'Alarms': 'Allarmi',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_VALUE,
    'Ambient temperature': 'Temperatura ambiente',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_AVAILABLE,
    'Ambient temperature sensor available': 'Sensore temperatura ambiente disponibile',

    // ../src/../src/views/related.js,
    'An error occured while creating a new CLFB': 'Si è verificato un errore durante la creazione di un nuovo CLFB',

    // IQ4 Text for parameter PERFORMAX_ANALOG_CLEANBAR_ENABLED,IQ4 Text for parameter EASION_ANALOG_CLEANBAR_ENABLED,IQ4 Text for parameter EASION_ANALOG_CLEANBAR_MODE,IQ4 Text for parameter PERFORMAX_ANALOG_CLEANBAR_MODE,
    'Analog cleanbar enabled': 'Pulizia barra analogica attivata',

    // IQ4 Text for parameter CM_TINY_ARC_DETECT_COUNTER,IQ4 Text for parameter IQ_COM_ARC_DETECT_COUNTER,IQ4 Text for parameter MPM_ARC_DETECT_COUNTER,
    'Arcs per minute counter': 'Contatore archi al minuto',

    // ../src/templates/user-delete.html,
    'Are you sure you want to delete the user': 'Sei sicuro di voler eliminare questo utente?',

    // IQ4 Text for parameter GATEWAY_AVAILABLE_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_AVAILABLE_SEGMENTS,
    'Available Sensor segments': 'Sensore di segmenti disponibile',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Available updates': 'Aggiornamenti disponibili',

    // ../src/templates/settings-updates.html,
    'Available version': 'Versione disponibile',

    // ../src/templates/device.html,
    'Back to top': 'Torna all\'inizio',

    //     // IQ4 Text for setting CLFB_NAME,
    'CLFB name': 'Nome del CLFB',

    // Action title for calibrate,
    'Calibrate bar': 'Calibrazione barra',

    // ../src/../src/views/related.js,
    'Can not delete CLFB output': 'Impossibile eliminare l\'uscita CLFB',

    // ../src/templates/parameter.html,src/templates/setting.html,
    'Cancel': 'Cancella',

    // IQ4 Text for parameter CMME_CHARGE_SINGLE_SHOT_TIME,IQ4 Text for parameter IQ_COM_CHARGE_SINGLE_SHOT_TIME,
    'Charge pulse single shot': 'Carica impulso singolo colpo',

    // ../src/templates/settings-install-updates-error.html,src/templates/settings.html,
    'Check available updates': 'Verifica la disponibilità di aggiornamenti',

    // IQ4 Text for parameter PERFORMAX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter EASION_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter THUNDERION2_CLEAN_BAR_WARNING_LEVEL,
    'Clean bar warning level': 'Allarme pulizia barra',

    // Action title for clear,
    'Clear action log': 'Cancella registro azioni',

    // ../src/../src/views/time.js,src/templates/clfb.html,src/templates/parameter.html,src/templates/device-dashboard.html,src/templates/dashboard.html,src/templates/device.html,src/templates/fieldbus.html,src/templates/setting.html,src/templates/action.html,src/templates/log-table.html,
    'Close': 'Chiudi',

    // ../src/templates/log.html,
    'Code': 'Codice',

    // ../src/templates/connecting.html,
    'Connecting to websocket, please wait.': 'Connessione alla presa web, attendere prego.',

    // ../src/templates/log-table.html,
    'Could not retrieve log': 'Impossibile recuperare il logfile',

    // ../src/../src/views/setting.js,
    'Could not save the new settings': 'Impossibile salvare le nuove impostazioni',

    // ../src/../src/views/parameter.js,
    'Could not save the new value': 'Impossibile salvare il nuovo valore',

    // Action title for create-related,
    'Create related object': 'Crea oggetto correlato',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_SETPOINT,
    'Current Control current setpoint': 'Impostazione corrente per modalità Controllo Corrente',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_LIMIT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_LIMIT_SETPOINT,
    'Current Control output voltage limit': 'Limite tensione di uscita modalità Controllo Corrente',

    // ../src/templates/time.html,
    'Current system time in UTC': 'Ora attuale di sistema in UTC',

    // ../src/templates/time.html,
    'Current time zone': 'Fuso orario corrente',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Current version': 'Versione attuale',

    // IQ4 Text for parameter CMME_CYCLE_OK_HIGH_DELAY_THRESHOLD,
    'Cycle OK high delay': 'Ciclo OK alto ritardo',

    // IQ4 Text for parameter CMME_CYCLE_OK_LOW_LEVEL_THRESHOLD_MNGR4,
    'Cycle OK low level': 'Ciclo OK basso livello',

    // ../src/templates/time.html,src/templates/log.html,
    'Date': 'Data',

    // ../src/templates/debug.html,
    'Debug': 'Correggi',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,
    'Default value': 'Valore predefinito',

    // ../src/templates/debug-test.html,src/templates/user.html,
    'Delete': 'Elimina',

    // Action title for delete-related,
    'Delete related object': 'Elimina oggetto correlato',

    // ../src/templates/user-delete.html,
    'Delete user': 'Elimina utente',

    // IQ4 Text for setting CLFB_DERIVATIVEGAIN,
    'Derivative Gain': 'Guadagno Derivativo',

    // ../src/templates/debug-report.html,
    'Description': 'Descrizione',

    // IQ4 Text for parameter CM_TINY_DEVICE_NAME,IQ4 Text for parameter CMME_DEVICE_NAME,IQ4 Text for parameter IQ_COM_DEVICE_NAME,IQ4 Text for parameter GATEWAY_DEVICE_NAME,IQ4 Text for parameter GATEWAY_EX_DEVICE_NAME,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_NAME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_DEVICE_NAME,IQ4 Text for parameter EASION_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_EX_DEVICE_NAME,IQ4 Text for parameter THUNDERION2_DEVICE_NAME,IQ4 Text for parameter VICINION_DEVICE_NAME,IQ4 Text for parameter CLEANFLEX_DEVICE_NAME,IQ4 Text for parameter BLOWFLEX_DEVICE_NAME,IQ4 Text for parameter MPM_DEVICE_NAME,IQ4 Text for parameter PERFOMASTER_DEVICE_NAME,
    'Device name': 'Nome del dispositivo',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE,IQ4 Text for parameter CMME_DEVICE_TYPE,IQ4 Text for parameter IQ_COM_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_EX_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE,IQ4 Text for parameter EASION_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE,IQ4 Text for parameter VICINION_DEVICE_TYPE,IQ4 Text for parameter CLEANFLEX_DEVICE_TYPE,IQ4 Text for parameter BLOWFLEX_DEVICE_TYPE,IQ4 Text for parameter MPM_DEVICE_TYPE,
    'Device type': 'Tipo del dispositivo',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE_VERSION,IQ4 Text for parameter CMME_DEVICE_TYPE_VERSION,IQ4 Text for parameter IQ_COM_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE_VERSION,IQ4 Text for parameter EASION_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE_VERSION,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE_VERSION,
    'Device type version': 'Versione del dispositivo',

    // Action title for disable,
    'Disable': 'Disabilita',

    // Action title for remove,
    'Disconnect': 'Disconnetti',

    // ../src/templates/log.html,
    'Dismiss log': 'Disconnetti log',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_AVAILABLE,
    'Distance sensor available': 'Sensore a distanza disponibile',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_MALFUNCTION,
    'Distance sensor malfunction': 'Malfunzionamento del sensore a distanza',

    // IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE_OVERRIDE,
    'Distance sensor override': 'Sovrascrivere il sensore a distanza',

    //     // ../src/templates/user.html,
    'Edit': 'Modifica',

    // ../src/templates/macros.html,
    'Edit parameter': 'Modifica i parametri',

    // Action title for edit-related,
    'Edit related objects': 'Modifica oggetti correlati',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Edit setting': 'Modifica impostazioni',

    // ../src/templates/user-edit.html,
    'Edit user': 'Modifica utente',

    // Action title for enable,
    'Enable': 'Abilita',

    // IQ4 Text for parameter GATEWAY_ENABLED_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_ENABLED_SEGMENTS,
    'Enabled Sensor segments': 'Sensore a segmenti disponibile',

    // ../src/../src/views/time.js,../src/../src/views/time.js,
    'Error changing the system time': 'Errore durante la modifica dell\'orario di sistema',

    // ../src/../src/views/user.js,
    'Error deleting user': 'Errore durante l\'eliminazione dell\'utente',

    // ../src/templates/settings-install-updates-error.html,
    'Error installing updates': 'Errore nell\'installazione degli aggiornamenti',

    // ../src/../src/views/chart.js,../src/../src/views/table.js,
    'Error retrieving history': 'Errore nel recupero della cronologia',

    // ../src/../src/views/chart.js,../src/../src/views/table.js,
    'Error retrieving recent history': 'Errore nel recupero della cronologia recente',

    // ../src/../src/views/user.js,../src/../src/views/user.js,
    'Error: failed to create user': 'Errore: creazione utente fallita',

    // ../src/../src/views/user.js,
    'Error: failed to delete user': 'Errore: eliminazione utente non riuscita',

    // ../src/../src/views/user.js,
    'Error: failed to edit user': 'Errore: modifica utente non riuscita',

    // ../src/../src/views/user.js,
    'Error: failed to update user': 'Errore: modifica utente non riuscita',

    // ../src/templates/log.html,
    'Events': 'Eventi',

    // Action title for export,
    'Export configuration': 'Esporta configurazione',

    // ../src/templates/settings.html,
    'Factory name': 'Nome di fabbrica',

    // ../src/../src/views/log.js,
    'Failed to clear the log': 'Impossibile cancellare il log',

    // ../src/templates/failed-to-connect.html,
    'Failed to connect to the websocket, please check your settings.': 'Impossibile connettersi alla presa web, controlla le impostazioni.',

    // ../src/../src/views/action.js,
    'Failed to perform the action ': 'Impossibile eseguire l\'azione',

    // ../src/../src/views/login.js,../src/../src/views/login.js,
    'Failed to reset the password. Please check the username and recovery key.': 'Impossibile reimpostare la password. Controlla il nome utente e la chiave di ripristino.',

    // ../src/../src/views/related.js,
    'Failed to retrieve CLFB': 'Impossibile recuperare il CLFB',

    // ../src/../src/views/related.js,
    'Failed to retrieve device': 'Impossibile recuperare il dispositivo',

    // ../src/../src/views/related.js,
    'Failed to retrieve fieldbus': 'Impossibile recuperare il Fieldbus',

    // ../src/../src/views/related.js,../src/../src/views/related.js,../src/../src/views/related.js,
    'Failed to retrieve the CLFB': 'Recupero del CLFB non riuscito',

    // ../src/../src/views/related.js,
    'Failed to retrieve the fieldbus': 'Impossibile recuperare il Fieldbus',

    // ../src/../src/views/related.js,../src/../src/views/related.js,../src/../src/views/related.js,
    'Failed to update the CLFB settings': 'Aggiornamento delle impostazioni CLFB non riuscito',

    // ../src/../src/views/related.js,
    'Failed to update the Fieldbus Map': 'Impossibile aggiornare la mappa del Fieldbus',

    // IQ4 Text for setting FIELDBUS_NAME,
    'Fieldbus name': 'Nome del Fieldbus',

    // ../src/templates/log.html,
    'Filter': 'Filtro',

    // IQ4 Text for parameter CM_TINY_FIRMWARE_VERSION,IQ4 Text for parameter CMME_FIRMWARE_VERSION,IQ4 Text for parameter IQ_COM_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter SEGMENT_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_FIRMWARE_VERSION,IQ4 Text for parameter EASION_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_EX_FIRMWARE_VERSION,IQ4 Text for parameter THUNDERION2_FIRMWARE_VERSION,IQ4 Text for parameter VICINION_FIRMWARE_VERSION,IQ4 Text for parameter CLEANFLEX_FIRMWARE_VERSION,IQ4 Text for parameter BLOWFLEX_FIRMWARE_VERSION,IQ4 Text for parameter MPM_FIRMWARE_VERSION,IQ4 Text for parameter PERFOMASTER_FIRMWARE_VERSION,
    'Firmware version': 'Versione del firmware',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG1,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG1,
    'Firmware version seg1': 'Versione Firmware seg1',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG10,
    'Firmware version seg10': 'Versione Firmware seg10',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG11,
    'Firmware version seg11': 'Versione Firmware seg11',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG12,
    'Firmware version seg12': 'Versione Firmware seg12',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG13,
    'Firmware version seg13': 'Versione Firmware seg13',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG14,
    'Firmware version seg14': 'Versione Firmware seg14',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG15,
    'Firmware version seg15': 'Versione Firmware seg15',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG16,
    'Firmware version seg16': 'Versione Firmware seg16',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG2,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG2,
    'Firmware version seg2': 'Versione Firmware seg2',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG3,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG3,
    'Firmware version seg3': 'Versione Firmware seg3',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG4,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG4,
    'Firmware version seg4': 'Versione Firmware seg4',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG5,
    'Firmware version seg5': 'Versione Firmware seg5',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG6,
    'Firmware version seg6': 'Versione Firmware seg6',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG7,
    'Firmware version seg7': 'Versione Firmware seg7',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG8,
    'Firmware version seg8': 'Versione Firmware seg8',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG9,
    'Firmware version seg9': 'Versione Firmware seg9',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Hide release notes': 'Nascondi note della release',

    // ../src/../src/views/chart.js,../src/../src/views/clfb-io-chart.js,
    'Hide table': 'Nascondi tabella',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter CMME_OUTPUT_CURRENT_FEEDBACK,IQ4 Text for parameter MPM_OUTPUT_CURRENT_FEEDBACK,
    'High voltage output current': 'Corrente di uscita alta tensione',

    // IQ4 Text for parameter CMME_OUTPUT_CURRENT_LIMIT_MNGR4,
    'High voltage output current limit': 'Limite corrente di uscita ad alta tensione',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_CURRENT_PEAK_MNGR4,
    'High voltage output current peak per cycle': 'Corrente di uscita alta tensione picco per ciclo',

    // IQ4 Text for parameter THUNDERION2_OUTPUT_FREQUENCY_SETPOINT,IQ4 Text for parameter VICINION_OUTPUT_FREQUENCY_SETPOINT,
    'High voltage output frequency': 'Frequenza di uscita alta tensione',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter VICINION_OUTPUT_VOLTAGE_FEEDBACK,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_FEEDBACK,
    'High voltage output voltage': 'Tensione di uscita alta tensione',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter MPM_HV_OVERLOAD_LEVEL_ALARM,
    'High voltage overload alarm level': 'Livello allarme sovraccarico alta tensione',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter MPM_HV_OVERLOAD_LEVEL_WARNING,
    'High voltage overload warning level': 'Livello di allarme sovraccarico alta tensione',

    // ../src/templates/user-edit.html,
    'Id': 'ID',

    // Action title for identify,
    'Identify': 'Identifica',

    // ../src/templates/log.html,
    'Info': 'Informazioni',

    // IQ4 Text for setting CLFB_INPUTSEGMENTS,
    'Input Segments Selection': 'Selezione segmenti di ingresso',

    // ../src/templates/clfb.html,src/templates/clfb-io.html,src/templates/clfb-chart.html,
    'Input value': 'Valori di input',

    // ../src/templates/settings-updates.html,
    'Install all updates': 'Installa tutti gli aggiornamenti',

    // IQ4 Text for setting CLFB_INTEGRALDEADBAND,
    'Integral Deadband': 'Deadband Integrale',

    // IQ4 Text for setting CLFB_INTEGRALGAIN,
    'Integral Gain': 'Guadagno Integrale',

    // IQ4 Text for setting CLFB_INTEGRALMAX,
    'Integral Maximum': 'Massimo Integrale',

    // IQ4 Text for setting CLFB_INTEGRALMIN,
    'Integral Minimum': 'Minimo Integrale',

    // IQ4 Text for parameter CM_TINY_INTERNAL_TEMP,IQ4 Text for parameter CMME_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter QID_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE,IQ4 Text for parameter THUNDERION2_INTERNAL_TEMPERATURE,IQ4 Text for parameter VICINION_INTERNAL_TEMPERATURE,IQ4 Text for parameter EASION_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter BLOWFLEX_INTERNAL_TEMPERATURE,IQ4 Text for parameter MPM_INTERNAL_TEMP,IQ4 Text for parameter INTERNAL_TEMPERATURE,
    'Internal temperature': 'Temperatura interna',

    // ../src/templates/macros.html,
    'Internal use': 'Uso interno',

    // ../src/../src/views/login.js,../src/../src/views/login.js,
    'Invalid login, please try again': 'Accesso non valido, per favore riprova',

    // ../src/../src/views/parameter.js,
    'Invalid parameter': 'Parametro non valido',

    // ../src/../src/views/related.js,
    'Invalid related object target': 'Destinazione oggetto correlato non valida',

    // ../src/../src/views/setting.js,
    'Invalid setting': 'Impostazione non valida',

    // IQ4 Text for parameter PERFORMAX_BALANCE_OFFSET,IQ4 Text for parameter EASION_BALANCE_OFFSET,IQ4 Text for parameter PERFORMAX_EX_BALANCE_OFFSET,IQ4 Text for parameter THUNDERION2_BALANCE_OFFSET,
    'Ionisation balance offset': 'Scostamento bilancio di ionizzazione',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter EASION_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_NEGATIVE,IQ4 Text for parameter THUNDERION2_IONISATION_CURRENT_NEGATIVE,IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEG,
    'Ionisation current negative emitters': 'Emettitori di ionizzazione negativi correnti',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter EASION_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_POSITIVE,IQ4 Text for parameter THUNDERION2_IONISATION_CURRENT_POSITIVE,IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POS,
    'Ionisation current positive emitters': 'Emettitori di ionizzazione positivi correnti',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_EFFICIENCY,IQ4 Text for parameter EASION_IONIZATION_EFFICIENCY,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_EFFICIENCY,IQ4 Text for parameter THUNDERION2_IONISATION_EFFICIENCY,
    'Ionisation efficiency': 'Efficienza di ionizzazione',

    // ../src/templates/login.html,
    'Language': 'Lingua',

    // IQ4 Text for parameter CM_TINY_LAST_ALARM,IQ4 Text for parameter CMME_LAST_ALARM,IQ4 Text for parameter IQ_COM_LAST_ALARM,IQ4 Text for parameter GATEWAY_LAST_ALARM,IQ4 Text for parameter GATEWAY_EX_LAST_ALARM,IQ4 Text for parameter PERFORMAX_LAST_ALARM,IQ4 Text for parameter EASION_LAST_ALARM,IQ4 Text for parameter PERFORMAX_EX_LAST_ALARM,IQ4 Text for parameter THUNDERION2_LAST_ALARM,IQ4 Text for parameter VICINION_LAST_ALARM,IQ4 Text for parameter CLEANFLEX_LAST_ALARM,IQ4 Text for parameter BLOWFLEX_LAST_ALARM,IQ4 Text for parameter MPM_LAST_ALARM,
    'Last alarm date-time': 'Data e ora dell\'ultimo allarme',

    // IQ4 Text for parameter SEGMENT_4_QID_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_LAST_CALIBRATION_DATE,IQ4 Text for parameter EASION_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_EX_LAST_CALIBRATION_DATE,
    'Last calibration date': 'Ultima data di calibrazione',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG1,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG1,
    'Last calibration date seg1': 'Data ultima calibrazione seg1',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG10,
    'Last calibration date seg10': 'Data ultima calibrazione seg10',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG11,
    'Last calibration date seg11': 'Data ultima calibrazione seg11',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG12,
    'Last calibration date seg12': 'Data ultima calibrazione seg12',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG13,
    'Last calibration date seg13': 'Data ultima calibrazione seg13',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG14,
    'Last calibration date seg14': 'Data ultima calibrazione seg14',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG15,
    'Last calibration date seg15': 'Data ultima calibrazione seg15',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG16,
    'Last calibration date seg16': 'Data ultima calibrazione seg16',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG2,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG2,
    'Last calibration date seg2': 'Data ultima calibrazione seg2',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG3,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG3,
    'Last calibration date seg3': 'Data ultima calibrazione seg3',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG4,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG4,
    'Last calibration date seg4': 'Data ultima calibrazione seg4',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG5,
    'Last calibration date seg5': 'Data ultima calibrazione seg5',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG6,
    'Last calibration date seg6': 'Data ultima calibrazione seg6',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG7,
    'Last calibration date seg7': 'Data ultima calibrazione seg7',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG8,
    'Last calibration date seg8': 'Data ultima calibrazione seg8',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG9,
    'Last calibration date seg9': 'Data ultima calibrazione seg9',

    // IQ4 Text for parameter PERFORMAX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter EASION_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter PERFORMAX_EX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter THUNDERION2_LAST_CLEAN_BAR_WARNING,
    'Last clean bar warning date-time': 'Data e ora ultimo avviso allarme pulizia barra',

    // IQ4 Text for parameter CM_TINY_LAST_WARNING,IQ4 Text for parameter CMME_LAST_WARNING,IQ4 Text for parameter IQ_COM_LAST_WARNING,IQ4 Text for parameter GATEWAY_LAST_WARNING,IQ4 Text for parameter GATEWAY_EX_LAST_WARNING,IQ4 Text for parameter PERFORMAX_LAST_WARNING,IQ4 Text for parameter EASION_LAST_WARNING,IQ4 Text for parameter THUNDERION2_LAST_WARNING,IQ4 Text for parameter VICINION_LAST_WARNING,IQ4 Text for parameter CLEANFLEX_LAST_WARNING,IQ4 Text for parameter BLOWFLEX_LAST_WARNING,IQ4 Text for parameter MPM_LAST_WARNING,
    'Last warning date-time': 'Data e ora dell\'ultimo allarme',

    // ../src/templates/user-edit.html,src/templates/user-new.html,
    'Level': 'Livello',

    // ../src/templates/log.html,
    'Log for': 'Log per',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Loop selection': 'Selezione ciclo',

    // IQ4 Text for parameter CM_TINY_MACHINE_POSITION,IQ4 Text for parameter CMME_MACHINE_POSITION,IQ4 Text for parameter IQ_COM_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_EX_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_MACHINE_POSITION,IQ4 Text for parameter EASION_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_EX_MACHINE_POSITION,IQ4 Text for parameter THUNDERION2_MACHINE_POSITION,IQ4 Text for parameter VICINION_MACHINE_POSITION,IQ4 Text for parameter CLEANFLEX_MACHINE_POSITION,IQ4 Text for parameter BLOWFLEX_MACHINE_POSITION,IQ4 Text for parameter MPM_MACHINE_POSITION,
    'Machine position (location)': 'Posizione macchina (posizione)',

    // IQ4 Text for event CLFB_INPUT_WATCHDOG_ELAPSED,
    'Make sure the CLFB is being updated. Sensor is connected and running.': 'Assicurarsi che il CLFB sia stato aggiornato. Il sensore è connesso e in esecuzione.',

    // IQ4 Text for event CLFB_INPUT_DEVICE_NOT_READY,
    'Make sure the input device is available and in RUN mode': 'Assicurarsi che il dispositivo di ingresso sia disponibile e in modalità RUN',

    // IQ4 Text for event CLFB_OUTPUT_DEVICE_NOT_READY,
    'Make sure the output device is available and in run mode': 'Assicurarsi che il dispositivo di uscita sia disponibile e in modalità esecuzione',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MAX_TIME,
    'Max. Charge pulse duration': 'Durata massima dell\'impulso di carica',

    // IQ4 Text for parameter CM_TINY_MAX_INTERNAL_TEMP,IQ4 Text for parameter CMME_MAX_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter EASION_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter VICINION_MAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter MPM_MAX_INTERNAL_TEMP,
    'Max. internal temperature': 'Temperatura interna massima',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_MAX_OLD,
    'Max. primary current': 'Corrente primaria massima',

    // IQ4 Text for parameter CM_TINY_MAX_QUANTITY_ARCS,IQ4 Text for parameter IQ_COM_MAX_QUANTITY_ARCS,
    'Max. quantity arcs per minute': 'Quantità massima di archi al minuto',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,
    'Maximum value': 'Valore massimo',

    // ../src/templates/log.html,
    'Message': 'Messaggio',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MIN_TIME,
    'Min. Charge pulse duration': 'Durata minima dell\'impulso di carica',

    // ../src/templates/parameter.html,src/templates/table.html,src/templates/chart.html,
    'Minimum value': 'Valore minimo',

    // IQ4 Text for parameter SENSOR_MOUNTING_DISTANCE,IQ4 Text for parameter SENSOR_EX_MOUNTING_DISTANCE,IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_MOUNTING_DISTANCE,IQ4 Text for parameter EASION_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_EX_MOUNTING_DISTANCE,IQ4 Text for parameter THUNDERION2_MOUNTING_DISTANCE,IQ4 Text for parameter VICINION_MOUNTING_DISTANCE,
    'Mounting distance': 'Distanza di montaggio',

    // ../src/templates/user-edit.html,src/templates/user-new.html,src/templates/settings-updates.html,src/templates/settings.html,
    'Name': 'Nome',

    // ../src/templates/related-add.html,
    'New Closed Loop Feedback': 'Nuovo Closed Loop Feedback',

    // ../src/../src/views/log.js,
    'New log entries have been received. Click on the "refresh log" button to update the list.': 'Sono state ricevute nuove voci di registro. Clicca sul pulsante "Aggiorna registro" per aggiornare la lista.',

    // IQ4 Text for parameter CM_TINY_OPERATION_MODE,IQ4 Text for parameter CMME_OPERATION_MODE,IQ4 Text for parameter IQ_COM_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_BAR_OPERATION_MODE,IQ4 Text for parameter EASION_BAR_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_EX_BAR_OPERATION_MODE,IQ4 Text for parameter THUNDERION2_BAR_OPERATION_MODE,IQ4 Text for parameter VICINION_BAR_OPERATION_MODE,
    'Operation mode': 'Modalità operativa',

    // IQ4 Text for parameter CMME_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_ALARM_CURRENT_PEAK_MNGR4,
    'Output current alarm limit': 'Limite di allarme corrente di uscita',

    // IQ4 Text for parameter CMME_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_WARNING_CURRENT_PEAK_MNGR4,
    'Output current peak alarm limit': 'Limite di allarme picco corrente in uscita',

    // IQ4 Text for parameter EASION_OUTPUT_FREQUENCY_SETPOINT,
    'Output frequency setpoint': 'Impostazione frequenza di uscita',

    // ../src/templates/clfb.html,src/templates/clfb-io.html,src/templates/clfb-chart.html,
    'Output value': 'Valore di output',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_MAX,
    'Output voltage max setpoint': 'Setpoint tensione di uscita max',

    // IQ4 Text for parameter PERFORMAX_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter PERFORMAX_EX_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_NEGATIVE,IQ4 Text for parameter PERFORMAX_VSENSE_NEG,
    'Output voltage negative emitters': 'Tensione di uscita emettitori negativi',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_NEGATIVE_SETPOINT,
    'Output voltage negative setpoint': 'Setpoint tensione di uscita negativa',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_PEAK_MNGR4,
    'Output voltage peak per cycle': 'Tensione di uscita picco per ciclo',

    // IQ4 Text for parameter PERFORMAX_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter PERFORMAX_EX_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_POSITIVE,IQ4 Text for parameter PERFORMAX_VSENSE_POS,
    'Output voltage positive emitters': 'Tensione di uscita emettitori positivi',

    // IQ4 Text for parameter EASION_OUTPUT_VOLTAGE_POSITIVE_SETPOINT,
    'Output voltage positive setpoint': 'Setpoint tensione di uscita positiva',

    // ../src/templates/404.html,
    'Page could not be found': 'La pagina non è stata trovata',

    // ../src/templates/macros.html,
    'Parameter locked': 'Parametro bloccato',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Password reset': 'Reimpostare la password',

    // ../src/../src/views/parameter.js,
    'Please input a valid number': 'Inserisci un numero valido',

    // ../src/templates/parameter.html,
    'Plot 48h chart': 'Traccia grafico 48h',

    // ../src/templates/parameter.html,src/templates/clfb-io.html,
    'Plot realtime chart': 'Traccia grafico in tempo reale',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Position': 'Posizione',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CMME_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter THUNDERION2_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CLEANFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter BLOWFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE,
    'Power supply voltage': 'Tensione di alimentazione',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter CMME_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE_WRN_LEVEL,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE_WARNING,
    'Power supply warning level': 'Livello di allarme alimentazione',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter THUNDERION2_PRIMARY_CURRENT,IQ4 Text for parameter CLEANFLEX_PRIMARY_CURRENT,IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT,
    'Primary current': 'Corrente primaria',

    // IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT_CALIBRATION_OFFSET,
    'Primary current calibration offset': 'Scostamento di calibrazione della corrente primaria',

    // IQ4 Text for setting CLFB_PROPORTIONALGAIN,
    'Proportional Gain': 'Guadagno Proporzionale',

    // ../src/templates/chart.html,
    'Realtime chart': 'Grafico in tempo reale',

    // Action title for reboot,
    'Reboot': 'Riavvia',

    // ../src/templates/password-reset.html,
    'Recovery key': 'Chiave di ripristino',

    // ../src/templates/log.html,
    'Refresh log': 'Aggiorna i log',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_VALUE,
    'Relative Humidity': 'Umidità relativa',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_AVAILABLE,
    'Relative Humidity sensor available': 'Sensore di umidità relativa disponibile',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Release notes': 'Note sulla release',

    // ../src/templates/customize-view.html,
    'Reorder': 'Riordino',

    // ../src/templates/debug-report.html,src/templates/debug.html,
    'Report': 'Segnala',

    // ../src/templates/password-reset.html,
    'Reset password': 'Reimposta password',

    // ../src/templates/chart.html,src/templates/clfb-chart.html,
    'Reset zoom': 'Reimposta zoom',

    // ../src/templates/debug.html,
    'Restart': 'Riavvia',

    // ../src/../src/core/state.js,
    'Restarting the system, please wait': 'Riavvio del sistema, attendere prego',

    // ../src/../src/core/state.js,
    'Restarting, please wait': 'Riavvio, attendere prego',

    // Action title for restore-factory,
    'Restore factory settings': 'Ripristino impostazioni di fabbrica',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Result': 'Risultato',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Run': 'Esegui',

    // ../src/templates/debug-test.html,
    'Run test selection': 'Esegui selezione test',

    // ../src/templates/user-edit.html,src/templates/parameter.html,src/templates/time.html,src/templates/time.html,src/templates/time.html,src/templates/user-new.html,src/templates/setting.html,
    'Save': 'Salva',

    // IQ4 Text for setting CLFB_SEGMENTSELECTION,
    'Segment Selection Mode': 'Modalità Selezione Segmento',

    // ../src/templates/related-select-slot.html,
    'Select fieldbus slot': 'Seleziona lo slot fieldbus',

    // ../src/templates/debug-report.html,
    'Send': 'Invia',

    // ../src/templates/debug-test.html,
    'Sequences': 'Sequenze',

    // IQ4 Text for event CLFB_SETPOINT_NOT_READY,
    'Set all requested setpoint settings and relations.': 'Imposta tutte le impostazioni e le relazioni di setpoint richieste.',

    // Action title for run,
    'Set device in RUN': 'Imposta dispositivo in RUN',

    // ../src/templates/time.html,
    'Set local settings': 'Imposta le impostazioni locali',

    // ../src/templates/time.html,
    'Set new system time in UTC': 'Imposta il nuovo orario di sistema in UTC',

    // IQ4 Text for parameter BLOWFLEX_SETPOINT_CURRENT,
    'Setpoint current': 'Impostazione corrente',

    // IQ4 Text for parameter CM_TINY_SETPOINT_VOLTAGE_CLFB,IQ4 Text for parameter IQ_COM_SETPOINT_VOLTAGE_CLFB,IQ4 Text for parameter CMME_SETPOINT_VOLTAGE_CLFB,
    'Setpoint voltage balance for CLFB': 'Impostazione bilanciamento tensione per CLFB',

    //     // ../src/templates/macros.html,
    'Setting locked': 'Impostazione bloccata',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Settings': 'Impostazioni',

    // ../src/templates/log.html,
    'Show all ': 'Mostra tutto',

    // ../src/templates/settings-updates.html,
    'Show current versions': 'Mostra le versioni attuali',

    // ../src/templates/log.html,
    'Show dismissed': 'Mostra cancellati',

    // ../src/templates/related-delete.html,src/templates/related-delete.html,src/templates/related-add.html,src/templates/related-select-slot.html,Action title for show-related,
    'Show related objects': 'Mostra oggetti correlati',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Show release notes': 'Mostra le note di release',

    // ../src/../src/views/chart.js,../src/../src/views/clfb-io-chart.js,src/templates/chart.html,src/templates/clfb-chart.html,
    'Show table': 'Mostra tabella',

    // ../src/templates/related-select-slot.html,
    'Slot ': 'Slot',

    // ../src/../src/core/state.js,
    'Starting the system, please wait': 'Avvio del sistema, attendere prego',

    // ../src/templates/debug-state.html,src/templates/debug.html,
    'State': 'Stato',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Steps': 'Passaggi',

    // ../src/templates/debug-test.html,src/templates/debug-test.html,
    'Test': 'Prova',

    // ../src/templates/debug.html,
    'Tests': 'Prove',

    // ../src/../src/views/time.js,
    'The local time settings were changed successfully': 'Le impostazioni dell\'ora locale sono state modificate con successo',

    // ../src/templates/password-reset.html,
    'The new password is': 'La nuova password è',

    // ../src/templates/password-reset.html,
    'The password for ': 'Password per',

    // ../src/../src/views/time.js,
    'The system time was changed successfully': 'L\'ora di sistema è stata modificata con successo',

    // ../src/templates/related-add.html,
    'There are no objects to add.': 'Non ci sono oggetti da aggiungere.',

    // ../src/templates/related-delete.html,
    'There are no objects to delete.': 'Non ci sono oggetti da eliminare.',

    // ../src/templates/related.html,
    'There are no related objects.': 'Non ci sono oggetti correlati.',

    // ../src/templates/settings-updates.html,
    'There are no updates available': 'Non ci sono aggiornamenti disponibili',

    // ../src/templates/settings-install-updates.html,
    'This might take a while and the system will reboot.': 'Questo potrebbe richiedere un po\' di tempo e il sistema si riavvierà.',

    // ../src/templates/settings-confirm-update.html,
    'This will reboot the entire system and cause some downtime.': 'Questo riavvierà l\'intero sistema e causerà qualche tempo di inattività.',

    // ../src/templates/time.html,
    'Time': 'Ora',

    // IQ4 Text for parameter CM_TINY_TIME_RUNNING,IQ4 Text for parameter CMME_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TIME_RUNNING,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_UPTIME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_UPTIME,IQ4 Text for parameter PERFORMAX_TIME_RUNNING,IQ4 Text for parameter EASION_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TIME_RUNNING,IQ4 Text for parameter VICINION_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TIME_RUNNING,IQ4 Text for parameter BLOWFLEX_TIME_RUNNING,IQ4 Text for parameter MPM_TIME_RUNNING,
    'Time running since power up': 'Tempo in esecuzione dall\'accensione',

    // IQ4 Text for parameter CMME_TIME_TO_CYCLE_OK,
    'Time to Cycle OK': 'Tempo di ciclo OK',

    // ../src/templates/time.html,
    'Timezone': 'Fuso orario',

    // IQ4 Text for parameter GATEWAY_4_QID_TOTAL_RUNNING_TIME,IQ4 Text for parameter SEGMENT_4_QID_TOTAL_RUNNING_TIME,
    'Total running time': 'Tempo di funzionamento totale',

    // IQ4 Text for parameter CM_TINY_TOTAL_TIME_RUNNING,IQ4 Text for parameter CMME_TOTAL_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TOTAL_TIME_RUNNING,IQ4 Text for parameter VICINION_TOTAL_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TOTAL_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TOTAL_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_TOTAL_TIME_RUNNING,IQ4 Text for parameter EASION_TOTAL_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TOTAL_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TOTAL_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TOTAL_TIME_RUNNING,IQ4 Text for parameter MPM_TOTAL_TIME_RUNNING,
    'Total time running': 'Tempo totale in esecuzione',

    // IQ4 Text for parameter CM_TINY_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter CMME_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter THUNDERION2_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter VICINION_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter IQ_COM_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter CLEANFLEX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter PERFORMAX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter EASION_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter PERFORMAX_EX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter GATEWAY_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter GATEWAY_EX_TOTAL_TIMES_POWERED_ON,IQ4 Text for parameter MPM_TOTAL_TIMES_POWERED_ON,
    'Total times powered on': 'Totale volte accese',

    // ../src/templates/log.html,src/templates/settings-updates.html,src/templates/settings.html,
    'Type': 'Tipo',

    // ../src/../src/views/device.js,
    'Unexpected error occured while disabling': 'Si è verificato un errore imprevisto durante la disattivazione',

    // ../src/../src/views/device.js,
    'Unexpected error occured while enabling': 'Si è verificato un errore imprevisto durante l\'abilitazione',

    // IQ4 Text for parameter CM_TINY_UNIQUE_DEVICE_ID,IQ4 Text for parameter CMME_UNIQUE_DEVICE_ID,IQ4 Text for parameter IQ_COM_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter SEGMENT_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_UNIQUE_DEVICE_ID,IQ4 Text for parameter EASION_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter THUNDERION2_UNIQUE_DEVICE_ID,IQ4 Text for parameter VICINION_UNIQUE_DEVICE_ID,IQ4 Text for parameter CLEANFLEX_UNIQUE_DEVICE_ID,IQ4 Text for parameter BLOWFLEX_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFOMASTER_UNIQUE_DEVICE_ID,
    'Unique device ID': 'Id unico del dispositivo',

    // ../src/../src/core/gui.js,
    'Unknown': 'Sconosciuto',

    // ../src/../src/views/setting.js,../src/../src/views/setting.js,
    'Unknown IQ Object Type': 'Tipo apparecchiatura IQ sconosciuta',

    // ../src/templates/error.html,
    'Unknown error': 'Errore sconosciuto',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Unknown object': 'Oggetto sconosciuto',

    // ../src/templates/settings-confirm-update.html,Action title for update,
    'Update': 'Aggiorna',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,../src/../src/views/settings.js,src/templates/settings.html,
    'Updating': 'Aggiornamento',

    // ../src/../src/core/state.js,
    'Updating the system, please wait': 'Aggiornamento del sistema, attendere prego',

    // ../src/templates/related.html,src/templates/related.html,
    'Use the + button to add a related object.': 'Utilizzare il pulsante + per aggiungere un oggetto correlato.',

    // ../src/templates/user.html,
    'User': 'Utente',

    // ../src/../src/views/user.js,
    'User created successfully': 'Utente creato con successo',

    // ../src/../src/views/user.js,
    'User deleted successfully': 'Utente eliminato con successo',

    // ../src/templates/user.html,
    'User list': 'Lista utente',

    // ../src/../src/views/user.js,
    'User updated successfully': 'Utente aggiornato correttamente',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Username': 'Nome utente',

    // ../src/templates/settings.html,
    'Versions': 'Versioni',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_LIMIT_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_LIMIT_SETPOINT,
    'Voltage Control output current limit': 'Controllo Tensione limite corrente di uscita',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter VICINION_OUTPUT_VOLTAGE_SETPOINT,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_SETPOINT,
    'Voltage Control voltage setpoint': 'Punto impostazione tensione in modalità Controllo Tensione',

    // ../src/../src/main.js,../src/../src/core/websocket.js,
    'Waiting for server connection, please wait': 'In attesa della connessione al server, attendere',

    // IQ4 Text for parameter GATEWAY_WARNING_SETPOINT,IQ4 Text for parameter GATEWAY_EX_WARNING_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_WARNING_SETPOINT,
    'Warning level, measured value over maximum': 'Livello di avvertimento, valore misurato sopra massimo',

    // IQ4 Text for parameter GATEWAY_WARNING_MINIMUM,IQ4 Text for parameter GATEWAY_EX_WARNING_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_WARNING_MINIMUM,
    'Warning level, measured value under minimum': 'Livello di avvertimento, valore misurato sotto minimo',

    // IQ4 Text for parameter GATEWAY_WARNING_ERROR_DELAY,IQ4 Text for parameter GATEWAY_EX_WARNING_ERROR_DELAY,
    'Warning/alarm delay': 'Attenzione/ritardo allarme',

    // ../src/templates/log.html,
    'Warnings': 'Avvertimenti',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_SPEED,IQ4 Text for parameter EASION_WEB_SPEED,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_SPEED,IQ4 Text for parameter PERFORMAX_WEB_SPEED,
    'Web speed': 'Velocità materiale',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,IQ4 Text for parameter GATEWAY_EX_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,
    'Web voltage average (all)': 'Tensione media materiale (tutti)',

    // IQ4 Text for setting CLFB_WEBVOLTAGEOFFSET,
    'Web voltage offset': 'Scostamento tensione materiale',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG1,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG1,
    'Web voltage seg1': 'Tensione materiale seg1',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG10,
    'Web voltage seg10': 'Tensione materiale seg10',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG11,
    'Web voltage seg11': 'Tensione materiale seg11',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG12,
    'Web voltage seg12': 'Tensione materiale seg12',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG13,
    'Web voltage seg13': 'Tensione materiale seg13',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG14,
    'Web voltage seg14': 'Tensione materiale seg14',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG15,
    'Web voltage seg15': 'Tensione materiale seg15',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG16,
    'Web voltage seg16': 'Tensione materiale seg16',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG2,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG2,
    'Web voltage seg2': 'Tensione materiale seg2',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG3,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG3,
    'Web voltage seg3': 'Tensione materiale seg3',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG4,IQ4 Text for parameter SENSOR_EX_HIRES_WEB_VOLTAGE_SEG4,
    'Web voltage seg4': 'Tensione materiale seg4',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG5,
    'Web voltage seg5': 'Tensione materiale seg5',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG6,
    'Web voltage seg6': 'Tensione materiale seg6',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG7,
    'Web voltage seg7': 'Tensione materiale seg7',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG8,
    'Web voltage seg8': 'Tensione materiale seg8',

    // IQ4 Text for parameter SENSOR_HIRES_WEB_VOLTAGE_SEG9,
    'Web voltage seg9': 'Tensione materiale seg9',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_WIDTH,IQ4 Text for parameter EASION_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_WEB_WIDTH,
    'Web width': 'Larghezza materiale',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'Yes': 'Si',

    // ../src/templates/user.html,
    'You are currently logged in as the user': 'Sei attualmente connesso come utente',

    // ../src/../src/views/user.js,
    'You can not delete your own user account': 'Non è possibile eliminare il proprio account',

    // IQ4 Text for event WARNING_MASK_ARC_DETECTED,IQ4 Text for event ALARM_MASK_ARC_DETECTED,
    'arc detected': 'arco rilevato',

    // IQ4 Text for event WARNING_EFFICIENCY_LOW,
    'bar efficiency low': 'bassa efficienza della barra',

    // IQ4 Text for event WARNING_CALIBRATION_IN_PROGRESS,
    'calibration in progress': 'Calibrazione in corso',

    // IQ4 Text for event WARNING_CALIBRATION_INVALID,
    'calibration invalid': 'calibrazione non valida',

    // IQ4 Text for event WARNING_MASK_CHARGEPULSE_TOO_LONG,
    'chargepulse too long': 'Impulso di carica troppo lungo',

    // IQ4 Text for event WARNING_MASK_CLEAN_BAR,
    'clean bar': 'pulire la barra',

    // IQ4 Text for event ALARM_COMMUNICATION_LOST_SEGMENT,
    'communication lost with sensor segment': 'comunicazione persa con il segmento sensore',

    // IQ4 Text for event WARNING_MASK_HMI_COMM_LOST,
    'device communication lost': 'comunicazione dispositivo persa',

    // IQ4 Text for event WARNING_MASK_DEVICE_NEEDS_REBOOT,
    'device needs reboot': 'il dispositivo necessita di riavvio',

    // IQ4 Text for event SYS_WARNING_MASK_DEVICE_PORT_CHANGED,IQ4 Text for event WARNING_PORT_SWAP,
    'device port changed': 'porta dispositivo modificata',

    // IQ4 Text for event ALARM_MASK_EEPROM_DEFECTIVE,
    'eeprom defective': 'eeprom difettoso',

    // IQ4 Text for event WARNING_MASK_EEPROM_PREV_SETTINGS_REST,
    'eeprom previous settings restored': 'eeprom precedenti impostazioni ripristinate',

    // IQ4 Text for event WARNING_MASK_EEPROM_TOO_MANY_WRITES,
    'eeprom too many write cycles': 'eeprom troppi cicli di scrittura',

    // IQ4 Text for event WARNING_MASK_GENERATOR_NOT_CONNECTED,
    'generator not connected': 'generatore non collegato',

    // ../src/templates/password-reset.html,
    'has been reset': 'è stato resettato',

    // IQ4 Text for event WARNING_TEMPERATURE_HIGH,IQ4 Text for event WARNING_MASK_HIGH_TEMP,IQ4 Text for event ALARM_HIGH_TEMPERATURE,
    'high temperature': 'Temperatura alta',

    // IQ4 Text for event WARNING_COMMUNICATION_LOST,
    'hmi communication lost': 'comunicazione hmi persa',

    // ../src/templates/user.html,
    'id': 'ID',

    // ../src/templates/log-table.html,
    'inactive': 'inattivo',

    // IQ4 Text for event SYS_WARNING_MASK_NEW_DEVICE_CONNECTED,IQ4 Text for event WARNING_NEW_DEVICE_CONNECTED,
    'new device connected': 'Nuovo dispositivo connesso',

    // IQ4 Text for event WARNING_MASK_NO_ACTIVE_SEGMENTS,IQ4 Text for event WARNING_LINKED_SENSOR_NO_ACTIVE_SEGMENTS,
    'no active sensor segments': 'nessun segmento del sensore attivo',

    // IQ4 Text for event WARNING_MASK_NO_COMM_PAIRED_DEVICE,IQ4 Text for event WARNING_LINKED_SENSOR_NO_COMM_PAIRED_DEVICE,
    'no communication with paired device': 'nessuna comunicazione con il dispositivo accoppiato',

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_LOW,IQ4 Text for event ALARM_MASK_LEVEL_TOO_LOW,
    'output curent too low': 'Corrente di uscita troppo bassa',

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_HIGH,IQ4 Text for event ALARM_MASK_LEVEL_TOO_HIGH,
    'output current too high': 'corrente di uscita troppo alta',

    // IQ4 Text for event WARNING_MASK_OVERLOAD_HIGH_VOLTAGE,IQ4 Text for event ALARM_MASK_OVERLOAD_HIGH_VOLTAGE,IQ4 Text for event WARNING_HVOUT_OVERLOAD,IQ4 Text for event ALARM_HVOUT_OVERLOAD,
    'overload high voltage': 'sovraccarico alta tensione',

    // IQ4 Text for event WARNING_MASK_PAIRED_DEVICE_MISSING,IQ4 Text for event WARNING_LINKED_SENSOR_PAIRED_DEVICE_MISSING,
    'paired device missing': 'dispositivo accoppiato mancante',

    // IQ4 Text for event WARNING_MASK_SENSOR_MISSING,IQ4 Text for event WARNING_LINKED_SENSOR_SEGMENT_MISSING,
    'paired sensor missing': 'sensore accoppiato mancante',

    // IQ4 Text for event WARNING_MASK_POWER_SUPPLY_LOW,
    'power supply low': 'alimentazione bassa',

    // IQ4 Text for event ALARM_SENSOR_MALFUNCTION,
    'sensor malfunction': 'malfunzionamento sensore',

    //     // IQ4 Text for event ALARM_SUPPLY_CURRENT_HIGH,IQ4 Text for event ALARM_MASK_HIGH_PRIM_CUR,IQ4 Text for event ALARM_PRIMARY_CURRENT_HIGH,
    'supply current too high': 'corrente di alimentazione troppo alta',

    // IQ4 Text for event WARNING_SUPPLY_VOLTAGE_LOW,IQ4 Text for event WARNING_INPUT_VOLTAGE_LOW,
    'supply voltage too low': 'tensione di alimentazione troppo bassa',

    // IQ4 Text for event ALARM_MASK_HIGH_TEMP,IQ4 Text for event ALARM_TEMPERATURE_HIGH,
    'too high temperature': 'temperatura troppo alta',

    // ../src/templates/user.html,
    'userlevel': 'Livello utente',

    // ../src/templates/user.html,
    'username': 'nome utente',

    // IQ4 Text for event ALARM_WEB_VOLTAGE_OVERLOAD,
    'web voltage overload': 'sovraccarico tensione del materiale',

    // IQ4 Text for event WARNING_WEB_VOLTAGE_HIGH_WARNING,IQ4 Text for event ALARM_WEB_VOLTAGE_HIGH_ERROR,
    'web voltage too high': 'Tensione materiale troppo alta',

    // IQ4 Text for event WARNING_WEB_VOLTAGE_LOW_WARNING,IQ4 Text for event ALARM_WEB_VOLTAGE_LOW_ERROR,
    'web voltage too low': 'Tensione materiale troppo bassa',

    // ====================================================
    // TODO: translate these
    // ====================================================
    // ../src/../src/core/state.js, 
    'Error saving the user preferences': 'Error saving the user preferences', 

    // ../src/../src/core/gui.js, ../src/../src/core/gui.js, src/templates/user-delete.html, 
    'No': 'No', 

    // ../src/../src/core/gui.js, 
    'On': 'On', 

    // ../src/../src/core/gui.js, 
    'Off': 'Off', 

    // ../src/../src/core/gui.js, 
    'Started': 'Started', 

    // ../src/../src/core/helpers.js, 
    'device': 'device', 

    // ../src/../src/core/helpers.js, 
    'fieldbus': 'fieldbus', 

    // ../src/../src/core/helpers.js, 
    'CLFB': 'CLFB', 

    // ../src/../src/core/helpers.js, 
    'object': 'object', 

    // ../src/../src/debugger/log.js, 
    'Retrieving logs, please wait': 'Retrieving logs, please wait', 

    // ../src/../src/debugger/log.js, ../src/../src/debugger/log.js, 
    'Failed to download logs': 'Failed to download logs', 

    // ../src/../src/debugger/testrunner.js, 
    'Error: could not find test': 'Error: could not find test', 

    // ../src/../src/debugger/debugger.js, 
    'A test is already being recorded': 'A test is already being recorded', 

    // ../src/../src/debugger/debugger.js, src/templates/debug-test.html, 
    'Stop recording': 'Stop recording', 

    // ../src/../src/debugger/debugger.js, src/templates/debug-test.html, 
    'Record new test': 'Record new test', 

    // ../src/../src/debugger/debugger.js, 
    'Are you sure you want to delete this test?': 'Are you sure you want to delete this test?', 

    // ../src/../src/views/device-dashboard.js, 
    'Could not find Device': 'Could not find Device', 

    // ../src/../src/views/device.js, ../src/../src/views/table.js, 
    'Could not find device': 'Could not find device', 

    // ../src/../src/views/setting.js, 
    'Setting not found': 'Setting not found', 

    // ../src/../src/views/setting.js, ../src/../src/views/log.js, 
    'not found': 'not found', 

    // ../src/../src/views/setting.js, 
    'Failed to retrieve this CLFB\'s input device': 'Failed to retrieve this CLFB\'s input device', 

    // ../src/../src/views/action.js, 
    'Invalid action': 'Invalid action', 

    // ../src/../src/views/action.js, 
    'Before exporting the Fieldbus configuration you first have to enable it.': 'Before exporting the Fieldbus configuration you first have to enable it.', 

    // ../src/../src/views/action.js, 
    ' Please check the device help for more information.': ' Please check the device help for more information.', 

    // ../src/../src/views/action.js, 
    ' in use': ' in use', 

    // ../src/../src/views/action.js, 
    ' not found or does not support the requested action': ' not found or does not support the requested action', 

    // ../src/../src/views/action.js, 
    'Error': 'Error', 

    // ../src/../src/views/action.js, 
    'Action not found': 'Action not found', 

    // ../src/../src/views/action.js, 
    ' could not be not found': ' could not be not found', 

    // ../src/../src/views/clfb.js, ../src/../src/views/clfb-io-chart.js, ../src/../src/views/clfb-io.js, 
    'Could not find CLFB': 'Could not find CLFB', 

    // ../src/../src/views/chart.js, ../src/../src/views/parameter.js, 
    'Parameter not found': 'Parameter not found', 

    // ../src/../src/views/chart.js, ../src/../src/views/parameter.js, 
    'Device not found': 'Device not found', 

    // ../src/../src/views/time.js, 
    'Clear selection': 'Clear selection', 

    // ../src/../src/views/time.js, 
    'Decrement Hour': 'Decrement Hour', 

    // ../src/../src/views/time.js, 
    'Decrement Minute': 'Decrement Minute', 

    // ../src/../src/views/time.js, 
    'Decrement Second': 'Decrement Second', 

    // ../src/../src/views/time.js, 
    'Increment Hour': 'Increment Hour', 

    // ../src/../src/views/time.js, 
    'Increment Minute': 'Increment Minute', 

    // ../src/../src/views/time.js, 
    'Increment Second': 'Increment Second', 

    // ../src/../src/views/time.js, 
    'Next Century': 'Next Century', 

    // ../src/../src/views/time.js, 
    'Next Decade': 'Next Decade', 

    // ../src/../src/views/time.js, 
    'Next Month': 'Next Month', 

    // ../src/../src/views/time.js, 
    'Next Year': 'Next Year', 

    // ../src/../src/views/time.js, 
    'Pick Hour': 'Pick Hour', 

    // ../src/../src/views/time.js, 
    'Pick Minute': 'Pick Minute', 

    // ../src/../src/views/time.js, 
    'Pick Second': 'Pick Second', 

    // ../src/../src/views/time.js, 
    'Previous Century': 'Previous Century', 

    // ../src/../src/views/time.js, 
    'Previous Decade': 'Previous Decade', 

    // ../src/../src/views/time.js, 
    'Previous Month': 'Previous Month', 

    // ../src/../src/views/time.js, 
    'Previous Year': 'Previous Year', 

    // ../src/../src/views/time.js, 
    'Select Date': 'Select Date', 

    // ../src/../src/views/time.js, 
    'Select Decade': 'Select Decade', 

    // ../src/../src/views/time.js, 
    'Select Month': 'Select Month', 

    // ../src/../src/views/time.js, 
    'Select Time': 'Select Time', 

    // ../src/../src/views/time.js, 
    'Select Year': 'Select Year', 

    // ../src/../src/views/time.js, 
    'Go to today': 'Go to today', 

    // ../src/../src/views/time.js, 
    'Toggle Meridiem': 'Toggle Meridiem', 

    // ../src/../src/views/related.js, ../src/../src/views/related.js, 
    'You can not add a related object to a fieldbus while the fieldbus is running': 'You can not add a related object to a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'You can not remove a related object from a fieldbus while the fieldbus is running': 'You can not remove a related object from a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'You can not delete a related object from a fieldbus while the fieldbus is running': 'You can not delete a related object from a fieldbus while the fieldbus is running', 

    // ../src/../src/views/related.js, 
    'Can not delete unknown related object': 'Can not delete unknown related object', 

    // ../src/../src/views/parameter.js, 
    'Your number is less than the minimum value: ': 'Your number is less than the minimum value: ', 

    // ../src/../src/views/parameter.js, 
    'Your number is greater than the maximum value: ': 'Your number is greater than the maximum value: ', 

    // ../src/../src/views/login.js, 
    'You have logged in with an empty password. Click here to change it.': 'You have logged in with an empty password. Click here to change it.', 

    // ../src/../src/views/fieldbus.js, 
    'The fieldbus has unknown objects. It might help to remove them.': 'The fieldbus has unknown objects. It might help to remove them.', 

    // ../src/../src/views/fieldbus.js, 
    'Failed to enable fieldbus': 'Failed to enable fieldbus', 

    // ../src/../src/views/fieldbus.js, 
    'Failed to disable fieldbus': 'Failed to disable fieldbus', 

    // ../src/../src/views/fieldbus.js, 
    'Could not find fieldbus': 'Could not find fieldbus', 

    // ../src/../src/views/settings.js, 
    'Could not retrieve list of versions': 'Could not retrieve list of versions', 

    // ../src/../src/views/settings.js, 
    'Could not retrieve list of updates': 'Could not retrieve list of updates', 

    // ../src/../src/widgets/sensor-segments-chart.js, 
    'Can not find segments for this device': 'Can not find segments for this device', 

    // ../src/../src/widgets/sensor-segments-chart.js, 
    'Could not retrieve recent history for parameter': 'Could not retrieve recent history for parameter', 

    // ../src/templates/user-edit.html, src/templates/login.html, src/templates/user-new.html, 
    'Password': 'Password', 

    // ../src/templates/debug-test.html, src/templates/debug-test.html, 
    'New test name': 'New test name', 

    // ../src/templates/debug-test.html, src/templates/debug-test.html, src/templates/debug.html, 
    'Log': 'Log', 

    // ../src/templates/debug-test.html, 
    'Rename': 'Rename', 

    // ../src/templates/debug-test.html, 
    'Run recorded test selection': 'Run recorded test selection', 

    // ../src/templates/help.html, src/templates/legal.html, 
    'Legal summary': 'Legal summary', 

    // ../src/templates/debug-report.html, 
    'You can send a report to our central database. This will include a snapshot of the current state and the full log. Please add a little description so we know how to interpret this.': 'You can send a report to our central database. This will include a snapshot of the current state and the full log. Please add a little description so we know how to interpret this.', 

    // ../src/templates/parameter.html, src/templates/table.html, 
    'Plot realtime table': 'Plot realtime table', 

    // ../src/templates/parameter.html, src/templates/table.html, 
    'Plot 48h table': 'Plot 48h table', 

    // ../src/templates/parameter.html, src/templates/clfb-io.html, 
    'Record changes': 'Record changes', 

    // ../src/templates/clfb-io.html, 
    'CLFB I/O': 'CLFB I/O', 

    // ../src/templates/table.html, 
    'Realtime': 'Realtime', 

    // ../src/templates/table.html, 
    '48h': '48h', 

    // ../src/templates/macros.html, 
    'Unknown input': 'Unknown input', 

    // ../src/templates/macros.html, 
    'Unknown output': 'Unknown output', 

    // ../src/templates/time.html, 
    '24H': '24H', 

    // ../src/templates/time.html, 
    'AM/PM': 'AM/PM', 

    // ../src/templates/active-users.html, 
    'Active user sessions': 'Active user sessions', 

    // ../src/templates/active-users.html, 
    'User name': 'User name', 

    // ../src/templates/active-users.html, 
    'User level': 'User level', 

    // ../src/templates/active-users.html, 
    'Go to dashboard': 'Go to dashboard', 

    // ../src/templates/customize-view.html, 
    'You can choose one of the pre-defined orders, or you can sort manually by dragging and dropping after selecting "sort manually".': 'You can choose one of the pre-defined orders, or you can sort manually by dragging and dropping after selecting "sort manually".', 

    // ../src/templates/settings-updates.html, src/templates/settings.html, 
    'UID': 'UID', 

    // IQ4 Text for parameter CM_TINY_EEPROM_WRITE_COUNTS, IQ4 Text for parameter MPM_EEPROM_WRITE_COUNTS, 
    'Eeprom write counts': 'Eeprom write counts', 

    // IQ4 Text for parameter MPM_OPERATION_MODE, 
    'Operation mode (dipswitch 3)': 'Operation mode (dipswitch 3)', 

    // IQ4 Text for parameter MPM_REMOTE_ON_OFF_SOURCE, 
    'Remote on/off source (dipswitch 1)': 'Remote on/off source (dipswitch 1)', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT, 
    'Analog input (dipswitch 2)': 'Analog input (dipswitch 2)', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT_FUNCTION, 
    'Analog input function': 'Analog input function', 

    // IQ4 Text for parameter MPM_ANALOG_INPUT_FEEDBACK, 
    'Analog input': 'Analog input', 

    // IQ4 Text for parameter MPM_CALIBRATE_ANALOG_INPUT_OFFSET, 
    'Calibrate analog input offset': 'Calibrate analog input offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_ANALOG_INPUT_GAIN, 
    'Calibrate analog input gain': 'Calibrate analog input gain', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_FEEDBACK, 
    'High voltage output': 'High voltage output', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_FEEDBACK_OFFSET, 
    'Calibrate output voltage feedback offset': 'Calibrate output voltage feedback offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_FEEDBACK_GAIN, 
    'Calibrate output voltage feedback gain': 'Calibrate output voltage feedback gain', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_FEEDBACK_OFFSET, 
    'Calibrate output current offset': 'Calibrate output current offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_FEEDBACK_GAIN, 
    'Calibrate output current gain': 'Calibrate output current gain', 

    // IQ4 Text for parameter MPM_MAX_QUANTITY_ARCS, 
    'Max. arcs per minute': 'Max. arcs per minute', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_SETPOINT_MAX, 
    'Max. output voltage': 'Max. output voltage', 

    // IQ4 Text for parameter MPM_OUTPUT_VOLTAGE_SETPOINT, 
    'Output voltage setpoint': 'Output voltage setpoint', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_SETPOINT_OFFSET, 
    'Calibrate output voltage setpoint offset': 'Calibrate output voltage setpoint offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_VOLTAGE_SETPOINT_GAIN, 
    'Calibrate output voltage setpoint gain': 'Calibrate output voltage setpoint gain', 

    // IQ4 Text for parameter MPM_CURRENT_LIMIT_SETPOINT, 
    'Max. output current': 'Max. output current', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_SETPOINT_OFFSET, 
    'Calibrate output current setpoint offset': 'Calibrate output current setpoint offset', 

    // IQ4 Text for parameter MPM_CALIBRATE_OUTPUT_CURRENT_SETPOINT_GAIN, 
    'Calibrate output current setpoint gain': 'Calibrate output current setpoint gain', 

    // IQ4 Text for parameter MPM_BALANCE_OFFSET, 
    'Balance offset': 'Balance offset', 

    // IQ4 Text for parameter GATEWAY_IQ4_WEB_DISTANCE_SEG1, 
    'Web distance segment 1': 'Web distance segment 1', 

    // IQ4 Text for parameter MPM_UNIQUE_DEVICE_ID, 
    'Unique device id': 'Unique device id', 

    // IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE_MIN, 
    'Power supply warning level min.': 'Power supply warning level min.', 

    // IQ4 Text for parameter MPM_POWER_SUPPLY_VOLTAGE_MAX, 
    'Power supply warning level max.': 'Power supply warning level max.', 

    // IQ4 Text for parameter MPM_AUTORUN, 
    'Autorun': 'Autorun', 

    // IQ4 Text for parameter MPM_FREE_RAM_CNTR, 
    'Free RAM counter': 'Free RAM counter', 

    // IQ4 Text for parameter THUNDERION2_OUTPUT_VOLTAGE_MAX, 
    'Output voltage max': 'Output voltage max', 

    // IQ4 Text for parameter PERFOMASTER_VOLTAGE_SETPOINT, 
    'Voltage setpoint': 'Voltage setpoint', 

    // IQ4 Text for parameter PERFOMASTER_HV_CURRENT, 
    'Current in HV module': 'Current in HV module', 

    // IQ4 Text for parameter PERFOMASTER_VOLTAGE, 
    'Voltage out of the HV supply': 'Voltage out of the HV supply', 

    // IQ4 Text for setting CLFB_ACTIVESEGMENTS, 
    'Active Segments': 'Active Segments', 

    // Action title for standby, 
    'Standby': 'Standby', 

    // Action title for save-config, 
    'Save configuration': 'Save configuration', 

    // IQ4 Text for event WARNING_NO_SEGMENTS_SELECTED, 
    'No sensor segments selected': 'No sensor segments selected', 

    // IQ4 Text for event DEVICE_DISCONNECTED, 
    'Device disconnected': 'Device disconnected', 

    // IQ4 Text for event DEVICE_DISABLED, 
    'Device disabled': 'Device disabled', 

    // IQ4 Text for event ALARM_INPUT_VOLTAGE_HIGH, 
    'supply voltage too high': 'supply voltage too high', 

    // IQ4 Text for event ALARM_AIR_PRESSURE_HIGH, 
    'air pressure too high': 'air pressure too high', 

};

export function getTranslations() {
    return translations;
}