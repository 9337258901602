/**
 * Screensaver view module.
 * @module screensaver
 */

"use strict";

import * as Debug from '../core/debug';
import * as Gui from '../core/gui';
import * as Helpers from '../core/helpers';
import * as Permissions from '../core/permissions';
import * as State from '../core/state';
import {View} from '../core/view';

export class ScreensaverView extends View {
    constructor() {
        super();
        this.viewName = 'ScreensaverView';
    }

    destructor() {
        super.destructor();
        Gui.hideScreenSaver();
    }

    stopScreensaver() {
        Debug.recordEvent('stop-screensaver');
        this.destructor();
    }

    standbyAll() {
        const devices = State.getDevices();
        for(let device of devices) {
            this.Api.deviceStandby(device.uid);
        }
    }

    runAll() {
        const devices = State.getDevices();
        for(let device of devices) {
            this.Api.deviceRun(device.uid);
        }
    }

    /**
     * Render the screensaver view
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args) {
        let context = {
            'run_all_allowed': Permissions.hasGlobalReadPermission('run-all'),
            'standby_all_allowed': Permissions.hasGlobalReadPermission('standby-all'),
        };

        this.registerActionHandler(
           'screensaver', 'stop', this.stopScreensaver.bind(this)
        );

        this.registerActionHandler(
           'screensaver', 'run-all', this.runAll.bind(this)
        );

        this.registerActionHandler(
           'screensaver', 'standby-all', this.standbyAll.bind(this)
        );

        $('div.screensaver').html(Gui.renderToString('screensaver.html', context));
        $('div.screensaver div.status-list').html($('header div.status-list').html());
        Gui.showScreenSaver();
    }
}
