/**
 * Logout view module.
 * @module logout
 */

"use strict";

import * as Debug from '../core/debug.js';
import * as Gui from '../core/gui';
import * as Session from '../core/session';
import * as State from '../core/state';
import {View} from '../core/view';

export class LogoutView extends View {
    constructor() {
        super();
        this.viewName = 'LogoutView';
    }

    destructor() {
        super.destructor();
    }

    /**
     * Process a logout request
     * @param {Event} event - The originating DOM event
     * @param {Object} args - object with url arguments
     */
    render(event, args) {
        let token = Session.getAccessToken();
        if(typeof token !== 'string') {
            return;
        }
        this.Api.logout(token);
        Session.processLogout();
        window.REMOVE_DEBUGGER();
        Gui.hideIcons();
        Gui.hideStatusList();
        Gui.hideMenu();
        window.visit('/#/login');
    }

    /**
     * Do a silent logout, not notifying the server of our logout action
     */
    silentLogout() {
        Debug.log('Doing a silent logout, not notiyfing the server');
        Session.processLogout();
        Gui.hideIcons();
        Gui.hideStatusList();
        Gui.hideMenu();
        window.visit('/#/login');
    }
}
