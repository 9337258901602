/**
 * Parent view module.
 * @module view
 */


"use strict";

import * as Actions from '../core/actions';
import * as Helpers from './helpers';
import * as Signals from '../core/signals';
import {apiProxy} from './apiproxy';

export class View {
    constructor() {
        // zombie defines whether this view has been discarded. If it's true
        // then this means that it should only wait for the GC to pick it up and
        // not respond to any incoming signals of actions.
        this.zombie = false;
        this.Api = apiProxy(this);
        this.tag = Helpers.generateUuid();
        this.actionHandlerIds = [];
        this.listenerIds = [];
    }

    destructor() {
        this.zombie = true;
        Actions.removeActionHandlers(this.actionHandlerIds);
        Signals.removeListeners(this.listenerIds);
    }

    /**
     * Attach a listener to a signal.
     * @param {string} signal - The signal to attach to.
     * @param {function} callback - The callback to attach.
     * @param {string|false} [device=false] - The device to attach to (optional).
     */
    addListener(signal, callback, device=false) {
        this.listenerIds.push(
            Signals.addListener(
                signal, callback, device, this
            )
        );
    }

    /**
     * Listen to a given device.
     * @param {string} deviceUid - The device to listen to.
     * @param {function} callback - The callback to attach.
     */
    addDeviceListener(deviceUid, callback) {
        this.listenerIds.push(
            Signals.listenToDevice(deviceUid, callback, this)
        );
    }

    /**
     * Listen to a given device parameter.
     * @param {string} deviceUid - The device to listen to.
     * @param {string} parameter - The parameter to listen to.
     * @param {function} callback - The callback to attach.
     */
    addDeviceParameterListener(deviceUid, parameter, callback){
        this.listenerIds.push(
            Signals.listenToDeviceParameter(deviceUid, parameter, callback, this)
        );
    }

    /**
     * Register an action handler for given event type and target
     * @param {string} id - id of the action (i.e UID-QID)
     * @param {string} name - name of the action (i.e edit-parameter)
     * @param {function} callback - called when action is triggered
     */
    registerActionHandler(id, name, callback){
        this.actionHandlerIds.push(
            Actions.registerActionHandler(id, name, callback, this)
        );
    }
}
