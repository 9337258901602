/**
 * Signals module
 * @module signals
 */

"use strict";

import * as Api from './api';
import * as Debug from './debug';

let listeners = {};
let lastListenerId = 0;

// Dict with all the subscribed devices and the amount of attached listeners
let subscribedDevices = {};

/**
 * Attach a listener to a signal.
 * @param {string} signal - The signal to attach to.
 * @param {function} callback - The callback to attach.
 * @param {string|false} [device=false] - The device to attach to (optional).
 * @returns {number} - the id of the listener or false.
 */
export function addListener(signal, callback, device=false){
    if(!listeners.hasOwnProperty(signal)){
        listeners[signal] = [];
    }
    lastListenerId++;
    listeners[signal].push({
        id: lastListenerId,
        callback: callback,
        device: device,
    });


    if(device){
        if(!subscribedDevices[device] || subscribedDevices[device] === 0){
            subscribedDevices[device] = 1;
            Api.subscribe(device);
        } else {
            subscribedDevices[device]++;
        }
    }

    return lastListenerId;
}

/**
 * Listen to a given device parameter.
 * @param {string} device - The device to listen to.
 * @param {string} parameter - The parameter to listen to.
 * @param {function} callback - The callback to attach.
 * @returns {number|boolean} - the id of the listener or false.
 */
export function listenToDeviceParameter(device, parameter, callback){
    return addListener(`device/parameter/${device}/${parameter}`, callback, device);
}

/**
 * Listen to a given device.
 * @param {string} device - The device to listen to.
 * @param {function} callback - The callback to attach.
 * @returns {number} - the id of the listener or false if it failed.
 */
export function listenToDevice(device, callback){
    Debug.log('Listen to device ' + device, 0, false);
    return addListener(`device/${device}`, callback, device);
}

/**
 * Remove a listener from a signal.
 * @param {number} id - The id of the listener to remove.
 */
export function removeListener(id){
    for(const signal in listeners){
        if(!listeners.hasOwnProperty(signal)){
            continue;
        }
        for(let i = 0; i < listeners[signal].length; i++){
            if(listeners[signal][i].id === id){
                if(listeners[signal][i].device !== false){
                    const device = listeners[signal][i].device;
                    if(subscribedDevices[device]){
                        subscribedDevices[device]--;
                        if(subscribedDevices[device] === 0){
                            Api.unsubscribe(device);
                        }
                    }
                }
                listeners[signal].splice(i, 1);
                if(listeners[signal].length === 0){
                    delete listeners[signal];
                }
                return;
            }
        }
    }
}

/**
 * Remove a list of listeners from their signals
 * @param {array} ids - The list of listener ids
 */
export function removeListeners(ids) {
    for(let i = 0; i < ids.length; i++){
        removeListener(ids[i]);
    }
}

/**
 * Remove all the listeners. After doing this this app will process
 * none of the data received by the Websocket.
 */
export function removeAllListeners(){
    listeners = {};
    subscribedDevices = {};
}

/**
 * Return a list of signal names for the given message.
 * @param {object} message - The message to get the signals for.
 * @returns {string[]} - The list of signals.
 * @private
 */
function getSignalNamesForMessage(message){
    let signals = [];
    // Add global signal
    signals.push(message.tag);

    // Add device specific signal
    if(message.tag === 'device/parameter') {
        signals.push(
            message.tag + '-' +
            message.data.device + '/' +
            message.data.parameter
        );

        signals.push(
            'device/' +
            message.data.device
        );
    }

    if(message.tag === 'device/status') {
        signals.push(
            message.tag + '-' +
            message.data.uid
        );
    }

    return signals;
}

/**
 * Send a signal to the listeners.
 * @param {object} message - The data from the websocket.
 */
export function emit(message){
    /*
     * Example content of a message:
     * {
     *     "tag": "device/parameter",
     *     "data": {
     *        "device": "1532000000A1114210",
     *        "parameter": "PARAM_QID_CM_TINY_DEVICE_NAME",
     *        "value": "0d02h50m37s"
     *     }
     *  }
     */
    const signals = getSignalNamesForMessage(message);
    signals.forEach(signal => {
        if(listeners.hasOwnProperty(signal)){
            listeners[signal].forEach(listener => {
                listener.callback(message);
            });
        }
    });
}


/**
 * Send a signal to the listeners with only a tag and a message string
 * @param {string} tag
 * @param {string} messageStr
 */
export function quickEmit(tag, messageStr=''){
    emit({
        'tag': tag,
        'data': {
            'msg': messageStr,
        },
    });
}

/**
 * Resubscribe to all subscribed devices. This is useful after recovering from
 * connection loss since the server might have lost its recollection of which
 * devices we want updates on.
 */
export function reSubscribe() {
    for(let device in subscribedDevices) {
        if(!subscribedDevices.hasOwnProperty(device)){
            continue;
        }

        if(subscribedDevices[device] > 0) {
            Debug.log('Resubscribing to device ' + device, 0, false);
            Api.subscribe(device);
        }
    }
}
