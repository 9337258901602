/**
 * Session module.
 * The Session module handles everything that is session specific, like
 * language setting, screen preferences..etc
 * @module session
 */

"use strict";

import {log} from './debug';

export let sessionVariables = {};
export let loggedin = false;

/**
 * Initialize the current session, retrieving configuration from the
 * localStorage and keeping that available as session variables.
 * @param {function} callback - called when initialization is complete
 */
export function initSession(callback=()=>{}) {
    let sesVars = window.localStorage.getItem('sessionVariables');
    if(sesVars !== null){
        sessionVariables = JSON.parse(sesVars);
    }

    const accessToken = getAccessToken();
    if(accessToken !== false) {
        processLogin(accessToken);
    }

    setVar('uuid', generateUuid());
    callback();
}

/**
 * Generate a new UUID
 * @returns {string}
 */
function generateUuid() {
    /* jslint bitwise: true */
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c==='x' ? r : (r&0x3|0x8)).toString(16);
    });
    /* jslint bitwise: false */
    return uuid;
}

/**
 * Retrieve the uuid
 * @returns {string}
 */
export function getUuid() {
    return getVar('uuid');
}

/**
 * Returns the access token or False
 * @returns {string|false}
 */
export function getAccessToken() {
    return getVar('accessToken');
}

/**
 * Save the access token as a session variable and in local storage
 * @param {string|boolean} token
 */
export function setAccessToken(token) {
    setVar('accessToken', token);
}

/**
 * Sets up the session for using the login denoted by given token
 * @param {string} token
 */
export function processLogin(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const loginData = JSON.parse(jsonPayload);

    setVar('loginData', loginData);
    setAccessToken(token);
    setUserLevel(loginData.accessLevel);
    loggedin = true;

    window.DEBUG = [
        'admin',
        'service',
        'engineering',
        'factory'
    ].indexOf(loginData.accessLevel) !== -1;
    window.INIT_DEBUGGER();
}

export function processLogout() {
    setAccessToken(false);
    deleteVar('loginData');
    loggedin = false;
}

/**
 * Returns the login data that is part of the token payload
 * @returns {object}
 */
export function getLoginData() {
    return getVar('loginData');
}

/**
 * Set the user level
 * @param {string} level
 */
export function setUserLevel(level) {
    setVar('userLevel', level);
}

export function getUserLevel(){
    return getVar('userLevel');
}

/**
 * Returns whether the user is using the hardware touch screen that is
 * physically connected to the IQ4 box in the factory
 * @returns {boolean}
 */
export function usingTouchScreen() {
    return navigator.userAgent.indexOf('Simco-hmi-chromium') !== -1;
}

/**
 * Set the currently selected language to the given one
 * @param {string} language - ISO 2 letter language code
 */
export function setLanguage(language) {
    return window.localStorage.setItem('language', language);
}

/**
 * Return the currently selected language, which defaults to 'en'
 * @return {string} - the language as an ISO 2 letter code
 */
export function getLanguage() {
    return window.localStorage.getItem('language') || 'en';
}

/**
 * Set a session variable to the given value and also update the localStorage
 * @param {string} name
 * @param {string|boolean} value
 */
export function setVar(name, value) {
    sessionVariables[name] = value;
    window.localStorage.setItem('sessionVariables', JSON.stringify(sessionVariables));
}

/**
 * Remove session variable with given name, and also remove it from the localStorage
 */
export function deleteVar(name) {
    delete sessionVariables[name];
    window.localStorage.setItem('sessionVariables', JSON.stringify(sessionVariables));
}

/**
 * Retrieve a given session variable, or the default when it can not be found
 * @param {string} name
 * @param {string|false} [defaultValue=false] - value to return when not present (defaults to false)
 * @return {any}
 */
export function getVar(name, defaultValue=false) {
    return sessionVariables[name] || defaultValue;
}

/**
 * Completely remove all session variables and also wipe them from the local
 * storage
 */
export function deleteAllVars() {
    sessionVariables = {};
    window.localStorage.setItem('sessionVariables', JSON.stringify(sessionVariables));
}
